module.exports = `{
    "status": 200,
    "cost_of_insurance": [Float]
}`;

// ,
// {
//   "carrier_name": "UPSv2",
//   "service_name": "UPS NT Service",
//   "description": "-",
//   "service_key": "NT",
//   "price": "10",
//   "above_five_lbs_price": "12"
// }