export const walletHistoryTabs = (setFilterType, getUserPendingPayments) => [
    {
      type: "transaction-history",
      label: "Transaction History",
      onClick: () => {
        setFilterType("transaction-history");
      },
    },
    {
      type: "pending",
      label: "Pending Payments",
      onClick: async () => {
        await getUserPendingPayments();
        setFilterType("pending");
      },
    },
  ];

  export const settingsTabs =(setFilterType) => [
    {
      type:"stores",
      label:"Stores",
      onClick: () => {
        setFilterType("stores");
      },
    },
    // {
    //   type:"display-options",
    //   label:"Display Options",
    //   onClick: () => {
    //     setFilterType("display-options");
    //   },
    // },
    // {
    //   type:"packingSlip-templates",
    //   label:"Packing Slip templates",
    //   onClick: () => {
    //     setFilterType("packingSlip-templates");
    //   },
    // },
  ]