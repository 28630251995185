// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"


function TableInfoCreate(props) {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const [table, setTable] = useState(null);
  const [column, setColumn] = useState(null);
  const [values, setValues] = useState(null);
  const [description, setDescription] = useState(null);


  // const tableData = ;
  const handleAddTableInfo = async () => {
    try {
        const body = {
          table: table,
          column: column,
          values: values,
          description: description,
        };

        post('/admin/add-table-information', body, {headers}).then((response) => {
          if(response.status === 200) {
            toast.success(response.message);
            setTimeout(() => {
              props.history.push("/table-information");
            },2000)
          }
          else {
            toast.error(response.message);
          }
        });
    }
    catch (e) {
      // console.log(e)
    }
  }

  //meta title
  document.title = "Create Table's Information | NullShip";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Create Table's Information" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Table's Information</CardTitle>
                <div className="">
                  {responseSuccess && !responseSuccess ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {responseMessage}
                    </Alert>
                  ) : null}
                  {responseSuccess && responseSuccess ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {responseMessage}
                    </Alert>
                  ) : null}

                  <Form
                    className="form-horizontal"
                    onSubmit={async (e) => {
                      e.preventDefault();
                      await handleAddTableInfo();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Table</Label>
                      <Input
                        name="table"
                        className="form-control"
                        placeholder="Enter Table Name"
                        type="text"
                        required={true}
                        onChange={(e) => setTable(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Column</Label>
                      <Input
                        name="column"
                        className="form-control"
                        placeholder="Enter Column Name"
                        type="text"
                        required={true}
                        onChange={(e) => setColumn(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Values</Label>
                      <Input
                        name="values"
                        className="form-control"
                        placeholder="Enter Values"
                        type="text"
                        required={true}
                        onChange={(e) => setValues(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        className="form-control"
                        placeholder="Enter Description"
                        type="textarea"
                        required={true}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/table-information') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
TableInfoCreate.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default TableInfoCreate;