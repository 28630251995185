import React from "react"
import PropTypes from "prop-types"

const StatusTable = ({ data }) => {
  return (
    <>
      <br />
      <h5>Expected Status Codes</h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="p-2">Status Code</th>
            <th className="p-2">Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="p-2">{row.code}</td>
              <td className="p-2">{row.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />
      <br />
      <br />
    </>
  )
}

StatusTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default StatusTable
