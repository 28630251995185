import React, {lazy, useEffect, useState, createRef} from "react";
import {toast} from "react-hot-toast";
import axios from "axios";
import {useLocation, useNavigate} from "react-router";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import CardInputElement from './cardElement';
import moment from "moment-timezone";
import config from '../../../../config';
import { post } from "../../../../helpers/api_helper"
import { doc } from "prettier"

let transactionInProcess = false;
function Payment(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState(props.amount);

  const [loading, setLoading] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const handleAddBalance = async (balance) => {
    return new Promise(async (resolve) => {
      const id = JSON.parse(localStorage.getItem("authUser")).id;
      const body = {
        id,
        balance: balance,
        payment_mode: 'Debit or Credit Card'
      };
      await post('/admin/user/add-balance', body, { headers }).then(async (response) => {
        resolve(response.status)
      });
    })
  }

  const handleSubmit = async (event) => {
    if(!transactionInProcess) {
      transactionInProcess = true;
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      document.body.style.pointerEvents = "none";
      let loading = toast.loading("Processing Payment...")

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/stripe/check-create-customer', body, {headers}).then(async (response) => {
        if (response.status == 200) {
          // setStripeCustomerId(response.customer_id);
          await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
              email: JSON.parse(localStorage.getItem("authUser")).email,
              name: JSON.parse(localStorage.getItem("authUser")).first_name + " " + JSON.parse(localStorage.getItem("authUser")).last_name,
            },
          }).then(async (result) => {
            if (result.error) {
              transactionInProcess = false;
              toast.remove(loading);
              document.body.style.pointerEvents = "auto";
              toast.error(result.error.message);
            }
            else {
              let intentBody = {
                amount: amount,
                customer_id: response.customer_id,
                payment_method_id: result.paymentMethod.id
              }
              post('/stripe/payment-intent', intentBody, {headers}).then((responseIntent) => {
                if (responseIntent.status == 200) {
                  stripe.confirmCardPayment(responseIntent.client_secret).then(async function(resultConfirm) {
                    if (resultConfirm.error) {
                      // Show error to your customer (for example, insufficient funds)
                      transactionInProcess = false;
                      toast.remove(loading);
                      document.body.style.pointerEvents = "auto";
                      toast.error(resultConfirm.error.message);
                    }
                    else {
                      // The payment has been processed!
                      toast.remove(loading);
                      document.body.style.pointerEvents = "auto";
                      if (resultConfirm.paymentIntent.status === 'succeeded') {
                        toast.success("Payment processed successfully");
                        const addBalance = await handleAddBalance(amount);
                        if(addBalance == 200) {
                          transactionInProcess = false;
                          console.log("seeting closeModal close")
                          props.closeModal("close");
                        }
                        // Show a success message to your customer
                        // There's a risk of the customer closing the window before callback
                        // execution. Set up a webhook or plugin to listen for the
                        // payment_intent.succeeded event that handles any business critical
                        // post-payment actions.
                      }
                      else {
                        transactionInProcess = false;
                      }
                    }
                  });
                }
              }).catch((e) => {
                transactionInProcess = false;
                toast.remove(loading);
                document.body.style.pointerEvents = "auto";
                toast.error("Something went wrong")
              });
            }
          })
        }
      }).catch((e) => {
        // console.log("error", e)
        transactionInProcess = false;
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Something went wrong")
      })
    }
  };

  return (
    <div>
      <h6>Amount: ${parseFloat(amount).toFixed(2)}</h6>
      {!loading?
        <div className={"col-md-12 border border-2 rounded w-full py-3"} style={{paddingLeft:"7px"}}>
          <CardInputElement />
        </div>:"Loading..."
      }
      <br/>
      <button style={{float:"right"}} disabled={transactionInProcess} onClick={handleSubmit} className={"btn btn-success"}>Process Payment</button>
      <br/>
      <br/>
    </div>
  );
}

export default Payment;