import React, { useEffect, useRef, useState } from "react"

function CustomFilters(props) {
  const componentRef = useRef(null)
  const [options, setOptions] = useState(props.options);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(props.title=="Store"?localStorage.getItem("store"):(props.title=="Order Date"?props.selectedValue:null));

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
     setOptions(props.options);
  },[props.options])

  useEffect(() => {
    // console.log("props title",props.title)
    // console.log("props option",props.options)
  // console.log("props.selected",props.selectedValue);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (data) => {
    setVisible(false);
    setSelected(data.value);
    props.change({target:data});
  }
  // bx-check
  return (<>
    <div className={"custom-filter "+ props.class} ref={componentRef}>
      <span onClick={() => setVisible(!visible)}><span style={{color:selected?(selected!="none"?"#ff4800":localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"):localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"}}>{props.title}</span> <i className={`bx bx-${visible?"chevron-up":"chevron-down"} font-size-16`}></i></span>
      <ul className={"custom-filter-options"} style={{display: (visible?"block":"none"),backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
        {options.length>0?
          options.map((el,index) => {
            return (<li title={el.name} key={index} style={{color:(el.value=="none"?(selected?selected=="none"?"gray":"#FF6969":"gray"):localStorage.getItem("theme") == "dark"?"#a6b0c4":"black")}} onClick={() => {
              if(el.value=="none") {
                if(selected) {
                  handleSelect(el)
                }
              }
              else {
                handleSelect(el)
              }
            }}>{selected && selected != "none" ?selected == el.value?<i style={{float:"right"}} className={"bx bx-check"}></i>:"":""}<div className={"name"}>{el.name}</div></li>)
          })
          :<li>Loading...</li>}
      </ul>
    </div>
  </>);
}

export default CustomFilters;