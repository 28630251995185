import React, { useEffect, useState } from "react"
import "../stylesheet.css"
import { RiSecurePaymentFill } from "react-icons/ri"
import { RiFundsFill } from "react-icons/ri"
import { IoIosAddCircleOutline } from "react-icons/io"
import AddFundIcon from "../../../../assets/images/AddFunds.svg"
import CoinbaseIcon from "../../../../assets/images/coinbaseicon.png"
import MetamaskIcon from "../../../../assets/images/metamaskicon.png"
import BankTransferIcon from "../../../../assets/images/BankTransfer.svg"
import ZelleIcon from "../../../../assets/images/zelle.png"
import CurrencyDropdown from "../../../../components/Dropdown/CurrencyDropdown"
import AddFundDetails from "./AddFundDetails"
import DataTable from "react-data-table-component"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { post, get } from "../../../../helpers/api_helper"
import customStyles from "../../../../assets/css/customTableStyle"
import toast from "react-hot-toast"
import customDarkStyles from "assets/css/customTableDarkStyle"
import { MdCorporateFare, MdOutlineDateRange } from "react-icons/md"
import { FaUserCheck } from "react-icons/fa"
import { BsBank2 } from "react-icons/bs"
import config from "config"
import { TickCircle } from "iconsax-react"
const AddFunds = ({ btnClick }) => {
  const [active, setActive] = useState({
    paymentMethod: true,
    fundsValue: false,
    confirmation: false,
  })

  const [selectedMethod, setSelectedMethod] = useState("")
  const [confirmedMethod, setConfirmedMethod] = useState("")
  const [plaidAccounts, setPlaidAccount] = useState([])
  const [plaidModal, setPlaidModal] = useState(false)
  const [plaidAmount, setPlaidAmount] = useState(null)
  const [openTransferWithPlaidModal, setOpenTransferWithPlaidModal] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [plaidDetails, setPlaidDetails] = useState(null)
  const [details, setDetails] = useState(null)
  const [code, setCode] = useState("")
  const [showPaid, setShowPaid] = useState(false)
  const handleClick = key => { setActive(prevState => ({ ...prevState, [key]: !prevState[key], })) }

  const handleSelection = method => {
    console.log("method", method)
    setSelectedMethod(method)
  }
  const handlePlaidDefault = e => {
    const { value } = e.target
    console.log("value", value)
    try {
      const body = {
        id: value,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/user/set-default-plaid", body, { headers }).then(
        async response => {
          if (response.status == 200) {
            await retrievePlaidAccounts()
            toast.success(response.message)
          } else {
            toast.error(response.message)
          }
        }
      )
    } catch (e) {
      console.log("error", e)
    }
  }
  const handleDisconnectPlaid = id => {
    try {
      if (confirm("Are you sure! you want to disconnect this plaid account?")) {
        const body = {
          id,
        }
        post("/user/disconnect-plaid", body, { headers }).then(
          async response => {
            if (response.status == 200) {
              await retrievePlaidAccounts()
              toast.success(response.message)
            } else {
              toast.error(response.message)
            }
          }
        )
      }
    } catch (e) {
      console.log("error", e)
    }
  }
  const retrieveBalance = async () => {
    try {
      const body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/user/get-wallet-history", body, { headers }).then(response => {
        let sorted = []
        if (response.status == 200) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].batch_id) {
              let found = sorted.some(
                el => el.batch_id == response.data[i].batch_id
              )
              if (!found) {
                if (response.data[i].type == "credit") {
                  sorted.push({
                    payment_mode: response.data[i].payment_mode,
                    batch_id: response.data[i].batch_id,
                    debit_amount: 0,
                    credit_amount: response.data[i].total_amount,
                    created_at: response.data[i].created_at,
                    success_orders: 0,
                    failed_orders:
                      response.data[i].payment_mode != "Order Refund"
                        ? response.data[i].order_ids
                          ? response.data[i].order_ids.split(",").length
                          : 0
                        : "-",
                  })
                } else if (response.data[i].type == "debit") {
                  sorted.push({
                    payment_mode: response.data[i].payment_mode,
                    batch_id: response.data[i].batch_id,
                    debit_amount: response.data[i].total_amount,
                    credit_amount: 0,
                    created_at: response.data[i].created_at,
                    success_orders: response.data[i].order_ids
                      ? response.data[i].order_ids.split(",").length
                      : 0,
                    failed_orders: 0,
                  })
                }
              } else {
                let index = sorted.findIndex(
                  el => el.batch_id == response.data[i].batch_id
                )
                if (response.data[i].type == "credit") {
                  console.log(
                    "response.data[i].created_at",
                    response.data[i].created_at
                  )
                  console.log(
                    "response.data[i].order_ids",
                    response.data[i].order_ids
                  )
                  sorted[index].credit_amount = response.data[i].total_amount
                  sorted[index].debit_amount =
                    parseFloat(sorted[index].debit_amount) -
                    parseFloat(response.data[i].total_amount)
                  sorted[index].failed_orders =
                    parseInt(sorted[index].success_orders) -
                    (response.data[i].order_ids
                      ? response.data[i].order_ids.split(",").length
                      : 0)
                }
                if (response.data[i].type == "debit") {
                  sorted[index].debit_amount = parseFloat(
                    response.data[i].total_amount
                  )
                  sorted[index].success_orders =
                    response.data[i].order_ids.split(",").length -
                    parseInt(sorted[index].failed_orders)
                }
              }
            }
          }
        }
        let data = sorted
      })
    } catch (e) {
      console.log("Er", e)
    }
  }

  const transferWithPlaid = async event => {
    let loading = toast.loading("Processing transaction")
    console.log(plaidAmount)
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    console.log(authUser.id)
    const body = {
      user_id: authUser.id,
      amount: plaidAmount,
    }

    await post("/user/plaid-transfer-request-user", body, { headers }).then(
      async response => {
        console.log("plaid transfer", response)
        toast.remove(loading)
        if (response.status === 200) {
          toast.success(response.message)
          setPlaidAmount(null)
          setPlaidDetails(null)
          setOpenTransferWithPlaidModal(false)
          btnClick()
        } else {
          toast.error(response.message)
        }
      }
    )
  }

  const plaid_columns = [
    {
      name: "Account Name",
      // width: "130px",
      selector: row => {
        const accountName = JSON.parse(row.accounts).official_name;
        return row.is_default === 1 ? `${accountName} (Default)` : accountName;
      },
    },
    {
      name: "Actions",
      width: "130px",
      selector: row =>
        row.is_default == 0 || 1 ? (
          <button
            className={"btn btn-sm btn-danger"}
            onClick={() => handleDisconnectPlaid(row.id)}
            style={{
              borderRadius:"5px"
            }}
          >
            Disconnect
          </button>
        ) : (
          "-"
        ),
    },
  ]
  const handleProceed = () => {
    if (selectedMethod === "BankTransfer") {
      const filteredPlaidAccounts = plaidAccounts?.filter(
        account => account.is_default === 1
      )
      if (filteredPlaidAccounts?.length === 0) {
        toast.error("Connect an account to continue")
        setConfirmedMethod("")
        setActive({
          paymentMethod: true,
          fundsValue: false,
          confirmation: false,
        })
        return
      } else {
        setPlaidDetails(JSON.parse(filteredPlaidAccounts[0]?.accounts))
        setConfirmedMethod(selectedMethod)
      }
    }
    else {
      setConfirmedMethod(selectedMethod)
    }
  }
  const retrievePlaidAccounts = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
    }
    await post("/user/get-plaid-accounts", body, { headers }).then(response => {
      console.log("response", response)
      if (response.status === 200) {
        setPlaidAccount(response.data)
      } else {
        toast.error(response.message)
      }
    })
  }
  const handlePlaid = async () => {
    // await retrievePlaidAccounts()
    setPlaidModal(true)
  }
  let isPlaidProcessing = false
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }
  const handlePayWithPlaid = async () => {
    if (!isPlaidProcessing) {
      isPlaidProcessing = true
      let loading = toast.loading("Processing Plaid")
      const id = JSON.parse(localStorage.getItem("authUser")).id
      const body = {
        id,
      }
      await post("/user/generate-plaid-token", body, { headers }).then(
        async response => {
          console.log("res", response)
          if (response.status === 200) {
            const handler = Plaid.create({
              token: response.code.link_token,
              onSuccess: async (publicToken, metadata) => {
                isPlaidProcessing = false
                // console.log(`ONSUCCESS: Metadata ${JSON.stringify(metadata)}`);
                // console.log(
                //   `I have a public token: ${publicToken} I should exchange this`
                // );
                const body = {
                  public_token: publicToken,
                  user_id: id,
                }
                toast.remove(loading)
                await post("/user/plaid-exchange-token", body, {
                  headers,
                }).then(async response => {
                  console.log("res", response)
                  if (response.status === 200) {
                    await retrievePlaidAccounts()
                    toast.success(response.message)
                  } else {
                    toast.error(response.message)
                  }
                })
                //await exchangeToken(publicToken);
              },
              onExit: (err, metadata) => {
                toast.remove(loading)
                isPlaidProcessing = false
                console.log(
                  `Exited early. Error: ${JSON.stringify(
                    err
                  )} Metadata: ${JSON.stringify(metadata)}`
                )
              },
              onEvent: (eventName, metadata) => {
                toast.remove(loading)
                isPlaidProcessing = false
                console.log(
                  `Event ${eventName}, Metadata: ${JSON.stringify(metadata)}`
                )
              },
            })
            handler.open()
            await retrieveBalance()
            // toast.success(response.message);
          } else {
            toast.error(response.message)
          }
        }
      )
    }
  }
  const retrievePaymentOptions = async () => {
    try {
      const response = await get("/user/get-active-payment-options", {
        headers,
      })
      console.log("options", response)
      if (response.status === 200) {
        setPaymentOptions(response.data)
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      console.error("Error retrieving payment options:", error)
    }
  }
  const addManualPayment = async payment_option_id => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        payment_option_id: details.id,
      }
      post("/user/add-manual-payment", body, { headers }).then(response => {
        if (response.status === 200) {
          setCode(response.code)
        } else {
          toast.error(response.error)
        }
      })
    } catch (e) {
      console.log("e", e)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await retrievePlaidAccounts()
        await retrieveBalance()
        await retrievePaymentOptions()
      } catch (error) {
        console.error("Error retrieving Plaid accounts:", error)
      }
    }
    fetchData()
  }, [])
  const renderMethodValues = () => {
    console.log(confirmedMethod, 'confirmedMethod')
    switch (confirmedMethod) {
      case "BankTransfer":
        return {
          subtitle: "How much do you want to add to your account?",
          component: (
            <div>
              <CurrencyDropdown
                value={plaidAmount}
                onChange={e => setPlaidAmount(e.target.value)}
              />
              <div className="actions">
                <button className="cancel-button" onClick={() => { setSelectedMethod(""); setConfirmedMethod(""); setActive({ paymentMethod: true, fundsValue: false, confirmation: false, }) }}>Cancel</button>
                <button
                  className="btn btn-primary"
                  disabled={!plaidAmount}
                  style={{
                    borderRadius:"5px"
                  }}
                  onClick={() => {
                    if (plaidAmount != 0) {
                      setOpenTransferWithPlaidModal(!openTransferWithPlaidModal)
                    } else {
                      toast.error("Kindly enter an amount greater than 0.")
                    }
                    setActive({
                      paymentMethod: false,
                      fundsValue: false,
                      confirmation: true,
                    })
                  }}
                >
                  Add Funds
                </button>
              </div>
            </div>
          ),
        }
      case "":
        return {
          subtitle: "Pick the payment method that works best for you",
          component: (
            <>
              <div className="payment-methods">
                <div
                  style={{
                    background: "#fff",
                    padding: "8px",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    cursor: "pointer",
                    transition: "border 0.3s, box-shadow 0.3s",
                    display: "flex",
                    gap: "5px",
                    // flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border:
                      selectedMethod === "BankTransfer"
                        ? "2px solid #007bff"
                        : "1px solid #ddd",
                    boxShadow:
                      selectedMethod === "BankTransfer"
                        ? "0 0 10px rgba(0, 123, 255, 0.2)"
                        : "",
                  }}
                  onClick={() => {
                    handleSelection("BankTransfer")
                    if (plaidAccounts.length === 0) {
                      handlePlaid()
                    }
                  }}
                >
                  <div className="d-flex align-items-center gap-3">
                  <img src={BankTransferIcon} alt="CoinbaseIcon" />
                  <div className="d-flex align-items-center flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        className={`plaidMethodBank ${
                          selectedMethod === "BankTransfer" ? "selected" : ""
                        }`}
                      >
                        <div className="method-text-wrap">
                          <span className="transferTypeHeadings">Bank Transfer</span>
                          <p style={{ margin: 0 }} className="transferTypeText">
                            Use Plaid to link your bank account for transfers.
                          </p>
                        </div>
                      </div>
                    </div>
                      {plaidAccounts?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value={item.id}
                            defaultChecked={item.is_default}
                            checked={item.is_default}
                            onChange={handlePlaidDefault}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {JSON.parse(item.accounts).official_name}
                          </div>
                        </div>
                      ))}
                  </div>
                  </div>


                  <button onClick={handlePlaid} className="btn btn-primary" style={{
                    borderRadius:"5px"
                  }}>
                    {plaidAccounts?.length === 0
                      ? "Connect Account"
                      : "Add/Revoke Account"}
                  </button>
                </div>
                {paymentOptions.length ? paymentOptions.map((el, index) => (
                  <div key={index} className={`method ${selectedMethod === `${el.name}` ? "selected" : ""}`} onClick={() => { handleSelection(el.name); setDetails(el) }}>
                    <img src={config.site.BASE_URL_FOR_IMAGES + "payment-option/" + el.image_url} alt={el.name} height={"40px"}/>
                    <div className="method-text-wrap">
                      <span className="transferTypeHeadings">{el.name}</span>
                      <p className="transferTypeText">Add funds using your {el.name} account.</p>
                    </div>
                  </div>
                ))
                  : "No Payment Option Found"}
              </div>
              <div className="actions">
                <button className="cancel-button" onClick={() => { setSelectedMethod(""); setConfirmedMethod(""); btnClick() }}> Cancel</button>
                <button
                  className="btn btn-primary"
                  style={{
                    borderRadius:"5px",
                    background:selectedMethod == ""|| null ? "none":"",
                    color:selectedMethod == ""|| null ? "#CACFD8":"white",
                    border:"none"
                  }}
                  disabled={!selectedMethod}
                  onClick={async () => {
                    handleProceed()
                    if (selectedMethod != "BankTransfer") { await addManualPayment() }
                    setActive({ paymentMethod: false, fundsValue: true, confirmation: false, })
                  }}
                >
                  Proceed to add funds
                </button>
              </div>
            </>
          ),
        }
      default:
        return {
          subtitle: "How much do you want to add to your account?",
          component: (
            <div>
              <AddFundDetails details={details} code={code} />
              <div className="actions">
                <button className="cancel-button" onClick={() => { setSelectedMethod(""); setConfirmedMethod(""); setActive({ paymentMethod: true, fundsValue: false, confirmation: false, }) }}>Cancel</button>
                <button className="ipaid-button" onClick={() => { setShowPaid(true); setSelectedMethod(""); setConfirmedMethod(""); setActive({ paymentMethod: false, fundsValue: false, confirmation: true, }) }}>I Paid</button>
                {/* <button
                  className="btn btn-primary"
                  disabled={!plaidAmount}
                  onClick={() => {
                    if (plaidAmount != 0) {
                      setOpenTransferWithPlaidModal(!openTransferWithPlaidModal)
                    } else {
                      toast.error("Kindly enter an amount greater than 0.")
                    }
                  }}
                >
                  Add Funds
                </button> */}
              </div>
            </div>
          ),
        }
    }
  }

  return (
    <>
      <div className="addFundSmBoxMain">
        <div className="title">Account Funds</div>
        <div className="fund-options">
          <button className={`fund-options-item ${active.paymentMethod ? "active" : ""}`} >
            <RiSecurePaymentFill style={{ color: active.paymentMethod?"blue":"#525866 "}} /> Payment method
          </button>
          <button className={`fund-options-item ${active.fundsValue ? "active" : ""}`} >
            <RiFundsFill style={{ color: active.fundsValue?"blue":"#525866 " }} />
            Funds value
          </button>
          {/* onClick={() => handleClick("confirmation")} */}
          <button className={`fund-options-item ${active.confirmation ? "active" : ""}`}>
            <IoIosAddCircleOutline style={{ color: active.confirmation?"blue":"#525866" }} />
            Confirmation
          </button>
        </div>
      </div>

      {
        !showPaid ?
          <>
            <div className="add-funds-container">
              <img src={AddFundIcon} alt="AddFundIcon" />
              <h2>Add funds</h2>
              <p>{renderMethodValues().subtitle}</p>
              {renderMethodValues().component}
            </div>
            <Modal isOpen={plaidModal} role="dialog" autoFocus={true} centered={true} className="exampleModal modal-lg" tabIndex="-1" toggle={() => { setPlaidModal(!plaidModal) }}>
              <div>
                <ModalHeader toggle={() => { setPlaidModal(!plaidModal) }}>
                  <h5>Connected Accounts</h5>
                </ModalHeader>
                <ModalBody>
                  {plaidAccounts.length == 0 ? (
                    <>
                      <p>No Account Connected</p>
                    </>
                  ) : ("")}
                  <button className={"btn btn-success"} style={{ float: "right",borderRadius:"5px" }} onClick={handlePayWithPlaid}>Connect Plaid</button>
                  <br />
                  <br />
                  {plaidAccounts.length > 0 ? (
                    <>
                      <DataTable
                        columns={plaid_columns}
                        data={plaidAccounts}
                        pagination={30}
                        progressComponent={
                          <h4 className={"mt-5 mb-5 text-center"}>
                            Loading data, this may take a few moments...
                          </h4>
                        }
                        paginationPerPage={30}
                        paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                        highlightOnHover={true}
                        theme={
                          localStorage.getItem("theme") == "dark" ? "solarized" : ""
                        }
                        customStyles={
                          localStorage.getItem("theme") == "dark"
                            ? customDarkStyles
                            : customStyles
                        }
                      />
                    </>
                  ) : (
                    ""
                  )}
                </ModalBody>
              </div>
            </Modal>
            <Modal
              isOpen={openTransferWithPlaidModal}
              role="dialog"
              autoFocus={true}
              centered={true}
              className="exampleModal modal-md"
              tabIndex="-1"
              toggle={() => {
                setOpenTransferWithPlaidModal(!openTransferWithPlaidModal)
              }}
              onClosed={() => {
                setPlaidAmount(null)
              }}
            >
              <div>
                <ModalHeader
                  toggle={() => {
                    setOpenTransferWithPlaidModal(!openTransferWithPlaidModal)
                  }}
                >
                  Transaction Details
                </ModalHeader>
                <ModalBody>
                  <div>
                    <p className="m-0">Amount</p>
                    <p
                      className="fw-bold"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      $ {plaidAmount}.00
                    </p>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center mb-3 gap-2">
                    <FaUserCheck fontSize={24} />
                    <p className="m-0">
                      {plaidDetails?.official_name} - {plaidDetails?.mask}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-2">
                    <MdCorporateFare fontSize={24} />
                    <p className="m-0">NullShip LLC.</p>
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-2">
                    <MdOutlineDateRange fontSize={24} />
                    <p className="m-0">
                      {new Date().toLocaleDateString()}{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        (Date of debit)
                      </span>
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-2">
                    <BsBank2 fontSize={24} />
                    <p className="m-0">ACH Debit</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column gap-3">
                    <p
                      className="text-align: center"
                      style={{
                        margin: 0,
                      }}
                    >
                      By continuing, you authorize NullShip to debit your account.
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      You can revoke your connected Plaid accounts at any time using
                      the{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Add/Revoke Account
                      </span>{" "}
                      option available on the Add Funds page.
                    </p>
                    <button className="btn btn-primary" onClick={transferWithPlaid} style={{
                      borderRadius:"5px"
                    }}>
                      Pay Now
                    </button>
                  </div>
                </ModalBody>
              </div>
            </Modal>

          </>
          :
          <div className="confirm-container">
            <img src={AddFundIcon} alt="AddFundIcon" />
            <h2>Funds added</h2>
            {/* <p>{renderMethodValues().subtitle}</p> */}

            <div className="white">
              <TickCircle variant="Bold" color={"#43D082"} style={{ width: "7rem", height: "7rem" }} />
              <p className="text">Funds will be credited soon, once payment is checked</p>
              <button className="close-confirm-button" onClick={() => { setSelectedMethod(""); setConfirmedMethod(""); btnClick() }}>Close</button>
            </div>
          </div>


      }


    </>
  )
}

export default AddFunds
