import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Admin imports
import Users from "../pages/Admin/users/index";
import UserBalanceHistory from "../pages/Admin/users/balanceHistory";
import Service from "../pages/Admin/services/index";
import AddService from "../pages/Admin/services/create";
import CarrierIds from "../pages/Admin/carrier_ids/index";
import UserOrders from "../pages/Admin/orders/index";
import UserOrderErrors from "../pages/Admin/orders/errors";
import UserOrderSuccess from "../pages/Admin/orders/success";
import Discounts from "../pages/Admin/discounts/index";
import AddDiscount from "../pages/Admin/discounts/create";
import ApiSettings from "../pages/Admin/settings/index";
import DHLOrders from "../pages/Admin/dhl/index";
import USPSOrders from "../pages/Admin/usps/index";

//User imports
import WalletHistory from "../pages/User/walletHistory/index";
import Orders from "../pages/User/orders/index";
import OrdersCompleted from "../pages/User/orders/completed";
import OrderEdit from "../pages/User/orders/edit";
import OrderCreate from "../pages/User/orders/create";

import UserTickets from "../pages/User/tickets/index";
import UserTicketGenerate from "../pages/User/tickets/create";

import Tickets from "../pages/Admin/tickets/index";
import Otp from '../pages/Authentication/otp';
import CreateBlogCategory from "../pages/Admin/blogCategories/create"
import BlogCategories from "../pages/Admin/blogCategories"
import Blogs from "../pages/Admin/blogs"
import CreateBlog from "../pages/Admin/blogs/create"
import Tracking from "../pages/User/tracking"
import EditBlog from "../pages/Admin/blogs/edit"
import CoinbasePayments from "../pages/Admin/coinbase"
import Subscribers from "../pages/Admin/subscribers"
import TrackingLogs from "../pages/User/tracking/trackingLogs"
import GoogleAddressValidationLogs from "../pages/Admin/googleAddressValidations"
import ShopifyStoreConnection from "../pages/User/shopify/connection"
import ShopifyStores from "../pages/User/shopify/stores"
import ShopifyOrders from "../pages/User/shopify"
import OrderTrackingLogs from "../pages/Admin/orders/OrderTrackingLogs"
import OrdersTrackingAdminView from "../pages/Admin/tracking"
import TrackingWebhookLogs from "../pages/Admin/orders/TrackingWebhookLogs"
import OrdersReporting from "../pages/User/reporting"
import CarriersLogs from "../pages/Admin/carrier_ids/carrierLogs"
import UserWalletLogs from "../pages/Admin/users/UserWalletLogs"
import TablesInfo from "../pages/Admin/tables_info"
import TableInfoCreate from "../pages/Admin/tables_info/create"
import ShopifyOtherOrders from "../pages/User/shopify/other"
import EasypostWebhookLogs from "../pages/Admin/logs/easypostWebhookLogs"
import login from "../store/auth/login/reducer"
import DownloadPdf from "../pages/Admin/downloadPdf"
import WalletHistoryOld from "../pages/User/walletHistory/indexOld"
import Notifications from "../pages/User/notifications"
import Carriers from "../pages/Admin/carriers"
import Keys from "../pages/User/api/keys"
import Documentation from "../pages/User/api/documentation"
import CardPaymentLogs from "../pages/Admin/logs/cardPaymentLogs"
import InsuranceLogs from "../pages/Admin/logs/insuranceLogs"
import EbayStores from "../pages/User/ebay/stores"
import EbayOrders from "../pages/User/ebay"
import Stores from "../pages/User/stores"
import AddressValidation from "../pages/User/addressValidation"
import ApiLogs from "../pages/Admin/logs/apiLogs"
import CancelledOrders from "../pages/User/orders/cancelled"
import OrderCancellation from "../pages/Admin/orders/cancellation"
import AwaitingShipment from "../pages/User/orders/index"
import OnHold from "../pages/User/orders/onHold"
import DuplicateTracking from "../pages/Admin/orders/duplicateTracking"
import StoresReport from "../pages/Admin/reports/storeReport"
import SendNotification from "../pages/Admin/notification/create"
import CronReport from "../pages/Admin/reports/cronReport"
import UserCronReport from "../pages/User/reporting/userCronLogs"
import PiclistCronReport from "../pages/User/reporting/picklistCronLogs"
import Referral from "../pages/User/referral"
import ReferralAdmin from "../pages/Admin/referral"
import AddUser from "../pages/Admin/referral/addUser"
import AddPayment from "../pages/Admin/referral/addPayment"
import AddPaymentMethod from "../pages/Admin/payments/addPaymentMethod"
import PaymentMethods from "../pages/Admin/payments/paymentMethods"
import PaymentRequests from "../pages/Admin/payments/paymentRequests"
import DisabledOrdersReport from "../pages/Admin/users/disabledOrdersReport"
import CompletedOrdersReport from "../pages/Admin/users/completedOrdersReport"
import RunningCronReport from "../pages/Admin/reports/runningCronReport"
import ApprovedCancelledOrders from "../pages/Admin/orders/approvedCancelledOrders"
import RejectedCancelledOrders from "../pages/Admin/orders/rejectedCancelledOrders"
import EasypostRefundStatus from "../pages/Admin/orders/easpostRefundedOrders"
import TopLabelGenerators from "../pages/Admin/reports/topLabelGenerators"
import PlaidPayments from "../pages/Admin/payments/plaidPayments"
import DailyReport from "../pages/User/reporting/dailyReport"
import TrackingReport from "../pages/User/reporting/trackingCostReport"
import Webhook from "../pages/User/api/webhook"

const authProtectedAdminRoutes = [
  //Admin routes
  // { path: "/*", component: Pages404},

  { path: "/dashboard", component: Dashboard },

  { path: '/users', component: Users},
  { path: '/user/balance-history/:id', component: UserBalanceHistory},
  { path: '/services', component:  Service},
  { path: '/service/add', component: AddService},
  { path: '/carrier-ids', component: CarrierIds},
  { path: '/carriers', component: Carriers},
  { path: '/user/orders', component: UserOrders},
  { path: '/cancellation-requests', component: OrderCancellation},
  { path: '/cancellation-approved', component: ApprovedCancelledOrders},
  { path: '/cancellation-rejected', component: RejectedCancelledOrders},
  { path: '/easypost-refund-status', component: EasypostRefundStatus},
  { path: '/order-errors', component: UserOrderErrors},
  { path: '/order-success', component: UserOrderSuccess},
  { path: '/discounts', component: Discounts},
  { path: '/discount/add', component: AddDiscount},
  { path: '/api/settings', component: ApiSettings},
  { path: '/dhl/orders', component: DHLOrders},
  { path: '/usps/orders', component: USPSOrders},
  { path: '/google-address-validation-logs', component: GoogleAddressValidationLogs},
  { path: '/tracking-creation-logs', component: OrderTrackingLogs},
  { path: '/user/orders-tracking', component: OrdersTrackingAdminView},
  { path: '/stores-connections', component: StoresReport},
  { path: '/completed-schedulers', component: CronReport},
  { path: '/running-schedulers', component: RunningCronReport},
  { path: '/top-label-generators', component: TopLabelGenerators},
  { path: '/tracking-webhook', component: TrackingWebhookLogs},
  { path: '/carrier-id-logs', component: CarriersLogs},
  { path: '/user-wallet-logs', component: UserWalletLogs},
  { path: '/easypost-webhook-logs', component: EasypostWebhookLogs},
  { path: '/card-payment-logs', component: CardPaymentLogs},
  { path: '/table-information', component: TablesInfo},
  { path: '/table-information/create', component: TableInfoCreate},
  { path: '/coinbase/payments', component: CoinbasePayments},
  { path: '/subscribers', component: Subscribers},
  { path: '/tickets', component: Tickets},
  { path: '/download-pdf', component: DownloadPdf},
  { path: '/insured-order-logs', component: InsuranceLogs},
  { path: '/duplicated-tracking-orders', component: DuplicateTracking},
  { path: '/send-notification', component: SendNotification},
  { path: '/referral', component: ReferralAdmin },
  { path: '/add-user', component: AddUser },
  { path: '/add-payment', component: AddPayment },
  { path: '/add-payment-method', component: AddPaymentMethod },
  { path: '/payment-methods', component: PaymentMethods },
  { path: '/payment-requests', component: PaymentRequests },
  { path: '/plaid-payments', component: PlaidPayments},


  // profile
  { path: "/profile", component: UserProfile },

  { path: '/api-order-logs', component: ApiLogs},
  { path: '/disabled-orders-report/:user_id', component: DisabledOrdersReport},
  { path: '/completed-orders-report/:user_id', component: CompletedOrdersReport},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authProtectedUserRoutes = [
  //User routes
  { path: "/dashboard", component: Dashboard },
  // //profile
  { path: "/profile", component: UserProfile },
  { path: '/orders/awaiting-shipment', component: AwaitingShipment},
  { path: '/orders/completed', component: OrdersCompleted},
  { path: '/orders/cancelled', component: CancelledOrders},
  { path: '/orders/on-hold', component: OnHold},
  { path: '/order/edit/:id', component: OrderEdit},
  { path: '/order/create', component: OrderCreate},
  { path: '/wallet-history', component: WalletHistory},
  { path: '/wallet-history-old', component: WalletHistoryOld},
  { path: '/orders/tracking', component: TrackingLogs},
  { path: '/shopify/stores', component: ShopifyStores},
  { path: '/shopify/awaiting-shipments', component: ShopifyOrders},
  { path: '/shopify/other/orders', component: ShopifyOtherOrders},
  { path: '/balance-report', component: OrdersReporting},
  { path: '/daily-report', component: DailyReport},
  { path: '/tracking-cost-report', component: TrackingReport},
  { path: '/notifications', component: Notifications},
  { path: '/api/keys', component: Keys},
  { path: '/api/webhook', component: Webhook},
  { path: '/api/documentation', component: Documentation},

  { path: '/ebay/stores', component: EbayStores},
  { path: '/ebay/awaiting-shipments', component: EbayOrders},

  { path: '/settings', component: Stores},
  { path: '/address-validation', component: AddressValidation},
  { path: '/scheduler-logs', component: UserCronReport},
  { path: '/picklist-scheduler-logs', component: PiclistCronReport},
  { path: '/referral', component: Referral },


  { path: '/my/tickets', component: UserTickets},
  { path: '/ticket/generate', component: UserTicketGenerate},
  { path: "/*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authProtectedBloggerRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },

  //Blogger routes
  { path: '/blog/categories', component: BlogCategories },
  { path: '/blog/category/add', component: CreateBlogCategory },

  { path: '/blogs', component: Blogs },
  { path: '/blog/add', component: CreateBlog },
  { path: '/blog/edit/:id', component: EditBlog },
  { path: "/*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
{ path: "/", component: Login },
{ path: '/track/:tracking_code', component: Tracking },
{ path: '/shopify/store/connection', component: ShopifyStoreConnection },
{ path: "/logout", component: Logout },
{ path: "/login", component: Login },
{ path: "/otp/:token", component: Otp },
{ path: "/forgot-password", component: ForgetPwd },
{ path: "/reset-password/:email/:token", component: ResetPassword },
{ path: "/register", component: Register },
]

export { authProtectedAdminRoutes, authProtectedUserRoutes, authProtectedBloggerRoutes, publicRoutes }