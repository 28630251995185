// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"

function AddPayment(props) {

  // Page Title
  document.title = "Add Payment | NullShip";

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [emailList, setEmailList] = useState([]);
  const [userEmails, setUserEmails] = useState("")
  const [paymentMode, setPaymentMode] = useState("");
  const [totalOrders, setTotalOrders] = useState("");
  const [amount, setAmount] = useState("");

  // Get User Email from API
  const retrieveUser = () => {
    try {
      get('/admin/get-referrer-emails', {headers}).then((response) => {
        console.log("Get Referrer Emails", response);
        let arr = [];
        for(let i=0; i<response.data.length; i++){
          arr.push({value: response.data[i].refer_by_user_id, label:response.data[i].refer_by_email})
        }
        setEmailList(arr)
      }).catch(e => console.log("ee",e))
    }
    catch (e) {
      // // console.log(e)
    }
  }

  // Email Select 
  const handleEmailSelection = (data) => {
    setUserEmails(data)
  }

  // Add Payment
  const handleAddPayment = (e) => {
    e.preventDefault();
    const body = {
      paid_by: JSON.parse(localStorage.getItem("authUser")).id,
      user_id: userEmails.value,
      payment_mode: paymentMode,
      order_count: totalOrders,
      amount: amount
    }
    console.log("Add Payment Data", body)

    post('/admin/add-referrer-payment', body, {headers}).then((response) => {
      // console.log("Add Payment Data", response)
      if (response.status === 200) {
          toast.success(response.message)
          setTimeout(() => {
            props.history.push('/referral')
          }, 1500)
      } else {
        toast.error(response.message)
      }
    })
  }


  useEffect(() => {
    retrieveUser();
  },[])
  

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Reference" breadcrumbItem="Add Payment" />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden-not">
              <CardBody>
                <div className="">
                  <Form className="form-horizontal">
                    <div className="mb-3">
                      <Label className="form-label">Email <span style={{color:"red"}}>*</span></Label>
                      <Select
                        options={emailList}
                        placeholder="Select Email"
                        value={userEmails}
                        onChange={value => handleEmailSelection(value)}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Payment Mode <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="paymentMode"
                        className="form-control non-arrow-number"
                        placeholder="Enter payment mode"
                        type="text"
                        value={paymentMode}
                        maxLength={50}
                        onChange={(e) => {setPaymentMode(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Total Labels <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="amount"
                        className="form-control non-arrow-number"
                        placeholder="Enter Labels Count"
                        type="number"
                        step="any"
                        value={totalOrders}
                        maxLength={50}
                        onChange={(e) => {setTotalOrders(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Amount(USD) <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="amount"
                        className="form-control non-arrow-number"
                        placeholder="Enter amount"
                        type="number"
                        step="any"
                        value={amount}
                        maxLength={50}
                        onChange={(e) => {setAmount(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <div>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                          onClick={(e) => {handleAddPayment(e)}}
                          disabled={(userEmails && amount && totalOrders && paymentMode) ? false : true}
                        >
                          Add Payment
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddPayment;