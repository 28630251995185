// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import OrderViewModal from "../../../components/OrderViewModal"
import customStyles from "../../../assets/css/customTableStyle"


function InsuranceLogs(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [viewModal, setViewModal] = useState(false);
  const [rowId, setRowId] = useState()

  const [pending, setPending] = useState(true);

  const columns = [
    {
      name: 'Order Id',
      selector: row => row.order_unique_id,
      sortable: true,
      width:"200px"
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Price',
      // selector: ['insurance_amount','discounted_price'],
      //  sortable: true,
      selector: row => row.discounted_price ? '$' + amountFormat(parseFloat(row.discounted_price) - parseFloat(row.insurance_amount?row.insurance_amount:0) ) : "$0"
    },
    {
      name: 'Cost of Insurance',
      // selector: 'insurance_amount',
      //  sortable: true,
      selector: row => row.insurance_amount ? '$' + amountFormat(row.insurance_amount) : "$0"
    },
    {
      name: 'Total',
      // selector: 'discounted_price',
      //  sortable: true,
      selector: row => row.discounted_price ? '$' + amountFormat(row.discounted_price) : "$0"
    },
    {
      name: 'Status',
      // selector: 'status',
      //  sortable: true,
      selector: (row) => <span className={"badge badge-success"}>Completed</span>
    },
    {
      name: 'Actions',
      // selector: 'id',
      //  sortable: true,
      selector: row => {
        return(
          <>
            <Button className={"btn btn-success btn-sm"} onClick={() => {
              setRowId(row.id)
              setViewModal(true)
            }} style={{ float: "right", fontSize: "13px", marginRight: "10px" }} title="View Order"><i className={"bx bx-file"}></i></Button>
          </>
        )
      }
    },
  ];

  const retrieveUsers = async () => {
    try {
      get('/admin/getUsers', {headers}).then((response) => {
      // console.log("getUsers", response);
        setUsers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  // const tableData = ;
  const retrieveInsuredOrdersLogs = async (e) => {
    try {
      setPending(true);
      let body = {
        user_id: e.target.value
      }
      post('/admin/get-insured-orders-logs', body, {headers}).then((response) => {
      // console.log("response", response);
        setData(response.data);
        let data = response.data;
      // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
    // console.log(e)
    }
  }

  useEffect(() => {
    // console.log("here");
    retrieveUsers();
    setPending(false);
  }, []);

  //meta title
  document.title = "Insured Order Logs | NullShip";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Insured Order Logs" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className={"col-md-4"}>
              <label>Select User</label>
              <select className={"form-select"} onChange={retrieveInsuredOrdersLogs}>
                <option disabled={true} selected={true}>Select a User</option>
                {users.length>0?
                  users.map((part, id) => (
                    <option value={part.id} key={id}>{part.email}</option>
                  ))
                  :""}
              </select>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Insured Order Logs...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={50} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={(data) => { setViewModal(data) }}
        />
      </div>
    </div>
  );
}
InsuranceLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default InsuranceLogs;