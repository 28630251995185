import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react"

import { connect } from "react-redux";
import { Row, Col, DropdownItem, Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback } from "reactstrap"
import { Link } from "react-router-dom";
import {socket} from "../../assets/js/initializeSocket";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// import logo from "../../assets/images/logo.svg";
import logo from "../../assets/images/nullship_christmas_logo.gif";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

import {Maximize2} from "iconsax-react"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import user1 from "../../assets/images/users/avatar-1.jpg"
import { post } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import SiderBarStoreDropDown from "./SiderBarStoreDropDown"
import { doc } from "prettier"
import ThemeMode from "./themeMode"
import { amountFormat } from "../../assets/js/numberFormatter"
import DataTable from "react-data-table-component"
import customDarkStyles from "../../assets/css/customTableDarkStyle"
import customStyles from "../../assets/css/customTableStyle"
import DataTableExtensions from "react-data-table-component-extensions"
const displayNames = [
  {
    "cron_name": "sellercloud_import_order",
    "display_name": "Order Sync / Update Scheduler"
  },
  {
    "cron_name": "sellercloud_disable_shipped_void_cancelled_order_cron",
    "display_name": "Order Status Update Scheduler"
  },
  {
    "cron_name": "sellercloud_picklist_update_cron",
    "display_name": "Picklist Update Scheduler"
  },
]

const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [menu, setMenu] = useState(false);
  const [stores, setStores] = useState([]);

  const [cronLogStore, setCronLogStore] = useState([]);
  const [cronLogData, setCronLogData] = useState([]);
  const [modal, setModal] = useState(false);

  const columns = [
    // {
    //   name: 'Warehouse ID - Store Name',
    //   selector: 'store_name',
    //   width: '250px',
    //   cell: (row) => (
    //     <span>
    //       {row.warehouse_id} - {row.store_name}
    //     </span>
    //   )
    // },
    // {
    //   name: 'Store Type',
    //   selector: 'store_type',
    //   width: '120px'
    // },
    {
      name: 'Auto Scheduler',
      selector: 'cron_name',
      minWidth: '300px',
      cell: (row) => (
        <span title={row.cron_name}>
          {row.cron_name}
        </span>
      )
    },
    {
      name: 'Upcoming Fetch Date Time',
      selector: 'start_date',
      sortable: true,
      width:"230px",
      cell: (row) => (
        row.cron_name == "sellercloud_picklist_update_cron"?"-":
          <span>
          {moment(new Date(row.start_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Scheduler Start Date Time',
      selector: 'created_at',
      sortable: true,
      width:"210px",
      cell: (row) => (
        <span>
          {moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Scheduler End Date Time',
      selector: 'end_date',
      sortable: true,
      width:"210px",
      cell: (row) => (
        row.end_date?
          <span>
          {moment(new Date(row.end_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>:"-"
      )
    },
    {
      name: 'Status',
      selector: 'running_status',
      width: "120px",
      cell: (row) => (
        row.running_status == 1?
          <span className={"badge badge-warning"}>Running</span>:(row.running_status == 2?<span className={"badge badge-danger"}>Aborted</span>:<span className={"badge badge-success"}>Completed</span>)
      )
    }
  ];

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const retrieveStores = async () => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      const response = await post('/user/getStores', body, { headers });
      setStores(response.data);
    } catch (error) {
      console.error("Error retrieving stores:", error);
    }
  };
  

  const handleSync = async (store_id, store_type) => {
    const endpoints = {
      shopify: '/shopify/import-shopify-orders',
      ebay: '/ebay/import-ebay-orders',
      walmart: '/walmart/import-walmart-orders',
      zventory: '/zenventory/import-zenventory-orders',
      sellercloud: '/sellercloud/import-sellercloud-orders',
      shipstation: '/shipstation/import-shipstation-orders',
      veeqo: '/veeqo/import-veeqo-orders',
      shiphero: '/shiphero/import-shiphero-orders',
      orderdesk: '/orderdesk/import-orderdesk-orders',

    };
  
    const endpoint = endpoints[store_type];
  
    if (!endpoint) {
      toast.error('Unsupported store type');
      return;
    }
  
    const body = {
      store_id: store_id,
      user_id: JSON.parse(localStorage.getItem('authUser')).id,
    };
  
    try {
      // Show loading state
      localStorage.setItem('syncing', '1');
      document.getElementById('sync-btn').classList.add('spin');
  
      const response = await post(endpoint, body, { headers });
  
      // Handle response
      document.body.style.pointerEvents = 'auto';
      document.getElementById('sync-btn').classList.remove('spin');
  
      if (response.status === 200) {
        toast.success(`${stores.find(el => el.id === store_id)?.name} orders imported successfully`);
        await retrieveStores();
        
        // Reload orders if necessary
        if (store_id == localStorage.getItem('store')) {
          if (document.title === 'Awaiting Shipment | NullShip' || document.title === 'On Hold Orders | NullShip') {
            socket.emit('reloadOrders', JSON.parse(localStorage.getItem('authUser')).id);
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error syncing orders:', error);
      toast.error('Something went wrong');
    } finally {
      localStorage.setItem('syncing', '0');
    }
  };
  

  const handleSyncLogs = async (user_id, store_id) => {
    try {
      setCronLogData([]);
      setCronLogStore("");
      let body = {
        user_id,
        store_id
      }
      post('/user/get-store-cron-job-logs', body, {headers}).then((response) => {
          if(response.status == 200) {
            for (let i=0;i<response.data.length;i++) {
              if(response.data[i].cron_name) {
                response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length>0?displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name:response.data[i].cron_name;
              }
            }
            setCronLogStore(response.store);
            setCronLogData(response.data);
            setModal(true);
          }
          else {
            toast.error(response.message);
          }
      })
    }
    catch (e) {

    }
  }

  useEffect(() => {
    if(JSON.parse(localStorage.getItem("authUser")).role_id == 2) {
      retrieveStores();
    }
  },[]);

  useEffect(() => {
    const internal = setInterval(() => {
      if(document.getElementById("sync-btn")) {
        if(localStorage.getItem("syncing") == "1") {
          document.getElementById("sync-btn").classList.add("spin");
          clearInterval(internal);
        }
        // else {
        //   document.getElementById("sync-btn").classList.remove("spin");
        // }
      }
    }, 100)
  }, [])

  useEffect(() => {
    async function storeConnected() {
      console.log("emit storeConnected")
      retrieveStores();
    }
    async function storeDisconnected() {
      console.log("emit storeDisconnected")
      retrieveStores();
    }

    socket.on('storeConnected', storeConnected);
    socket.on('storeDisconnected', storeDisconnected);

    return () => {
      socket.off('storeConnected', storeConnected);
      socket.off('storeDisconnected', storeDisconnected);
    };
  },[]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        {localStorage.getItem("admvu") && localStorage.getItem("admvu") == 1 ? <span className="login-as-admin">⚠ Login as Admin</span>:""}
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAnCAYAAACbgcH8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT4SURBVHgB7VhrbNtUFD5OspQ2a2kLZH2srUvXbUVb55Zqq4q6eY/yo6M01YSYeNWFLUICkVb8BGmJ+IGAH10EP8ZLtYQ0Jh5tJzQkNB4GxIh4tBGbxBiCmAppEo81RTSoHa13rlM7aeI4dpxmf/ZJR9f2Pba/e/yde881BRYgeWEUm+HEBRgCCvrxyKNzm4h+Aep14CFHUGAB0hFgwYamwIZErgIDdrTVoNEGV98MYfQdoHgchElYIm0UEoeknRABbQY8LGDkTZCnsrysHNbhyyhs1xamJJM10tJjqE+badKjOQ6UkB9A8mE9J1PykCPvSNPrasQHSBKUhlyRRTLmSHvlzzcIhYGIxlOvQSC1wxzpxzF6/6dE0AYcrN1ANOWiSfrBh58Ybt5Ey5qcuXxZPBeaEvbt7uLct1TI/acmzvAXw4Ko+Mu6t1uURDIkiCKzIEbZr16SJMKHoShKSCPdwuz3jL/9yoRyHvntdzj7yVeC99H7WeXat1Pnge3u5JeWlgKNjY2i+uCjGHUKjlkiL0EQXOCnjiPxBGEfNn40QjyQRpph2HLPoYNJq5wknhr/UDh86CBXU1O1Y4P7Vk9ZaSk03V5PekUcOV9XV6fqTpbQsvwCc5KRQJCnvTewTZBlsSFBYJM8eVOajkQinN1uH9PoEtEC9fX1vPpCQl5CyUi6S7py7whKYRISZGmIz0Ba9/I2yA9otLGZmZnPcGDkGKgT+BVehQG5HgGNqUuSP38ASqBNIUx0i0YiOw069Ysaae7oMNO+Y5sPdFC63kVXVNzMkuOysvWwqYnO5Jqudy9KRgKfvOiQeZhCKZxIDAbJcgCG8iEhjxdefHni3r4D2T6lih8v/gIdd7bquWjrHadMA7o1Rnorw9Jdne2snndH23Z2Y221nGDVVW7ApAQDECFF7ytklZWTA3PIWyIagYCSGaJpmmiZyJDMULnUJ7wDCgcWBxxZXFyMOp1OS1Wj4yHuKd/OjlbGiLPweYgkonw8H/sPE7FBlokZrMjCEhx9vXv9rdu35vSg76cumCadDzjeHT8z8NGnX9JGnJMTkWDLlia4HihkIspwu91QVFQEFpC3FbGgUGeP5BUxGp2DK7Ozp//6+5+G5CRNTkQFNjtuvVtboJBQSbc0Nx3r2X+XuiJ+HZqmXa5iJluSfvPdD1BoqKTHTr4/8tOvkdPKeSg0Fb6KbXdnuxrp1EQkqK+rhULjRiIWCmmRfvLpZz1dHW2jWs4Oh73cjqbVF8MVsqO9FVyuEtCDxUiTumUorfaIzcfK1zkddKa7lpeXNa8XF98E/87HspK2ALJRGMFyV8z5Xx7ZodhsNj8+xNReMIdIi2hDZBeuXLD8AxLJsyvJSRvxN0Fa3o4h2eOpHbqke3of4Pp69+0hxwsLi9E333on6H3kcL/D6UirCmtrNniQvKp3UgVubm5M2ygYJM1DXApRrU7devoId99o8uJy/sKlirqG6sFtd2wGI7j0s2h0d6NAgHh0BT0nXdIfnz23FytANapfTIcnr8xGJ91VlVlL2e7Onf2duxije86MUtDCmv5UX1mMVu2wNeQRRPNnkoIW8kL6Hg/ncd+WOfp393T7SkqKGQpf13Ngj0JagLhuw2ASlkk3MywTfP6Z6UZ6oyH/P/6ci+7u3kXI8pAj8hJp/3Mv+SsrKxqy+UVn5+Dkex8Ekv+45oJr2iGdx6uqHqAAAAAASUVORK5CYII="} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAnCAYAAACbgcH8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT4SURBVHgB7VhrbNtUFD5OspQ2a2kLZH2srUvXbUVb55Zqq4q6eY/yo6M01YSYeNWFLUICkVb8BGmJ+IGAH10EP8ZLtYQ0Jh5tJzQkNB4GxIh4tBGbxBiCmAppEo81RTSoHa13rlM7aeI4dpxmf/ZJR9f2Pba/e/yde881BRYgeWEUm+HEBRgCCvrxyKNzm4h+Aep14CFHUGAB0hFgwYamwIZErgIDdrTVoNEGV98MYfQdoHgchElYIm0UEoeknRABbQY8LGDkTZCnsrysHNbhyyhs1xamJJM10tJjqE+badKjOQ6UkB9A8mE9J1PykCPvSNPrasQHSBKUhlyRRTLmSHvlzzcIhYGIxlOvQSC1wxzpxzF6/6dE0AYcrN1ANOWiSfrBh58Ybt5Ey5qcuXxZPBeaEvbt7uLct1TI/acmzvAXw4Ko+Mu6t1uURDIkiCKzIEbZr16SJMKHoShKSCPdwuz3jL/9yoRyHvntdzj7yVeC99H7WeXat1Pnge3u5JeWlgKNjY2i+uCjGHUKjlkiL0EQXOCnjiPxBGEfNn40QjyQRpph2HLPoYNJq5wknhr/UDh86CBXU1O1Y4P7Vk9ZaSk03V5PekUcOV9XV6fqTpbQsvwCc5KRQJCnvTewTZBlsSFBYJM8eVOajkQinN1uH9PoEtEC9fX1vPpCQl5CyUi6S7py7whKYRISZGmIz0Ba9/I2yA9otLGZmZnPcGDkGKgT+BVehQG5HgGNqUuSP38ASqBNIUx0i0YiOw069Ysaae7oMNO+Y5sPdFC63kVXVNzMkuOysvWwqYnO5Jqudy9KRgKfvOiQeZhCKZxIDAbJcgCG8iEhjxdefHni3r4D2T6lih8v/gIdd7bquWjrHadMA7o1Rnorw9Jdne2snndH23Z2Y221nGDVVW7ApAQDECFF7ytklZWTA3PIWyIagYCSGaJpmmiZyJDMULnUJ7wDCgcWBxxZXFyMOp1OS1Wj4yHuKd/OjlbGiLPweYgkonw8H/sPE7FBlokZrMjCEhx9vXv9rdu35vSg76cumCadDzjeHT8z8NGnX9JGnJMTkWDLlia4HihkIspwu91QVFQEFpC3FbGgUGeP5BUxGp2DK7Ozp//6+5+G5CRNTkQFNjtuvVtboJBQSbc0Nx3r2X+XuiJ+HZqmXa5iJluSfvPdD1BoqKTHTr4/8tOvkdPKeSg0Fb6KbXdnuxrp1EQkqK+rhULjRiIWCmmRfvLpZz1dHW2jWs4Oh73cjqbVF8MVsqO9FVyuEtCDxUiTumUorfaIzcfK1zkddKa7lpeXNa8XF98E/87HspK2ALJRGMFyV8z5Xx7ZodhsNj8+xNReMIdIi2hDZBeuXLD8AxLJsyvJSRvxN0Fa3o4h2eOpHbqke3of4Pp69+0hxwsLi9E333on6H3kcL/D6UirCmtrNniQvKp3UgVubm5M2ygYJM1DXApRrU7devoId99o8uJy/sKlirqG6sFtd2wGI7j0s2h0d6NAgHh0BT0nXdIfnz23FytANapfTIcnr8xGJ91VlVlL2e7Onf2duxije86MUtDCmv5UX1mMVu2wNeQRRPNnkoIW8kL6Hg/ncd+WOfp393T7SkqKGQpf13Ngj0JagLhuw2ASlkk3MywTfP6Z6UZ6oyH/P/6ci+7u3kXI8pAj8hJp/3Mv+SsrKxqy+UVn5+Dkex8Ekv+45oJr2iGdx6uqHqAAAAAASUVORK5CYII="} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">

            {/*<SiderBarStoreDropDown/>*/}

            {JSON.parse(localStorage.getItem("authUser")).role_id == 2 ?<ThemeMode/>:""}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button type="button" onClick={() => {toggleFullscreen();}} className="btn header-item noti-icon " data-toggle="fullscreen">
               <i><Maximize2 size={23} /></i>
              </button>
            </div>
            {stores.length>0?
              <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
                  <span id={"sync-btn"} style={{fontSize:"25px",borderRadius:"9999px",padding: "1px 8px"}} className={"btn btn-light"}>
                    <i className="mdi mdi-sync" />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" style={{minWidth:"330px",maxHeight:"350px",overflow:"auto"}}>
                  {stores.map((el, id) => (
                    <div key={id}>
                      <DropdownItem style={{padding:"10px"}}>
                        {/*<button className={"btn btn-warning btn-sm"} onClick={async () => {*/}
                        {/*  await handleSyncLogs(el.user_id, el.id);*/}
                        {/*}} style={{marginLeft:"10px",float:"right",display: (el.store_type=="sellercloud"?"block":"none")}} title={"View Sync Logs"}><i className={"bx bx-list-ul"}></i></button>*/}
                        {el.store_type == 'ebay' ?
                          (moment().isBefore(moment(el.refresh_token_expires_in, "YYYY-MM-DD HH:mm:ss"))?<button className={"btn btn-success btn-sm"} onClick={async () => {
                              await handleSync(el.id, el.store_type)
                            }} title={"Sync"} style={{marginLeft:"10px",float:"right"}}>Sync</button>:<button title={"Store is expired! Please refresh the store."} className={"btn btn-danger btn-sm"} style={{marginLeft:"20px",float:"right"}}>Expired</button>):
                          <button title={"Sync"} className={"btn btn-success btn-sm"} onClick={async () => {
                            await handleSync(el.id, el.store_type)
                          }} style={{marginLeft:"10px",float:"right"}}>Sync</button>
                        }
                        <span>
                          <h6
                            // style={{marginTop:"5px"}}
                          >{el.name}</h6>
                          <p className={"text-secondary"} style={{fontSize:"12px",marginTop:"-5px"}}>{el.store_type.charAt(0).toUpperCase() + el.store_type.slice(1)} Store</p>
                          <p className={"text-secondary"} style={{fontSize:"12px",marginTop:"-15px",marginBottom:"0px"}}>{el.last_synced?"Last synced at "+moment(el.last_synced).utc(false).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"):"-"}</p>
                        </span>
                      </DropdownItem>
                      {id!=(stores.length-1)?<div className="dropdown-divider" />:""}
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
              :""}
              <NotificationDropdown />
              <ProfileMenu />
          </div>
        </div>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
          onClosed={() => {
            setCronLogData([]);
            setCronLogStore("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Order Logs ({moment(new Date()).tz(moment.tz.guess()).subtract(2, "days").format("MMM DD, YYYY")} - {moment(new Date()).tz(moment.tz.guess()).format("MMM DD, YYYY")})
            </ModalHeader>
            <ModalBody>
              <p><b>Store:</b> {cronLogStore}</p>
              <DataTable columns={columns} data={cronLogData} pagination={10} paginationPerPage={10} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
