// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

function StoresReport() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(false);

  const retrieveStoreReport= async () => {
    try {
      setPending(true)
      get('/admin/stores-report', {headers}).then((response) => {
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'Store Type',
      selector: row => row.store_type,
      // sortable: true,
    },
    {
      name: 'Connection Count',
      selector: row => row.store_count,
    }
  ];

  useEffect(() => {
    retrieveStoreReport();
  }, []);

  //meta title
  document.title = "Stores Connections | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Report" breadcrumbItem="Stores Connections" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Stores Connections...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
StoresReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default StoresReport;