// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import { saveAs } from 'file-saver';

import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

function OrderTrackingLogs() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [viewModal, setViewModal] = useState(false);
  const [pending, setPending] = useState(true);

  const [rowId, setRowId] = useState()

  const columns = [
    {
      name: 'User Email',
      selector: row => row.email,
    //  sortable: true,
      width: '180px'
    },
    {
      name: 'Type',
      // selector: 'type',
    //  sortable: true,
      width: '150px',
      selector: row => row.type.includes("success")?<span className={"badge badge-success"}>{row.type.toString().toUpperCase()}</span>:<span className={"badge badge-danger"}>{row.type.toString().toUpperCase()}</span>
    },
    {
      name: 'Request',
      // selector: 'request_data',
    //  sortable: true,
      // width: '300px',
      selector: row => <span onDoubleClick={() => handleDownloadError(row.request_data)} title={"Double click to download"}>{row.request_data}</span>
    },
    {
      name: 'Response',
      // selector: 'response_data',
    //  sortable: true,
      width: '300px',
      selector: row => <span onDoubleClick={() => handleDownloadError(row.response_data)} title={"Double click to download"}>{row.response_data.toString().substring(0,150)}...</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      width: '200px',
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      // selector: 'order_id',
      selector: (row) => row.order_id ? <><button className={"btn btn-success btn-sm"} onClick={() => {
        setRowId(row.order_id)
        setViewModal(true)
        // handleView(row.order_id)
      }}>View Order</button></> : "-"
    }
  ];

  const handleDownloadError = (error) => {
    const data = error.toString();
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    saveAs(blob, 'order-tracking-log-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
    toast.success("Order tracking log downloaded");
  }

  // const tableData = ;
  const retrieveOrderTrackingLogs = async () => {
    try {
      get('/admin/get-order-tracking-logs', { headers }).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    retrieveOrderTrackingLogs();
  }, []);


  //meta title
  document.title = "Tracking Creations Logs | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Logs" breadcrumbItem="Tracking Creations" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Order Tracking Logs...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Custom Modal*/}
        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />
        {/*Custom Modal*/}


      </div>
    </div>
  );
}
OrderTrackingLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default OrderTrackingLogs;