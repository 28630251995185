import React, {useState, useEffect} from 'react';
import Switch from "react-switch";

import loader from "../../assets/images/loader.gif"

function ThemeMode() {
  const [switch1, setswitch1] = useState(localStorage.getItem("theme")?(localStorage.getItem("theme")=="light"?false:true):false);
  const [themeMode, setThemeMode] = useState("light");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "light");
    }

    if(localStorage.getItem("theme") == "light") {
      setswitch1(false);
      if(document.getElementsByClassName("react-switch-handle")) {
        // console.log("div exist");
        document.getElementsByClassName("react-switch-handle")[0].style.backgroundColor = "#ffc403";
        document.getElementsByClassName("react-switch-handle")[0].innerHTML = '<i style="font-size: 16px;margin-top: 5px;margin-left: 5px;color:white" class="bx bx-sun"/>';
      }
    }
    else {
      setswitch1(true);
      if(document.getElementsByClassName("react-switch-handle")) {
        // console.log("div exist");
        document.getElementsByClassName("react-switch-handle")[0].style.backgroundColor = "#333";
        document.getElementsByClassName("react-switch-handle")[0].innerHTML = '<i style="font-size: 16px;margin-top: 5px;margin-left: 5px;color:white" class="bx bx-moon"/>';
      }
    }

    document.body.setAttribute("data-layout-mode", localStorage.getItem("theme"))
  })


  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        <i className={"bx bx-moon"}/>
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        <i className={"bx bx-sun"}/>
      </div>
    );
  };
  return(
    <>
      <div id='loader' className={"align-items-center justify-content-center"} style={{display:(loading?"flex":"none"),alignItems:"center",margin:"auto", position: "absolute",width:"100%",height:"100vh",backgroundColor:localStorage.getItem("theme")?(localStorage.getItem("theme")=="dark"?"#0f0f0f":"white"):"white",zIndex:"99999",top:"0",left:"0"}}>
        <img style={{width:"10%"}} src={loader}/>
      </div>
      <div className={"theme-toggle"}>
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          className="me-1 mb-sm-8 mb-2"
          onColor="#888888"
          onChange={() => {
            let switchData = switch1;
            if(switchData == false) {
              setLoading(true);
              localStorage.setItem("theme", "dark");
              document.body.setAttribute("data-layout-mode", localStorage.getItem("theme"))
              setThemeMode("dark");
              document.body.click();
              setTimeout(() => {
                setLoading(false)
              },1500)
              // document.body.style.display = "none";
              // window.location.reload();
            }
            else {
              setLoading(true);
              localStorage.setItem("theme", "light");
              document.body.setAttribute("data-layout-mode", localStorage.getItem("theme"))
              setThemeMode("light");
              document.body.click();
              setTimeout(() => {
                setLoading(false)
              },1500)
              // document.body.style.display = "none";
              // window.location.reload();
            }
            setswitch1(!switchData);
          }}
          checked={switch1}
        />
      </div>
    </>
  )
}

export default ThemeMode;