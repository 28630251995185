import React from "react"
import BlueLogo from "../../assets/images/BlueLogo.svg"
import "../../pages/Authentication/stylesheet.css"
import {  useHistory } from "react-router-dom"

const AuthHeader = ({label,link,btnLabel}) => {
  const navigate = useHistory()

  return (
    <div className="mb-5 auth-header w-100">
      <div className="d-flex align-items-center gap-2">
        <img src={BlueLogo} />
        <h2>NullShip</h2>
      </div>
      <div className="d-flex align-items-center gap-2">
        <p style={{ marginBottom: 0 }}>{label}</p>
        <button
          className="bg-white px-2 py-1"
          style={{
            border: "1px solid #e1e4ea",
            borderRadius: "5px",
            color: "gray",
            // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => {
            navigate.push(link)
          }}
        >
        {btnLabel}
        </button>
      </div>
    </div>
  )
}

export default AuthHeader
