import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Form, FormFeedback, Input, Label } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { post } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"
import { Country, State, City }  from 'country-state-city';

//Address Api
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const ShipFromAddress = () => {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone, setPhone] = useState(null);
  const [street1, setStreet1] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);

  const retrieveShipFromAddress = async (id) => {
    const body = {
      id: id
    };
    post('/user/get-ship-from-address', body, {headers}).then((response) => {
      // console.log("get-ship-from-address",response);
      if(response.status === 200) {
        setName(response.data[0].name);
        setCompany(response.data[0].company);
        setPhone(response.data[0].phone);
        setStreet1(response.data[0].street1);
        setStreet2(response.data[0].street2);
        setCity(response.data[0].city);
        setZip(response.data[0].zip);
        setState(response.data[0].state);
        setCountry(response.data[0].country);
      }
      else {
        toast.error(response.message);
      }
    })
  }


  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setId(obj.id);
      retrieveShipFromAddress(obj.id);
    }
  }, []);

  const validationAddress = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id : id || '',
      name: name || '',
      company: company || '',
      phone: phone || '',
      street1: street1 || '',
      street2: street2 || '',
      city: city || '',
      zip: zip || '',
      state: state || '',
      country: country || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Ship From Name"),
      phone: Yup.string().min(10, 'Ship From Phone Number Must Contain 10 Digits').max(10, 'Ship From Phone Number Must Contain 10 Digits').required("Please Enter Ship From Phone"),
      street1: Yup.string().required("Please Enter Ship From Address"),
      city: Yup.string().required("Please Enter Ship From City"),
      zip: Yup.string().required("Please Enter Ship From Zip"),
      state: Yup.string().required("Please Enter Ship From State"),
      country: Yup.string().required("Please Enter Ship From Country"),
    }),
    onSubmit: (values) => {
      dispatch(handleShipFromAddress(values));
    }
  });

  const handleShipFromAddress = async (values) => {
   const {id, name, company, phone, street1, street2, city, zip, state, country} = values;
   const body = {
     id: id,
     name: name,
     company: company,
     phone: phone,
     street1: street1,
     street2: street2,
     city: city,
     zip: zip,
     state: state,
     country: country
   };
    // console.log("body",body);
   post('/user/update-ship-from-address', body, {headers}).then((response) => {
     if(response.status === 200) {
      toast.success(response.message);
     }
     else {
       toast.error(response.message);
     }
   })
  }

  const handleFromAddressStreet1 = async (value) => {
    // // console.log("value", value);
    // setFromAddress(value);
    try {
      const results = await geocodeByAddress(value);
      // // console.log("results", results);
      const addressComponents = results[0].address_components;
      // console.log("addressComponents", addressComponents);
      let street1 = "";
      let street2 = "";
      let country = "";
      let city = "";
      let state = "";
      let zip = "";
      addressComponents.map((el) => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if(el.types[0] === "street_number") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "route") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "premise") {
          street1 += el.long_name + " ";
        }
        else if(el.types[0] === "subpremise") {
          street2 += el.long_name + " ";
        }
        else if(el.types[0] === "locality") {
          city += el.long_name;
        }
        else if(el.types[0] === "administrative_area_level_1") {
          state += el.long_name;
        }
        else if(el.types[0] === "country") {
          country += el.short_name;
        }
        else if(el.types[0] === "postal_code") {
          zip += el.long_name;
        }
      })
      if(street1 !== "") {
        street1 = street1.substring(0, (street1.length - 1));
      }

      if(street2 !== "") {
        street2 = street2.substring(0, (street2.length - 1));
      }
      setStreet1(street1);
      setStreet2(street2);
      setCity(city);
      setCountry(country);
      setState(state);
      setZip(zip);
    } catch (error) {
      console.error("Error", error);
    }
  };


  return (
    <>
      <h4 className="card-title mb-4">Ship From Address</h4>

      <Card>
        <CardBody>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validationAddress.handleSubmit();
              return false;
            }}
          >
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <Label className="form-label">Name <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="name"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Ship From Name"
                        type="text"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.name || ""}
                        invalid={
                          validationAddress.touched.name && validationAddress.errors.name ? true : false
                        }
                      />
                      {validationAddress.touched.name && validationAddress.errors.name ? (
                        <FormFeedback type="invalid">{validationAddress.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <Label className="form-label">Company (Optional)</Label>
                      <Input
                        name="company"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Ship From Company (Optional)"
                        type="text"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.company || ""}
                        invalid={
                          validationAddress.touched.company && validationAddress.errors.company ? true : false
                        }
                      />
                      {validationAddress.touched.company && validationAddress.errors.company ? (
                        <FormFeedback type="invalid">{validationAddress.errors.company}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <Label className="form-label">Phone <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="phone"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Ship From Phone"
                        type="number"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.phone || ""}
                        invalid={
                          validationAddress.touched.phone && validationAddress.errors.phone ? true : false
                        }
                      />
                      {validationAddress.touched.phone && validationAddress.errors.phone ? (
                        <FormFeedback type="invalid">{validationAddress.errors.phone}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-md-12 mt-2"}>
                <div className={"row"}>
                  <div className={"col-md-6"}>
                    <PlacesAutocomplete
                      value={street1}
                      onChange={setStreet1}
                      onSelect={handleFromAddressStreet1}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{position:"relative"}}>
                          <div className="form-group">
                            <Label className="form-label">Address Street 1 <span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="from_street1"
                              // value={name}
                              className="form-control"
                              {...getInputProps({ placeholder: "Enter Ship From Street 1" })}
                              type="text"
                              // onChange={(e) => setFromStreet1(e.target.value)}
                              required={true}
                            />
                          </div>
                          <div style={{position:"absolute",top:"65px",left:"0px",backgroundColor:"white",zIndex:"99",width:"100%"}}>
                            {loading ? <div>Loading...</div> : null}
                            {loading ? <div style={{backgroundColor: localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1"}}>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? localStorage.getItem("theme") == "dark"? "#333":"#ff6600" : localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1",
                                padding: "5px 10px",border:"1px solid #efefef",
                                cursor: "pointer"
                              };
                              return (
                                <div
                                  key={suggestion.id}
                                  {...getSuggestionItemProps(suggestion, { style })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  {/*<div className={"col-md-6"}>*/}
                  {/*  <div className="form-group">*/}
                  {/*    <Label className="form-label">Address Street 1</Label>*/}
                  {/*    <Input*/}
                  {/*      name="street1"*/}
                  {/*      // value={name}*/}
                  {/*      className="form-control"*/}
                  {/*      placeholder="Enter Ship From Street 1"*/}
                  {/*      type="text"*/}
                  {/*      onChange={validationAddress.handleChange}*/}
                  {/*      onBlur={validationAddress.handleBlur}*/}
                  {/*      value={validationAddress.values.street1 || ""}*/}
                  {/*      invalid={*/}
                  {/*        validationAddress.touched.street1 && validationAddress.errors.street1 ? true : false*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*    {validationAddress.touched.street1 && validationAddress.errors.street1 ? (*/}
                  {/*      <FormFeedback type="invalid">{validationAddress.errors.street1}</FormFeedback>*/}
                  {/*    ) : null}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className={"col-md-6"}>
                    <div className="form-group">
                      <Label className="form-label">Address Street 2 (Optional)</Label>
                      <Input
                        name="street2"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Ship From Street 2 (Optional)"
                        type="text"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.street2 || ""}
                        invalid={
                          validationAddress.touched.street2 && validationAddress.errors.street2 ? true : false
                        }
                      />
                      {validationAddress.touched.street2 && validationAddress.errors.street2 ? (
                        <FormFeedback type="invalid">{validationAddress.errors.street2}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-md-12 mt-2"}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Country <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"country"}
                        className="form-control"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.country || ""}
                        // invalid={
                        //   validation.touched.from_state && validation.errors.from_state ? true : false
                        // }
                      >
                        <option disabled={true} value={""}>Select a country</option>
                        {Country.getAllCountries().map((part, id) => {
                          return (<option key={id} value={part.isoCode}>{part.name}</option>);
                        })};
                      </select>
                      {validationAddress.touched.country && validationAddress.errors.country ? (
                        <FormFeedback type="invalid">{validationAddress.errors.country}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">City <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="city"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Ship From City"
                        type="text"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.city || ""}
                        invalid={
                          validationAddress.touched.city && validationAddress.errors.city ? true : false
                        }
                      />
                      {validationAddress.touched.city && validationAddress.errors.city ? (
                        <FormFeedback type="invalid">{validationAddress.errors.city}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Zip</Label>
                      <Input
                        name="zip"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Ship From Zip"
                        type="text"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.zip || ""}
                        invalid={
                          validationAddress.touched.zip && validationAddress.errors.zip ? true : false
                        }
                      />
                      {validationAddress.touched.zip && validationAddress.errors.zip ? (
                        <FormFeedback type="invalid">{validationAddress.errors.zip}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">State <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"state"}
                        className="form-control"
                        onChange={validationAddress.handleChange}
                        onBlur={validationAddress.handleBlur}
                        value={validationAddress.values.state || ""}
                        // invalid={
                        //   validationAddress.touched.state && validationAddress.errors.state ? true : false
                        // }
                      >
                        <option value={""} disabled={true}>Select a State</option>
                        {State.getAllStates().filter(el => el.countryCode == validationAddress.values.country).map((part, id) => {
                          return (<option key={id} value={part.name}>{part.name}</option>);
                        })};
                      </select>
                      {validationAddress.touched.state && validationAddress.errors.state ? (
                        <FormFeedback type="invalid">{validationAddress.errors.state}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="success">
                Save From Address
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}
export default withRouter(ShipFromAddress);