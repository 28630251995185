module.exports = `{
    "carrier": [String],
    "service_key": [String],
    "from": {
            "name": [String],
            "company": [String],
            "phone": [String],
            "street1": [String],
            "street2": [String],
            "city": [String],
            "state": [String],
            "country": [String],
            "zip": [String]
            },
    "to": {
            "name": [String],
            "company": [String],
            "phone": [String],
            "street1": [String],
            "street2": [String],
            "city": [String],
            "state": [String],
            "country": [String],
            "zip": [String]
            },
    "return_to": {
            "name": [String],
            "company": [String],
            "phone": [String],
            "street1": [String],
            "street2": [String],
            "city": [String],
            "state": [String],
            "country": [String],
            "zip": [String]
            },
    "misc": {
            "length": [Float],
            "width": [Float],
            "height": [Float],
            "weight": [Float]
            },
    "options": {
            "is_insured": [Boolean],
            "cost_of_shipment": [Float],
            "receiver_signature": [Boolean],
            "address_validation": [Boolean],
            "label_format": [String],
            "dpi": [String],
            "saturday_delivery": [Boolean],
            "reference_number" : [String],
            "custom_reference_1" : [String],
            "custom_reference_2" : [String]
            }
}`