import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [filterType, setFilterType] = useState(tabs[0].type);
  const theme = localStorage.getItem("theme");

  return (
    <div className="d-flex">
      {tabs.map((tab) => {
        const isActive = filterType === tab.type;
        const backgroundColor = isActive
          ? theme === "dark"
            ? "#0f0f0f"
            : "white"
          : theme === "dark"
            ? "#222224"
            : "#E1E5E8";

        return (
          <button
            key={tab.type}
            className={`btn btn-md rounded-top ${isActive ? "text-dark" : "text-secondary"}`}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "4px 4px 0px 0px",
              borderColor: "rgba(0,0,0,0)",
              backgroundColor: backgroundColor,
            }}
            onClick={async () => {
              if (tab.onClick) await tab.onClick();
              setFilterType(tab.type);
            }}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
