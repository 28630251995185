// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Select from "react-select"

function DailyReport(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [stores, setStores] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [pending, setPending] = useState(true);

  const [filterStore, setFilterStore] = useState("all");
  const [filterDate, setFilterDate] = useState(moment(new Date()).format("YYYY-MM-DD"));


  const columns = [
    {
      name: 'Service Name',
      // selector: 'date',
      sortable: true,
      selector: (row) => row.service_name
    },
    {
      name: 'Completed Orders',
      selector: row => row.count,
      sortable: true,
    },
    {
      name: 'Debit Balance',
      // selector: 'debit',
      sortable: true,
      right: true,
      selector: row => row.sum_discounted_price ? '$' + amountFormat(row.sum_discounted_price) : "$0"
    }
  ];

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, {headers}).then((response) => {
        // console.log("stores in nav", response);
        let arr = [];
        arr.push({
          value: 'all',
          label: "All Stores"
        }, {
          value: 0,
            label: "Manual Store"
        })
        for (let i=0;i<response.data.length;i++) {
          arr.push({
            value: response.data[i].id,
            label: response.data[i].store_name,
          })
        }
        console.log("arr",arr);
        setStores(arr);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  // const tableData = ;
  const retrieveOrdersReporting = async (date = filterDate, store = filterStore) => {
    try {
      setPending(true);
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        date: date,
        store: store
      }
      post('/user/get-daily-reporting', body, {headers}).then((response) => {
        console.log("response", response);
        setPending(false);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    // // console.log("here");
    retrieveOrdersReporting();
    retrieveStores();
  }, []);

  const handleDate = async (e) => {
    setFilterDate(e.target.value);
    await retrieveOrdersReporting(e.target.value, filterStore);
  }

  const handleStore = async (e) => {
    setFilterStore(e.value);
    await retrieveOrdersReporting(filterDate, e.value);
  }

  //meta title
  document.title = "Daily Report | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Daily Report" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className="row">
              <div className={"col-md-2"}>
                <label>Select Store</label>
                <Select
                  options={stores}
                  defaultValue={{value: "all", label: "All Stores"}}
                  onChange={handleStore}
                  className="select2-selection"
                />
              </div>
              <div className={"col-md-2"}>
                <label>Select Date</label>
                <input type={"date"} className={"form-control"} onChange={handleDate} value={filterDate}/>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
              <DataTable columns={columns} data={filteredData} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

DailyReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default DailyReport;