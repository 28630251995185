// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get } from "../../../helpers/api_helper"
import { toast } from "react-hot-toast"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap"
import states from "../../../assets/js/states"
import { Country, State, City } from "country-state-city"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"

//Address Api
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import loader from "../../../assets/images/loader.gif"

let items = []
let carriersForLoad = []
let servicesForLoad = []
function OrderEdit(props) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const order_id = props.match.params.id
  const [id, setId] = useState(null)
  const [user_id, setUser_id] = useState(JSON.parse(localStorage.getItem("authUser")).id)
  const [from_country, setFromCountry] = useState(null)
  const [from_name, setFromName] = useState(null)
  const [from_company, setFromCompany] = useState(null)
  const [from_phone, setFromPhone] = useState(null)
  const [from_street1, setFromStreet1] = useState(null)
  const [from_street2, setFromStreet2] = useState(null)
  const [from_city, setFromCity] = useState(null)
  const [from_zip, setFromZip] = useState(null)
  const [from_state, setFromState] = useState(null)

  const [to_country, setToCountry] = useState(null)
  const [to_name, setToName] = useState(null)
  const [to_company, setToCompany] = useState(null)
  const [to_phone, setToPhone] = useState(null)
  const [to_street1, setToStreet1] = useState(null)
  const [to_street2, setToStreet2] = useState(null)
  const [to_city, setToCity] = useState(null)
  const [to_zip, setToZip] = useState(null)
  const [to_state, setToState] = useState(null)

  const [is_return_enable, setIsReturnEnable] = useState(false)
  const [is_return_same_as_from, setIsReturnSameAsFrom] = useState(false)

  const [return_country, setReturnCountry] = useState(null)
  const [return_name, setReturnName] = useState(null)
  const [return_company, setReturnCompany] = useState(null)
  const [return_phone, setReturnPhone] = useState(null)
  const [return_street1, setReturnStreet1] = useState(null)
  const [return_street2, setReturnStreet2] = useState(null)
  const [return_city, setReturnCity] = useState(null)
  const [return_zip, setReturnZip] = useState(null)
  const [return_state, setReturnState] = useState(null)

  const [length, setLength] = useState(null)
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const [weight, setWeight] = useState(null)
  const [name, setName] = useState(null)
  const [price, setPrice] = useState(null)
  const [aboveFiveLbsPrice, setAboveFiveLbsPrice] = useState(null)
  const [discount_type, setDiscountType] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [description, setDescription] = useState(null)
  const [created_at, setCreatedAt] = useState(null)
  const [is_custom_form, setIsCustomForm] = useState(0)
  const [content_type, setContentType] = useState("Merchandise")
  const [restriction_type, setRestrictionType] = useState("None")
  const [content_explanation, setContentExplanation] = useState("")
  const [restriction_comments, setRestrictionComments] = useState("")
  const [non_delivery_action, setNonDeliveryAction] = useState("Return")
  const [eel_or_ppc, setEelOrPpc] = useState("")
  const [store_type, setStoreType] = useState("")

  const [receiver_signature, setReceiverSignature] = useState(0)

  const [order_number, setOrderNumber] = useState("")
  const [reference_number, setReferenceNumber] = useState("")
  const [customReference1, setCustomReference1] = useState("")
  const [customReference2, setCustomReference2] = useState("")

  const [saturdayDelivery, setSaturdayDelivery] = useState(0)

  const [services, setServices] = useState([])
  const [service_id, setServiceId] = useState(null)
  const [service_name, setServiceName] = useState(null)
  const [selectAvailableService, setSelectAvailableService] = useState(true)

  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedCarrierName, setSelectedCarrierName] = useState(null)
  const [selectAvailableCarrier, setSelectAvailableCarrier] = useState(true)

  const [stateShort, setStateShort] = useState([])

  const [isDHL, setIsDHL] = useState(false)

  const [isFromToCountryDiff, setIsFromToCountryDiff] = useState(true)
  const [customCheckbox, setCustomCheckbox] = useState(false)
  const [customFormItems, setCustomFormItems] = useState([])
  const [customFormItemsData, setCustomFormItemsData] = useState("")

  const [validationError, setValidationError] = useState(null)

  const [isInsured, setIsInsured] = useState(false)
  const [defaultIsInsured, setDefaultIsInsured] = useState(false)
  const [shipment_amount, setShipmentAmount] = useState("")
  const [insurance_amount, setInsuranceAmount] = useState("")

  const [tooltip, setTooltip] = useState(false)

  const [loading, setLoading] = useState(true)

  const retrieveOrder = async () => {
    try {
      let body = {
        id: order_id,
      }
      // console.log("body",props.match.params.id);
      post("/user/get-order", body, { headers }).then(async response => {
        // console.log("get order", response.data[0]);
        let data = response.data[0]
        // console.log("JSON.parse(data.custom_items_data)",JSON.parse(data.custom_items_data));
        setId(data.id)

        setFromCountry(data.from_country ? data.from_country : "")
        setFromName(data.from_name)
        setFromCompany(data.from_company)
        setFromPhone(data.from_phone)
        setFromStreet1(data.from_street1)
        setFromStreet2(data.from_street2)
        setFromCity(data.from_city)
        setFromZip(data.from_zip)
        setFromState(data.from_state ? data.from_state : "")

        setToCountry(data.to_country ? data.to_country : "")
        setToName(data.to_name)
        setToCompany(data.to_company)
        setToPhone(data.to_phone)
        setToStreet1(data.to_street1)
        setToStreet2(data.to_street2)
        setToCity(data.to_city)
        setToZip(data.to_zip)
        setToState(data.to_state ? data.to_state : "")

        setIsReturnSameAsFrom(data.return_same_as_from == 1 ? true : false)

        if (data.return_same_as_from == 0) {
          setReturnCountry(data.return_country ? data.return_country : "")
          setReturnName(data.return_name)
          setReturnCompany(data.return_company)
          setReturnPhone(data.return_phone)
          setReturnStreet1(data.return_street1)
          setReturnStreet2(data.return_street2)
          setReturnCity(data.return_city)
          setReturnZip(data.return_zip)
          setReturnState(data.return_state ? data.return_state : "")
          handleIsReturnEnable(
            data.return_name ? data.return_name : "",
            data.return_company ? data.return_company : "",
            data.return_country ? data.return_country : "",
            data.return_phone ? data.return_phone : "",
            data.return_street1 ? data.return_street1 : "",
            data.return_street2 ? data.return_street2 : "",
            data.return_city ? data.return_city : "",
            data.return_state ? data.return_state : "",
            data.return_zip ? data.return_zip : ""
          )
        } else {
          document.getElementById("same_as_from").checked = true
          setReturnState("")
          setReturnCountry("")
        }

        setLength(data.length)
        setHeight(data.height)
        setWidth(data.width)
        setWeight(data.weight)
        setServiceId(data.service_id)
        setServiceName(
          data.store_type == null
            ? servicesForLoad.filter(el => el.id == data.service_id).length > 0
              ? servicesForLoad.filter(el => el.id == data.service_id)[0].name
              : ""
            : ""
        )
        setSelectAvailableService(data.service_id ? true : false)
        setSelectedCarrier(data.carrier_id)
        setSelectedCarrierName(
          data.store_type == null
            ? carriersForLoad.filter(el => el.id == data.carrier_id)[0].name
            : ""
        )
        setReceiverSignature(data.receiver_signature)
        setSaturdayDelivery(data.saturday_delivery)
        setName(data.name)
        setPrice(data.price)
        setAboveFiveLbsPrice(data.above_five_lbs_price)
        setDescription(data.description)
        setDiscount(data.discount)
        setDiscountType(data.discount_type)
        setCreatedAt(data.created_at)
        setIsCustomForm(parseInt(data.is_custom_form))
        setIsInsured(data.is_insured == 1 ? true : false)
        setDefaultIsInsured(data.is_insured == 1 ? true : false)
        setInsuranceAmount(data.insurance_amount)
        setShipmentAmount(data.shipment_amount)
        setOrderNumber(data.order_number)
        setReferenceNumber(data.reference_number)
        setCustomReference1(data.custom_reference_1)
        setCustomReference2(data.custom_reference_2)
        setStoreType(data.store_type)

        setCustomFormItems(JSON.parse(data.custom_items_data))
        setCustomFormItemsData(data.custom_items_data)
        items = data.custom_items_data ? JSON.parse(data.custom_items_data) : []

        if (data.is_insured == 1) {
          document.getElementById("insurance").checked = true
        }

        // console.log("carrier_ids.filter(el => el.id === data.carrier_id).name",data.carrier_id,carrier_ids.filter(el=>el.id == data.carrier_id)[0].name);
        if (data.carrier_id == 3 || data.carrier_id == 4) {
          setIsDHL(true)
        }

        setTimeout(async () => {
          if (
            data.is_custom_form.toString() === "1" &&
            data.store_type == null
          ) {
            setContentType(data.content_type)
            setContentExplanation(data.content_explanation)
            setRestrictionType(data.restriction_type)
            setRestrictionComments(data.restriction_comments)
            setNonDeliveryAction(data.non_delivery_action)
            setEelOrPpc(data.eel_or_ppc)

            document.getElementById("customCheckbox").checked = true
            setCustomCheckbox(true)
          }

          if (
            data.from_country === data.to_country &&
            data.store_type == null
          ) {
            setCustomCheckbox(false)
            await countryIsSame()
            setIsFromToCountryDiff(false)
          }
        }, 1000)

        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
    } catch (e) {
      // console.log(e)
      setLoading(false)
    }
  }
  const getDiscounts = async () => {
    try {
      const response = await post(
        "/user/get-discounts-all",
        {
          user_id: user_id,
        },
        { headers }
      )
      console.log("discounts", response)
      return response
    } catch (error) {}
  }
  const retrieveServices = async () => {
    try {
      const response = await get("/admin/get-services", { headers })
      console.log("service", response);
  
        const discounts = await getDiscounts()
        if (discounts.data.userDiscount.length > 0) {
          discounts?.data.userDiscount.map(dis => {
            response?.data.map(res => {
              if (dis.service_id == res.id) {
                res.price = res.price - dis.amount
                res.above_five_lbs_price =
                  res.above_five_lbs_price - dis.amount_above_five_lbs
                res.above_twenty_lbs_price =
                  res.above_twenty_lbs_price - dis.amount_above_twenty_lbs
                res.under_one_lbs_price =
                  res.under_one_lbs_price - dis.amount_under_one_lbs
                res.discount = "0"
              }
              setServices(response.data)
              servicesForLoad = response.data
            })
          })
        }
        else{
          setServices(response.data)
          servicesForLoad = response.data
        }
      // get("/admin/get-services", { headers }).then(response => {
      //   // console.log("service", response);
      //   setServices(response.data)
      //   servicesForLoad = response.data
      // })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carriers", { headers }).then(response => {
        // console.log("carriers", response);
        setCarriers(response.data)
        carriersForLoad = response.data
      })
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setLoading(true)
      retrieveCarriers().then(() => {
        retrieveServices().then(() => {
          setTimeout(() => {
            retrieveOrder()
          }, 1000)
        })
      })

      let arr = []
      states.forEach(el => {
        arr.push(el.abbreviation)
      })
      setStateShort(arr)
    }
    // console.log("customFormItems",customFormItems);
  }, [])

  const handleOrder = async e => {
    // console.log("values",values);
    e.preventDefault()
    // console.log("from_phone",from_phone);
    // console.log("to_phone",to_phone.toString().replace(/[^0-9]/g, '').replaceAll(" ",""));
    if (
      from_phone &&
      from_phone
        .toString()
        .replace(/[^0-9]/g, "")
        .replaceAll(" ", "").length < 10
    ) {
      toast.error("Ship From Phone must contain at least 10 numeric characters")
      return false
    }

    if (
      to_phone &&
      to_phone
        .toString()
        .replace(/[^0-9]/g, "")
        .replaceAll(" ", "").length < 10
    ) {
      toast.error("Ship To Phone must contain at least 10 numeric characters")
      return false
    }

    if (
      return_phone &&
      return_phone
        .toString()
        .replace(/[^0-9]/g, "")
        .replaceAll(" ", "").length < 10
    ) {
      toast.error("Return To Phone must contain at least 10 numeric characters")
      return false
    }

    let loading = toast.loading("Validating Order...")
    document.body.style.pointerEvents = "none"
    const body = {
      id: id,
      from_country: from_country,
      from_name: from_name,
      from_company: from_company,
      from_phone: from_phone,
      from_street1: from_street1,
      from_street2: from_street2,
      from_city: from_city,
      from_zip: from_zip,
      from_state: from_state,
      to_country: to_country,
      to_name: to_name,
      to_company: to_company,
      to_phone: to_phone,
      to_street1: to_street1,
      to_street2: to_street2,
      to_city: to_city,
      to_zip: to_zip,
      to_state: to_state,
      return_same_as_from: is_return_same_as_from ? 1 : 0,
      return_country: is_return_same_as_from ? from_country : return_country,
      return_name: is_return_same_as_from ? from_name : return_name,
      return_company: is_return_same_as_from ? from_company : return_company,
      return_phone: is_return_same_as_from ? from_phone : return_phone,
      return_street1: is_return_same_as_from ? from_street1 : return_street1,
      return_street2: is_return_same_as_from ? from_street2 : return_street2,
      return_city: is_return_same_as_from ? from_city : return_city,
      return_zip: is_return_same_as_from ? from_zip : return_zip,
      return_state: is_return_same_as_from ? from_state : return_state,
      length: length,
      width: width,
      height: height,
      weight: weight,
      service_id: service_id,
      receiver_signature: receiver_signature,
      custom_form: customCheckbox,
      content_type: customCheckbox ? content_type : null,
      content_explanation: customCheckbox ? content_explanation : null,
      restriction_type: customCheckbox ? restriction_type : null,
      restriction_comments: customCheckbox ? restriction_comments : null,
      non_delivery_action: customCheckbox ? non_delivery_action : null,
      eel_or_ppc: customCheckbox ? eel_or_ppc : null,
      custom_items_data: customFormItemsData,
      is_insured: isInsured ? 1 : 0,
      insurance_amount: insurance_amount,
      shipment_amount: shipment_amount,
      order_number: order_number,
      reference_number: reference_number,
      custom_reference_1: customReference1,
      custom_reference_2: customReference2,
      saturday_delivery: saturdayDelivery,
    }

    // console.log("body",body);
    setValidationError(null)
    post("/user/update-order", body, { headers }).then(response => {
      toast.remove(loading)
      document.body.style.pointerEvents = "auto"
      if (response.status === 200) {
        toast.success(response.message)
        setTimeout(() => {
          get("/admin/get-carriers", { headers }).then(response => {
            // console.log("carriers", response.data);
            setCarriers(response.data)
            carriersForLoad = response.data
            if (response.data.length > 0) {
              if (localStorage.getItem("order-filter") == null) {
                // console.log("localStorage.setItem order-filter", localStorage.getItem("order-filter"))
                // console.log("localStorage.setItem order-filter", response.data)
                // console.log("localStorage.setItem order-filter", response.data.filter(el => el.is_active == 1)[0].name)
                localStorage.setItem(
                  "order-filter",
                  response.data.filter(el => el.is_active == 1)[0].name
                )
              } else if (localStorage.getItem("order-filter") == "Unassigned") {
                localStorage.setItem("order-filter", "Unassigned")
              } else if (
                response.data.filter(
                  el => el.name == localStorage.getItem("order-filter")
                )[0].is_active == 0
              ) {
                // console.log("localStorage.setItem order-filter", localStorage.getItem("order-filter"))
                // console.log("localStorage.setItem order-filter", response.data)
                // console.log("localStorage.setItem order-filter", response.data.filter(el => el.is_active == 1)[0].name)
                localStorage.setItem(
                  "order-filter",
                  response.data.filter(el => el.is_active == 1)[0].name
                )
              }
            }

            props.history.push("/orders/awaiting-shipment")
          })
        }, 2000)
      } else if (response.status === 400) {
        toast.error(response.message)
        // console.log("validation err", response);
        setValidationError(response.errors)
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleIsInsured = e => {
    const { checked } = e.target
    if (checked) {
      setIsInsured(true)
    } else {
      setIsInsured(false)
      setShipmentAmount("")
      setInsuranceAmount("")
    }
  }

  const handleShipmentAmount = e => {
    const { value } = e.target
    setShipmentAmount(value)
    if (value && parseFloat(value) > 0) {
      if (value < 100) {
        setInsuranceAmount("")
      } else if (value >= 100 && value <= 300) {
        setInsuranceAmount("3.45")
      } else if (value > 300) {
        let extraAmount = parseFloat(value) - 300
        // console.log("extraAmount",extraAmount);
        let resultedAmount = parseFloat(extraAmount) / 100
        // console.log("resultedAmount",resultedAmount);
        let final = 3.45 + 1.15 * Math.floor(resultedAmount)
        // console.log("final = 3.45 + (1.15  * ", Math.// console.log(resultedAmount) , ") ===",final);
        setInsuranceAmount(parseFloat(final).toFixed(2))
      } else {
        setInsuranceAmount("")
      }
    } else {
      setInsuranceAmount("")
    }
  }

  const handleCustomCheckBox = e => {
    const { checked } = e.target
    if (checked) {
      setCustomCheckbox(true)
      // items = [{"item_id": 1,"description":"","quantity":"","total_value":"","total_weight":"","harmonized_tariff_schedule":"","product_identifier":"","origin_country":"","status":1}];
      // setCustomFormItems(items);
    } else {
      setCustomCheckbox(false)
      // items = [];
      // setCustomFormItems(items);
    }
    // setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)));
    // setCustomForms([{"form_id":1,"status":1}]);
  }

  const handleCustomFormAddItem = async () => {
    // console.log("items",items);
    items.push({
      item_id: items.length + 1,
      description: "",
      quantity: "",
      total_value: "",
      total_weight: "",
      harmonized_tariff_schedule: "",
      product_identifier: "",
      origin_country: "",
      status: 1,
    })
    setCustomFormItems(items)
    // console.log("items",items);
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleRemoveCustomItem = async item_id => {
    const index = items.findIndex(el => el.item_id === item_id)
    // console.log("removeIndex",removeIndex)
    // items.splice(item_id, 1);
    // console.log("removeIndex items",items);
    items[index].status = 0
    setCustomFormItems(items)
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemDescription = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].description = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemQuantity = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].quantity = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemTotalValue = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].total_value = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemTotalWeight = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].total_weight = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemHarmonized = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].harmonized_tariff_schedule = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemProductIdentifier = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].product_identifier = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleItemOriginCountry = e => {
    const { value } = e.target
    const item_id = e.target.getAttribute("data-id")
    // console.log("item_id",item_id);

    const index = items.findIndex(el => el.item_id === parseInt(item_id))
    // console.log("updating value at index",index,"value",value);
    items[index].origin_country = value
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const countryIsSame = () => {
    document.getElementById("customCheckbox").checked = false
    // items = [];
    // setCustomFormItems(items);
  }

  const handleFromCountry = async e => {
    const { value } = e.target
    setFromCountry(value)
    if (value !== to_country) {
      setIsFromToCountryDiff(true)
    } else {
      setCustomCheckbox(false)
      await countryIsSame()
      setIsFromToCountryDiff(false)
    }
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleToCountry = async e => {
    const { value } = e.target
    setToCountry(value)
    if (value !== from_country) {
      setIsFromToCountryDiff(true)
    } else {
      setCustomCheckbox(false)
      await countryIsSame()
      setIsFromToCountryDiff(false)
    }
    setCustomFormItemsData(JSON.stringify(items.filter(el => el.status === 1)))
  }

  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
    setSelectedCarrierName(e.target.getAttribute("data-name"))
    setServiceId("")
    setServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)

    if (
      e.target.getAttribute("data-name") == "DHL" ||
      e.target.getAttribute("data-name") == "USPS"
    ) {
      setIsDHL(true)
    } else {
      setIsDHL(false)
    }
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  //meta title
  document.title = "Edit Order | NullShip"

  const handleFromAddressStreet1 = async value => {
    // console.log("value", value);
    // setFromAddress(value);
    try {
      const results = await geocodeByAddress(value)
      // console.log("results", results);
      const addressComponents = results[0].address_components
      // console.log("addressComponents", addressComponents);
      let street1 = ""
      let street2 = ""
      let country = ""
      let city = ""
      let state = ""
      let zip = ""
      addressComponents.map(el => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if (el.types[0] === "street_number") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "route") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "premise") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "subpremise") {
          street2 += el.long_name + " "
        } else if (el.types[0] === "locality") {
          city += el.long_name
        } else if (el.types[0] === "administrative_area_level_1") {
          state += el.short_name
        } else if (el.types[0] === "country") {
          country += el.short_name
        } else if (el.types[0] === "postal_code") {
          zip += el.long_name
        }
      })
      if (street1 !== "") {
        street1 = street1.substring(0, street1.length - 1)
      }

      if (street2 !== "") {
        street2 = street2.substring(0, street2.length - 1)
      }
      setFromStreet1(street1)
      setFromStreet2(street2)
      setFromCity(city)
      setFromCountry(country)
      setFromState(state)
      setFromZip(zip)

      if (country !== to_country) {
        setIsFromToCountryDiff(true)
      } else {
        setCustomCheckbox(false)
        await countryIsSame()
        setIsFromToCountryDiff(false)
      }
      setCustomFormItemsData(
        JSON.stringify(items.filter(el => el.status === 1))
      )
    } catch (error) {
      console.error("Error", error)
    }
  }

  const handleToAddressStreet1 = async value => {
    try {
      const results = await geocodeByAddress(value)
      // console.log("results", results);
      const addressComponents = results[0].address_components
      // console.log("addressComponents", addressComponents);
      let street1 = ""
      let street2 = ""
      let country = ""
      let city = ""
      let state = ""
      let zip = ""
      addressComponents.map(el => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if (el.types[0] === "street_number") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "route") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "premise") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "subpremise") {
          street2 += el.long_name + " "
        } else if (el.types[0] === "locality") {
          city += el.long_name
        } else if (el.types[0] === "administrative_area_level_1") {
          state += el.short_name
        } else if (el.types[0] === "country") {
          country += el.short_name
        } else if (el.types[0] === "postal_code") {
          zip += el.long_name
        }
      })
      if (street1 !== "") {
        street1 = street1.substring(0, street1.length - 1)
      }

      if (street2 !== "") {
        street2 = street2.substring(0, street2.length - 1)
      }
      setToStreet1(street1)
      setToStreet2(street2)
      setToCity(city)
      setToCountry(country)
      setToState(state)
      setToZip(zip)

      if (country !== from_country) {
        setIsFromToCountryDiff(true)
      } else {
        setCustomCheckbox(false)
        await countryIsSame()
        setIsFromToCountryDiff(false)
      }
      setCustomFormItemsData(
        JSON.stringify(items.filter(el => el.status === 1))
      )
    } catch (error) {
      console.error("Error", error)
    }
  }

  const handleReturnAddressStreet1 = async value => {
    try {
      const results = await geocodeByAddress(value)
      // console.log("results", results);
      const addressComponents = results[0].address_components
      // console.log("addressComponents", addressComponents);
      let street1 = ""
      let street2 = ""
      let country = ""
      let city = ""
      let state = ""
      let zip = ""
      addressComponents.map(el => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if (el.types[0] === "street_number") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "route") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "premise") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "subpremise") {
          street2 += el.long_name + " "
        } else if (el.types[0] === "locality") {
          city += el.long_name
        } else if (el.types[0] === "administrative_area_level_1") {
          state += el.short_name
        } else if (el.types[0] === "country") {
          country += el.short_name
        } else if (el.types[0] === "postal_code") {
          zip += el.long_name
        }
      })
      if (street1 !== "") {
        street1 = street1.substring(0, street1.length - 1)
      }

      if (street2 !== "") {
        street2 = street2.substring(0, street2.length - 1)
      }
      setReturnStreet1(street1)
      setReturnStreet2(street2)
      setReturnCity(city)
      setReturnCountry(country)
      setReturnState(state)
      setReturnZip(zip)
    } catch (error) {
      console.error("Error", error)
    }
  }

  const handleReturnSameAsFrom = e => {
    const { checked } = e.target
    if (checked) {
      setIsReturnEnable(false)
      setIsReturnSameAsFrom(true)
      setReturnName("")
      setReturnCompany("")
      setReturnPhone("")
      setReturnStreet1("")
      setReturnStreet2("")
      setReturnCity("")
      setReturnCountry("")
      setReturnState("")
      setReturnZip("")
    } else {
      setIsReturnSameAsFrom(false)
    }
  }

  const handleClearReturn = () => {
    setIsReturnEnable(false)
    setReturnName("")
    setReturnCompany("")
    setReturnPhone("")
    setReturnStreet1("")
    setReturnStreet2("")
    setReturnCity("")
    setReturnCountry("")
    setReturnState("")
    setReturnZip("")
  }

  const handleIsReturnEnable = (
    return_name,
    return_company,
    return_country,
    return_phone,
    return_street1,
    return_street2,
    return_city,
    return_state,
    return_zip
  ) => {
    // console.log("return_name",return_name);
    // console.log("return_company",return_company);
    // console.log("return_country",return_country);
    // console.log("return_phone",return_phone);
    // console.log("return_street1",return_street1);
    // console.log("return_street2",return_street2);
    // console.log("return_city",return_city);
    // console.log("return_state",return_state);
    // console.log("return_zip",return_zip);
    if (
      return_name == "" &&
      return_company == "" &&
      return_country == "" &&
      return_phone == "" &&
      return_street1 == "" &&
      return_street2 == "" &&
      return_city == "" &&
      return_state == "" &&
      return_zip == ""
    ) {
      setIsReturnEnable(false)
    } else {
      setIsReturnEnable(true)
    }
  }

  return (
    <div
      className="page-content"
      onClick={() => {
        tooltip ? setTooltip(false) : ""
      }}
    >
      <div className="container-fluid">
        <Breadcrumbs
          title="NullShip"
          sourceItem="Orders"
          breadcrumbItem="Edit Order"
        />
        {/* <Table columns={columns} data={data} /> */}
        <div
          id="loader"
          className={"align-items-center justify-content-center"}
          style={{
            display: loading ? "flex" : "none",
            alignItems: "center",
            margin: "auto",
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: localStorage.getItem("theme")
              ? localStorage.getItem("theme") == "dark"
                ? "#0f0f0f"
                : "white"
              : "white",
            zIndex: "99999",
            top: "0",
            left: "0",
          }}
        >
          <img style={{ width: "10%" }} src={loader} />
        </div>
        <Form
          className="form-horizontal"
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   validation.handleSubmit();
          //   return false;
          // }}
          style={{ display: loading ? "none" : "block" }}
          onSubmit={handleOrder}
        >
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Ship From</h4>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Name <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="from_name"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship From Name"
                          type="text"
                          required={true}
                          onChange={e => setFromName(e.target.value)}
                          value={from_name}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">Company (Optional)</Label>
                        <Input
                          name="from_company"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship From Company (Optional)"
                          type="text"
                          onChange={e => setFromCompany(e.target.value)}
                          value={from_company}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Phone <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="from_phone"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship From Phone"
                          type="text"
                          onWheel={e => e.target.blur()}
                          onChange={e => setFromPhone(e.target.value)}
                          value={from_phone}
                          required={true}
                          // minLength={"10"}
                          // maxLength={"10"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <PlacesAutocomplete
                        value={from_street1}
                        onChange={setFromStreet1}
                        onSelect={handleFromAddressStreet1}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <div className="form-group">
                              <Label className="form-label">
                                Address Street 1{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="from_street1"
                                // value={name}
                                className="form-control"
                                {...getInputProps({
                                  placeholder: "Enter Ship From Street 1",
                                })}
                                type="text"
                                // onChange={(e) => setFromStreet1(e.target.value)}
                                required={true}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: "65px",
                                left: "0px",
                                backgroundColor: "white",
                                zIndex: "99",
                                width: "100%",
                              }}
                            >
                              {loading ? (
                                <div
                                  style={{
                                    backgroundColor:
                                      localStorage.getItem("theme") == "dark"
                                        ? "#0f0f0f"
                                        : "#f1f1f1",
                                  }}
                                >
                                  Loading...
                                </div>
                              ) : null}
                              {suggestions.map(suggestion => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? localStorage.getItem("theme") == "dark"
                                      ? "#333"
                                      : "#ff6600"
                                    : localStorage.getItem("theme") == "dark"
                                    ? "#0f0f0f"
                                    : "#f1f1f1",
                                  padding: "5px 10px",
                                  border: "1px solid #efefef",
                                  cursor: "pointer",
                                }
                                return (
                                  <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <div className={"col-md-6"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Address Street 2 (Optional)
                        </Label>
                        <Input
                          name="from_street2"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship From Street 2 (Optional)"
                          type="text"
                          onChange={e => setFromStreet2(e.target.value)}
                          value={from_street2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Country <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name={"from_country"}
                          className="form-select"
                          onChange={handleFromCountry}
                          required={true}
                          value={from_country}
                        >
                          <option disabled={true} value={""}>
                            Select a country
                          </option>
                          {Country.getAllCountries().map((part, id) => {
                            return (
                              <option key={id} value={part.isoCode}>
                                {part.name}
                              </option>
                            )
                          })}
                          ;
                        </select>
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          City <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="from_city"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship From City"
                          type="text"
                          onChange={e => setFromCity(e.target.value)}
                          value={from_city}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Zip <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="from_zip"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship From Zip"
                          type="text"
                          onChange={e => setFromZip(e.target.value)}
                          value={from_zip}
                          required={true}
                          // minLength={5}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          State <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name={"from_state"}
                          className="form-select"
                          onChange={e => setFromState(e.target.value)}
                          value={from_state}
                          required={
                            State.getAllStates().filter(
                              el => el.countryCode === from_country
                            ).length > 0
                              ? true
                              : false
                          }
                        >
                          <option disabled={true} value={""}>
                            Select a state
                          </option>
                          {State.getAllStates()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(el => el.countryCode === from_country)
                            .map((part, id) => {
                              return (
                                <option key={id} value={part.isoCode}>
                                  {part.name}
                                </option>
                              )
                            })}
                          ;
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 mt-2">Ship To</h4>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Name <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="to_name"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship To Name"
                          type="text"
                          onChange={e => setToName(e.target.value)}
                          value={to_name}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">Company (Optional)</Label>
                        <Input
                          name="to_company"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship To Company (Optional)"
                          type="text"
                          onChange={e => setToCompany(e.target.value)}
                          value={to_company}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Phone
                          {/*<span style={{color:"red"}}>*</span>*/}
                        </Label>
                        <Input
                          name="to_phone"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship To Phone"
                          type="text"
                          onWheel={e => e.target.blur()}
                          onChange={e => setToPhone(e.target.value)}
                          value={to_phone}
                          // required={true}

                          // minLength={"10"}
                          // maxLength={"10"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <PlacesAutocomplete
                        value={to_street1}
                        onChange={setToStreet1}
                        onSelect={handleToAddressStreet1}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <div className="form-group">
                              <Label className="form-label">
                                Address Street 1{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="from_street1"
                                // value={name}
                                className="form-control"
                                {...getInputProps({
                                  placeholder: "Enter Ship To Street 1",
                                })}
                                type="text"
                                // onChange={(e) => setFromStreet1(e.target.value)}
                                required={true}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: "65px",
                                left: "0px",
                                backgroundColor: "white",
                                zIndex: "99",
                                width: "100%",
                              }}
                            >
                              {loading ? (
                                <div
                                  style={{
                                    backgroundColor:
                                      localStorage.getItem("theme") == "dark"
                                        ? "#0f0f0f"
                                        : "#f1f1f1",
                                  }}
                                >
                                  Loading...
                                </div>
                              ) : null}
                              {suggestions.map(suggestion => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? localStorage.getItem("theme") == "dark"
                                      ? "#333"
                                      : "#ff6600"
                                    : localStorage.getItem("theme") == "dark"
                                    ? "#0f0f0f"
                                    : "#f1f1f1",
                                  padding: "5px 10px",
                                  border: "1px solid #efefef",
                                  cursor: "pointer",
                                }
                                return (
                                  <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <div className={"col-md-6"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Address Street 2 (Optional)
                        </Label>
                        <Input
                          name="to_street2"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship To Street 2 (Optional)"
                          type="text"
                          onChange={e => setToStreet2(e.target.value)}
                          value={to_street2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Country <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name={"to_country"}
                          className="form-select"
                          onChange={handleToCountry}
                          value={to_country}
                          required={true}
                        >
                          <option disabled={true} value={""}>
                            Select a country
                          </option>
                          {Country.getAllCountries().map((part, id) => {
                            return (
                              <option key={id} value={part.isoCode}>
                                {part.name}
                              </option>
                            )
                          })}
                          ;
                        </select>
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          City <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="to_city"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Ship To City"
                          type="text"
                          onChange={e => setToCity(e.target.value)}
                          value={to_city}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Zip <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="to_zip"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Ship To Zip"
                          type="text"
                          onChange={e => setToZip(e.target.value)}
                          value={to_zip}
                          required={true}
                          // minLength={5}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          State <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name={"to_state"}
                          className="form-select"
                          onChange={e => setToState(e.target.value)}
                          value={to_state}
                          required={
                            State.getAllStates().filter(
                              el => el.countryCode === to_country
                            ).length > 0
                              ? true
                              : false
                          }
                        >
                          <option disabled={true} value={""}>
                            Select a state
                          </option>
                          {State.getAllStates()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(el => el.countryCode === to_country)
                            .map((part, id) => {
                              return (
                                <option key={id} value={part.isoCode}>
                                  {part.name}
                                </option>
                              )
                            })}
                          ;
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 mt-2">
                Return To
                <button
                  onClick={handleClearReturn}
                  style={{
                    float: "right",
                    fontWeight: "normal",
                    marginLeft: "10px",
                    marginTop: "-12px",
                  }}
                  disabled={is_return_same_as_from}
                  className="btn btn-sm btn-danger"
                  type="button"
                >
                  Clear
                </button>
                <span
                  style={{
                    float: "right",
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}
                >
                  Same as Ship From{" "}
                  <input
                    type="checkbox"
                    id="same_as_from"
                    onChange={handleReturnSameAsFrom}
                    className="order-checkbox"
                  />
                </span>
              </h4>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Name{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <Input
                          name="to_name"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Return To Name"
                          type="text"
                          onChange={e => {
                            setReturnName(e.target.value)
                            handleIsReturnEnable(
                              e.target.value,
                              return_company,
                              return_country,
                              return_phone,
                              return_street1,
                              return_street2,
                              return_city,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_name}
                          required={is_return_enable}
                          disabled={is_return_same_as_from}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">Company (Optional)</Label>
                        <Input
                          name="to_company"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Return To Company (Optional)"
                          type="text"
                          onChange={e => {
                            setReturnCompany(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              e.target.value,
                              return_country,
                              return_phone,
                              return_street1,
                              return_street2,
                              return_city,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_company}
                          disabled={is_return_same_as_from}
                        />
                      </div>
                    </div>
                    <div className={"col-md-4"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Phone{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <Input
                          name="to_phone"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Return To Phone"
                          type="text"
                          onWheel={e => e.target.blur()}
                          onChange={e => {
                            setReturnPhone(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              return_country,
                              e.target.value,
                              return_street1,
                              return_street2,
                              return_city,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_phone}
                          required={is_return_enable}
                          disabled={is_return_same_as_from}
                          // minLength={"10"}
                          // maxLength={"10"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <PlacesAutocomplete
                        value={return_street1}
                        onChange={e => {
                          setReturnStreet1(e)
                          handleIsReturnEnable(
                            return_name,
                            return_company,
                            return_country,
                            return_phone,
                            e,
                            return_street2,
                            return_city,
                            return_state,
                            return_zip
                          )
                        }}
                        onSelect={handleReturnAddressStreet1}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <div className="form-group">
                              <Label className="form-label">
                                Address Street 1{" "}
                                {is_return_enable ? (
                                  <span style={{ color: "red" }}>*</span>
                                ) : null}
                              </Label>
                              <Input
                                name="from_street1"
                                // value={name}
                                className="form-control"
                                {...getInputProps({
                                  placeholder: "Enter Return To Street 1",
                                })}
                                type="text"
                                // onChange={(e) => setFromStreet1(e.target.value)}
                                required={is_return_enable}
                                disabled={is_return_same_as_from}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: "65px",
                                left: "0px",
                                backgroundColor: "white",
                                zIndex: "99",
                                width: "100%",
                              }}
                            >
                              {loading ? (
                                <div
                                  style={{
                                    backgroundColor:
                                      localStorage.getItem("theme") == "dark"
                                        ? "#0f0f0f"
                                        : "#f1f1f1",
                                  }}
                                >
                                  Loading...
                                </div>
                              ) : null}
                              {suggestions.map(suggestion => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? localStorage.getItem("theme") == "dark"
                                      ? "#333"
                                      : "#ff6600"
                                    : localStorage.getItem("theme") == "dark"
                                    ? "#0f0f0f"
                                    : "#f1f1f1",
                                  padding: "5px 10px",
                                  border: "1px solid #efefef",
                                  cursor: "pointer",
                                }
                                return (
                                  <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {/*<div className={"col-md-6"}>*/}
                    {/*  <div className="form-group">*/}
                    {/*    <Label className="form-label">Address Street 1 <span style={{ color: "red" }}>*</span></Label>*/}
                    {/*    <Input*/}
                    {/*      name="to_street1"*/}
                    {/*      // value={name}*/}
                    {/*      className="form-control"*/}
                    {/*      placeholder="Enter Ship To Street 1"*/}
                    {/*      type="text"*/}
                    {/*      onChange={(e) => setToStreet1(e.target.value)}*/}
                    {/*      value={to_street1}*/}
                    {/*      required={true}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className={"col-md-6"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Address Street 2 (Optional)
                        </Label>
                        <Input
                          name="to_street2"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Return To Street 2 (Optional)"
                          type="text"
                          onChange={e => {
                            setReturnStreet2(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              return_country,
                              return_phone,
                              return_street1,
                              e.target.value,
                              return_city,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_street2}
                          disabled={is_return_same_as_from}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-md-12 mt-2"}>
                  <div className={"row"}>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Country{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <select
                          name={"to_country"}
                          className="form-select"
                          onChange={e => {
                            setReturnCountry(e.target.value)
                            if (e.target.value === "") {
                              setReturnState("")
                            }
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              e.target.value,
                              return_phone,
                              return_street1,
                              return_street2,
                              return_city,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_country}
                          required={is_return_enable}
                          disabled={is_return_same_as_from}
                        >
                          <option disabled={true} value={""}>
                            Select a country
                          </option>
                          {/*<option value={""}>No Country</option>*/}
                          {Country.getAllCountries().map((part, id) => {
                            return (
                              <option key={id} value={part.isoCode}>
                                {part.name}
                              </option>
                            )
                          })}
                          ;
                        </select>
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          City{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <Input
                          name="to_city"
                          // value={name}
                          className="form-control"
                          placeholder="Enter Return To City"
                          type="text"
                          onChange={e => {
                            setReturnCity(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              return_country,
                              return_phone,
                              return_street1,
                              return_street2,
                              e.target.value,
                              return_state,
                              return_zip
                            )
                          }}
                          value={return_city}
                          required={is_return_enable}
                          disabled={is_return_same_as_from}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Zip{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <Input
                          name="to_zip"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Return To Zip"
                          type="text"
                          onChange={e => {
                            setReturnZip(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              return_country,
                              return_phone,
                              return_street1,
                              return_street2,
                              return_city,
                              return_state,
                              e.target.value
                            )
                          }}
                          value={return_zip}
                          required={is_return_enable}
                          disabled={is_return_same_as_from}
                          // minLength={5}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          State{" "}
                          {is_return_enable ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <select
                          name={"to_state"}
                          className="form-select"
                          onChange={e => {
                            setReturnState(e.target.value)
                            handleIsReturnEnable(
                              return_name,
                              return_company,
                              return_country,
                              return_phone,
                              return_street1,
                              return_street2,
                              return_city,
                              e.target.value,
                              return_zip
                            )
                          }}
                          value={return_state}
                          required={
                            is_return_enable
                              ? State.getAllStates().filter(
                                  el => el.countryCode === return_country
                                ).length > 0
                                ? true
                                : false
                              : false
                          }
                          disabled={is_return_same_as_from}
                        >
                          <option disabled={true} value={""}>
                            Select a state
                          </option>
                          {/*<option value={""}>No State</option>*/}
                          {State.getAllStates()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter(el => el.countryCode === return_country)
                            .map((part, id) => {
                              return (
                                <option key={id} value={part.isoCode}>
                                  {part.name}
                                </option>
                              )
                            })}
                          ;
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {/*<h4 className="card-title mb-4 mt-2">Package Dimensions (Inches)</h4>*/}
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <div className={"row"}>
                    <h4 className="card-title mb-4 mt-2">
                      Dimensions & Weight
                    </h4>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Length
                          {isDHL && <span style={{ color: "red" }}> *</span>}
                          {/*<span style={{ color: "red" }}> *</span>*/}
                        </Label>
                        <Input
                          name="length"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Package Length"
                          type="number"
                          step="any"
                          onWheel={e => e.target.blur()}
                          onChange={e => setLength(e.target.value)}
                          value={length}
                          required={isDHL}
                          // required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Width
                          {isDHL && <span style={{ color: "red" }}> *</span>}
                          {/*<span style={{ color: "red" }}> *</span>*/}
                        </Label>
                        <Input
                          name="width"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Package Width"
                          type="number"
                          step="any"
                          onWheel={e => e.target.blur()}
                          onChange={e => setWidth(e.target.value)}
                          value={width}
                          required={isDHL}
                          // required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Height
                          {isDHL && <span style={{ color: "red" }}> *</span>}
                          {/*<span style={{ color: "red" }}> *</span>*/}
                        </Label>
                        <Input
                          name="height"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Package Height"
                          type="number"
                          step="any"
                          onWheel={e => e.target.blur()}
                          onChange={e => setHeight(e.target.value)}
                          value={height}
                          required={isDHL}
                          // required={true}
                        />
                      </div>
                    </div>
                    <div className={"col-md-3"}>
                      <div className="form-group">
                        <Label className="form-label">
                          Weight (Pounds){" "}
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="weight"
                          // value={name}
                          className="form-control non-arrow-number"
                          placeholder="Enter Package Weight"
                          type="number"
                          step="any"
                          onWheel={e => e.target.blur()}
                          onChange={e => setWeight(e.target.value)}
                          value={weight}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4 mt-2">Options</h4>
                  <div className={"row"}>
                    <div className={"col-md-3 mt-2"}>
                      <label>Receiver Signature</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <select
                            name={"receiver_signature"}
                            className="form-select"
                            onChange={e => setReceiverSignature(e.target.value)}
                            value={receiver_signature}
                          >
                            <option value={"0"}>No</option>
                            <option value={"1"}>Yes</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-3 mt-2"}>
                      <label>Saturday Delivery</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <select
                            name={"saturday_delivery"}
                            className="form-select"
                            onChange={e => setSaturdayDelivery(e.target.value)}
                            value={saturdayDelivery}
                          >
                            <option value={"0"}>No</option>
                            <option value={"1"}>Yes</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-3 mt-2"}>
                      <label>Order Number</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <input
                            type={"text"}
                            className={"form-control"}
                            placeholder={"Enter Order Number"}
                            maxLength={"20"}
                            value={order_number}
                            disabled={store_type != null}
                            onChange={e => {
                              if (store_type == null) {
                                setOrderNumber(e.target.value)
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-3 mt-2"}>
                      <label>Reference Number</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <input
                            type={"text"}
                            className={"form-control"}
                            placeholder={"Enter Reference Number"}
                            maxLength={"20"}
                            value={reference_number}
                            onChange={e => {
                              setReferenceNumber(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-6 mt-2"}>
                      <label>Custom Reference 1</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <input
                            type={"text"}
                            className={"form-control"}
                            placeholder={"Enter Custom Reference 1"}
                            maxLength={"20"}
                            value={customReference1}
                            onChange={e => {
                              setCustomReference1(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-6 mt-2"}>
                      <label>Custom Reference 2</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <input
                            type={"text"}
                            className={"form-control"}
                            placeholder={"Enter Custom Reference 2"}
                            maxLength={"20"}
                            value={customReference2}
                            onChange={e => {
                              setCustomReference2(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className={"col-md-12"}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4 mt-2">
                    Insurance
                    <input
                      className="order-checkbox"
                      type="checkbox"
                      // defaultChecked={defaultIsInsured}
                      id="insurance"
                      style={{ float: "right" }}
                      onChange={handleIsInsured}
                    />
                  </h4>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>
                        Cost of Shipment{" "}
                        <span style={{ position: "relative" }}>
                          <span
                            className={"bx bx-info-circle"}
                            onClick={() => {
                              setTooltip(!tooltip)
                            }}
                          ></span>
                          <span
                            className={
                              "tooltip-body-" + (tooltip ? "show" : "hidden")
                            }
                          >
                            Cost of shipment must be equal or greater than $100
                          </span>
                        </span>
                      </label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <div className="input-group">
                            <div className="input-group-text">$</div>
                            <Input
                              name="shipment_amount"
                              // value={name}
                              className="form-control non-arrow-number"
                              placeholder="Enter Cost of Shipment"
                              type="number"
                              step="any"
                              min={100}
                              onWheel={e => e.target.blur()}
                              onChange={handleShipmentAmount}
                              value={shipment_amount}
                              disabled={!isInsured}
                              required={isInsured}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-6"}>
                      <label>Cost of Insurance</label>
                      <div className={"row"}>
                        <div className={"col-md-12"}>
                          <div className="input-group">
                            <div className="input-group-text">$</div>
                            <Input
                              name="insurance_amount"
                              // value={name}
                              className="form-control non-arrow-number"
                              placeholder="Cost of Insurance"
                              type="number"
                              step="any"
                              onWheel={e => e.target.blur()}
                              // onChange={(e) => setInsuranceAmount(e.target.value)}
                              value={insurance_amount}
                              disabled={true}
                              // style={{backgroundColor:"#eff2f7"}}
                              required={isInsured}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 mt-2">Available Services</h4>
              <label>
                Select Carrier <span style={{ color: "red" }}>*</span>
              </label>
              <div
                className={"col-md-12 rounded p-2"}
                style={{ border: "1px solid #ced4da", cursor: "pointer" }}
                onClick={() => {
                  setSelectAvailableCarrier(!selectAvailableCarrier)
                }}
              >
                <h5 className={"mt-2"}>
                  {selectedCarrierName
                    ? selectedCarrierName
                    : "Select Available Carrier"}
                </h5>
              </div>
              <div
                className={"row"}
                style={{ display: selectAvailableCarrier ? "block" : "none" }}
              >
                <div className={"col-md-12"}>
                  <table className={"table table-hover"}>
                    <tbody>
                      {carriers.map((part, id) => {
                        return part.is_active == 1 ? (
                          <tr
                            key={id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              document
                                .getElementById(`carrier-${part.id}`)
                                .click()
                            }}
                          >
                            <td style={{ width: "10px" }}>
                              <input
                                className="order-checkbox"
                                type="radio"
                                name="carrier"
                                id={`carrier-${part.id}`}
                                data-name={part.name}
                                style={{ marginRight: "20px" }}
                                checked={part.id == selectedCarrier}
                                onClick={handleCarrier}
                                value={part.id}
                                required={true}
                              />
                            </td>
                            <td style={{ width: "30px" }}>
                              <img
                                src={
                                  part.name == "FedEx"
                                    ? fedex
                                    : part.name == "UPS" || part.name == "UPSv2"
                                    ? ups
                                    : part.name == "USPS"
                                    ? usps
                                    : dhl
                                }
                                height={"30px"}
                              />
                            </td>
                            <td>{part.name}</td>
                          </tr>
                        ) : (
                          ""
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <label>
                Service <span style={{ color: "red" }}>*</span>
              </label>
              <div
                className={"col-md-12 rounded p-2"}
                style={{ border: "1px solid #ced4da" }}
                onClick={() => {
                  if (selectedCarrier) {
                    setSelectAvailableService(!selectAvailableService)
                  } else {
                    toast.error("Please select a carrier")
                  }
                }}
              >
                <h5 className={"mt-2"}>
                  {service_name ? service_name : "Select Available Service"}
                </h5>
              </div>
              <div
                className={"row"}
                style={{ display: selectAvailableService ? "block" : "none" }}
              >
                <div className={"col-md-12"}>
                  <table className={"table table-hover"}>
                    <thead>
                      <tr>
                        <th colSpan={3}>Services</th>
                        <th style={{ textAlign: "right" }}>Discount Type</th>
                        {/* <th style={{ textAlign: "right" }}>Discount</th> */}
                        <th style={{ textAlign: "right" }}>Below 1 lb. Cost</th>
                        <th style={{ textAlign: "right" }}>1 lb. to 5 lb. Cost</th>
                        <th style={{ textAlign: "right" }}>Above 5 lb. Cost</th>
                        <th style={{ textAlign: "right" }}>Above 20 lb. Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services
                        .filter(el => el.carrier_id == selectedCarrier)
                        .map((part, id) => {
                          return (
                            <tr key={id} style={{ cursor: "pointer" }} onClick={() => {document.getElementById(`service-${part.id}`).click()}}>
                              <td style={{ width: "10px" }}>
                                <input
                                  className="order-checkbox"
                                  type="radio"
                                  name="service_id"
                                  style={{ marginRight: "20px" }}
                                  id={`service-${part.id}`}
                                  checked={part.id == service_id}
                                  data-name={part.name}
                                  onClick={e => {
                                    setServiceId(e.target.value)
                                    setServiceName(
                                      e.target.getAttribute("data-name")
                                    )
                                  }}
                                  value={part.id}
                                  required={true}
                                />
                              </td>
                              <td style={{ width: "30px" }}>
                                {/*<label*/}
                                {/*  className="form-check-label"*/}
                                {/*  style={{width:"100%"}}*/}
                                {/*  htmlFor={`service-${part.id}`}*/}
                                {/*>*/}
                                <img
                                  src={`data:image/jpeg;base64,${part.service_image}`}
                                  height={"30px"}
                                />
                                {/*</label>*/}
                              </td>
                              <td>
                                {part.name}
                                {part.description
                                  ? " - " + part.description
                                  : ""}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {part.discount_type === "None"
                                  ? "-"
                                  : part.discount_type}
                              </td>
                              {/* <td style={{ textAlign: "right" }}>
                                {part.discount == "" ? "-" : part.discount}
                              </td> */}
                              <td style={{ textAlign: "right" }}>
                                {part.under_one_lbs_price ? `${part.discount_type === "None"? part.under_one_lbs_price: part.discount_type === "Flat"? part.under_one_lbs_price -part.discount: part.under_one_lbs_price -(part.discount / 100) *part.under_one_lbs_price}`: "-"}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {part.price
                                  ? `${
                                      part.discount_type === "None"
                                        ? part.price
                                        : part.discount_type === "Flat"
                                        ? part.price - part.discount
                                        : part.price -
                                          (part.discount / 100) * part.price
                                    }`
                                  : "-"}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {part.above_five_lbs_price
                                  ? `${
                                      part.discount_type === "None"
                                        ? part.above_five_lbs_price
                                        : part.discount_type === "Flat"
                                        ? part.above_five_lbs_price -
                                          part.discount
                                        : part.above_five_lbs_price -
                                          (part.discount / 100) *
                                            part.above_five_lbs_price
                                    }`
                                  : "-"}
                              </td>

                              <td style={{ textAlign: "right" }}>
                                {part.above_twenty_lbs_price
                                  ? `${
                                      part.discount_type === "None"
                                        ? part.above_twenty_lbs_price
                                        : part.discount_type === "Flat"
                                        ? part.above_twenty_lbs_price -
                                          part.discount
                                        : part.above_twenty_lbs_price -
                                          (part.discount / 100) *
                                            part.above_twenty_lbs_price
                                    }`
                                  : "-"}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*<h4 className="card-title mb-4 mt-2">Available Services</h4>*/}
              {/*<label style={{width:"100%"}}>Service <span style={{color:"red"}}>*</span> <span style={{float:"right"}}>Costs</span></label>*/}
              {/*<div className={"row"}>*/}
              {/*  <div className={"col-md-12"}>*/}
              {/*    {services.map((part, id) => {*/}
              {/*      return (*/}
              {/*        <div key={id} className="form-check mb-3">*/}
              {/*          <input*/}
              {/*            className="order-checkbox"*/}
              {/*            type="radio"*/}
              {/*            name="service_id"*/}
              {/*            checked={part.id==service_id}*/}
              {/*            // defaultChecked={part.id==service_id}*/}
              {/*            // id={`service-${part.id}`}*/}
              {/*            onClick={(e) => setServiceId(e.target.value)}*/}
              {/*            value={part.id}*/}
              {/*            required={true}*/}
              {/*          />*/}
              {/*          <label*/}
              {/*            className="form-check-label"*/}
              {/*            style={{width:"100%"}}*/}
              {/*            htmlFor={`service-${part.id}`}*/}
              {/*          >*/}
              {/*            <img src={`data:image/jpeg;base64,${part.service_image}`} height={"30px"}/> {part.name}{part.description?" - "+part.description:""} <span style={{float:"right"}}>${part.discount_type==="None"?part.price:(part.discount_type==="Flat"?part.price - part.discount:part.price - (part.discount / 100 * part.price))}</span>*/}
              {/*          </label>*/}
              {/*        </div>*/}
              {/*      );*/}
              {/*    })}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </CardBody>
          </Card>
          {store_type == null ? (
            <>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4 mt-2">Custom Items</h4>
                  {customFormItems && customFormItems.length !== 0
                    ? customFormItems.map((el, id) => {
                        return (
                          <Row
                            key={id}
                            style={{
                              border: "2px solid #eeeeee",
                              padding: "10px",
                              borderRadius: "10px",
                              marginBottom: "20px",
                              display: el.status === 1 ? "block" : "none",
                            }}
                          >
                            <Col xl={12}>
                              <Row>
                                <h5>
                                  <span style={{ width: "100%" }}>
                                    Custom Item
                                    {id >= 0 ? (
                                      <button
                                        className={"btn btn-danger btn-sm"}
                                        type={"button"}
                                        style={{
                                          marginLeft: "20px",
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          handleRemoveCustomItem(el.item_id)
                                        }}
                                      >
                                        <i
                                          style={{
                                            fontSize: "18px",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                          className="bx bx-x color-danger mt-1"
                                        ></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </h5>
                              </Row>
                              <div className="mb-3">
                                <Label>
                                  Description of Items{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  data-id={el.item_id}
                                  onChange={handleItemDescription}
                                  defaultValue={el.description}
                                  required={el.status === 1 ? true : false}
                                  placeholder="Enter Description of Items"
                                />
                              </div>
                              <Row>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>
                                      Quantity{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      onWheel={e => e.target.blur()}
                                      data-id={el.item_id}
                                      onChange={handleItemQuantity}
                                      defaultValue={el.quantity}
                                      required={el.status === 1 ? true : false}
                                      className="form-control non-arrow-number"
                                      placeholder="Enter Quantity"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>
                                      Unit Price{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      onWheel={e => e.target.blur()}
                                      data-id={el.item_id}
                                      defaultValue={el.total_value}
                                      className="form-control non-arrow-number"
                                      onChange={handleItemTotalValue}
                                      required={el.status === 1 ? true : false}
                                      placeholder={"Enter Unit Price"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>
                                      Total Weight (oz){" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      onWheel={e => e.target.blur()}
                                      className="form-control non-arrow-number"
                                      data-id={el.item_id}
                                      defaultValue={el.total_weight}
                                      onChange={handleItemTotalWeight}
                                      required={el.status === 1 ? true : false}
                                      placeholder={"Enter Total Weight (oz)"}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>Harmonized Tariff Schedule</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      data-id={el.item_id}
                                      defaultValue={
                                        el.harmonized_tariff_schedule
                                      }
                                      onChange={handleItemHarmonized}
                                      // required={(el.status === 1?true:false)}
                                      placeholder={
                                        "Enter Harmonized Tariff Schedule"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>
                                      Product Identifier{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      data-id={el.item_id}
                                      defaultValue={el.product_identifier}
                                      onChange={handleItemProductIdentifier}
                                      required={el.status === 1 ? true : false}
                                      placeholder={"Enter Product Identifier"}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6} md={6}>
                                  <div className="mb-3">
                                    <Label>
                                      Origin Country{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <select
                                      name={"origin_country"}
                                      className="form-select"
                                      data-id={el.item_id}
                                      defaultValue={el.origin_country}
                                      onChange={handleItemOriginCountry}
                                      required={el.status === 1 ? true : false}
                                    >
                                      <option
                                        disabled={true}
                                        selected={true}
                                        value={""}
                                      >
                                        Select a country
                                      </option>
                                      {Country.getAllCountries().map(
                                        (part, id) => {
                                          return (
                                            <option
                                              key={id}
                                              value={part.isoCode}
                                            >
                                              {part.name}
                                            </option>
                                          )
                                        }
                                      )}
                                      ;
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                      })
                    : ""}
                  <input
                    type="button"
                    className="btn btn-success mt-3 mt-lg-0"
                    value="Add Item"
                    onClick={handleCustomFormAddItem}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className={"col-md-12"}>
                    <div className="form-check form-check-end">
                      <input
                        className="order-checkbox"
                        type="checkbox"
                        value={customCheckbox}
                        disabled={!isFromToCountryDiff}
                        id="customCheckbox"
                        style={{ marginRight: "10px" }}
                        onChange={handleCustomCheckBox}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckbox"
                      >
                        Custom Form
                      </label>
                    </div>
                    {customCheckbox ? (
                      <div className={"mt-3"}>
                        <hr />
                        <h4 className="card-title mb-3">
                          Custom Info (required)
                        </h4>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>Content Type</Label>
                              <select
                                name={"content_type"}
                                className="form-select"
                                onChange={e => setContentType(e.target.value)}
                                value={content_type}
                                // required={customCheckbox}
                              >
                                <option value={"Merchandise"}>
                                  Merchandise
                                </option>
                                <option value={"Returned Goods"}>
                                  Returned Goods
                                </option>
                                <option value={"Documents"}>Documents</option>
                                <option value={"Gifts"}>Gifts</option>
                                <option value={"Sample"}>Sample</option>
                                <option value={"Other"}>Other</option>
                              </select>
                            </div>
                            <div className="mb-3">
                              <label className="control-label">
                                Restriction Type
                              </label>
                              <select
                                name={"restriction_type"}
                                onChange={e =>
                                  setRestrictionType(e.target.value)
                                }
                                value={restriction_type}
                                className="form-select"
                                // required={customCheckbox}
                              >
                                <option value={"None"}>None</option>
                                <option value={"Other"}>Other</option>
                                <option value={"Quarantine"}>Quarantine</option>
                                <option
                                  value={"Sanitary Phytosanitary Inspection"}
                                >
                                  Sanitary Phytosanitary Inspection
                                </option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <label className="form-label">
                                Content Explanation
                              </label>
                              <input
                                className="form-control"
                                name={"content_explanation"}
                                onChange={e =>
                                  setContentExplanation(e.target.value)
                                }
                                value={content_explanation}
                                type="text"
                                placeholder="Enter Content Explanation"
                                // required={customCheckbox}
                              />
                            </div>
                            <div className="mb-3 templating-select select2-container">
                              <label className="form-label">
                                Restriction Comments
                              </label>
                              <input
                                className="form-control"
                                name={"restriction_comments"}
                                onChange={e =>
                                  setRestrictionComments(e.target.value)
                                }
                                value={restriction_comments}
                                type="text"
                                placeholder="Enter Restriction Comments"
                                // required={customCheckbox}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <Label>Non Delivery Action</Label>
                              <select
                                className="form-select"
                                name={"non_delivery_action"}
                                onChange={e =>
                                  setNonDeliveryAction(e.target.value)
                                }
                                value={non_delivery_action}
                                // required={customCheckbox}
                                // isDisabled={true}
                              >
                                <option value={"Return"}>Return</option>
                                <option value={"Abandon"}>Abandon</option>
                              </select>
                            </div>
                            <div className="mb-3 mt-3 templating-select select2-container">
                              <label className="form-label">EEL or PPC</label>
                              <input
                                className="form-control"
                                name={"eel_or_ppc"}
                                onChange={e => setEelOrPpc(e.target.value)}
                                value={eel_or_ppc}
                                type="text"
                                placeholder="Enter EEL or PPC"
                                // required={customCheckbox}
                              />
                            </div>
                            <input
                              type={"hidden"}
                              // onChange={(e) => setCustomFormItemsData(e.target.value)}
                              value={customFormItemsData}
                              name={"custom_form_items"}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}

          {validationError != null ? (
            <Card>
              <CardBody
                style={{ border: "2px solid #f46a6a", borderRadius: "5px" }}
              >
                <Label>Validation Error</Label>
                <ul>
                  {validationError != ""
                    ? validationError.split(",").map((part, id) => {
                        if (part) {
                          return (
                            <li className={"text-danger"} key={id}>
                              {part}
                            </li>
                          )
                        }
                      })
                    : ""}
                </ul>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <div className="text-center mt-4">
            <Button
              type="button"
              onClick={() => props.history.push("/orders/awaiting-shipment")}
              color="danger"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success">
              Update Order
            </Button>
            <br />
            <br />
          </div>
        </Form>
      </div>
    </div>
  )
}
OrderEdit.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default OrderEdit
