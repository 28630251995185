// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';

import axios from 'axios'

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';


import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from '../../../components/PaypalButton'
import coinbase from '../../../assets/images/Coinbase.svg.png'
import config from '../../../config';
import OrderViewModal from "components/OrderViewModal";
import payment_successful from '../../../assets/images/Successful Payment.gif'
import payment_failed from '../../../assets/images/Payment Error.gif'
import logo from '../../../assets/images/nullship-icon.png';
import { amountFormat } from "../../../assets/js/numberFormatter"

function WalletHistoryOld(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [balance, setBalance] = useState("-");

  // const [coinbaseLogs, setCoinbaseLogs] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);

  const [inputAmount, setInputAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const [coinbaseKey, setCoinbaseKey] = useState(null);
  const [coinbaseWebhookSecret, setCoinbaseWebhookSecret] = useState(null);

  const [product, setProduct] = useState({
    "name":"NullShip",
    "description":"Add balance to your account",
    "logo_url": logo,
    "pricing_type":"fixed_price",
    "local_price": {
      "amount": '',
      "currency": "USD"
    },
    "metadata": {
      "user_id": JSON.parse(localStorage.getItem("authUser")).id
    },
    "redirect_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Successful`,
    "cancel_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Failed`
  });

  const [rowId, setRowId] = useState();

  const [paymentStatus, setPaymentStatus] = useState(null);

  const [pending, setPending] = useState(true);


  // let product = {
  //   // price: 100,
  //   price: parseFloat(inputAmount),
  //   description: "checkout",
  // }
  // // console.log(product.price)

  //OLD WAY
  const columns = [
    {
      name: 'Type',
      selector: 'type',
      //  sortable: true,
      cell: (row) => row.type === "credit" ? <span className={"badge badge-success"}>{row.type}</span> : <span className={"badge badge-danger"}>{row.type}</span>
    },
    {
      name: 'Deduction',
      selector: 'balance',
      //  sortable: true,
      cell: row => row.balance ? "$" + amountFormat(row.balance) : "-"
    },
    {
      name: 'Remaining Balance',
      selector: 'remaining_amount',
      //  sortable: true,
      width: "180px",
      cell: row => row.remaining_amount ? "$" + amountFormat(row.remaining_amount) : "-"
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      width:"230px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: "Payment Mode",
      selector: 'payment_mode',
      sortable: true,
      width:"140px",
      cell: (row) => row.payment_mode !== null ? row.payment_mode : "-"
    },
    {
      name: 'Description',
      selector: ['type', 'order_id'],
      // sortable: true,
      width:"140px",
      cell: row => row.type === "credit" ? row.order_id ? "Refund" : "-" : "-"
    },
    {
      name: 'Actions',
      selector: 'order_id',
      width:"120px",
      cell: (row) => row.order_id ? <><button className={"btn btn-success btn-sm"} onClick={() => {
        setRowId(row.order_id)
        setViewModal(true)
        // handleView(row.order_id)
      }}>View Order</button></> : ""
    }
  ];



  function getQueryParam(name) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  }

  const recursiveRetrieve = (page, orderData = []) => {
    const body = {
      id: JSON.parse(localStorage.getItem('authUser')).id,
      page: page
    };
    // post('/user/get-wallet-history', body, { headers }).then((response) => {
    //   // // console.log("response", response);
    //   setData(response.data);
    //   let data = response.data;
    //   // console.log("table data" , { columns, data });
    //   setTableData({ columns, data });
    //   setPending(false);
    // })
    post("/user/get-wallet-history", body, { headers }).then(
      async (response) => {
        if(response.status === 200) {
          if(response.data.length > 0) {
            let res;
            if(orderData.length == 0) {
              res = response.data;
            }
            else {
              res = orderData.concat(response.data);
            }
            setFilteredData(res)
            let data = res;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            let nextPage = +page + +1;
            // console.log("next page", nextPage)
            await recursiveRetrieve(nextPage, res);
          }
          else {
            setFilteredData(orderData)
            let data = orderData;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            return data;
          }
        }
        else {
          setFilteredData(orderData)
          let data = orderData;
          // console.log("table data", { columns, data })
          setTableData({ columns, data })
          setPending(false)
          return data;
        }
      }
    )
  }

  const retrieveBalance = async () => {
    try {
      setPending(true);
      // let page = 1;
      // const fetchingOrders = await recursiveRetrieve(page);
      // // console.log("fetchingOrders",fetchingOrders);

      const body = {
        id: JSON.parse(localStorage.getItem('authUser')).id
      };
      post('/user/get-wallet-history-old', body, { headers }).then((response) => {
        // // console.log("response", response);
        // // Old Way
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {

    }
  }

  const getUserBalance = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/get-balance', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance);
        setBalance(response.balance);
      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  const handleAmount = (e) => {
    setProduct({ ...product, local_price: {
        amount: parseFloat(e.target.value),
        currency: "USD"
      } })
    // // console.log("ppp", { ...product, price: parseFloat(e.target.value) });

    if (e.target.value === "") {
      setDisabled(true);
    }
    else if (parseInt(e.target.value) === 0) {
      setDisabled(true);
    }
    else {
      setDisabled(false);
    }
  }

  const retrieveCoinbaseKey = async () => {
    await get('/admin/get-coinbase-key', { headers }).then((response) => {
      // console.log("key response", response);
      if (response.status === 200) {
        setCoinbaseKey(response.key);
        setCoinbaseWebhookSecret(response.secret);
      }
      else {
        setCoinbaseKey(null);
        setCoinbaseWebhookSecret(null);
      }
    })
  }

  useEffect(() => {
    // console.log("product",product);
    retrieveBalance();
    getUserBalance();
    retrieveCoinbaseKey();
    if(getQueryParam('status') == "Successful" || getQueryParam('status') == "Failed") {
      setPaymentStatus(getQueryParam('status'));
    }
  }, []);

  const handlePayWithCoinbase = async () => {
    if(coinbaseKey && coinbaseWebhookSecret) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.commerce.coinbase.com/charges',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CC-Api-Key': coinbaseKey,
          'X-CC-Version': '2018-03-22',
          'X-CC-Webhook-Signature': coinbaseWebhookSecret
        },
        data: JSON.stringify(product)
      };

      // console.log("config", config);

      axios(config)
        .then((response) => {
          // console.log("create coinbase",response.data.data);
          window.location.href = response.data.data.hosted_url;
        })
        .catch((error) => {
          // console.log("catch");
          // console.log(error);
          toast.error("Something went wrong, Please try again later");
        });
    }
    else {
      toast.error("Something went wrong, Please try again later");
    }
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    // console.log("download array",array);
    const link = document.createElement('a');

    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = type + moment(new Date()).format("DD-MM-YYYY") + '.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => <button className={"export-csv-btn"} onClick={e => onExport(e.target.value)}>Export CSV</button>;

  const actionsMemo = (array) => {
    // if(encryptStorage.getItem("user-type") === "user") {
    // console.log("array",array);
    return (<Export onExport={() => downloadCSV(array)} />);
    // }
  };

  //meta title
  document.title = "Wallet History | NullShip";

  return (
    <PayPalScriptProvider
      options={{ "client-id": config.paypal.CLIENT_ID }}
    >
      <div className={"align-items-center justify-content-center"} style={{display:(paymentStatus?"flex":"none"),alignItems:"center",margin:"auto", position: "absolute",width:"100%",height:"100vh",backgroundColor:"rgba(0,0,0,0.5)",zIndex:"99999",top:"0",left:"0"}}>
        <div className={"col-sm-10 col-md-4 bg-white rounded shadow p-2"}>
          <h4 className={"text-center"}>Payment {paymentStatus}</h4>
          <img className={"center-block"} src={paymentStatus=="Successful"?payment_successful:payment_failed} height={"200px"}/>
          <h2 className={"text-center"}>{paymentStatus=="Successful"?"It may take several minutes to process your transaction.":""}</h2>
          <hr/>
          <span style={{width:"100%",textAlign:"center"}}>
               <button className={"btn btn-warning btn-sm mb-2"} onClick={() => {
                 props.history.replace("wallet-history");
                 setPaymentStatus(null);
               }} style={{float:"right"}}>Continue</button>
            </span>
        </div>
      </div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="NullShip" breadcrumbItem="Wallet History" />
          {/* <Table columns={columns} data={data} /> */}
          <Card>
            <CardBody>
              <div className={"row"}>
                <div className={"col-md-12 col-lg-10 col-xl-8 col-xxl-6 btn-div"} style={{ height: "fit-content",display:"flex" }}>
                  <button className={"btn btn-success btn-sm"} onClick={() => {setPaymentModal(!paymentModal)}}><i className={"bx bx-dollar"}></i> Add Balance</button>
                  {/*<button onClick={() => handleCoinbasePaymentSuccess('ZBJBTHYE')}>Show Charge</button>*/}
                  {/*<button onClick={() => { setPaymentModal(!paymentModal); }} className={"btn btn-light btn-sm waves-effect waves-light"}>Pay with <span style={{fontWeight:"600",color:"#003087",fontStyle:"italic"}}>Pay</span><span style={{fontWeight:"600",color:"#009cde",fontStyle:"italic"}}>Pal</span></button>*/}
                  {/*<div className="vr" style={{ border: "1px solid black", margin: "0px 10px" }}></div>*/}



                  {/*{coinbaseKey?<CoinbaseCommerceButton*/}
                  {/*    style={{*/}
                  {/*      justifyContent: 'center', marginLeft: "10px", cursor: "pointer"*/}
                  {/*    }}*/}
                  {/*    className={"btn btn-light btn-sm waves-effect waves-light"*/}
                  {/*    // + (disabled ? "button-coinbase-disabled" : "button-coinbase")*/}
                  {/*    }*/}
                  {/*    placeholder={"nullship"}*/}
                  {/*    checkoutId={coinbaseKey}*/}
                  {/*    onChargeSuccess={async (MessageData) => {*/}
                  {/*      // let load = toast.loading("Payment processing...");*/}
                  {/*      // console.log("Payment successful", MessageData)*/}
                  {/*      await handleCoinbasePaymentLogs(MessageData).then( () => {*/}
                  {/*        toast.success("Your transaction was successful. The payment shall be added to your waller after approval from admin");*/}
                  {/*        // setTimeout(async() => {*/}
                  {/*        //   await handleCoinbasePaymentSuccess(MessageData.code).then(() => {*/}
                  {/*        //     toast.remove(load);*/}
                  {/*        //     toast.success("Payment successful");*/}
                  {/*        //   });*/}
                  {/*        // },30000);*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*    onChargeFailure={*/}
                  {/*      (MessageData) => {*/}
                  {/*        toast.error("Payment failed");*/}
                  {/*        // console.log("Payment failed", MessageData.code)*/}
                  {/*        try {*/}
                  {/*          handleCoinbasePaymentLogs(MessageData)*/}
                  {/*        }*/}
                  {/*        catch (e) {*/}
                  {/*          // console.log("Error handleCoinbasePaymentLogs", e)*/}
                  {/*        }*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*    onPaymentDetected={*/}
                  {/*      (MessageData) => {*/}
                  {/*        alert("Payment detected")*/}
                  {/*        // console.log("Payment detected", MessageData)*/}
                  {/*        handleCoinbasePaymentLogs(MessageData)*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    Pay with <span style={{fontWeight:"600",color:"#0052ff"}}>coinbase</span>*/}
                  {/*    /!*<img src={coinbase} height={'15px'} />*!/*/}
                  {/*  </CoinbaseCommerceButton>:""}*/}
                </div>
                <div className={"col-md-2 col-lg-2 col-xl-4 col-xxl-6"} style={{ position: "relative" }}>
                  {
                    JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                      <div className={"order-desktop-balance"} style={{ right: '10px', top: '-10px', position: "absolute" }}>
                        <h6>Balance<br /><span style={{ fontSize: "1.5rem" }}>${amountFormat(balance)}</span></h6>
                      </div> : ""
                  }
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className={"order-mobile-balance"}>
            {/*<Card>*/}
            <CardBody>
              {
                JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                  <div>
                    <h6>Balance<hr /><span style={{ fontSize: "1.5em" }}>${amountFormat(balance)}</span></h6>
                  </div> : ""
              }
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}>
              </div>
              <DataTableExtensions
                {...tableData}
                exportHeaders={true}
                filterPlaceholder={`Filter Wallet History...`}
              >
                <DataTable columns={columns} actions={actionsMemo(filteredData)} data={filteredData} pagination={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                           paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                           highlightOnHover={true} />
              </DataTableExtensions>
            </CardBody>
          </Card>


          {/*Modal*/}
          <Modal
            isOpen={paymentModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setPaymentModal(!paymentModal);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setPaymentModal(!paymentModal);
                }}
              >
                Add Balance To Your Wallet
              </ModalHeader>
              <ModalBody>
                <div className={"row"}>
                  <div className="form-group">
                    <Label className="form-label">Enter Amount</Label>
                    <div className="input-group">
                      <div className="input-group-text">$</div>
                      <Input
                        // name="input_amount"
                        className="form-control  non-arrow-number"
                        placeholder="Enter Amount"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleAmount}
                        value={product.local_price.amount}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  margin: 10
                }}>
                  {/*<div style={{ marginRight: "10px", marginTop: "5px" }}>*/}
                  {/*  <PaypalButton*/}
                  {/*    isDisabled={disabled}*/}
                  {/*    product={product}*/}
                  {/*    updateData={() => {*/}
                  {/*      // console.log("handle update data");*/}
                  {/*      retrieveBalance();*/}
                  {/*      getUserBalance();*/}
                  {/*      setPaymentModal(!paymentModal);*/}
                  {/*      setDisabled(true);*/}
                  {/*      setProduct({*/}
                  {/*        price: 0,*/}
                  {/*        description: "NullShip add balance"*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {/*<div className={"vr"} style={{ border: "1px solid black", margin: "0px 10px" }}></div>*/}
                  <button
                    style={{
                      justifyContent: 'center', marginLeft: "10px", cursor: "pointer",padding: "8px 30px 10px 30px"
                    }}
                    className={"btn btn-light waves-effect waves-light"}
                    disabled={disabled}
                    onClick={handlePayWithCoinbase}
                  >
                    <img src={coinbase} height={'15px'} />
                  </button>
                </div>
              </ModalBody>
            </div>
          </Modal>
          {/*Modal*/}

          <OrderViewModal
            orderId={rowId}
            viewModal={viewModal}
            showPrice={false}
            setViewModal={(data) => { setViewModal(data) }}
          />

        </div>
      </div>
    </PayPalScriptProvider >
  );
}
WalletHistoryOld.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default WalletHistoryOld;