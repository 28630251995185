import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap"
import config from "../../config"
//redux
import { useDispatch } from "react-redux"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import img11 from "assets/images/img11.png"
import img12 from "assets/images/img12.png"
import anim_logo from "../../assets/images/ainimationlogo.gif"
import Recaptcha from "components/Recaptcha"
import "../../assets/css/loginfooter.css"
import { post } from "../../helpers/api_helper"
import { Toaster } from "react-hot-toast"
import { toast } from "react-hot-toast"
import loader from "../../assets/images/loader.gif"
import { GoogleTagManager, initGA } from "../../assets/js/gtag"
import Account from "../../assets/images/Account.svg"
import Apple from "../../assets/images/apple.png"
import Google from "../../assets/images/google.png"
import "./stylesheet.css"
import AuthHeader from "components/Common/AuthHeader"
import AuthImg from "../../assets/images/AuthImg.svg"
import AuthFooter from "components/Common/AuthFooter"
import LockIcon from "../../assets/images/Lock.svg"
import EnvelopeIcon from "../../assets/images/Envelope.svg"
const Login = props => {
  const [responseSuccess, setResponseSuccess] = useState(false)
  const [responseError, setResponseError] = useState(false)
  const [responseMessage, setResponseMessage] = useState("")
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [isShow, setIsShow] = useState(true)

  //meta title
  document.title = "Login | NullShip"

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      handleLogin(values)
    },
  })

  useEffect(() => {
    document.body.setAttribute("data-layout-mode", "light")
    const urlParams = new URLSearchParams(window.location.search)
    if (localStorage.getItem("authUser")) {
      if (
        localStorage.getItem("authUser") &&
        JSON.parse(localStorage.getItem("authUser")).role_id == 2
      ) {
        if (urlParams.get("auth") == "shopify" && urlParams.get("hmac")) {
          window.location =
            config.site.BASE_URL + "/shopify/auth?shop=" + urlParams.get("shop")
          return true
        }
      }
      window.location = "/dashboard"
    }

    if (config.site.MODE == "production") {
      // initGA();
      GoogleTagManager("UA-254226105-1")
      GoogleTagManager("G-YQ0XXFE0NR")
    }

    window.scrollTo({ top: 0, behavior: "smooth" })
    setIsShow(true)
    document.body.style.visibility = "hidden"
    document.body.style.overflowY = "hidden"
    document.body.style.overflowX = "hidden"
    setTimeout(() => {
      document.body.style.visibility = "visible"
      setTimeout(() => {
        setIsShow(false)
        document.body.style.overflowY = "visible"
      }, 600)
    }, 500)
  }, [])

  const handleLogin = async values => {
    try {
      if (recaptchaToken) {
        const { email, password } = values
        document.body.style.pointerEvents = "none"
        let loading = toast.loading("Processing your request...")
        let body = {
          email: email,
          password: password,
          token: recaptchaToken,
        }
        setIsShow(true)
        await post("/user/login", body).then(response => {
          // console.log("response", response);
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status === 200) {
            localStorage.removeItem("authUser")
            localStorage.removeItem("logout-event")
            localStorage.removeItem("token")
            localStorage.setItem("authUser", JSON.stringify(response.user))
            // localStorage.setItem("gapi", response.google_api);
            localStorage.setItem("token", response.token)
            localStorage.setItem("store", "0")
            localStorage.setItem("skt", "false")
            if (response.user.role_id === 2) {
              localStorage.setItem("balance", response.user.balance)
              //Store 0 = Manual Store
              localStorage.setItem("store", 0)
            } else {
              localStorage.setItem("theme", "light")
            }

            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get("auth") == "shopify" && urlParams.get("hmac")) {
              window.location =
                config.site.BASE_URL +
                "/shopify/auth?shop=" +
                urlParams.get("shop")
            } else {
              props.history.push("/dashboard")
            }
          } else if (response.status === 300) {
            props.history.push(response.url)
          } else {
            setIsShow(false)
            setResponseSuccess(false)
            setResponseError(true)
            setResponseMessage(response.message)
          }
        })
      } else {
        toast.error("Please verify that you are not a robot")
      }
    } catch (e) {
      // console.log(e);
    }
  }

  const handleUpdate = newValue => {
    setRecaptchaToken(newValue)
  }
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName={"zin"}
      />
      <div
        id="loader"
        className={"align-items-center justify-content-center"}
        style={{
          display: isShow ? "flex" : "none",
          alignItems: "center",
          margin: "auto",
          position: "fixed",
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          zIndex: "99999",
          top: "0",
          left: "0",
        }}
      >
        <img style={{ width: "10%" }} src={loader} />
      </div>
      <div>
        {/* //loginpage below */}
        <div
          className="col-xl-11 "
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: "10px",
              margin: 0,
            }}
          >
            <CardBody
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Row>
                <Col xl={6} className="segap p-0 ps-3 pe-3 ps-md-5 pe-md-5">
                  <div className="account-pages  pt-sm-3">
                    <Container>
                      <Row className="justify-content-center">
                        <Col md={12} lg={12}>
                          <div className="overflow-hidden d-flex flex-column align-items-center justify-content-center">
                            <AuthHeader
                              label="Don't have an account?"
                              link="/register"
                              btnLabel="Register"
                            />

                            <div className="d-flex flex-column align-items-center justify-content-center mt-3 width80">
                              <img src={Account} alt="Account" />
                              <h3 className="mt-2">Login to your Account</h3>
                              <p className="mb-3">
                                Enter your details to login
                              </p>
                              <div className="d-flex w-100 justify-content-center gap-5">
                                <Button className="pt-1 pb-1 googleBtn">
                                  <img src={Apple} alt="apple" />
                                </Button>
                                <Button className="pt-1 pb-1 googleBtn">
                                  {" "}
                                  <img src={Google} alt="google" />
                                </Button>
                              </div>
                              <div className="d-flex align-items-center justify-content-center w-100 mt-4 mb-3 gap-2">
                                <div
                                  style={{
                                    border: "1px solid #e1e4ea",
                                    width: "35%",
                                    height: "1px",
                                  }}
                                ></div>
                                <p className="mb-0 mx-1">OR</p>
                                <div
                                  style={{
                                    border: "1px solid #e1e4ea",
                                    width: "35%",
                                    height: "1px",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <CardBody className="pt-0 width80">
                              <div className="p-2">
                                <Form
                                  className="form-horizontal"
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                  }}
                                >
                                  {responseError ? (
                                    <Alert color="danger">
                                      {responseMessage}
                                    </Alert>
                                  ) : null}

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Email{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                        }}
                                      >
                                        *
                                      </span>
                                    </Label>
                                    <InputGroup
                                      className={
                                        validation.touched.email &&
                                        validation.errors.email
                                          ? "1px solid red"
                                          : ""
                                      }
                                      style={{
                                        border:
                                          validation.touched.email &&
                                          validation.errors.email
                                            ? "1px solid red"
                                            : "1px solid #ced4da",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <InputGroupText
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                        }}
                                      >
                                        <img
                                          src={EnvelopeIcon}
                                          alt="EnvelopeIcon"
                                        />
                                      </InputGroupText>
                                      <Input
                                        name="email"
                                        placeholder="Enter email"
                                        type="email"
                                        required={true}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                          validation.touched.email &&
                                          validation.errors.email
                                        }
                                        style={{
                                          border: "none",
                                        }}
                                      />
                                    </InputGroup>
                                  </div>

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Password{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                        }}
                                      >
                                        *
                                      </span>
                                    </Label>
                                    <InputGroup
                                      className={
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? "1px solid red"
                                          : ""
                                      }
                                      style={{
                                        border:
                                          validation.touched.password &&
                                          validation.errors.password
                                            ? "1px solid red"
                                            : "1px solid #ced4da",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <InputGroupText
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                        }}
                                      >
                                        <img src={LockIcon} alt="LockIcon" />
                                      </InputGroupText>
                                      <Input
                                        name="password"
                                        value={validation.values.password || ""}
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        required={true}
                                        placeholder="Enter Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.password &&
                                          validation.errors.password
                                            ? true
                                            : false
                                        }
                                        style={{
                                          border: "none",
                                        }}
                                      />
                                      <InputGroupText
                                        onClick={toggleShowPassword}
                                        style={{
                                          cursor: "pointer",
                                          background: "white",
                                          // borderTopRightRadius: "10px",
                                          // borderBottomRightRadius: "10px",
                                          border: "none",
                                        }}
                                      >
                                        {showPassword ? (
                                          <FaEyeSlash />
                                        ) : (
                                          <FaEye />
                                        )}
                                      </InputGroupText>
                                    </InputGroup>
                                    {validation.touched.password &&
                                    validation.errors.password ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.password}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="d-flex justify-content-between mb-3">
                                    <div className="form-check d-flex align-items-center gap-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customControlInline"
                                      />
                                      <label
                                        className="form-check-label m-0"
                                        htmlFor="customControlInline"
                                      >
                                        Keep me logged in
                                      </label>
                                    </div>

                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot your password?
                                    </Link>
                                  </div>
                                  <Row className="rec">
                                    <Col>
                                      <Recaptcha onUpdate={handleUpdate} />
                                    </Col>
                                  </Row>
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn text-white m-auto btn-block ntb w-100"
                                      type="submit"
                                      style={{
                                        backgroundColor: "#835af4",
                                        borderRadius: "12px",
                                      }}
                                      onMouseEnter={e =>
                                        (e.target.style.backgroundColor =
                                          "#6999e0")
                                      }
                                      onMouseLeave={e =>
                                        (e.target.style.backgroundColor =
                                          "#835af4")
                                      }
                                    >
                                      Log In
                                    </button>
                                  </div>
                                </Form>
                              </div>
                            </CardBody>
                          </div>
                          <AuthFooter />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
                <Col
                  xl={6}
                  style={{
                    background: "#fcf7fa",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={AuthImg}
                      alt="AuthImg"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
  )

  // return (
  //   <React.Fragment>
  //     <div className="home-btn d-none d-sm-block">
  //       <Link to="/" className="text-dark">
  //         <i className="bx bx-home h2" />
  //       </Link>
  //     </div>
  //     <div className="account-pages my-5 pt-sm-5">
  //       <Container>
  //         <Row className="justify-content-center">
  //           <Col md={8} lg={6} xl={5}>
  //             <Card className="overflow-hidden">
  //               <div className="bg-primary bg-soft">
  //                 <Row>
  //                   <Col xs={7}>
  //                     <div className="text-primary p-4">
  //                       <h5 className="text-primary">Welcome Back !</h5>
  //                       <p>Sign in to continue to NullShip.</p>
  //                     </div>
  //                   </Col>
  //                   <Col className="col-5 align-self-end">
  //                     <img src={profile} alt="" className="img-fluid" />
  //                   </Col>
  //                 </Row>
  //               </div>
  //               <CardBody className="pt-0">
  //                 <div>
  //                   <Link to="/" className="auth-logo-light">
  //                     <div className="avatar-md profile-user-wid mb-4">
  //                       <span className="avatar-title rounded-circle bg-light">
  //                         <img
  //                           src={logo}
  //                           alt=""
  //                           // className="rounded-circle"
  //                           height="34"
  //                         />
  //                       </span>
  //                     </div>
  //                   </Link>
  //                 </div>
  //                 <div className="p-2">
  //                   <Form
  //                     className="form-horizontal"
  //                     onSubmit={(e) => {
  //                       e.preventDefault();
  //                       validation.handleSubmit();
  //                       return false;
  //                     }}
  //                   >
  //                     {responseError ? <Alert color="danger">{responseMessage}</Alert> : null}
  //
  //                     <div className="mb-3">
  //                       <Label className="form-label">Email</Label>
  //                       <Input
  //                         name="email"
  //                         className="form-control"
  //                         placeholder="Enter email"
  //                         type="email"
  //                         onChange={validation.handleChange}
  //                         onBlur={validation.handleBlur}
  //                         value={validation.values.email || ""}
  //                         invalid={
  //                           validation.touched.email && validation.errors.email ? true : false
  //                         }
  //                       />
  //                       {validation.touched.email && validation.errors.email ? (
  //                         <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
  //                       ) : null}
  //                     </div>
  //
  //                     <div className="mb-3">
  //                       <Label className="form-label">Password</Label>
  //                       <Input
  //                         name="password"
  //                         value={validation.values.password || ""}
  //                         type="password"
  //                         placeholder="Enter Password"
  //                         onChange={validation.handleChange}
  //                         onBlur={validation.handleBlur}
  //                         invalid={
  //                           validation.touched.password && validation.errors.password ? true : false
  //                         }
  //                       />
  //                       {validation.touched.password && validation.errors.password ? (
  //                         <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
  //                       ) : null}
  //                     </div>
  //
  //                     <div className="form-check">
  //                       <input
  //                         type="checkbox"
  //                         className="form-check-input"
  //                         id="customControlInline"
  //                       />
  //                       <label
  //                         className="form-check-label"
  //                         htmlFor="customControlInline"
  //                       >
  //                         Remember me
  //                       </label>
  //                     </div>
  //                     <div className="g-recaptcha" data-sitekey={config.recaptcha.SITE_KEY}></div>
  //
  //                     <div className="mt-3 d-grid">
  //                       <button
  //                         className="btn btn-primary btn-block"
  //                         type="submit"
  //                       >
  //                         Log In
  //                       </button>
  //                     </div>
  //
  //                     {/*<div className="mt-4 text-center">*/}
  //                     {/*  <h5 className="font-size-14 mb-3">Sign in with</h5>*/}
  //
  //                     {/*  <ul className="list-inline">*/}
  //                     {/*    <li className="list-inline-item">*/}
  //                     {/*      <FacebookLogin*/}
  //                     {/*        appId={facebook.APP_ID}*/}
  //                     {/*        autoLoad={false}*/}
  //                     {/*        callback={facebookResponse}*/}
  //                     {/*        render={renderProps => (*/}
  //                     {/*          <Link*/}
  //                     {/*            to="#"*/}
  //                     {/*            className="social-list-item bg-primary text-white border-primary"*/}
  //                     {/*            onClick={renderProps.onClick}*/}
  //                     {/*          >*/}
  //                     {/*            <i className="mdi mdi-facebook" />*/}
  //                     {/*          </Link>*/}
  //                     {/*        )}*/}
  //                     {/*      />*/}
  //                     {/*    </li>*/}
  //                     {/*    /!*<li className="list-inline-item">*!/*/}
  //                     {/*    /!*  <TwitterLogin*!/*/}
  //                     {/*    /!*    loginUrl={*!/*/}
  //                     {/*    /!*      "http://localhost:4000/api/v1/auth/twitter"*!/*/}
  //                     {/*    /!*    }*!/*/}
  //                     {/*    /!*    onSuccess={this.twitterResponse}*!/*/}
  //                     {/*    /!*    onFailure={this.onFailure}*!/*/}
  //                     {/*    /!*    requestTokenUrl={*!/*/}
  //                     {/*    /!*      "http://localhost:4000/api/v1/auth/twitter/revers"*!/*/}
  //                     {/*    /!*    }*!/*/}
  //                     {/*    /!*    showIcon={false}*!/*/}
  //                     {/*    /!*    tag={"div"}*!/*/}
  //                     {/*    /!*  >*!/*/}
  //                     {/*    /!*    <a*!/*/}
  //                     {/*    /!*      href=""*!/*/}
  //                     {/*    /!*      className="social-list-item bg-info text-white border-info"*!/*/}
  //                     {/*    /!*    >*!/*/}
  //                     {/*    /!*      <i className="mdi mdi-twitter"/>*!/*/}
  //                     {/*    /!*    </a>*!/*/}
  //                     {/*    /!*  </TwitterLogin>*!/*/}
  //                     {/*    /!*</li>*!/*/}
  //                     {/*    <li className="list-inline-item">*/}
  //                     {/*      <GoogleLogin*/}
  //                     {/*        clientId={google.CLIENT_ID}*/}
  //                     {/*        render={renderProps => (*/}
  //                     {/*          <Link*/}
  //                     {/*            to="#"*/}
  //                     {/*            className="social-list-item bg-danger text-white border-danger"*/}
  //                     {/*            onClick={renderProps.onClick}*/}
  //                     {/*          >*/}
  //                     {/*            <i className="mdi mdi-google" />*/}
  //                     {/*          </Link>*/}
  //                     {/*        )}*/}
  //                     {/*        onSuccess={googleResponse}*/}
  //                     {/*        onFailure={() => { }}*/}
  //                     {/*      />*/}
  //                     {/*    </li>*/}
  //                     {/*  </ul>*/}
  //                     {/*</div>*/}
  //
  //                     <div className="mt-4 text-center">
  //                       <Link to="/forgot-password" className="text-muted">
  //                         <i className="mdi mdi-lock me-1" />
  //                         Forgot your password?
  //                       </Link>
  //                     </div>
  //                   </Form>
  //                 </div>
  //               </CardBody>
  //             </Card>
  //             <div className="mt-5 text-center">
  //               <p>
  //                 Don&#39;t have an account ?{" "}
  //                 <Link to="/register" className="fw-medium text-primary">
  //                   {" "}
  //                   Signup now{" "}
  //                 </Link>{" "}
  //               </p>
  //               {/*<p>*/}
  //               {/*  © {new Date().getFullYear()} Skote. Crafted with{" "}*/}
  //               {/*  <i className="mdi mdi-heart text-danger" /> by Themesbrand*/}
  //               {/*</p>*/}
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //   </React.Fragment>
  // );
}

export default withRouter(Login)

// Login.propTypes = {
//   history: PropTypes.object,
// };
