// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"
import Select from "react-select"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

function PiclistCronReport() {
  // let selectedStoreId = "";
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [picklistData, setPicklistData] = useState([]);
  const [stores, setStores] = useState([]);
  const [pending, setPending] = useState(false);

  const retrieveCronReport= async () => {
    try {
      setPending(true);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/user/get-picklist-cron-job-logs', body, {headers}).then((response) => {
        if(response.status == 200) {
          setPicklistData(response.data);
        }
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const reloadCronReport= async () => {
    try {
      setPending(true);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/user/get-picklist-cron-job-logs', body, {headers}).then((response) => {
        if(response.status == 200) {
          setPicklistData(response.data);
        }
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    // {
    //   name: 'Store Name',
    //   selector: 'store_name',
    //   width: '250px',
    //   cell: (row) => (
    //     <span>
    //       {row.store_name}
    //     </span>
    //   )
    // },
    // {
    //   name: 'Upcoming Fetch Date Time',
    //   selector: 'start_date',
    //   sortable: true,
    //   width:"210px",
    //   cell: (row) => (
    //     row.cron_name == "sellercloud_picklist_update_cron"?"-":
    //       <span>
    //       {moment(new Date(row.start_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
    //     </span>
    //   )
    // },
    {
      name: 'Scheduler Start Date Time',
      // selector: 'created_at',
      sortable: true,
      minWidth:"210px",
      selector: (row) => (
        <span>
          {moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Scheduler End Date Time',
      // selector: 'end_date',
      sortable: true,
      minWidth:"210px",
      selector: (row) => (
        row.end_date?
          <span>
          {moment(new Date(row.end_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>:"-"
      )
    },
    {
      name: 'Message',
      // selector: 'message',
      sortable: true,
      width:"210px",
      selector: (row) => (<span>{row.message?(row.message.toString().includes("displayMessage")?JSON.parse(row.message).displayMessage:row.message):"-"}</span>)
    },
    {
      name: 'Status',
      // selector: 'running_status',
      minWidth: "120px",
      selector: (row) => (
        row.running_status == 1?
          <span className={"badge badge-warning"}>Running</span>:(row.running_status == 2?<span className={"badge badge-danger"}>Aborted</span>:<span className={"badge badge-success"}>Completed</span>)
      )
    }
  ];


  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    retrieveCronReport();
  }, []);

  //meta title
  document.title = "Scheduler Logs | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Picklist Scheduler Logs" />
        <Card>
          <CardBody>
            <div className={"row"}>
              <div className={"col-sm-2 col-md-2"}>
                <button className={"btn btn-light"} onClick={reloadCronReport}>Reload</button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
              <>
                <h4>Picklist Update Scheduler ({moment(new Date()).tz(moment.tz.guess()).subtract(2, "days").format("MMM DD, YYYY")} - {moment(new Date()).tz(moment.tz.guess()).format("MMM DD, YYYY")})</h4>
                <DataTable columns={columns} data={picklistData} theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles} pagination={20} paginationPerPage={20} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                           highlightOnHover={true}/>
              </>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
PiclistCronReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default PiclistCronReport;