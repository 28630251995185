import React, { useState } from "react"
import { Input, InputGroup, InputGroupText } from "reactstrap"
const CurrencyDropdown = ({ value, onChange }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "baseline",
        width: "100%",
      }}
    >
      <label
        htmlFor="amount"
        style={{ display: "block", marginBottom: "5px", fontWeight: "500" }}
      >
        Amount (USD) <span style={{ color: "red" }}> *</span>
      </label>
      <InputGroup>
        <InputGroupText
          style={{
            background: "white",
            // borderRight: "none",
          }}
        >
          $
        </InputGroupText>
        <Input
          type="number"
          id="amount"
          placeholder="0.00"
          value={value}
          onChange={onChange}
          style={{
            // width: "100%",
            // padding: ".375rem .75rem",
            // paddingLeft: "30px",
            // outline: "none",
            // borderLeft: "none",
          }}
        />
      </InputGroup>
    </div>
  )
}

export default CurrencyDropdown
