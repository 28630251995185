// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import config from '../../../config';
import loader from "../../../assets/images/loader.gif"
import { isUserAuthenticated } from "../../../helpers/fakebackend_helper"

function ShopifyStoreConnection(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [isShow, setIsShow] = useState(true);
  const [store, setStore] = useState("");
  const [message, setMessage] = useState("");
  const [isConnected, setIsConnected] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'shop' parameter
    const shopParam = urlParams.get('shop');
    setStore(shopParam);
    // Get the value of the 'token' parameter
    const tokenParam = urlParams.get('token');

    // Get the value of the 'email' parameter
    const emailParam = urlParams.get('email');

    if(isUserAuthenticated()) {
      // console.log("user is login")
      connectStore(shopParam, tokenParam);
    }
    else {
      // console.log("user is not login")
      connectStoreByEmail(shopParam, tokenParam, emailParam)
    }

    // Do something with the parameters
    // console.log('Shop:', shopParam);
    // console.log('token:', tokenParam);
    //
  }, []);

  const connectStore = async (shop, token) => {
    try {
      const body = {
        shop: shop,
        accessToken: token,
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      // console.log("body", body);
      await post('/shopify/connect', body, {headers}).then((response) => {
        // console.log("response",response);
        if (response.status == 200) {
          setIsConnected(true);
          toast.success(response.message);
          setMessage(response.message);
          setTimeout(() => {
            window.location = '/settings';
          }, 2500)
        }
        else {
          setIsConnected(false);
          toast.error(response.message);
          setMessage(response.message);
          setTimeout(() => {
            window.location = '/settings';
          }, 2500)
        }
      })
    }
    catch (e) {
      toast.error("Something went wrong");
    }
  }

  //this function calls when we dont redirect the user from shopify to login page
  const connectStoreByEmail = async (shop, token, email) => {
    try {
      const body = {
        shop: shop,
        accessToken: token,
        email: email
      };
      // console.log("body", body);
      await post('/shopify/connect-by-email', body, {headers}).then((response) => {
        // console.log("response",response);
        if (response.status == 200) {
          setIsConnected(true);
          toast.success(response.message);
          setMessage(response.message);
          setTimeout(() => {
            window.location = '/login';
          }, 2500)
        }
        else if(response.status == 404) {
          setIsConnected(false);
          toast.success(response.message);
          setMessage(response.message);
          setTimeout(() => {
            window.location = '/login';
          }, 2500)
        }
        else {
          setIsConnected(false);
          toast.error(response.message);
          setMessage(response.message);
          setTimeout(() => {
            window.location = '/login';
          }, 2500)
        }
      })
    }
    catch (e) {
      toast.error("Something went wrong");
    }
  }

  //meta title
  document.title = "Shopify Store Connection | NullShip";

  return (
    <div className="page-content">
      <div id='loader' className={"align-items-center justify-content-center"} style={{display:(isShow?"flex":"none"),alignItems:"center",margin:"auto", position: "absolute",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99",top:"0",left:"0"}}>
        <div style={{textAlign:"center"}}><img style={{width:"100px"}} src={loader}/>
          <br/><br/>
          {isConnected==null?
            <h6>Connecting Store {store}</h6>:
            isConnected==true?
              <h6>{store} Connected Successfully</h6>:
              <h6>{store} Connection Failed</h6>
          }
          <br/><br/>
          {message?<span className={"alert alert-warning mt-3"}>{message}</span>:""}
          </div>
      </div>
      {/*<div className="container-fluid">*/}
      {/*  <Breadcrumbs title="NullShip" sourceItem="Shopify" breadcrumbItem="Store Connection" />*/}
      {/*  <div >*/}

      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}
ShopifyStoreConnection.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default ShopifyStoreConnection;