import React, { useState, useEffect } from "react"
import { Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { get, post } from '../../../../helpers/api_helper';
import { toast } from "react-hot-toast";
import config from '../../../../config';
function ManualPayment({ viewModal, setViewModal }) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [paymentOptions, setPaymentOptions] = useState([]);

  const [detailsModal, setDetailsModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [code, setCode] = useState("");
  useEffect(() => {
    retrievePaymentOptions();
  }, [viewModal])

  const retrievePaymentOptions = async () => {
    try {
      get('/user/get-active-payment-options', {headers}).then((response) => {
        if(response.status === 200) {
          setPaymentOptions(response.data)
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      console.log("eee",e)
    }
  }

  const addManualPayment = async (payment_option_id) => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        payment_option_id: payment_option_id
      }
      post('/user/add-manual-payment', body, {headers}).then((response) => {
        if(response.status === 200) {
          setCode(response.code);
          setDetailsModal(true);
        }
        else {
          toast.error(response.error);
        }
      })
    }
    catch (e) {
      console.log("e",e)
    }
  }

  return (
    <>
      {/*Option Modal*/}
      <Modal
        isOpen={viewModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setViewModal(false);
          setDetailsModal(false);
          setDetails(null);
          setCode("");
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setViewModal(false);
              setDetailsModal(false);
              setDetails(null);
              setCode("");
            }}
          >
            Add Balance To Your Wallet Manually
          </ModalHeader>
          <ModalBody>
            <div className={"store-connect-main"}>
            {paymentOptions.length?
              paymentOptions.map((el, index) => (
                <div key={index} className={"store-connect"} onClick={async () => {
                  await addManualPayment(el.id);
                  setDetails(el);
                  setViewModal(false);
                }} title={"Pay with "+el.name}>
                  {el.image_url?<img alt={el.name} src={config.site.BASE_URL_FOR_IMAGES+"payment-option/"+el.image_url} style={{margin:"auto",width:"90%"}}/>:el.name}
                </div>
              ))
              :"No Payment Option Found"}
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/*Option Modal*/}

      {/*Option Details Modal*/}
      <Modal
        isOpen={detailsModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        // toggle={() => {
        //   setDetailsModal(false);
        // }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setDetailsModal(false);
            }}
          >
            Payment Details
          </ModalHeader>
          <ModalBody>
            <div>
              {details?
                <div>
                  <div style={{margin:"auto"}}>
                    {details.image_url?<img alt={details.name} src={config.site.BASE_URL_FOR_IMAGES+"payment-option/"+details.image_url} style={{float:"right",width:"80px"}}/>:null}
                    <p><b>Payment Code:</b> {code} <span style={{ color: "#e3e3e3", cursor: "pointer" }} className={"bx bx-clipboard"} title={"Copy Payment Code"} onClick={() => {navigator.clipboard.writeText(code);toast.success("Payment Code Copied!")}}></span></p>
                    <p><b>Account Type:</b> { details.name?details.name:"-"}</p>
                  </div>
                  <br/>
                  <fieldset style={{textAlign:"left", position:"relative"}}>
                    <legend style={{color:"red"}}>Account Details</legend>
                    <div style={{width:"100%",marginBottom:"10px"}}>
                      <span style={{ color: "#e3e3e3", cursor: "pointer",position:"absolute",right:"10px",top:"10px" }} className={"bx bx-clipboard"} title={"Copy Account Details"} onClick={() => {navigator.clipboard.writeText(details.account_details);toast.success("Account Details Copied!")}}></span>
                      {details.account_details?details.account_details.toString().split("|").map((el,index) => (<p key={index}><b>{el.split(":")[0]}</b>: {el.split(":")[1]}</p>)):"-"}
                    </div>
                  </fieldset>
                </div>
                :null
              }
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/*Option Details Modal*/}
    </>
  )
}

export default ManualPayment;