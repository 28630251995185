// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.csv"
import templateXlsx from "../../../assets/templete/Order bulk import template.xlsx"
import templateXls from "../../../assets/templete/Order bulk import xls template.xls"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  ButtonDropdown,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"


let selectedOrders = [];
let selectedOrderJson = [];
function ShopifyOrders(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")

  const [failedOrders, setFailedOrders] = useState([])
  const [isRetry, setIsRetry] = useState(false)

  const [allData, setAllData] = useState([])

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState(null)
  const [modal, setModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [orderDetailsModal, setOrderDetailsModal] = useState(false)

  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState("")
  const [selectedServiceName, setSelectedServiceName] = useState(null)
  const [selectAvailableService, setSelectAvailableService] = useState(false)

  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedCarrierName, setSelectedCarrierName] = useState(null)
  const [selectAvailableCarrier, setSelectAvailableCarrier] = useState(false)

  const [balance, setBalance] = useState("-")

  const [calculatedAmounts, setCalculatedAmounts] = useState([])
  const [calculatedAmountsAboveFiveLbs, setCalculatedAmountsAboveFiveLbs] =
    useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const [filterType, setFilterType] = useState(
    localStorage.getItem("shopify-order-filter")
      ? localStorage.getItem("shopify-order-filter")
      : "Unassigned"
  )
  const [pending, setPending] = useState(true)

  const [drp_link, setdrp_link] = useState(false)

  const [verificationModal, setVerificationModal] = useState(false)
  const [customVerificationModal, setCustomVerificationModal] = useState(false)

  const [orderErrors, setOrderErrors] = useState([])
  const [orderErrorModal, setOrderErrorModal] = useState(false)

  const [storeModal, setStoreModal] = useState(false)

  const [rowId, setRowId] = useState()

  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)
  const [clickedJson, setClickedJson] = useState([]);
  const [singleOrderWeight, setSingleOrderWeight] = useState("")
  const [SingleOrderServiceId, setSingleOrderServiceId] = useState("")
  const [singleOrderWidth, setSingleOrderWidth] = useState("")
  const [singleOrderHeight, setSingleOrderHeight] = useState("")
  const [singleOrderLength, setSingleOrderLength] = useState("")
  const [singleOrderRate, setSingleOrderRate] = useState("")
  const [singleIsDimenssion, setSingleIsDimenssion] = useState(false)
  const [orderUniqueId, setOrderUniqueId] = useState(null)


  const [showFilters, setShowFilters] = useState(false);
  //Sort states
  const [nameSort, setNameSort] = useState("");
  const [weightSort, setWeightSort] = useState("");
  const [quantitySort, setQuantitySort] = useState("");
  const [filterShipment, setFilterShipment] = useState("");

  const columns = [
    {
      name: (
        <input
          type={"checkbox"}
          title={"Select All"}
          className={"select-all-checkbox"}
          onChange={checkAll}
        />
      ),
      selector: ["id", "name"],
      // sortable: true,
      width: "60px",
      cell: row => (
        <input
          type={"checkbox"}
          className={"order-checkbox"}
          id={`order-${row.id}`}
          value={JSON.stringify(row)}
          name="order_id"
          onChange={handleOrderCheck}
        />
      ),
    },
    // {
    //   name: 'Order ID',
    //   selector: 'order_unique_id',
    //   sortable: true,
    //   cell: row => row.order_unique_id ? row.order_unique_id : "-"
    // },
    {
      name: "Order Id",
      selector: "order_unique_id",
      // cell: row => row.name ? row.name : <span style={{ color: "red" }}>Not Assigned</span>
    },
    {
      name: "Store Name",
      selector: "shopify_store",
      sortable: true,
      // cell: row => row.name ? row.name : <span style={{ color: "red" }}>Not Assigned</span>
    },
    {
      name: "Recipient",
      selector: "to_name",
      sortable: true,
      cell: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: 'Service Name',
      selector: 'name',
      sortable: true,
      cell: row => row.name ? row.name : <span style={{ color: "red" }}>Unassigned</span>
    },
    {
      name: "Rate",
      selector: ["price","above_five_lbs_price","weight","name"],
      // sortable: true,
      width: "100px",
      cell: row => (row.name ? (row.weight ?
        ( parseFloat(row.weight) < 5 ? "$" + row.price: "$" + row.above_five_lbs_price)
        : "-") : "-"),
    },
    // {
    //   name: 'Created At',
    //   selector: 'created_at',
    //   sortable: true,
    //   width: "150px",
    //   cell: (row) => moment(row.created_at).format('MMM DD, YYYY')
    // },
    {
      name: "Updated At",
      selector: "updated_at",
      sortable: true,
      width: "150px",
      cell: row => moment(row.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY"),
    },
    {
      name: "Status",
      selector: ["id", "error_message", "is_verified"],
      // sortable: true,
      width: "100px",
      style: {
        textAlign: "center",
        justifyContent: "center",
      },
      // cell: (row) => row.error_message ? <span className={"badge badge-danger"}>{row.error_message}</span> : "-"
      cell: row =>
        row.is_verified == 1 && row.error_message != "Try Again" ? (
          <span className={"badge badge-success"}>Passed</span>
        ) : row.error_message ? (
          <>
            <span
              className={"badge badge-danger"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleFailedOrderErrorMessage(row.id)
              }}
            >
              Failed
            </span>
            {/*<span className={"text-danger fw-medium text-center"} title={row.error_message.toString().replaceAll("null,","")}><i style={{cursor:"pointer",fontSize:"15px"}} className={"bx bx-error"}></i></span>*/}
          </>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      selector: "id",
      width: "180px",
      button: true,
      cell: row => (
        <>
          <Button
            className={"btn btn-success btn-sm"}
            onClick={() => {
              setRowId(row.id)
              setViewModal(true)
            }}
            style={{ float: "right", fontSize: "13px", marginRight: "10px" }}
            title="View Order"
          >
            <i className={"bx bx-file"}></i>
          </Button>
          {/*<Button className={"btn btn-light btn-sm"} data-id={row["id"]} onClick={() => {*/}
          {/*  props.history.push("/order/edit/" + row["id"])*/}
          {/*}} style={{ float: "right", fontSize: "13px", marginRight: "10px" }} title="Edit Order"><i className={"bx bx-edit"}></i></Button>*/}
          {/*<Button className={"btn btn-danger btn-sm"} onClick={async () => { await handleDelete(row.id) }} style={{ float: "right", fontSize: "13px" }} title="Delete Order"><i className={"bx bx-trash"}></i></Button>*/}
        </>
      ),
    },
  ]

  const handleFailedOrderErrorMessage = async id => {
    try {
      get(`/user/get-order-errors/${id}`, { headers }).then(response => {
        // console.log("get-order-errors", response)
        if (response.status === 200) {
          // console.log(response.data[0].error_message.split(","))
          setOrderErrors(response.data[0].error_message)
          setOrderErrorModal(true)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const handleOrderCheck = async e => {
    const { value, checked } = e.target
    if (checked) {
      // console.log("value",JSON.parse(value));
      selectedOrders.push(parseInt(JSON.parse(value).id));
      // // console.log("handleOrderCheck selectedOrders", selectedOrders);
      // // console.log("data",data);
      // selectedOrderJson.push(JSON.parse(value));
    } else {
      let index = selectedOrders.findIndex(el => el == parseInt(JSON.parse(value).id));
      selectedOrders.splice(index, 1);
      // let i = selectedOrderJson.findIndex(el => el.id == parseInt(JSON.parse(value).id));
      // selectedOrderJson.splice(i, 1);
    }
    setSelectedOrdersCount(selectedOrders.length);
    // // console.log("selectedOrderJson", selectedOrderJson);
    // await setClickedJson(selectedOrderJson);
    // if(selectedOrders.length == 1) {
    //   retrieveOrder(parseInt(JSON.parse(value).id));
    // }
    // else {
    //   setSingleOrderWeight("")
    //   setSingleOrderServiceId("")
    //   setSingleOrderWidth("")
    //   setSingleOrderHeight("")
    //   setSingleOrderLength("")
    // }
    // document.body.click();
  }

  const handleBulKRate = () => {
    if (selectedOrders.length > 0) {
      // document.body.style.overflow = "hidden"
      // // console.log("selectedOrders", selectedOrders)
      let arr = [];
      for (let i=0;i<selectedOrders.length;i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i]);
        arr.push(allData[index]);
        selectedOrderJson.push(allData[index]);
      }
      setClickedJson(arr);

      if(selectedOrders.length == 1) {
        retrieveOrder(selectedOrders[0]);
      }
      else {
        setSingleOrderWeight("")
        setSingleOrderServiceId("")
        setSingleOrderWidth("")
        setSingleOrderHeight("")
        setSingleOrderLength("")
      }

    } else {
      toast.error("Please select at least one order")
    }
  }


  // const handleOrderCheck = async e => {
  //   const { value, checked } = e.target
  //   if (checked) {
  //     // console.log("value",JSON.parse(value));
  //     selectedOrders.push(parseInt(JSON.parse(value).id));
  //     // console.log("handleOrderCheck selectedOrders", selectedOrders);
  //     // console.log("data",data);
  //     selectedOrderJson.push(JSON.parse(value));
  //   } else {
  //     let index = selectedOrders.findIndex(el => el == parseInt(JSON.parse(value).id));
  //     selectedOrders.splice(index, 1);
  //     let i = selectedOrderJson.findIndex(el => el.id == parseInt(JSON.parse(value).id));
  //     selectedOrderJson.splice(i, 1);
  //     // setClickedJson(selectedOrderJson);
  //   }
  //   // console.log("selectedOrderJson", selectedOrderJson);
  //   await setClickedJson(selectedOrderJson);
  //   if(selectedOrders.length == 1) {
  //     retrieveOrder(parseInt(JSON.parse(value).id));
  //   }
  //   else {
  //     setSingleOrderWeight("")
  //     setSingleOrderServiceId("")
  //     setSingleOrderWidth("")
  //     setSingleOrderHeight("")
  //     setSingleOrderLength("")
  //   }
  //   document.body.click();
  // }

  const retrieveServices = async () => {
    try {
      get("/admin/get-services", { headers }).then(response => {
        // // console.log("response", response);
        // console.log("data", response.data)
        setServices(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carriers", { headers }).then(response => {
        // console.log("carriers", response)
        setCarriers(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveNoServiceOrders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");

      localStorage.setItem("shopify-order-filter", "Unassigned")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-no-service-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                    null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                  "unshipped"
            )
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                    null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                  "unshipped"
            )
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
          )
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveFedexOrders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");

      localStorage.setItem("shopify-order-filter", "FedEx")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-fedex-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
              "unshipped"
          ).filter(el => el.error_message != "In Process")
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsOrders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");


      localStorage.setItem("shopify-order-filter", "UPS")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-ups-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
              "unshipped"
          ).filter(el => el.error_message != "In Process")
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      );
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsv2Orders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");


      localStorage.setItem("shopify-order-filter", "UPSv2")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-ups-v2-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
              "unshipped"
          ).filter(el => el.error_message != "In Process")
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      );
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveDhlOrders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");


      localStorage.setItem("shopify-order-filter", "DHL")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-dhl-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
              "unshipped"
          ).filter(el => el.error_message != "In Process")
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      );
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUspsOrders = async () => {
    try {
      // refresh filter on carrier change
      setNameSort("");
      setWeightSort("");
      setQuantitySort("");
      setFilterShipment("All");


      localStorage.setItem("shopify-order-filter", "USPS")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/get-usps-orders", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setAllData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          setFilteredData(
            response.data.filter(
              el =>
                (JSON.parse(el.store_order_status).financial_status == "paid" &&
                  JSON.parse(el.store_order_status).fulfillment_status ==
                  null) ||
                JSON.parse(el.store_order_status).fulfillment_status ==
                "unshipped"
            ).filter(el => el.error_message != "In Process")
          )
          let data = response.data.filter(
            el =>
              (JSON.parse(el.store_order_status).financial_status == "paid" &&
                JSON.parse(el.store_order_status).fulfillment_status == null) ||
              JSON.parse(el.store_order_status).fulfillment_status ==
              "unshipped"
          ).filter(el => el.error_message != "In Process")
          // console.log("response.data", response.data)
          // console.log("table data", data)

          setTableData({ columns, data })
          setPending(false)
        }
      );
    } catch (e) {
      // console.log(e)
    }
  }

  const getUserBalance = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
    }
    await post("/user/get-balance", body, { headers }).then(response => {
      // console.log("response", response)
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance)
        setBalance(response.balance)
      } else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  useEffect(() => {
    // // console.log("here");
    setIsRetry(false)
    setFailedOrders([])
    setResponseSuccess(null)
    selectedOrders = []
    getUserBalance()
    retrieveOrdersByFilterType()
    retrieveStores()
    // retrieveOrders();
    retrieveCarriers().then(() => {
      retrieveServices()
    })
  }, [])

  const handleChange = event => {
    // console.log(fileInput.current.files)
  }

  const retrieveOrdersByFilterType = async () => {
    if (filterType == "Unassigned") {
      await retrieveNoServiceOrders()
    }
    if (filterType == "FedEx") {
      await retrieveFedexOrders()
    } else if (filterType == "UPS") {
      await retrieveUpsOrders()
    } else if (filterType == "DHL") {
      await retrieveDhlOrders()
    } else if (filterType == "USPS") {
      await retrieveUspsOrders()
    }

    return true
  }

  const handleImportSubmit = event => {
    event.preventDefault()
    setModal(!modal)
    document.body.style.pointerEvents = "none"
    let loading = toast.loading("Importing shopify orders...")
    // if(selectedService !== "") {
    const formData = new FormData()
    formData.append("file", fileInput.current.files[0])
    formData.append("id", JSON.parse(localStorage.getItem("authUser")).id)

    postFile("/shopify/import-orders", formData).then(response => {
      // // console.log("response", response);
      if (response.status === 200) {
        retrieveOrdersByFilterType().then(() => {
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
          toast.success(response.message)
        })
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleDelete = async id => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure you want to delete this order?")
    if (confrm) {
      const body = {
        id: id,
      }
      let loading = toast.loading("Deleting Order...")
      document.body.style.pointerEvents = "none"
      post("/user/delete-order", body, { headers }).then(response => {
        // // console.log("delete-order", response);
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          if (filterType === "FedEx") {
            retrieveFedexOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message)
            })
          } else if (filterType === "UPS") {
            retrieveUpsOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message)
            })
          } else if (filterType === "DHL") {
            retrieveDhlOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message)
            })
          } else if (filterType === "USPS") {
            retrieveUspsOrders().then(() => {
              toast.remove(loading)
              toast.success(response.message)
            })
          }
          // toast.success(response.message);
          // retrieveOrders();
        } else {
          toast.remove(loading)
          toast.success(response.message)
        }
      })
    }
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("order-checkbox");
    selectedOrders = [];
    let selectAllCheckbox = document.getElementsByClassName(
      "select-all-checkbox"
    )
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  function checkAll(e) {
    const { checked } = e.target
    let checkboxes = document.getElementsByClassName("order-checkbox")
    selectedOrders = []

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false
        selectedOrders = []
      } else {
        checkboxes[i].checked = true
        selectedOrders.push(parseInt(JSON.parse(checkboxes[i].value).id));
      }
    }
    // console.log("checkAll selectedOrders",selectedOrders)
  }

  const handleCalculatedPrice = async () => {
    retrieveOrdersByFilterType().then(() => {
      setVerificationModal(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: selectedOrders.toString(),
      }
      post("/user/calculate-order-amount", body, { headers }).then(
        async response => {
          if (response.status === 200) {
            // // console.log("data", response);
            setCalculatedAmounts(response.data)
            setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

            let price = 0
            if (response.data.length > 0) {
              price = await response.data
                .map(item => item.price)
                .reduce((prev, next) => prev + next)
            }

            let above5lbsPrice = 0
            if (response.dataAboveFiveLbs.length > 0) {
              above5lbsPrice = await response.dataAboveFiveLbs
                .map(item => item.price)
                .reduce((prev, next) => prev + next)
            }

            // console.log("price", price)
            // console.log("above5lbsPrice", above5lbsPrice)

            setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice))
            setOrderDetailsModal(!orderDetailsModal)
          }
        }
      )
    })
  }

  const handleBulkDelete = async () => {
    if (selectedOrders.length > 0) {
      let confirmation = confirm(
        "Are you sure you want to delete these orders?"
      )
      if (confirmation) {
        let loading = toast.loading("Deleting Orders...")
        document.body.style.pointerEvents = "none"
        let body = {
          ids: selectedOrders.toString(),
        }
        post("/user/delete-bulk-orders", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              toast.remove(loading)
              document.body.style.pointerEvents = "auto"
              await uncheckAll()
              selectedOrders = []
              retrieveOrdersByFilterType().then(() => {
                toast.success(response.message)
              })
            } else {
              toast.remove(loading)
              toast.error(response.message)
            }
          }
        )
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleExport = async () => {
    if (selectedOrders.length > 0) {
      let loading = toast.loading("Exporting orders...")
      document.body.style.pointerEvents = "none"
      const header =
        "FromCountry,FromName,FromCompany,FromPhone,FromStreet1,FromStreet2,FromCity,FromZip,FromState,ToCountry,ToName,ToCompany,ToPhone,ToStreet1,ToStreet2,ToCity,ToZip,ToState,Length,Height,Width,Weight"
      let orders = ""

      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/get-bulk-orders-by-ids", body, { headers })
        .then(response => {
          // console.log("bulk orders export", response)
          if (response.status === 200) {
            let ordersByApi = response.data
            for (let i = 0; i < ordersByApi.length; i++) {
              let order = ordersByApi[i]
              orders =
                orders +
                `${order.from_country},${order.from_name},${order.from_company},${order.to_phone},${order.from_street1},${order.from_street2},${order.from_city},${order.from_zip},${order.from_state},${order.to_country},${order.to_name},${order.to_company},${order.to_phone},${order.to_street1},${order.to_street2},${order.to_city},${order.to_zip},${order.to_state},${order.length},${order.height},${order.width},${order.weight}\n`
            }
          }
          // // console.log("orders",orders);
          return response
        })
        .then(response => {
          if (response.status === 200) {
            let data = header + "\n" + orders
            const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
            saveAs(
              blob,
              "exported-shopify-order-" +
                moment(new Date()).format("DD-MMM-YYYY") +
                ".csv"
            )
            uncheckAll()
            selectedOrders = []
            toast.success("Orders exported successfully")
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
          } else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error(response.message)
          }
        })
        .catch((err) => {
          selectedOrders = []
          toast.error("Something went wrong")
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
        })
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleRetry = async () => {
    if (failedOrders.length > 0) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: failedOrders.toString(),
      }
      post("/user/calculate-order-amount", body, { headers }).then(
        async response => {
          if (response.status === 200) {
            // // console.log("data", response);
            setCalculatedAmounts(response.data)
            setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
            setOrderDetailsModal(!orderDetailsModal)
          }
        }
      )
    }
  }

  const upsPrintBulk = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing bulk Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    post('/user/print-batch-orders-ups', body, { headers }).then(async (response) => {
      // toast.remove(loading);
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      await getUserBalance();
      await retrieveUpsOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }
    });
  }

  const upsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    // post('/user/print-batch-orders-ups', body, { headers }).then(async (response) => {
    //   // toast.remove(loading);
    //   selectedOrders = [];
    //   setSelectedOrdersCount(selectedOrders.length);
    //   await getUserBalance();
    //   await retrieveUpsOrders();
    //   uncheckAll();
    //   document.body.style.pointerEvents = "auto";
    //   if (response.status === 200) {
    //     toast.remove(loading);
    //     setResponseSuccess(true);
    //     setResponseMessage(response.message);
    //   }
    //   else {
    //     toast.remove(loading);
    //     setResponseSuccess(false);
    //     setResponseMessage(response.message);
    //   }
    // });
    post('/user/print-orders-ups', body, { headers }).then(async (response) => {
      // // console.log("response", response);
      // if (response.failedOrders.length > 0) {
      //   let fo = [];
      //   for (let i=0;i<failedOrders.length;i++) {
      //     fo.push(failedOrders[i].order_id);
      //   }
      //   setFailedOrders(fo);
      //   setIsRetry(true);
      // }
      // else {
      //   setFailedOrders([]);
      //   setIsRetry(false);
      // }
      // toast.remove(loading);
      // if (response.pdf && response.pdf.length > 0) {
      //   const pdfUrls = response.pdf;
      //   const zip = new JSZip();
      //   let loadingComp = toast.loading("Creating compressed file...");
      //   new Promise(async function(resolve, reject) {
      //     for (let i = 0; i < pdfUrls.length; i++) {
      //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers })
      //         .then(async (response) => {
      //           // console.log(response.headers["file-name"]);
      //           await zip.file(`${response.headers["file-name"]}.pdf`, response.data)
      //
      //           if(i === (pdfUrls.length - 1)) {
      //             resolve(zip);
      //           }
      //         });
      //     }
      //   }).then(async () => {
      //     await zip.generateAsync({ type: "blob" })
      //       .then(function (content) {
      //         toast.remove(loadingComp);
      //         saveAs(content, "orders.zip");
      //       });
      //   })
      // }
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      await getUserBalance();
      await retrieveUpsOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      if(response.success_orders.length > 0 ) {
        let body = {
          data: response.success_orders
        };
        // console.log("generate tracking body", body);
        await post('/user/generate-tracking', body, {headers});
      }
    });
  }

  const fedExPrintBulk = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing bulk Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: isRetry ? failedOrders.toString() : selectedOrders.toString()
    };
    post('/user/print-batch-orders-fedex', body, { headers }).then(async (response) => {
      // toast.remove(loading);
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      await getUserBalance();
      await retrieveUpsOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }
    });
  }

  const fedExPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: isRetry ? failedOrders.toString() : selectedOrders.toString()
    };
    // post('/user/print-batch-orders-fedex', body, { headers }).then(async (response) => {
    //   // toast.remove(loading);
    //   selectedOrders = [];
    //   setSelectedOrdersCount(selectedOrders.length);
    //   await getUserBalance();
    //   await retrieveUpsOrders();
    //   uncheckAll();
    //   document.body.style.pointerEvents = "auto";
    //   if (response.status === 200) {
    //     toast.remove(loading);
    //     setResponseSuccess(true);
    //     setResponseMessage(response.message);
    //   }
    //   else {
    //     toast.remove(loading);
    //     setResponseSuccess(false);
    //     setResponseMessage(response.message);
    //   }
    // });

    post('/user/print-orders-fedex-updated', body, { headers }).then(async (response) => {
      // // console.log("print-orders response", response);
      toast.remove(loading);
      // if (response.failedOrders.length > 0) {
      //   let fo = [];
      //   for (let i=0;i<failedOrders.length;i++) {
      //     fo.push(failedOrders[i].order_id);
      //   }
      //   setFailedOrders(fo);
      //   setIsRetry(true);
      // }
      // else {
      //   setFailedOrders([]);
      //   setIsRetry(false);
      // }
      // if (response.pdf && response.pdf.length > 0) {
      //   const pdfUrls = response.pdf;
      //   const zip = new JSZip();
      //
      //   let loadingComp = toast.loading("Creating compressed file...");
      //   new Promise(async function(resolve, reject) {
      //     for (let i = 0; i < pdfUrls.length; i++) {
      //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers })
      //         .then(async (response) => {
      //           // console.log(response.headers["file-name"]);
      //           await zip.file(`${response.headers["file-name"]}.pdf`, response.data)
      //           if(i === (pdfUrls.length - 1)) {
      //             resolve(zip);
      //           }
      //         });
      //     }
      //   }).then(async () => {
      //     await zip.generateAsync({ type: "blob" })
      //       .then(function (content) {
      //         toast.remove(loadingComp);
      //         saveAs(content, "orders.zip");
      //       });
      //   })
      // }
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      await retrieveFedexOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      if(response.success_orders.length > 0 ) {
        let body = {
          data: response.success_orders
        };
        // console.log("generate tracking body", body);
        await post('/user/generate-tracking', body, {headers});
      }
    });
  }

  const dhlPrint = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
    }
    post("/user/submit-orders-dhl-usps", body, { headers }).then(
      async response => {
        // // console.log("print-orders response", response);
        setTimeout(async () => {
          toast.remove(loading)
          await getUserBalance()
          await retrieveDhlOrders()
          selectedOrders = []
          uncheckAll()
          document.body.style.pointerEvents = "auto"
          if (response.status === 200) {
            setResponseSuccess(true)
            setResponseMessage(response.message)
          } else {
            toast.remove(loading)
            setResponseSuccess(false)
            setResponseMessage(response.message)
          }
        }, 3000)
      }
    )
  }

  const uspsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    // post('/user/print-batch-orders-ups', body, { headers }).then(async (response) => {
    //   // toast.remove(loading);
    //   selectedOrders = [];
    //   setSelectedOrdersCount(selectedOrders.length);
    //   await getUserBalance();
    //   await retrieveUpsOrders();
    //   uncheckAll();
    //   document.body.style.pointerEvents = "auto";
    //   if (response.status === 200) {
    //     toast.remove(loading);
    //     setResponseSuccess(true);
    //     setResponseMessage(response.message);
    //   }
    //   else {
    //     toast.remove(loading);
    //     setResponseSuccess(false);
    //     setResponseMessage(response.message);
    //   }
    // });
    post('/user/print-orders-usps-labelaxxess', body, { headers }).then(async (response) => {
      // // console.log("response", response);
      // if (response.failedOrders.length > 0) {
      //   let fo = [];
      //   for (let i=0;i<failedOrders.length;i++) {
      //     fo.push(failedOrders[i].order_id);
      //   }
      //   setFailedOrders(fo);
      //   setIsRetry(true);
      // }
      // else {
      //   setFailedOrders([]);
      //   setIsRetry(false);
      // }
      // toast.remove(loading);
      // if (response.pdf && response.pdf.length > 0) {
      //   const pdfUrls = response.pdf;
      //   const zip = new JSZip();
      //   let loadingComp = toast.loading("Creating compressed file...");
      //   new Promise(async function(resolve, reject) {
      //     for (let i = 0; i < pdfUrls.length; i++) {
      //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers })
      //         .then(async (response) => {
      //           // console.log(response.headers["file-name"]);
      //           await zip.file(`${response.headers["file-name"]}.pdf`, response.data)
      //
      //           if(i === (pdfUrls.length - 1)) {
      //             resolve(zip);
      //           }
      //         });
      //     }
      //   }).then(async () => {
      //     await zip.generateAsync({ type: "blob" })
      //       .then(function (content) {
      //         toast.remove(loadingComp);
      //         saveAs(content, "orders.zip");
      //       });
      //   })
      // }
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      await getUserBalance();
      await retrieveUspsOrders();
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      if(response.success_orders.length > 0 ) {
        let body = {
          data: response.success_orders
        };
        // console.log("generate tracking body", body);
        await post('/user/generate-tracking', body, {headers});
      }
    });
  }

  const handleBulkProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders");
        await fedExPrintBulk();
      }
      else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders");
        await upsPrintBulk();
      }
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders")
        await fedExPrint()
      } else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders")
        await upsPrint()
      } else if (filterType === "DHL") {
        // //DHL
        // console.log("printing dhl orders")
        await dhlPrint()
      } else if (filterType === "USPS") {
        // //DHL
        // console.log("printing usps orders")
        await uspsPrint()
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleService = async e => {
    const { value } = e.target
    // console.log("selected service", selectedService)
    setSelectedService(value)
    setSelectedServiceName(e.target.getAttribute("data-name"))
  }

  const handleNoServiceOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("Unassigned")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveNoServiceOrders()
  }

  const handleFedExOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("FedEx")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveFedexOrders()
  }

  const handleUPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("UPS")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveUpsOrders()
  }

  const handleUPSv2Orders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("UPSv2")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveUpsv2Orders()
  }

  const handleDHLOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("DHL")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveDhlOrders()
  }

  const handleUSPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("USPS")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    uncheckAll()
    retrieveUspsOrders()
  }

  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
    setSelectedCarrierName(e.target.getAttribute("data-name"))
    setSelectedService("")
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  const handleImport = () => {
    setModal(!modal)
    setSelectedCarrier("")
    setSelectedCarrierName(null)
    setSelectedService("")
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shopify/getStores", body, { headers }).then(response => {
        // console.log("response", response)
        setStores(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const handleImportShopifyOrder = async () => {
    if (selectedStore) {
      document.body.style.pointerEvents = "none"
      setStoreModal(false)
      const body = {
        store_id: selectedStore,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      let index = stores.findIndex(el => el.id == selectedStore)
      let loading = toast.loading(`Importing ${stores[index].name} orders...`)
      post("/shopify/import-shopify-orders", body, { headers })
        .then(async response => {
          // console.log("import-shopify-orders", response)
          document.body.style.pointerEvents = "auto"
          setSelectedStore(null)
          toast.remove(loading)
          if (response.status === 200) {
            await retrieveNoServiceOrders()
            toast.success(`${stores[index].name} orders imported successfully`)
          } else {
            toast.success(response.message)
          }
        })
        .catch(err => {
          document.body.style.pointerEvents = "auto"
          toast.success("Something went wrong")
        })
    } else {
      toast.error("Please select a store")
    }
  }

  //meta title
  document.title = "Shopify Awaiting Shipments | NullShip"
  const retrieveOrder = async oid => {
    try {
      let body = {
        id: oid,
      }
      post("/user/get-order", body, { headers }).then(async response => {
        // console.log("get order", response)
        let data = response.data[0]
        setSingleOrderWeight(data.weight)
        setSingleOrderServiceId(data.service_id != null ? data.service_id : "")
        setSingleOrderWidth(data.width != null ? data.width : "")
        setSingleOrderHeight(data.height != null ? data.height : "")
        setSingleOrderLength(data.length != null ? data.length : "")
        setSingleOrderRate("")
        if (data.weight && data.service_id != null) {
          const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id,
            service_id: data.service_id,
            weight: data.weight,
          }
          await post("/shopify/get-service-rates-by-weight-and-service", body, {
            headers,
          }).then(response => {
            // console.log("get rate response", response)
            if (response.status === 200) {
              setSingleOrderRate(response.data.servicePrice)
            } else {
              // setResponseSuccess(false);
              // setResponseMessage(response.message);
            }
          })
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }
  const handleRowClick = row => {
    // Handle row click event here
    // let data = row
    // // console.log("data", JSON.parse(data.custom_items_data))
    // // // console.log("data.id", data.id)
    // // // console.log("tableData", tableData)
    // let index = tableData.data.findIndex(el => el.id == data.id)
    // setOrderUniqueId(tableData.data[index].order_unique_id)
    //
    // retrieveOrder(data.id)
    // uncheckAll();
    // document.getElementById(`order-${data.id}`).checked = true;
    // selectedOrders = [data.id];
    // // console.log("handleRowClick selectedOrders", selectedOrders);
    // selectedOrderJson.push(data);
    // // console.log("selectedOrderJson", selectedOrderJson);
    // setClickedJson(selectedOrderJson);
    // console.log(`order-${row.id}`)
    document.getElementById(`order-${row.id}`).click();
  }

  const handleOrderWeight = async e => {
    setSingleOrderWeight(e.target.value)
    if (e.target.value && parseFloat(e.target.value) > 0 && SingleOrderServiceId) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: SingleOrderServiceId,
        weight: e.target.value,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    }
  }
  const handleServiceOrder = async e => {
    setSingleOrderServiceId(e.target.value)
    let index = services.findIndex(el => el.id == e.target.value)
    if (
      services[index].carrier_name == "DHL" ||
      services[index].carrier_name == "USPS"
    ) {
      setSingleIsDimenssion(true)
    } else {
      setSingleIsDimenssion(false)
    }
    if (e.target.value && parseFloat(singleOrderWeight) > 0 && singleOrderWeight) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: singleOrderWeight,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    } else {
      to
    }
  }
  const singleOrderUpdate = async () => {
    // console.log("working...")
    if (singleOrderWeight && SingleOrderServiceId) {
      if(singleOrderWeight == 0) {
        toast.error("Shipment weight must be greater than 0")
        return true;
      }
      if(clickedJson.length == 1) {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_id: selectedOrders[0],
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
        }
        await post("/shopify/update-single-order", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          await retrieveOrdersByFilterType();
          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            uncheckAll();
            selectedOrders = [];
            selectedOrderJson = [];
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
          } else {
            toast.error(response.message)
          }
        })
      }
      else {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_ids: selectedOrders.toString(),
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
        }
        await post("/shopify/update-bulk-orders", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          await retrieveOrdersByFilterType();
          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            uncheckAll();
            selectedOrders = [];
            selectedOrderJson = [];
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
          } else {
            toast.error(response.message)
          }
        })
      }
    } else {
      toast.error("Please fill required fields")
    }
  }
  const fedexValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const dhlValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }


  const uspsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const noServiceValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-shopify-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }



  const handleValidate = async () => {
    document.body.style.pointerEvents = "none"
    if (filterType == "FedEx") {
      let response = await fedexValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "UPS") {
      let response = await upsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "DHL") {
      let response = await dhlValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "USPS") {
      let response = await uspsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
    else if(filterType == "Unassigned") {
      let response = await noServiceValidation();
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }
  const handleVerification = async () => {
    if (selectedOrders.length > 0) {
      for (let i=0;i<selectedOrders.length;i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i]);
        // console.log("index",index, "data[index]",allData[index]);
        if(allData[index].service_id == null) {
          toast.error("Selected orders do not have any associated service")
          return false;
        }
      }

      handleValidate().then(data => {
        document.body.style.pointerEvents = "auto"
        setVerificationModal(!verificationModal)
      })
    } else {
      toast.error("Please select at least one order")
    }
  }
  // customValidation
  const handleCustomCalculatedPrice = async () => {
    retrieveOrdersByFilterType().then(() => {
      setCustomVerificationModal(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: selectedOrders.toString(),
      }
      post("/user/calculate-order-amount", body, { headers }).then(
        async response => {
          if (response.status === 200) {
            // // console.log("data", response);
            setCalculatedAmounts(response.data)
            setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

            let price = 0
            if (response.data.length > 0) {
              price = await response.data
                .map(item => item.price)
                .reduce((prev, next) => prev + next)
            }

            let above5lbsPrice = 0
            if (response.dataAboveFiveLbs.length > 0) {
              above5lbsPrice = await response.dataAboveFiveLbs
                .map(item => item.price)
                .reduce((prev, next) => prev + next)
            }

            // console.log("price", price)
            // console.log("above5lbsPrice", above5lbsPrice)

            setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice))
            setOrderDetailsModal(!orderDetailsModal)
          }
        }
      )
    })
  }
  const fedexCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const dhlCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const uspsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }
  const handleCustomOrderValidate = async () => {
    document.body.style.pointerEvents = "none"
    let index = services.findIndex(el => el.id == SingleOrderServiceId)
    if (services[index].carrier_name == "FedEx") {
      let response = await fedexCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "UPS") {
      let response = await upsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "DHL") {
      let response = await dhlCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "USPS") {
      let response = await uspsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }
  const handleCustomOrderVerification = async () => {
    if (selectedOrders.length > 0) {
      handleCustomOrderValidate().then(data => {
        // console.log("handleCustomOrderValidate", data)
        document.body.style.pointerEvents = "auto"
        setCustomVerificationModal(!customVerificationModal)
        setClickedJson([])
        document.body.style.overflow = "visible"
      })
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleSort = (name_sort, weight_sort, quantity_sort, filtered_Data) => {
    let data = [...filtered_Data];
    if(name_sort) {
      if(name_sort == "ASC") {
        data.sort((a, b) => a.to_name.localeCompare(b.to_name));
      }
      else {
        data.sort((a, b) => b.to_name.localeCompare(a.to_name));
      }
    }

    if(weight_sort) {
      if(weight_sort == "ASC") {
        data.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight));
      }
      else {
        data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
      }
    }

    if(quantity_sort) {
      if(quantity_sort == "ASC") {
        data.sort((a, b) => {
          if (a.custom_items_data && b.custom_items_data) {
            return JSON.parse(a.custom_items_data).length - JSON.parse(b.custom_items_data).length;
          }
        });
      }
      else {
        data.sort((a, b) => {
          if (a.custom_items_data && b.custom_items_data) {
            return JSON.parse(b.custom_items_data).length - JSON.parse(a.custom_items_data).length; // Compare normal ages
          }
        });
      }
    }

    setTableData({ columns, data });
    setFilteredData(data);
  }

  //Filter and sortings
  const handleSortName = (e) => {
    setNameSort(e.target.value);
    handleSort(e.target.value, weightSort, quantitySort, filteredData);
  }

  const handleSortWeight = (e) => {
    setWeightSort(e.target.value);
    handleSort(nameSort, e.target.value, quantitySort, filteredData);
  }

  const handleSortQuantity = (e) => {
    setQuantitySort(e.target.value);
    handleSort(nameSort, weightSort, e.target.value, filteredData);
  }

  const handleFilterShipment = async (e) => {
    setFilterShipment(e.target.value);
    let data1 = allData;
    if (e.target.value == "All") {
      let data = data1;
      await handleSort(nameSort, weightSort, quantitySort, data);
    }
    if (e.target.value == "International") {
      let data = [];
      data1.map(el => {
        if(el.from_country != el.to_country) {
          data.push(el);
        }
      });
      await handleSort(nameSort, weightSort, quantitySort, data);
    }
    else if (e.target.value == "Domestic") {
    // console.log("filter domestic")
      let data = [];
      data1.map(el => {
        if(el.from_country == el.to_country) {
          data.push(el);
        }
      });
      await handleSort(nameSort, weightSort, quantitySort, data);
    }
  }

  return (
    <div className="page-content">
      <div className={(clickedJson.length>0 ? "modal-background" : "")}>
      </div>
      <div
        className={
          "order-info " + (clickedJson.length>0 ? "order-active" : "order-inactive")
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            singleOrderUpdate()
          }}
        >
          <div className={"head my-2"}>
            <span
              className={"close-btn"}
              onClick={() => {
                document.body.style.overflow = "visible"
                setClickedJson([])
                uncheckAll();
                selectedOrders = [];
                selectedOrderJson = [];
                setSingleOrderWeight("")
                setSingleOrderServiceId("")
                setSingleOrderWidth("")
                setSingleOrderHeight("")
                setSingleOrderLength("")
              }}
            >
              ⨉
            </span>
            <h4 onClick={() => {}} style={{ width: "fit-content" }}>
              Update {clickedJson.length>1?"Shipments":"Shipment"}
              {/*{orderUniqueId != null ? orderUniqueId : ""}*!/*/}
            </h4>
            <p style={{fontSize:"12px",marginBottom:"0px"}}>{clickedJson.length > 0
              ? clickedJson.map((part, id) => {
                if(id == (clickedJson.length - 1)) {
                  return ("Shipment #"+part.order_unique_id)
                }
                else {
                  return ("Shipment #"+part.order_unique_id+", ")
                }
              })
              :""}</p>
          </div>
          <div className={"body"}>
            <Row className="mb-3 mt-3">
              <label htmlFor="weight_Input" className="col-md-3 col-form-label">
                Weight <span style={{ color: "red" }}> *</span>
              </label>
              <div className="col-md-9">
                <input
                  onChange={handleOrderWeight}
                  value={singleOrderWeight}
                  name="weight"
                  placeholder="Weight in (lb)"
                  className="form-control"
                  type="number"
                  step="any"
                  min={0}
                  required={true}
                />
              </div>
            </Row>
            <Row className="mb-3 mt-3">
              <label className="col-md-3 col-form-label">
                Service <span style={{ color: "red" }}> *</span>
              </label>
              <div className="col-md-9">
                <select
                  name="serviceName"
                  className="form-control"
                  value={SingleOrderServiceId}
                  onChange={handleServiceOrder}
                >
                  <option selected="true" value={""} disabled="true">
                    Select a service
                  </option>
                  {services.map((part, ind) => {
                    return (
                      part.is_active == 1?
                        <option key={ind} value={part.id}>
                          {part.carrier_name} - {part.name}
                        </option>:""
                    )
                  })}
                </select>
              </div>
            </Row>
            <Row className="mb-3 mt-3">
              <fieldset>
                <legend>Dimensions</legend>
                <Row style={{ justifyContent: "center" }}>
                  <div className="col-md-4 mb-2">
                    <label>
                      Width{" "}
                      {singleIsDimenssion && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </label>
                    <input
                      onChange={e => setSingleOrderWidth(e.target.value)}
                      placeholder="Enter width"
                      className="form-control"
                      type="number"
                      step="any"
                      min={0}
                      value={singleOrderWidth}
                      required={singleIsDimenssion}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <label>
                      Height{" "}
                      {singleIsDimenssion && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </label>
                    <input
                      value={singleOrderHeight}
                      onChange={e => setSingleOrderHeight(e.target.value)}
                      placeholder="Enter height"
                      className="form-control"
                      type="number"
                      step="any"
                      min={0}
                      required={singleIsDimenssion}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <label>
                      Length{" "}
                      {singleIsDimenssion && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </label>
                    <input
                      value={singleOrderLength}
                      onChange={e => setSingleOrderLength(e.target.value)}
                      placeholder="Enter length"
                      className="form-control"
                      type="number"
                      step="any"
                      min={0}
                      required={singleIsDimenssion}
                    />
                  </div>
                </Row>
              </fieldset>
            </Row>
            <Row className="mb-12 py-3">
              <div className="col">
                <h4
                  className="text-center"
                  onClick={() => {}}
                  // style={{ textAlign: "center" }}
                >
                  Rate $
                  {singleOrderRate
                    ? parseFloat(singleOrderRate).toFixed(2)
                    : "0.00"}
                </h4>
              </div>
            </Row>
            <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <button
                type="submit"
                className="w-25 btn btn-sm btn-success waves-effect waves-light"
              >
                Update Order <i className={"bx bx-edit"}></i>
              </button>
              {selectedOrders.length == 1 ?
                (allData.filter(el => el.id == selectedOrders[0])[0].service_id != null?
                  <button
                    onClick={handleCustomOrderVerification}
                    type="button"
                    disabled={
                      singleOrderWeight
                        ? SingleOrderServiceId
                        ? false
                        : true
                        : true
                    }
                    className="w-25 btn btn-sm btn-warning waves-effect waves-light"
                  >
                    Print <i className={"bx bx-printer"}></i>
                  </button>: "")
                : ""}

            </Row>
            <hr />
            <div className={"my-2"}>
              <h4 onClick={() => {}} style={{ width: "fit-content" }}>
                Items
              </h4>
            </div>
            <hr />
            {clickedJson.length > 0
              ? clickedJson.map((part, id) => {
                return (
                  <>
                    <h6>Shipment #{part.order_unique_id}</h6>
                    {
                      JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row key={ind}>
                            <Col sm="2">
                              <img width="100%" src={val.image_url} />
                            </Col>
                            <Col sm="10">
                              <h5>
                                {val.title}{" "}
                                <span style={{ float: "right" }}>
                            {val.quantity} x
                          </span>
                              </h5>
                              <p>
                                {val.price} {val.price_set.shop_money.currency_code}
                              </p>
                            </Col>
                          </Row>
                        )
                      })
                    }
                    <hr/>
                  </>
                )
              })
              : ""}
            {/* <div className={"col-md-8"}>
            <label>Ship Station</label>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <select
                  name={"ship_station"}
                  className="form-select"
                  // onChange={(e) => setShip_Station(e.target.value)}
                  // value={ship_station}
                >
                  <option value={"usps"}>Select Station</option>
                  <option value={"usps"}>USPS</option>
                  <option value={"dlh"}>DHL</option>
                  <option value={"fedex"}>FedEx</option>
                  <option value={"ups"}>UPS</option>
                </select>
              </div>
            </div>
          </div> */}
          </div>
        </Form>
      </div>
      <div className="container-fluid">
        <Breadcrumbs
          title="NullShip"
          sourceItem="Shopify"
          breadcrumbItem="Awaiting Shipments"
        />
        <Card>
          <CardBody>
            <div className={"row"}>
              <div
                className={"col-md-12 col-lg-11 col-xl-10 col-xxl-8 btn-div"}
                style={{ height: "fit-content" }}
              ></div>
              <div
                className={"col-md-1 col-lg-1 col-xl-2 col-xxl-4"}
                style={{ position: "relative" }}
              >
                {JSON.parse(localStorage.getItem("authUser")).role_id === 2 ? (
                  <div
                    className={"order-desktop-balance"}
                    style={{
                      right: "10px",
                      top: "-10px",
                      position: "absolute",
                    }}
                  >
                    <h6>
                      Balance
                      <br />
                      <span style={{ fontSize: "1.5rem" }}>
                        ${amountFormat(balance)}
                      </span>
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                <br />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className={"order-mobile-balance"}>
          <CardBody>
            {JSON.parse(localStorage.getItem("authUser")).role_id === 2 ? (
              <div>
                <h6>
                  Balance
                  <hr />
                  <span style={{ fontSize: "1.5em" }}>
                    ${amountFormat(balance)}
                  </span>
                </h6>
              </div>
            ) : (
              ""
            )}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 style={{marginBottom:"-5px", cursor:"pointer"}} onClick={() => setShowFilters(!showFilters)}>Filters
              <span style={{float:"right", cursor:"pointer"}}>{showFilters?"-":"+"}</span>
            </h4>
            <div className={"row mt-4"} style={{display:(showFilters?"flex":"none")}}>
              <hr/>
              <div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2"}>
                <label>
                  Destination
                </label>
                <select className={"form-select"} value={filterShipment} onChange={handleFilterShipment}>
                  {/*<option disabled={true} selected={true}>Select Filter</option>*/}
                  <option value="All">All</option>
                  <option value="International">International</option>
                  <option value="Domestic">Domestic</option>
                </select>
              </div>
              <div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2"}>
                <label>
                  Ship To Name
                </label>
                <select className={"form-select"} value={nameSort} onChange={handleSortName}>
                  <option selected={true} value={""}>None</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </select>
              </div>
              <div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2"}>
                <label>
                  Shipment Weight
                </label>
                <select className={"form-select"} value={weightSort} onChange={handleSortWeight}>
                  <option selected={true} value={""}>None</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </select>
              </div>
              <div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2"}>
                <label>
                  Items Quantity
                </label>
                <select className={"form-select"} value={quantitySort} onChange={handleSortQuantity}>
                  <option selected={true} value={""}>None</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </select>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className={"row"}>
              <div className={"col-md-6 shopify-btns-cats"}>
                <button className={"btn btn-sm btn-outline-dark " + (filterType==="Unassigned"?"active":"")} style={{marginRight:"5px"}} onClick={handleNoServiceOrders}>Unassigned</button>
                <div className={"vr"}></div>
                {/*/!*<button className={"btn btn-sm btn-outline-dark " + (filterType==="FedEx"?"active":"")} style={{marginRight:"5px", marginLeft: "5px"}} onClick={handleFedExOrders}>FedEx</button>*!/*/}
                {/*/!*<div className={"vr"}></div>*!/*/}
                {/*<button className={"btn btn-sm btn-outline-dark " + (filterType === "UPS" ? "active" : "")} style={{ marginLeft: "5px", marginRight: "5px" }} onClick={handleUPSOrders}>UPS</button>*/}
                {/*<div className={"vr"}></div>*/}
                {/*/!*<button className={"btn btn-sm btn-outline-dark " + (filterType === "UPSv2" ? "active" : "")} style={{ marginLeft: "5px", marginRight: "5px" }} onClick={handleUPSv2Orders}>UPSv2</button>*!/*/}
                {/*/!*<div className={"vr"}></div>*!/*/}
                {/*/!*<button className={"btn btn-sm btn-outline-dark " + (filterType==="DHL"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} onClick={handleDHLOrders}>DHL</button>*!/*/}
                {/*/!*<div className={"vr"}></div>*!/*/}
                {/*<button className={"btn btn-sm btn-outline-dark " + (filterType==="USPS"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} onClick={handleUSPSOrders}>USPS</button>*/}

                {carriers.length == 0?" Loading...":""}
                {carriers.length > 0 && carriers.filter(el => el.name == "FedEx")[0].is_active == 1 ?
                  <>
                    <button className={"btn btn-sm btn-outline-dark " + (filterType==="FedEx"?"active":"")} style={{marginRight:"5px"}} disabled={pending} onClick={handleFedExOrders}>FedEx</button>
                    <div className={"vr"}></div>
                  </>:""}
                {carriers.length > 0 && carriers.filter(el => el.name == "UPS")[0].is_active == 1 ?
                  <>
                    <button className={"btn btn-sm btn-outline-dark " + (filterType === "UPS" ? "active" : "")} style={{ marginLeft: "5px", marginRight: "5px" }} disabled={pending} onClick={handleUPSOrders}>UPS</button>
                    <div className={"vr"}></div>
                  </>:""}
                {carriers.length > 0 && carriers.filter(el => el.name == "UPSv2")[0].is_active == 1 ?
                  <>
                    <button className={"btn btn-sm btn-outline-dark " + (filterType === "UPSv2" ? "active" : "")} style={{ marginLeft: "5px", marginRight: "5px" }} disabled={pending} onClick={handleUPSv2Orders}>UPSv2</button>
                    <div className={"vr"}></div>
                  </>:""}
                {carriers.length > 0 && carriers.filter(el => el.name == "DHL")[0].is_active == 1 ?
                  <>
                    <button className={"btn btn-sm btn-outline-dark " + (filterType==="DHL"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} disabled={pending} onClick={handleDHLOrders}>DHL</button>
                    <div className={"vr"}></div>
                  </>:""}
                {carriers.length > 0 && carriers.filter(el => el.name == "USPS")[0].is_active == 1 ?
                  <>
                    <button className={"btn btn-sm btn-outline-dark " + (filterType==="USPS"?"active":"")} style={{marginLeft:"5px",marginRight:"5px"}} disabled={pending} onClick={handleUSPSOrders}>USPS</button>
                  </>:""}

              </div>
              <div className={"col-md-6"}>
                <div
                  // style={{float:"right"}}
                  className={"shopify-btns"}
                >

                  {filterType!="Unassigned" ? <button
                    className={"btn btn-sm btn-warning"}
                    onClick={handleVerification}
                  >
                    Print <i className={"bx bx-printer"}></i>
                  </button>:""}
                  <button
                    className={"mx-2 btn btn-sm btn-info"}
                    onClick={handleBulKRate}
                  >
                    Get Rate <i className={"bx bx-dollar"}></i>
                  </button>
                  <ButtonDropdown
                    isOpen={drp_link}
                    toggle={() => {
                      setdrp_link(!drp_link)
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    <DropdownToggle className={"btn btn-sm"} caret color="light">
                      <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {/*<DropdownItem onClick={handleBulkDelete}>Bulk Delete</DropdownItem>*/}
                      <DropdownItem onClick={handleExport}>
                        Export Orders
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>

             <br/>
            {responseSuccess === false ? (
              <>
                <br />
                <Alert color="danger">{responseMessage}</Alert>
              </>
            ) : null}
            {responseSuccess === true ? (
              <>
                <br />
                <Alert color="success">
                  {responseMessage}{" "}
                  {isRetry ? (
                    <span>
                      {failedOrders.length} orders failed due to excessive
                      resource consumption, Please{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={handleRetry}
                      >
                        Try Again
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </Alert>
              </>
            ) : null}
            <br />
            <h5 style={{ marginBottom: "-50px" }}>{filterType} Orders<br/>
              <span style={{fontSize:"12px",fontWeight:"normal",marginTop:"-10px !important"}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>
            </h5>
            {/*<h4 style={{ marginBottom: "-40px" }}>{filterType} Orders</h4>*/}
            <DataTableExtensions
              {...tableData}
              // exportHeaders={true}
              filterPlaceholder={`Filter Order With Detail or Item Name...`}
            >
              <DataTable
                keyField={"id"}
                columns={columns}
                data={allData}
                pagination={50}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
                onRowClicked={handleRowClick}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={orderDetailsModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          toggle={() => {
            setOrderDetailsModal(!orderDetailsModal)
            selectedOrders = []
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderDetailsModal(!orderDetailsModal)
                selectedOrders = []
              }}
            >
              Order Cost Calculation
            </ModalHeader>
            <ModalBody>
              <table className={"table table-bordered"}>
                <tbody>
                  <tr style={{ border: "0px" }}>
                    <th style={{ border: "0px" }}>
                      {/* <img
                        src={
                          services.length > 0
                            ? services[
                                services.findIndex(
                                  el => el.id == SingleOrderServiceId
                                )
                              ].carrier_name == "FedEx"
                              ? fedex
                              : filterType == "UPS"
                              ? ups
                              : filterType == "USPS"
                              ? usps
                              : dhl
                            : ""
                        }
                        height={"50px"}
                      /> */}
                    </th>
                    <th style={{ textAlign: "right", border: "0px" }}>
                      Current Balance
                      <br />
                      <h4>${amountFormat(balance)}</h4>
                    </th>
                  </tr>
                </tbody>
              </table>
              <br />
              <table className={"table table-bordered"}>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                    <th>Discount Type</th>
                    <th>Discount</th>
                    <th>Final Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {calculatedAmounts.length > 0 ? (
                    calculatedAmounts.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                  <tr>
                    <th className={"bg-light"} colSpan={6}>
                      Above 5 lb. Orders
                    </th>
                  </tr>
                  {calculatedAmountsAboveFiveLbs.length > 0 ? (
                    calculatedAmountsAboveFiveLbs.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th style={{ textAlign: "right" }} colSpan={5}>
                      Total
                    </th>
                    {/*<th style={{textAlign:"right"}}>{calculatedAmounts.length>0?calculatedAmounts.map(item => item.price).reduce((prev, next) => prev + next):0}</th>*/}
                    <th style={{ textAlign: "right" }}>
                      ${amountFormat(totalAmount)}
                    </th>
                  </tr>
                </tfoot>
              </table>
              {/*<br/>*/}
              {totalAmount <= parseFloat(balance) ?
                (filterType == "UPS" || filterType == "FedEx" ? <>
                    <button style={{ float: "right",marginLeft:"10px" }} className={"btn btn-warning float-right"} title={"Click to proceed the order"} onClick={handleBulkProceed}>Bulk Proceed</button>
                    <button style={{ float: "right" }} className={"btn btn-success float-right"} title={"Click to proceed the order"} onClick={handleProceed}>Proceed</button>
                  </>:
                  <button style={{ float: "right" }} className={"btn btn-success float-right"} title={"Click to proceed the order"} onClick={handleProceed}>Proceed</button>)
                :
                <button style={{ float: "right" }} className={"btn btn-danger float-right"} title={"You have insufficient balance to proceed this order."}>Insufficient Balance</button>
              }
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-lg"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal)
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal)
              }}
            >
              Import Bulk Orders
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleImportSubmit}>
                <Label className="form-label">
                  Select A File (.xlsx / .csv / .xls)
                </Label>
                <input
                  type="file"
                  required={true}
                  className="form-control mb-3"
                  ref={fileInput}
                  onChange={handleChange}
                  accept={
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                />
                <button className="btn btn-primary w-md mt-3" type="submit">
                  Import
                </button>
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={verificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            retrieveOrdersByFilterType().then(() => {
              setVerificationModal(!verificationModal)
              selectedOrders = []
            })
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                retrieveOrdersByFilterType().then(() => {
                  setVerificationModal(!verificationModal)
                  selectedOrders = []
                })
              }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <button
                className={"btn btn-warning"}
                style={{ float: "right" }}
                disabled={selectedOrders.length > 0 ? false : true}
                onClick={handleCalculatedPrice}
              >
                Proceed To Checkout
              </button>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={customVerificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            retrieveOrdersByFilterType().then(() => {
              setCustomVerificationModal(!customVerificationModal)
              selectedOrders = []
            })
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                retrieveOrdersByFilterType().then(() => {
                  setCustomVerificationModal(!customVerificationModal)
                  selectedOrders = []
                })
              }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <button
                className={"btn btn-warning"}
                style={{ float: "right" }}
                disabled={selectedOrders.length > 0 ? false : true}
                onClick={handleCustomCalculatedPrice}
              >
                Proceed To Checkout
              </button>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={orderErrorModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderErrorModal(!orderErrorModal)
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderErrorModal(!orderErrorModal)
              }}
            >
              Order Errors
            </ModalHeader>
            <ModalBody>
              <ul>
                {orderErrors != ""
                  ? orderErrors.split(",").map((part, id) => {
                      if (part && part != "null") {
                        return (
                          <li className={"text-danger"} key={id}>
                            {part}
                          </li>
                        )
                      }
                    })
                  : ""}
              </ul>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={storeModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setStoreModal(!storeModal)
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setStoreModal(!storeModal)
              }}
            >
              Import Shopify Orders
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleImportShopifyOrder()
                }}
              >
                <label>Stores</label>
                <select
                  className={"form-select"}
                  required={true}
                  value={selectedStore}
                  onChange={e => {
                    setSelectedStore(e.target.value)
                  }}
                >
                  {stores.length > 0 ? (
                    <>
                      <option value="" selected={true} disabled={true}>
                        Select a store
                      </option>
                      {stores.map((el, id) => (
                        <option value={el.id} key={id}>
                          {el.name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option>No store found</option>
                  )}
                </select>
                <br />
                <button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Import
                </button>
              </Form>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={data => {
            setViewModal(data)
          }}
        />
      </div>
    </div>
  )
}
ShopifyOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ShopifyOrders
