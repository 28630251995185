export const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-254226105-1');
}

export const initGADev = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-YQ0XXFE0NR');
}

function createScript(id, scriptText, src, async) {
  var script = document.createElement("script")

  if(id) {
    script.setAttribute("id", id)
  }

  if(src) {
    script.setAttribute("src", src);
  }

  if(async) {
    script.async = true;
  }

  if(scriptText) {
    script.appendChild(document.createTextNode(scriptText));
  }

  try {
    document.head.appendChild(script);
  } catch (e) {
    script.text = scriptText;
    document.head.appendChild(script);
  }
}

function noScriptForGTM(id, url) {
  let script = document.createElement("noscript")
  script.setAttribute("id", id)
  let x = document.createElement("iframe");
  x.setAttribute("src", url);
  x.setAttribute("width", "0")
  x.setAttribute("height", "0")
  x.setAttribute("style", "display:none;visibility:hidden")
  script.appendChild(x);

  let body = document.getElementsByTagName("body")[0];
  body.insertBefore(script, body.firstChild);
}

function removeScript(scriptId) {
  let elements = document.querySelectorAll(`#${scriptId}`);
  elements.forEach((element)=> {
    if (element) {
      element.parentNode.removeChild(element);
    }
  })
}

export const GoogleTagManager = (value) => {

  if(!value) {
    return false;
  }

  const id = "google-tag-manager"
  const scriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});let f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${value}');`
  const url = `https://www.googletagmanager.com/ns.html?id=${value}`
  noScriptForGTM(id, url);
  createScript(id, scriptText, null, false);

  return false;
}