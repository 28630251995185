// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"
import axios from "axios"
import JSZip from "jszip"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"

function SendNotification(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };


  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");


  // const tableData = ;
  const handleSendNotification = async () => {
    try {
      if(title && description) {
        const body = {
          title,
          description
        }

        post('/admin/send-notification', body, {headers}).then((response) => {
          if(response.status == 200) {
            toast.success(response.message)
          }
          else {
            toast.error(response.message)
          }
        })
      }
      else {
        toast.error("Please fill all required fields");
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  //meta title
  document.title = "Send Notification | NullShip";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Send Notification" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Notification Information</CardTitle>
                <div className="">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSendNotification()
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Title <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="title"
                        className="form-control non-arrow-number"
                        placeholder="Enter Notification Title"
                        type="text"
                        value={title}
                        maxLength={50}
                        onChange={(e) => {setTitle(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Description <span style={{color:"red"}}>*</span></Label>
                      <textarea
                        name="description"
                        className="form-control non-arrow-number"
                        placeholder="Enter Notification Description"
                        type="text"
                        maxLength={200}
                        onChange={(e) => {setDescription(e.target.value)}}
                        required={true}
                      >
                        {description}
                      </textarea>
                    </div>
                    <div>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Send Notification
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
SendNotification.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default SendNotification;