import React from "react"
export const rateApiCodes = [
  {
    code: "200",
    description: "Rates of all services of all carriers fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "500",
    description: "Unexpected condition while fulfilling the request.",
  },
]

export const storeApiCodes = [
  { code: "200", description: "Connected stores fetched successfully." },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "500",
    description: "Unexpected condition while fulfilling the request.",
  },
]

export const validationApiCodes = [
  {
    code: "200",
    description:
      "Retrieves validation rules for different carriers, filters out inactive carriers, and returns the active validations.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "500",
    description: "Unexpected condition while fulfilling the request.",
  },
]

export const insuranceApiCodes = [
  {
    code: "200",
    description:
      "Successfully calculated the insurance rate based on the cost of shipment.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "403",
    description:
      "cost_of_shipment can not be empty and must be equal or greater than $100",
  },
  {
    code: "500",
    description:
      "Failed to calculate insurance rate due to internal server error.",
  },
]

export const shipmentCreateApiCodes = [
  { code: "200", description: "Shipment created successfully." },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "400",
    description: (
      <div>
        <p>It can occur due to one of the following issues:</p>
        <ul
          style={{
            background: "none",
          }}
        >
          <li>Empty or invalid carrier field.</li>
          <li>Empty or invalid service_key field.</li>
          <li>Validation failure (errors will be provided in the response).</li>
        </ul>
      </div>
    ),
  },
  {
    code: "403",
    description: "Not enough balance available to process this order.",
  },
  {
    code: "422",
    description:
      "Occurs due to a failure of validation in a specific service (E.g: USPS, Fedex etc).",
  },
  {
    code: "429",
    description:
      "Too many requests. Requests are limited to 1 request per 800 milliseconds.",
  },
  { code: "503", description: "Carrier is temporary disabled." },
]

export const getShipmentCodes = [
  {
    code: "200",
    description: "Shipments/ completed orders fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "500",
    description:
      "Failed to fetch completed orders due to internal server error.",
  },
]
export const getShipmentByIdCodes = [
  {
    code: "200",
    description: "Specific shipment/completed order fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "500",
    description:
      "Failed to fetch specific completed order due to internal server error.",
  },
]

export const cancelShipmentCodes = [
  { code: "200", description: "Order/s cancelled successfully." },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "409",
    description: (
      <div>
        <p>
          {" "}
          The request could not be processed due to invalid input. This status
          code indicates one of the following issues:
        </p>
        <ul
          style={{
            background: "none",
          }}
        >
          <li>order_unique_ids must be an array.</li>
          <li>tracking_ids must be an array.</li>
          <li>
            Both order_unique_ids or tracking_ids must be provided in the
            request body.
          </li>
        </ul>
      </div>
    ),
  },
  {
    code: "500",
    description: "Failed to cancel order/s due to internal server error.",
  },
]
export const cancellationCode = [
  {
    code: "200",
    description: "Completed order cancellation status fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  { code: "409", description: "order_unique_id is required." },
  {
    code: "500",
    description:
      "Failed to fetch status of cancelled order due to internal server error.",
  },
]

export const packingSlipforIdCodes = [
  {
    code: "200",
    description:
      "Packing slips for a specific tracking id fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  { code: "409", description: "order_unique_id is required." },
  {
    code: "500",
    description:
      "Failed to fetch packing slips for a particular tracking_id due to internal server error.",
  },
]

export const mergedPackingSlipCodes = [
  {
    code: "200",
    description:
      "Merged label and Packing slip for a specific tracking id fetched successfully.",
  },
  {
    code: "401",
    description: "Access Denied! Unauthorized Request or Invalid Keys.",
  },
  {
    code: "402",
    description: "Access Denied! Account blocked or API key expired.",
  },
  {
    code: "404",
    description:
      (
        <div>
          <p>It can occur due to following reasons:</p>
          <ul
            style={{
              background: "none",
            }}
          >
            <li>Label does not exist.</li>
            <li>Packing Slip does not exist.</li>
          </ul>
        </div>
      ),
  },
  {
    code: "500",
    description:
      "Failed to fetch packing slips for a particular tracking_id due to internal server error.",
  },
]
