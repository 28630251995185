// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl, postFile } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"

function AddPaymentMethod(props) {
  const fileInput = useRef(null);
  // Page Title
  document.title = "Add Payment Method | NullShip";

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const headersMultipart = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const [emailList, setEmailList] = useState([]);
  const [name, setName] = useState("")
  const [accountDetails, setAccountDetails] = useState("");
  // const [amount, setAmount] = useState("");

  // Get User Email from API
  // const retrieveUser = () => {
  //   try {
  //     get('/admin/get-referrer-emails', {headers}).then((response) => {
  //       console.log("Get Referrer Emails", response);
  //       let arr = [];
  //       for(let i=0; i<response.data.length; i++){
  //         arr.push({value: response.data[i].refer_by_user_id, label:response.data[i].refer_by_email})
  //       }
  //       setEmailList(arr)
  //     }).catch(e => console.log("ee",e))
  //   }
  //   catch (e) {
  //     // // console.log(e)
  //   }
  // }

  // Email Select 
  // const handleEmailSelection = (data) => {
  //   setUserEmails(data)
  // }

  // Add Payment Method
  const handleAddPaymentMethod = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', fileInput.current.files[0]);
    formData.append('created_by', JSON.parse(localStorage.getItem("authUser")).id);
    formData.append('name', name);
    formData.append('accountDetails', accountDetails);

    // console.log("Add Payment Method Data", body)

    postFile('/admin/add-payment-option', formData, {headersMultipart}).then((response) => {
      if (response.status === 200) {
          toast.success(response.message)
          setTimeout(() => {
            props.history.push('/payment-methods')
          }, 1500)
      } else {
        toast.error(response.message)
      }
    })
  }


  useEffect(() => {
    // retrieveUser();
  },[])

  const handleChange = (event) => {
    // console.log(fileInput.current.files);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Payments" breadcrumbItem="Add Payment Method" />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden-not">
              <CardBody>
                <div className="">
                  <Form className="form-horizontal" encType={"multipart/form-data"}>
                    <div className="mb-3">
                      <Label className="form-label">Name <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="Name"
                        className="form-control non-arrow-number"
                        placeholder="Enter name"
                        type="text"
                        value={name}
                        maxLength={50}
                        onChange={(e) => {setName(e.target.value)}}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Image <span style={{color:"red"}}>*</span></Label>
                      <input
                        type="file"
                        className="form-control"
                        ref={fileInput}
                        id={"fileInput"}
                        required={true}
                        accept={"image/*"}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Account Details <span style={{color:"red"}}>*</span></Label>
                      <Input
                        name="account-details"
                        className="form-control non-arrow-number h-150"
                        placeholder="Enter account details"
                        type="textarea"
                        value={accountDetails}
                        maxLength={300}
                        onChange={(e) => {setAccountDetails(e.target.value)}}
                        required={true}
                      />
                      <p style={{color:"red"}}>Note: Use : to separate the label and value (i.e. Account Number <b>:</b> 123).</p>
                      <p style={{color:"red",marginTop:"-15px"}}>Note: Use | to break the line.</p>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                          onClick={(e) => {handleAddPaymentMethod(e)}}
                          disabled={(name && accountDetails) ? false : true}
                        >
                          Add Payment Method
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddPaymentMethod;