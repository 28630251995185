// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

function DHL() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  // const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [viewModal, setViewModal] = useState(false);
  // const [service_image, setServiceImage] = useState(null);
  // const [id, setId] = useState(null);
  // const [from_name, setFromName] = useState(null);
  // const [from_company, setFromCompany] = useState(null);
  // const [from_phone, setFromPhone] = useState(null);
  // const [from_street1, setFromStreet1] = useState(null);
  // const [from_street2, setFromStreet2] = useState(null);
  // const [from_city, setFromCity] = useState(null);
  // const [from_zip, setFromZip] = useState(null);
  // const [from_state, setFromState] = useState(null);
  // const [to_name, setToName] = useState(null);
  // const [to_company, setToCompany] = useState(null);
  // const [to_phone, setToPhone] = useState(null);
  // const [to_street1, setToStreet1] = useState(null);
  // const [to_street2, setToStreet2] = useState(null);
  // const [to_city, setToCity] = useState(null);
  // const [to_zip, setToZip] = useState(null);
  // const [to_state, setToState] = useState(null);
  // const [length, setLength] = useState(null);
  // const [height, setHeight] = useState(null);
  // const [width, setWidth] = useState(null);
  // const [weight, setWeight] = useState(null);
  // const [serviceId, setServiceId] = useState(null);
  // const [name, setName] = useState(null);
  // const [price, setPrice] = useState(null);
  // const [aboveFiveLbsPrice, setAboveFiveLbsPrice] = useState(null);
  // const [discount_type, setDiscountType] = useState(null);
  // const [discount, setDiscount] = useState(null);
  // const [description, setDescription] = useState(null);
  // const [created_at, setCreatedAt] = useState(null);
  // const [is_custom_form, setIsCustomForm] = useState(0);
  // const [content_type, setContentType] = useState(null);
  // const [restriction_type, setRestrictionType] = useState(null);
  // const [content_explanation, setContentExplanation] = useState(null);
  // const [restriction_comments, setRestrictionComments] = useState(null);
  // const [non_delivery_action, setNonDeliveryAction] = useState(null);
  // const [eel_or_ppc, setEelOrPpc] = useState(null);
  // const [custom_form_items, setCustomFormItems] = useState([]);

  const [completing_order_id, setCompletingOrderId] = useState(null);
  const [order_shipment_id, setOrderShipmentID] = useState(null);
  const [order_tracking_id, setOrderTrackingID] = useState(null);
  const [viewModalCompleteOrder, setViewModalCompleteOrder] = useState(false);

  const [error_order_id, setErrorOrderId] = useState(null);
  const [viewModalErrorOrder, setViewModalErrorOrder] = useState(false);
  const [order_error, setOrderError] = useState("");

  const [rowId, setRowId] = useState()
  
  const retrieveDHLOrders = async () => {
    try {
      get('/admin/get-dhl-orders', {headers}).then((response) => {
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };

  const columns = [
    {
      name: 'User Email',
      selector: row => row.email,
      // sortable: true,
    },
    {
      name: 'Service',
      // selector: name',
    //  sortable: true,
      selector: row => row.name ? row.name : <span style={{color:"red"}}>Not Assigned</span>
    },
    {
      name: 'From Name',
      // selector: 'from_name',
    //  sortable: true,
      selector: row => row.from_name ? row.from_name : "-"
    },
    {
      name: 'To Name',
      // selector: 'to_name',
    //  sortable: true,
      selector: row => row.to_name ? row.to_name : "-"
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      // selector: 'status',
     sortable: true,
      selector: (row) => row.status === 0 ? <span className={"badge badge-danger"}>Incompleted</span>:<span className={"badge badge-success"}>Completed</span>
    },
    // {
    //   name: 'Error',
    //   selector: 'error_message',
    //   sortable: true,
    //   width:"160px",
    //   cell: (row) => row.error_message?<span className={"badge badge-danger"}>{row.error_message}</span>:"-"
    // },
    {
      name:'Actions',
      // selector: 'id',
      width:"330px",
      selector: (row) =>
        <>
          <button className={"btn btn-success btn-sm"} onClick={() => {
            setRowId(row.id)
            setViewModal(true)
            // handleView(row.id)
            
            }}>View Order</button>
          <button className={"btn btn-warning btn-sm"} onClick={() => {handleCompleteOrder(row.id)}} style={{float:"right",marginLeft:"10px"}}>Complete Order</button>
          <button className={"btn btn-danger btn-sm"} onClick={() => {handleErrorOrder(row.id)}} style={{float:"right",marginLeft:"10px"}}>Update Error</button>
          {/*<button className={"btn btn-danger btn-sm"} onClick={() => { handleDelete(row.id)}} style={{float:"right",fontSize:"18px"}}><i className={"bx bx-trash"}></i></button>*/}
        </>
    }
  ];


  const handleCompleteOrder = (order_id) => {
    setCompletingOrderId(order_id);
    setViewModalCompleteOrder(true);
  }

  const handleErrorOrder = (order_id) => {
    setErrorOrderId(order_id);
    setOrderError("");
    setOrderShipmentID("");
    setOrderTrackingID("");
    setViewModalErrorOrder(true);
  }

  const handleCompleteOrderSubmit = (e) => {
    e.preventDefault();
    let loading = toast.loading("Completing order...");
    const formData = new FormData();
    formData.append('file', fileInput.current.files[0]);
    formData.append('order_id', completing_order_id);
    formData.append('order_shipment_id', order_shipment_id);
    formData.append('order_tracking_id', order_tracking_id);
    document.body.style.pointerEvents = "none";
    postFile("/user/complete-dhl-order", formData).then(async (response) => {
      // console.log("response",response);
      toast.remove(loading);
      if (response.status === 200) {
        document.body.style.pointerEvents = "auto";
        retrieveDHLOrders().then(() => {
          toast.success(response.message);
          setViewModalCompleteOrder(!viewModalCompleteOrder);
          setOrderShipmentID("");
          setOrderTrackingID("");
        });

        // if(response.success_orders.length > 0 ) {
        //   let body = {
        //     data: response.success_orders
        //   };
        //   // console.log("generate tracking body", body);
        //   await post('/user/generate-tracking', body, {headers});
        // }

      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleErrorOrderSubmit = (e) => {
    e.preventDefault();
    const body = {
      order_id: error_order_id,
      message: order_error
    }

    post("/admin/error-dhl-order", body, {headers}).then((response) => {
      // console.log("response",response);
      if (response.status === 200) {
        retrieveDHLOrders().then(() => {
          toast.success(response.message);
          setViewModalErrorOrder(!viewModalErrorOrder);
        });
      }
      else {
        toast.error(response.message);
      }
    })
  }

  useEffect(() => {
    retrieveDHLOrders();
  }, []);

  //meta title
  document.title = "DHL Orders In Process | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="In Process" breadcrumbItem="DHL Orders" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter DHL Orders...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Custom Modal*/}
        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />
        {/*Custom Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={viewModalCompleteOrder}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setViewModalCompleteOrder(!viewModalCompleteOrder);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setViewModalCompleteOrder(!viewModalCompleteOrder);
              }}
            >
              Complete Order
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={handleCompleteOrderSubmit}
              >
                <div className="mb-3">
                  <label>Shipment ID</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Shipment ID"
                    type="text"
                    onChange={(e) => setOrderShipmentID(e.target.value)}
                    value={order_shipment_id}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label>Tracking ID</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Tracking ID"
                    type="text"
                    onChange={(e) => setOrderTrackingID(e.target.value)}
                    value={order_tracking_id}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label>Order Pdf</label>
                  <input type={"file"} multiple={false} required={true} ref={fileInput} onChange={handleChange} className={"form-control"} accept={".pdf"}/>
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={viewModalErrorOrder}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setViewModalErrorOrder(!viewModalErrorOrder);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setViewModalErrorOrder(!viewModalErrorOrder);
              }}
            >
              Order Error
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={handleErrorOrderSubmit}
              >
                <div className="mb-3">
                  <label>Error</label>
                  <Input
                    name="order_error"
                    className="form-control"
                    placeholder="Enter Order Error"
                    type="text"
                    onChange={(e) => setOrderError(e.target.value)}
                    value={order_error}
                    required={true}
                  />
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Update Error
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
DHL.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default DHL;



// {/*Modal*/}
// <Modal
// isOpen={viewModal}
// role="dialog"
// autoFocus={true}
// centered={true}
// className="exampleModal modal-lg"
// tabIndex="-1"
// toggle={() => {
//   setViewModal(!viewModal);
// }}
// >
// <div>
//   <ModalHeader
//     toggle={() => {
//       setViewModal(!viewModal);
//     }}
//   >
//     Order View
//   </ModalHeader>
//   <ModalBody>
//     <div className={"container"}>
//       <div className={"col-md-12"}>
//         <div className={"row"}>
//           {name?
//             <div className={"col-md-12"}>
//               {/*<h2><img src={ups_logo} height={"50px"}/>*/}
//               <h2><img src={`data:image/jpeg;base64,${service_image}`} height={"50px"}/>
//                 {" "+name}
//                 {discount_type!=="None"?<span style={{float:"right",textDecoration:"line-through",color:"gray"}}>
//                     ${weight<5?price:aboveFiveLbsPrice}
//                   </span>:""}
//                 <span style={{float:"right",fontSize:"3rem"}}>
//                     ${weight<5?
//                   discount_type==="None"?price:(discount_type==="Flat"?price - discount:price - (discount / 100 * price)):
//                   discount_type==="None"?aboveFiveLbsPrice:(discount_type==="Flat"?aboveFiveLbsPrice - discount:aboveFiveLbsPrice - (discount / 100 * aboveFiveLbsPrice))
//                 }
//                   </span>
//               </h2>
//               <p style={{ marginLeft: "59px",marginTop:"-20px" }}>
//                 {description}
//               </p>
//             </div>:<h4 className={"text-danger"}>Service Not Assigned</h4>
//           }
//           <div>
//             <h4 className="card-title mb-4 mt-2">
//               Order Details
//               <span style={{float:"right",color:"gray",fontWeight:"400"}}>
//                       {moment(created_at).format('MMM DD, YYYY')}
//                     </span>
//             </h4>
//             <fieldset>
//               <legend>From</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Name:</strong> {from_name}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Company:</strong> {from_company?from_company:"-"}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Phone:</strong> {from_phone}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>City:</strong> {from_city}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Zip:</strong> {from_zip}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>State:</strong> {from_state}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-12"}>
//                     <p><strong>Address:</strong> {from_street1} {from_street2}</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>
//             <fieldset>
//               <legend>To</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Name:</strong> {to_name}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Company:</strong> {to_company?to_company:"-"}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Phone:</strong> {to_phone}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>City:</strong> {to_city}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Zip:</strong> {to_zip}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>State:</strong> {to_state}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-12"}>
//                     <p><strong>Address:</strong> {to_street1} {to_street2}</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>
//             <fieldset>
//               <legend>Dimensions & Weight</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Length:</strong> {length}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Width:</strong> {width}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Height:</strong> {height}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Weight:</strong> {weight} lb</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>
//             {is_custom_form===1?
//               <fieldset>
//                 <legend>Custom Form</legend>
//                 <div className={"col-md-12"}>
//                   <div className={"row"}>
//                     <div className={"col-md-6"}>
//                       <p><strong>Content Type:</strong> {content_type?content_type:"-"}</p>
//                     </div>
//                     <div className={"col-md-6"}>
//                       <p><strong>Restriction Type:</strong> {restriction_type?restriction_type:"-"}</p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className={"col-md-12"}>
//                   <div className={"row"}>
//                     <div className={"col-md-6"}>
//                       <p><strong>Content Explanation:</strong> {content_explanation?content_explanation:"-"}</p>
//                     </div>
//                     <div className={"col-md-6"}>
//                       <p><strong>Restriction Comments:</strong> {restriction_comments?restriction_comments:"-"}</p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className={"col-md-12"}>
//                   <div className={"row"}>
//                     <div className={"col-md-6"}>
//                       <p><strong>Non Delivery Action:</strong> {non_delivery_action?non_delivery_action:"-"}</p>
//                     </div>
//                     <div className={"col-md-6"}>
//                       <p><strong>EEL or PPC:</strong> {eel_or_ppc?eel_or_ppc:"-"}</p>
//                     </div>
//                   </div>
//                 </div>
//                 <hr/>
//                 <h6>Custom Items</h6>
//                 {custom_form_items.length>0?
//                   custom_form_items.map((el, id) => {
//                     return (
//                       <div key={id} style={{border:"2px solid #eeeeee",padding:"10px",borderRadius:"10px",marginBottom:"20px",display:(el.status === 1?"block":"none")}}>
//                         <div className={"col-md-12"}>
//                           <p><strong>Description of Items:</strong> {el.description?el.description:"-"}</p>
//                         </div>
//                         <div className={"col-md-12"}>
//                           <div className={"row"}>
//                             <div className={"col-md-6"}>
//                               <p><strong>Quantity:</strong> {el.quantity?el.quantity:"-"}</p>
//                             </div>
//                             <div className={"col-md-6"}>
//                               <p><strong>Total Value:</strong> {el.total_value?el.total_value:"-"}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className={"col-md-12"}>
//                           <div className={"row"}>
//                             <div className={"col-md-6"}>
//                               <p><strong>Total Weight (oz):</strong> {el.total_weight?el.total_weight:"-"}</p>
//                             </div>
//                             <div className={"col-md-6"}>
//                               <p><strong>Harmonized Tariff Schedule:</strong> {el.harmonized_tariff_schedule?el.harmonized_tariff_schedule:"-"}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className={"col-md-12"}>
//                           <div className={"row"}>
//                             <div className={"col-md-6"}>
//                               <p><strong>Product Identifier:</strong> {el.product_identifier?el.product_identifier:"-"}</p>
//                             </div>
//                             <div className={"col-md-6"}>
//                               <p><strong>Origin Country:</strong> {el.origin_country?el.origin_country:"-"}</p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     )
//                   })
//                   :""}
//               </fieldset>
//               :""}

//           </div>
//         </div>
//       </div>
//     </div>
//   </ModalBody>
// </div>
// </Modal>
// {/*Modal*/}