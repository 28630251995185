// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import JSZip from "jszip"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"


function Notifications(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [pending, setPending] = useState(true);

  function capitalizeWords(str) {
    // split the string into an array of words
    let words = str.split(' ');

    // capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    // join the words back together into a string and return it
    return words.join(' ');
  }

  const columns = [
    {
      name: 'Batch Id',
      // selector: 'batch_id',
      width:"250px",
      selector: row => row.batch_id ? "#"+row.batch_id:"-"
      //  sortable: true,
    },
    {
      name: 'Title',
      // selector: 'title',
      width:"200px",
      selector: row => row.title ? row.title:"-"
      //  sortable: true,
    },
    {
      name: 'Description',
      // selector: 'description',
      selector: row => row.description ? row.description:"-"
      //  sortable: true,
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      width:"210px",
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      // selector: 'files',
      width: "160px",
      selector: (row) => row.files?
          <>
            <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleDownload(row.files)}>Download</button>
          </>
        :"-"
    },
  ];

  const handleDownload = async (files) => {
    if (files) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      try {
        const zip = new JSZip();
        let notExistingFilesCount = 0;
        let downloadFiles = files.split(",");

        let order_ids = [];
        for (let i = 0; i < downloadFiles.length; i++) {
          await axios.get(`${baseUrl()}/user/get-pdf/${downloadFiles[i]}`, { headers, responseType: "blob" })
            .then(async (response) => {
              if (response && !response.data.status) {
                // console.log(response.headers["file-name"]);
                const blob = new Blob([response.data], { type: 'application/pdf' });
                // const url = URL.createObjectURL(blob);
                // window.open(url, '_blank');
                await zip.file(`${response.headers["file-name"]}.pdf`, blob)
                order_ids.push(response.headers["order-id"]);
              } else if (response.data && response.data.status == 500) {
                notExistingFilesCount++;
              }
            }).catch((err) => {
              // console.log("Something went wrong, Please try again", err)
              document.body.style.pointerEvents = "auto"
              toast.remove(loading)
              toast.error("Something went wrong");
            });
        }

        let payload = {
          order_ids: order_ids.toString()
        }
        console.log("payload",payload)
        post('/user/mark-pdf-printed', payload, {headers}).then(async (response) => {
          if (Object.keys(zip.files).length > 0) {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)

            let loading1 = toast.loading("Downloading orders...")
            if (notExistingFilesCount > 0) {
              toast.error(`${notExistingFilesCount} files do not exist`);
            }
            zip.generateAsync({ type: "blob" }).then(function(content) {
              saveAs(content, "orders.zip");
              toast.remove(loading1);
            })
          } else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error("Files do not exist. Please share your order number to admin for the PDF Label");
          }
        }).catch(async (errr) => {
          if (Object.keys(zip.files).length > 0) {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)

            let loading1 = toast.loading("Downloading orders...")
            if (notExistingFilesCount > 0) {
              toast.error(`${notExistingFilesCount} files do not exist`);
            }
            zip.generateAsync({ type: "blob" }).then(function(content) {
              saveAs(content, "orders.zip");
              toast.remove(loading1);
            })
          } else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error("Files do not exist. Please share your order number to admin for the PDF Label");
          }
        })


      } catch (e) {
        toast.remove(loading)
        toast.error("Something went wrong");
      }
    }
  }

    const retrieveNotifications = async () => {
      // console.log("heree");
      const body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      };
      await post('/user/get-notifications', body, { headers }).then((response) => {
        // console.log("response", response);
        if (response.status === 200) {
          let arr = [];
          for (let i=0;i<response.data.length;i++) {
            let found = arr.some(el => el.title == response.data[i].title && el.batch_id && el.batch_id == response.data[i].batch_id);
            // console.log("ffff",found);
            if(!found) {
              arr.push(response.data[i]);
            }
          }
          setFilteredData(arr);
          let data = arr;
          // console.log("table data" , { columns, data });
          setTableData({ columns, data });
          setPending(false);
        }
        else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    }


  useEffect(() => {
    // // console.log("here");
    retrieveNotifications();
  }, []);

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  //meta title
  document.title = "Notifications | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Notifications" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Notifications...`}
            >
              <DataTable columns={columns} data={filteredData} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
Notifications.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Notifications;