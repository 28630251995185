module.exports = `{
    "status": 200,
    "data": [
        {
            "carrier_name": [String],
            "service_name": [String],
            "description": [String],
            "service_key": [String],
            "one_lbs_to_five_lbs_price": [Float],
            "above_five_lbs_price": [Float],
            "below_one_lbs_price": [Float],
            "above_twenty_lbs_price": [Float]
        }
    ]
}`;

// ,
// {
//   "carrier_name": "UPSv2",
//   "service_name": "UPS NT Service",
//   "description": "-",
//   "service_key": "NT",
//   "price": "10",
//   "above_five_lbs_price": "12"
// }