import React from 'react';
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';

const NotesModal = ({
  isOpen,
  toggle,
  onClosed,
  handleUpdateNotes,
  notesValue,
  setNotesValue,
  modalTitle,
  placeholder
}) => {
  // const isReadOnly = localStorage.getItem("store") != "0";
  const isReadOnly = false;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal modal"
      tabIndex="-1"
      toggle={toggle}
      onClosed={onClosed}
    >
      <div>
        <ModalHeader toggle={toggle}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              handleUpdateNotes();
            }}
          >
            <label>Notes</label>
            <textarea
              type="text"
              required={true}
              readOnly={isReadOnly}
              placeholder={placeholder}
              onChange={e => setNotesValue(e.target.value)}
              className="form-control"
              value={notesValue}
            />
            <br />
            {!isReadOnly && (
              <button
                className="btn btn-sm btn-success"
                type="submit"
                style={{ float: "right" }}
              >
                Save
              </button>
            )}
          </Form>
          <br />
          <br />
        </ModalBody>
      </div>
    </Modal>
  );
};

export default NotesModal;
