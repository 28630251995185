import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const StoreModal = ({
  isOpen,
  toggle,
  heading,
  onClosed,
  data,
  handleSubmit,
}) => {
  const headingMappings = {
    Shiphero: {
      idKey: "id",
      identifierKey: "identifier",
      tradingNameKey: "trading_name",
    },
    Veeqo: {
      idKey: "id",
      identifierKey: "name",
      tradingNameKey: "trading_name",
    },
    ShipStation: {
      idKey: "storeId",
      identifierKey: "storeName",
      tradingNameKey: "marketplaceName",
    },
  }
  const { idKey, identifierKey, tradingNameKey } =
    headingMappings[heading] || {}

    // console.log("stores sss",data)
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      onClosed={onClosed}
    >
      <div>
        <ModalHeader toggle={toggle}>Connect {heading} Store</ModalHeader>
        <ModalBody>
          <div className={"col-md-12"}>
            {data.length > 0 ? (
              data.map((el, index) => (
                <div className="shipstation-stores" key={index}>
                  {el.is_connected ? (
                    <button
                      style={{ float: "right", marginTop: "5px" }}
                      className={"btn btn-light btn-sm"}
                      disabled={true}
                    >
                      Connected
                    </button>
                  ) : (
                    <button
                      style={{ float: "right", marginTop: "5px" }}
                      className={"btn btn-success btn-sm"}
                      onClick={() => handleSubmit(el[idKey])}
                    >
                      Connect
                    </button>
                  )}
                  <h5
                    title={el[identifierKey]}
                    style={{
                      marginBottom: "0px",
                      maxWidth: "280px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {el[identifierKey]}
                  </h5>
                  <p style={{ marginBottom: "0px" }}>
                    {el[tradingNameKey]} Store
                  </p>
                </div>
              ))
            ) : (
              <h4>No stores found</h4>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default StoreModal
