// src/components/filter.
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody } from "reactstrap"
import {
  CodeBlock,
  dracula,
  irBlack,
  atomOneDark,
  CopyBlock,
} from "react-code-blocks"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import config from "../../../../config"
import get_rates_response from "../../../../assets/js/get_rates_response"
import get_validations_response from "../../../../assets/js/get_validations_response"
import calculate_insurance_rate from "../../../../assets/js/calculate_insurance_rate"
import calculate_insurance_rate_body from "../../../../assets/js/calculate_insurance_rate_body"
import create_shipment_body from "../../../../assets/js/create_shipment_body"
import v1Collection from "../../../../assets/templete/NullShip_v1_Apis.json"
import { saveAs } from "file-saver"
import StatusTable from "./StatusCodeTable"
import {
  cancellationCode,
  cancelShipmentCodes,
  getShipmentByIdCodes,
  getShipmentCodes,
  insuranceApiCodes,
  mergedPackingSlipCodes,
  packingSlipforIdCodes,
  rateApiCodes,
  shipmentCreateApiCodes,
  storeApiCodes,
  validationApiCodes,
} from "constants/StatusCodes"
import RequiredHeaders from "./RequiredHeader"
function Documentation(props) {
  //meta title
  document.title = "API Documentation | NullShip"

  const downloadCollection = () => {
    // Define the path to your Postman collection file
    // console.log(v1Collection)
    const blob = new Blob([JSON.stringify(v1Collection)], {
      type: "application/json",
    })
    saveAs(blob, "NullShip-Api-v1.json")
  }
  const [open, setOpen] = useState("1")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title="NullShip"
          sourceItem="API"
          breadcrumbItem="Documentation"
        />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={12} lg={12} xl={12}>
            <Card className="overflow-hidden">
              <CardBody>
                <div className={"row"}>
                  <div className={"col-md-3 pt-2"}>
                    <ul className={"list-group"}>
                      <a href={"#introduction"}>
                        <li className={"list-group-item"}>Introduction</li>
                      </a>
                      <a href={"#get-started"}>
                        <li className={"list-group-item"}>Get Started</li>
                      </a>
                      <a href={"#api-endpoint"}>
                        <li className={"list-group-item"}>Api Endpoint</li>
                      </a>
                      <a href={"#authentication"}>
                        <li className={"list-group-item"}>Authentication</li>
                      </a>
                      <a href={"#rates"}>
                        <li className={"list-group-item"}>Rates</li>
                      </a>
                      <a href={"#stores"}>
                        <li className={"list-group-item"}>Stores</li>
                      </a>
                      <a href={"#calculate-insurance-rate"}>
                        <li className={"list-group-item"}>
                          Calculate Insurance Rate
                        </li>
                      </a>
                      <a href={"#validations"}>
                        <li className={"list-group-item"}>Validations</li>
                      </a>
                      <a href={"#create-shipment"}>
                        <li className={"list-group-item"}>Create Shipment</li>
                      </a>
                      <a href={"#shipments"}>
                        <li className={"list-group-item"}>Get All Shipments</li>
                      </a>
                      <a href={"#shipment"}>
                        <li className={"list-group-item"}>
                          Get Single Shipment
                        </li>
                      </a>
                      <a href={"#cancel-shipments"}>
                        <li className={"list-group-item"}>Cancel Shipments</li>
                      </a>
                      <a href={"#cancelled-shipment-status"}>
                        <li className={"list-group-item"}>
                          Cancelled Shipment Status
                        </li>
                      </a>
                      <a href={"#get-packing-slip"}>
                        <li className={"list-group-item"}>Get Packing Slip</li>
                      </a>
                      <a href={"#get-merged-label-and-packing-slip"}>
                        <li className={"list-group-item"}>
                          Get Merged Label & Packing Slip
                        </li>
                      </a>
                    </ul>
                  </div>
                  <div className={"col-md-9"}>
                    <div className={"col-md-12 p-2"}>
                      <h1>API Documentation</h1>
                      <div
                        id="introduction"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <h3>Introduction</h3>
                      <p>
                        NullShip API is a cutting-edge shipping solution that
                        revolutionizes the way businesses handle their shipping
                        operations. With a focus on affordability and
                        convenience, NullShip API offers a comprehensive suite
                        of services that include printing shipment labels at low
                        cost and providing real-time tracking capabilities.
                      </p>
                      <p>
                        The primary feature of NullShip API is its ability to
                        generate and print shipment labels at significantly
                        reduced costs. By integrating with the API, businesses
                        can seamlessly generate labels for their shipments
                        directly from their own applications or websites. This
                        eliminates the need for manual label creation or
                        reliance on expensive third-party solutions, saving both
                        time and money. With the low-cost printing service,
                        businesses can enjoy substantial cost savings while
                        maintaining a high level of efficiency in their shipping
                        processes.
                      </p>
                      <p>
                        In addition to cost-effective label printing, NullShip
                        API offers robust tracking functionality. Businesses can
                        provide their customers with the ability to track their
                        shipments in real-time, ensuring transparency and
                        enhancing the overall customer experience. The tracking
                        feature allows users to monitor the progress of their
                        packages from the moment they are picked up until they
                        reach their final destinations. This level of visibility
                        empowers both businesses and customers to stay informed,
                        anticipate any potential issues, and proactively manage
                        their shipping operations.
                      </p>
                      <p>
                        NullShip API boasts a user-friendly interface and
                        provides seamless integration capabilities, making it
                        easy for businesses to incorporate the service into
                        their existing systems. Whether it's an e-commerce
                        platform, inventory management software, or a
                        custom-built application, NullShip API can seamlessly
                        integrate with various platforms, enabling businesses to
                        streamline their shipping processes and enhance overall
                        operational efficiency.
                      </p>
                      <p>
                        With its low-cost label printing service and
                        comprehensive tracking capabilities, NullShip API is an
                        ideal choice for businesses seeking an affordable
                        shipping solution without compromising on quality. By
                        leveraging this powerful API, businesses can optimize
                        their shipping operations, improve customer
                        satisfaction, and ultimately drive growth and success in
                        their industry.
                      </p>
                    </div>
                    <div className={"col-md-12 p-2 mt-5"}>
                      <div
                        id="get-started"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <h3>Get Started</h3>
                      <p>
                        The API offered by this service requires users to
                        include an <b>api-key</b> and <b>api-secret</b> in the
                        headers of each request in order to process the request
                        successfully. To obtain these keys, visit{" "}
                        <a href={"keys"} target="_blank" rel="noreferrer">
                          Api Keys
                        </a>
                        , click <b>Generate API key</b> to generate your API key
                        and API secret.
                      </p>
                      <button
                        onClick={downloadCollection}
                        className={"btn btn-dark"}
                        title={"Download v1 Postman Collection"}
                      >
                        Download v1 Postman Collection
                      </button>
                    </div>
                    <div className={"col-md-12 p-2 mt-5"}>
                      <div
                        id="api-endpoint"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <h3>API Endpoint</h3>
                      <CopyBlock
                        text={`${config.site.URL}/apis/api/v1`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={irBlack}
                        wrapLines={true}
                        codeBlock
                      />
                      <p>
                        All endpoints are only accessible via <b>HTTPS</b> and
                        are located at{" "}
                        {config.site.URL.toString().replace("https://", "")}
                      </p>
                    </div>
                    <div className={"col-md-12 p-2 mt-5"}>
                      <div
                        id="authentication"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <h3>Authentication</h3>
                      <h5>How to get your API Key?</h5>
                      Visit{" "}
                      <a href={"keys"} target="_blank" rel="noreferrer">
                        API keys
                      </a>
                      . Click <b>Generate API key</b> to generate your API key.
                      <br />
                      <p>
                        The API Key method is a more straightforward
                        authentication method that only verifies whether the API
                        Key value is correct or not.
                      </p>
                    </div>
                    <div className={"col-md-12 p-2 mt-5"}>
                      <div id="rates" style={{ marginBottom: "80px" }}></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader
                            targetId="1"
                            style={{ cursor: "pointer" }}
                          >
                            <h3>
                              Rates{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/rates`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />
                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`${get_rates_response}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={rateApiCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div id="stores" style={{ marginBottom: "80px" }}></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            {" "}
                            <h3>
                              Stores{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/get-stores`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />
                            <h5>Response Example</h5>

                            <CodeBlock
                              text={`{
    "status": 200,
    "data": [
        {
            "id": [Integer],
            "name": [String],
            "store_type": [String]
        }
    ]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={storeApiCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="validations"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="3">
                            <h3>
                              Validations{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/validations`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`${get_validations_response}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={validationApiCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="calculate-insurance-rate"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="4">
                            <h3>
                              Calculate Insurance Rate{" "}
                              <span className={"badge badge-success"}>
                                POST
                              </span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="4">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/calculate-insurance-rate`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Required Body</h5>
                            <CopyBlock
                              text={`${calculate_insurance_rate_body}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <br />
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  cost_of_shipment
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>Cost of Shipment</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`${calculate_insurance_rate}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={insuranceApiCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="create-shipment"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="5">
                            <h3>
                              Create Shipment{" "}
                              <span className={"badge badge-success"}>
                                POST
                              </span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="5">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/create-shipment`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Required Body</h5>
                            <CopyBlock
                              text={`${create_shipment_body}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <br />
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  carrier
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Carrier name i.e. UPS</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  service_key
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Service key</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <h6>from</h6>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  name
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Name of shipped from</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  company
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from company name
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  phone
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from phone number
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street1
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from address line 1
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street2
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from address line 2
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  city
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Shipped from city</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  state
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from state (Must be in state code i.e.
                                  TX)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  country
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipped from country (Must be in country code
                                  i.e. US)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <h6>to</h6>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  name
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Name of shipping to</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  company
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to company name
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  phone
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to phone number
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street1
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to address line 1
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street2
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to address line 2
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  city
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Shipping to city</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  state
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to state (Must be in state code i.e.
                                  TX)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  country
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping to country (Must be in country code
                                  i.e. US)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <h6>return_to</h6>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  name
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Name of return to</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  company
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return company name
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  phone
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return phone number
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street1
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return address line 1
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  street2
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return address line 2
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  city
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Shipping return city</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  state
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return state (Must be in state code
                                  i.e. TX)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  country
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipping return country (Must be in country
                                  code i.e. US)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <h6>misc</h6>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  length
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipment length (in inches)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  width
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipment width (in inches)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  height
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipment height (in inches)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  weight
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>
                                  Shipment weight (in lb.)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <h6>options</h6>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  is_insured
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">
                                    boolean
                                  </span>
                                </td>
                                <td className={"p-2"}>Package insurance</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  cost_of_shipment
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">float</span>
                                </td>
                                <td className={"p-2"}>Cost of shipment</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required (if is_insured = true)
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  receiver_signature
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">
                                    boolean
                                  </span>
                                </td>
                                <td className={"p-2"}>Receiver Signature</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  saturday_delivery
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">
                                    boolean
                                  </span>
                                </td>
                                <td className={"p-2"}>
                                  Set this value to true to make your shipment
                                  eligible for Saturday delivery.
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  address_validation
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">
                                    boolean
                                  </span>
                                </td>
                                <td className={"p-2"}>
                                  Validate shipment from/to address through
                                  google if set true
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  label_format
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  PDF or ZPL (Only applicable on USPS services)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  dpi
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  203 or 600, Default 203 (Only applicable on
                                  ZPL label format)
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                                {/*<td><span style={{fontSize:"12px",fontWeight:"600",color:"red",float:"right"}}>Required (if label_format = ZPL)</span></td>*/}
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  reference_number
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>Reference Number</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                                {/*<td><span style={{fontSize:"12px",fontWeight:"600",color:"red",float:"right"}}>Required (if label_format = ZPL)</span></td>*/}
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  custom_reference_1
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Optional Reference Number 1
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                                {/*<td><span style={{fontSize:"12px",fontWeight:"600",color:"red",float:"right"}}>Required (if label_format = ZPL)</span></td>*/}
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  custom_reference_2
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Optional Reference Number 2
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                                {/*<td><span style={{fontSize:"12px",fontWeight:"600",color:"red",float:"right"}}>Required (if label_format = ZPL)</span></td>*/}
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <p>
                              Response contain <b>pdf</b> of created{" "}
                              <b>shipment label</b> with shipment information
                              attached in response headers.
                            </p>
                            <h6>Response Header</h6>
                            <table className={"table  table-bordered w-full"}>
                              <tr>
                                <th className={"p-2"}>File-Name</th>
                                <td className={"p-2"}>
                                  Name of shipment label file
                                </td>
                              </tr>
                              <tr>
                                <th className={"p-2"}>Order-Id</th>
                                <td className={"p-2"}>
                                  NullShip order id of shipment label
                                </td>
                              </tr>
                              <tr>
                                <th className={"p-2"}>Tracking-Id</th>
                                <td className={"p-2"}>
                                  NullShip tracking id of shipment label
                                </td>
                              </tr>
                              <tr>
                                <th className={"p-2"}>Service-Price</th>
                                <td className={"p-2"}>
                                  Service price applied on shipment label
                                </td>
                              </tr>
                              <tr>
                                <th className={"p-2"}>Time</th>
                                <td className={"p-2"}>
                                  Response time of shipment label
                                </td>
                              </tr>
                            </table>

                            <StatusTable data={shipmentCreateApiCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="shipments"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="6">
                            <h3>
                              Get All Shipments{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="6">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/shipments?storeId=[Integer]`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />
                            <h5>Query Parameters</h5>
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  storeId
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">
                                    integer
                                  </span>
                                </td>
                                <td className={"p-2"}>ID of store</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`{
    "status": 200,
    "data": [
      {
          "order_id": [Integer],
          "order_unique_id": [String],
          "to_name": [String],
          "to_street1": [String],
          "to_street2": [String],
          "to_state": [String],
          "to_city": [String],
          "to_zip": [String],
          "to_country": [String],
          "from_name": [String],
          "from_street1": [String],
          "from_street2": [String],
          "from_city": [String],
          "from_zip": [String],
          "from_state": [String],
          "from_country": [String],
          "order_date": [String],
          "store_id": [Integer],
          "store_order_updated_at": [String],
          "store_type": [String],
          "order_number": [String],
          "package_number": [String],
          "reference_number": [String],
          "custom_reference_1": [String],
          "custom_reference_2": [String],
          "from_buyer_notes": [String],
          "to_buyer_notes": [String],
          "internal_notes": [String],
          "custom_items_data": [String],
          "service_name": [String],
          "wallet_batch_id": [String],
          "completed_order_date": [String],
          "completed_order_updated_date": [String],
          "pdf": [String],
          "discounted_price": [String],
          "carrier_name": [String],
          "tracking_status": [String],
          "tracking_updated_at": [String],
          "tracking_code": [String]
      }
    ]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={getShipmentCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div id="shipment" style={{ marginBottom: "80px" }}></div>

                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="7">
                            <h3>
                              Get Single Shipment{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="7">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/shipment/[order_unique_id]`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`{
    "status": 200,
    "data": {
          "order_id": [Integer],
          "order_unique_id": [String],
          "to_name": [String],
          "to_street1": [String],
          "to_street2": [String],
          "to_state": [String],
          "to_city": [String],
          "to_zip": [String],
          "to_country": [String],
          "from_name": [String],
          "from_street1": [String],
          "from_street2": [String],
          "from_city": [String],
          "from_zip": [String],
          "from_state": [String],
          "from_country": [String],
          "order_date": [String],
          "store_id": [Integer],
          "store_order_updated_at": [String],
          "store_type": [String],
          "order_number": [String],
          "package_number": [String],
          "reference_number": [String],
          "custom_reference_1": [String],
          "custom_reference_2": [String],
          "from_buyer_notes": [String],
          "to_buyer_notes": [String],
          "internal_notes": [String],
          "custom_items_data": [String],
          "service_name": [String],
          "wallet_batch_id": [String],
          "completed_order_date": [String],
          "completed_order_updated_date": [String],
          "pdf": [String],
          "discounted_price": [String],
          "carrier_name": [String],
          "tracking_status": [String],
          "tracking_updated_at": [String],
          "tracking_code": [String]
      }
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={getShipmentByIdCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="cancel-shipments"
                        style={{ marginBottom: "80px" }}
                      ></div>

                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="8">
                            <h3>
                              Cancel Shipments{" "}
                              <span className={"badge badge-success"}>
                                POST
                              </span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="8">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/cancel-shipments`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Required Body</h5>
                            <CopyBlock
                              text={`{
    "order_unique_ids": [Array],
    "tracking_ids": [Array]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <br />
                            <table className={"table  table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  order_unique_ids
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">array</span>
                                </td>
                                <td className={"p-2"}>
                                  Array of order_unique_id
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  tracking_ids
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">array</span>
                                </td>
                                <td className={"p-2"}>Array of tracking_ids</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      float: "right",
                                    }}
                                  >
                                    Optional
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`{
    "status": 200,
    "message": [String]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={cancelShipmentCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="cancelled-shipment-status"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="9">
                            <h3>
                              Cancelled Shipment Status{" "}
                              <span className={"badge badge-success"}>GET</span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="9">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/cancelled-shipment-status/[order_unique_id/tracking_id]`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`{
    "status": 200,
    "data": {
        "order_id": [Integer],
        "order_unique_id": [String],
        "tracking_code": [String],
        "cancel_status": [String],
        "cancellation_date": [String],
        "completed_order_date": [String],
        "completed_order_updated_date": [String]
    }
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={cancellationCode} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="get-packing-slip"
                        style={{ marginBottom: "80px" }}
                      ></div>

                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="10">
                            <h3>
                              Get Packing Slip{" "}
                              <span className={"badge badge-success"}>
                                POST
                              </span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="10">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/get-packing-slip`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Required Body</h5>
                            <CopyBlock
                              text={`{
    "tracking_id": [String]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <br />
                            <table className={"table table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  Tracking Id
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Tracking-Id received in header after creating
                                  shipment
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <CodeBlock
                              text={`{
    "status": 200,
    "data": [Array of packing slip]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <StatusTable data={packingSlipforIdCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <div
                        id="get-merged-label-and-packing-slip"
                        style={{ marginBottom: "80px" }}
                      ></div>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                          <AccordionHeader targetId="11">
                            <h3>
                              Get Merged Label & Packing Slip{" "}
                              <span className={"badge badge-success"}>
                                POST
                              </span>
                            </h3>
                          </AccordionHeader>
                          <AccordionBody accordionId="11">
                            <CopyBlock
                              text={`${config.site.URL}/apis/api/v1/get-merged-label-and-packing-slip`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <RequiredHeaders />

                            <h5>Required Body</h5>
                            <CopyBlock
                              text={`{
    "tracking_id": [String]
}`}
                              language={"json"}
                              showLineNumbers={false}
                              theme={irBlack}
                              wrapLines={true}
                              codeBlock
                            />
                            <br />
                            <table className={"table table-bordered"}>
                              <tr>
                                <th
                                  className={"p-2"}
                                  style={{ width: "150px" }}
                                >
                                  Tracking Id
                                </th>
                                <td className={"p-2"}>
                                  <span className="text-secondary">string</span>
                                </td>
                                <td className={"p-2"}>
                                  Tracking-Id received in header after creating
                                  shipment
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "red",
                                      float: "right",
                                    }}
                                  >
                                    Required
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <br />
                            <h5>Response Example</h5>
                            <p>
                              Response contain <b>merged pdf</b> of{" "}
                              <b>shipment label</b> and <b>packing slip</b>.
                            </p>

                            <StatusTable data={mergedPackingSlipCodes} />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
Documentation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Documentation
