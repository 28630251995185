// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import config from '../../../config';
import { Country, State } from "country-state-city"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import customStyles from "../../../assets/css/customTableStyle"
import OrderViewModal from "../../../components/OrderViewModal"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

function OnHold(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const [viewModal, setViewModal] = useState(false)
  const [rowId, setRowId] = useState()

  const columns = [
    {
      name: <span style={{visibility:"hidden"}}>...</span>,
      // selector: "created_at",
      sortable: true,
      width: "100px",
      minWidth: "80px",
      maxWidth: "140px",
      reorder: true,
      style: {
        color: "#FF6600", fontWeight: "500", cursor: "pointer"
      },
      selector: (row) => {
        const date1 = moment(row.created_at.toString().substring(0,10)).tz(moment.tz.guess()).toDate();
        const date2 = moment(new Date());
        // console.log("date1",date1);
        // console.log("date2",date2);
        // console.log("")
        return (date2.diff(date1, 'days') + " days")
      }
    },
    {
      name: "Item Name",
      // selector: "custom_items_count",
      sortable: true,
      width: "170px",
      reorder: true,
      selector: row => row.custom_items_count?row.custom_items_count + (parseInt(row.custom_items_count)>1?" Items":" Item"):"0 Items",
    },
    {
      name: "From Name",
      // selector: "from_name",
      sortable: true,
      width: "170px",
      reorder: true,
      selector: row => row.from_name ? row.from_name : "-"
    },
    {
      name: "To Name",
      // selector: "to_name",
      sortable: true,
      width: "170px",
      reorder: true,
      selector: row => row.to_name ? row.to_name : "-"
    },
    {
      name: "Order #",
      // selector: "order_number",
      sortable: true,
      width: "140px",
      conditionalCellStyles: [
        {
          when: row => row.order_number,
          style: {
            color: "#FF6600", fontWeight: "500", cursor: "pointer"
          }
        }],
      button: true,
      reorder: true,
      selector: row => row.order_number ? <span onClick={() => {
        setRowId(row.id)
        setViewModal(true)
      }}>{row.order_number}</span> : "-",
    },
    {
      name: "Order Date",
      // selector: "created_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")
    },
    {
      name: "Updated At",
      // selector: "updated_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: (row) => moment(row.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")
    },
    {
      name: "Status",
      // selector: ["id", "error_message", "is_verified"],
      //  sortable: true,
      width: "100px",
      button: true,
      reorder: true,
      // cell: (row) => row.error_message ? <span className={"badge badge-danger"}>{row.error_message}</span> : "-"
      selector: (row) => <span className={"badge badge-warning"}>On Hold</span>
    },
    {
      name: "Actions",
      // selector: "id",
      width: "100px",
      button: true,
      selector: (row) =>
        <>
          <Button className={"btn btn-success btn-sm"} onClick={() => {
            setRowId(row.id)
            setViewModal(true)
          }} style={{ marginRight: "10px" }} title="View Order Details"><i
            className={"bx bx-list-ul"}></i></Button>
        </>
    },
    {
      name: "",
      selector: "order_unique_id",
      omit: true
    },
    {
      name: "",
      selector: "reference_number",
      omit: true
    }
  ];

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState({columns, data: []});
  const [storeName, setStoreName] = useState("");

  // const tableData = ;
  const retrieveOnHold = async () => {
    try {
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      };
      post('/user/get-onhold-orders', body, {headers}).then((response) => {
        // console.log("response", response);
        setData(response.data);
        let data = response.data;
        setTableData({ columns, data });
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const expandableRows = (row) => {
    // console.log("row",row);
    return (
      <>
        <div className={"col-md-12 mt-2 mb-2 row p-2"} style={{backgroundColor:localStorage.getItem("theme") == "dark"?"#222224":"#f7f5f5"}}>
          <h5>Item Details</h5>
          <div style={{display:"flex", justifyContent:"start", alignItems:"center", flexWrap:"wrap"}}>
            {row.data.is_custom_form >= 0 ?
              (row.data.custom_items_data?.length > 0 ?
                JSON.parse(row.data.store_type=="ebay"?JSON.parse(row.data.custom_items_data):row.data.custom_items_data).map((el, id) => {
                  if(row.data.store_type == null) {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.product_identifier}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.description ? el.description : "-"}</div></h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'shopify') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.id}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.name ? el.name : "-"}</div></h5>
                        <p>{el.price ? el.price : "-"} {el.price_set.shop_money.currency_code}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'ebay') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.lineItemId}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.title ? el.title : "-"}</div></h5>
                        <p>{el.lineItemCost.value} {el.lineItemCost.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'walmart') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>SKU: {el.item.sku}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                        <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'zenventory') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.customerorderitemid}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.itemmaster.description ? el.itemmaster.description : "-"}</div></h5>
                        <p>${el.customerprice}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'sellercloud') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.ProductID}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.Qty ? el.Qty : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.DisplayName ? el.DisplayName : "-"}</div></h5>
                        <p>{el.DisplayDescription?el.DisplayDescription:"-"}</p>
                        <div style={{display:"flex"}}>
                          <p style={{width:"50%"}}>${el.LineTotal}</p>
                          <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>
                        </div>
                        <p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>
                      </div>
                    )
                  }
                })
                : "")
              : <h6 style={{textAlign:"center"}}>No Item Found</h6>}
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    // // console.log("here");
    retrieveOnHold();
  }, []);


  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  //meta title
  document.title = "On Hold Orders | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Orders" breadcrumbItem="On Hold"/>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter On Hold Orders...`}
            >
              <DataTable columns={columns} data={data} pagination={30} paginationPerPage={30}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                         highlightOnHover={true}
                         expandableRows={true} expandableRowsComponent={expandableRows}
                         expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
      <OrderViewModal
        orderId={rowId}
        viewModal={viewModal}
        setViewModal={data => {
          setViewModal(data)
        }}
      />
    </div>
  );
}
OnHold.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default OnHold;