// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link, useParams } from "react-router-dom"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function CompletedOrdersReport() {
  const { user_id } = useParams();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(false);



  const columns = [
    {
      name: "Order Date",
      width: "200px",
      selector: (row) => row.Order_Date?moment(row.Order_Date).format('MMM DD, YYYY hh:mm A'):"-"
      // sortable: true,
      // minWidth: "180px",
      // reorder: true,
      // cell: (row) => {
      //   if(row.store_order_json) {
      //     let order = JSON.parse(row.store_order_json);
      //     if(row.store_type == "shopify") {
      //       return (<span>{moment(order.created_at).format("MMM DD, YYYY")}</span>);
      //     }
      //     else if(row.store_type == "ebay") {
      //       return (<span>{moment(order.updated_at).format("MMM DD, YYYY")}</span>);
      //     }
      //     else if(row.store_type == "walmart") {
      //       return (<span>{moment(order.orderDate).format("MMM DD, YYYY")}</span>);
      //     }
      //     else if(row.store_type == "zenventory") {
      //       return (<span>{moment(order.ordereddate).format("MMM DD, YYYY")}</span>);
      //     }
      //     else if(row.store_type == "sellercloud") {
      //       return (<span>{moment(order.TimeOfOrder).format("MMM DD, YYYY hh:mm A")}</span>);
      //     }
      //     else if(row.store_type == "shipstation") {
      //       return (<span>{moment(order.createDate).format("MMM DD, YYYY hh:mm A")}</span>);
      //     }
      //   }
      //   else {
      //     return ("-")
      //   }
      // }
    },
    {
      name: 'Update Date',
      width: "200px",
      selector: (row) => moment(row.Updated_At).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Sync Date',
      width: "200px",
      selector: (row) => moment(row.Sync_Date).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Status',
      width: "200px",
      selector: (row) => row.Status?row.Status:"-"
    },
    {
      name: 'Weight',
      width: "200px",
      selector: (row) => row.Weight?row.Weight:"-"
    },
    {
      name: 'Order Number',
      width: "200px",
      selector: (row) => row.Order_Number?row.Order_Number:"-"
    },
    {
      name: 'Is Package',
      width: "200px",
      selector: (row) => row.Is_Package?row.Is_Package:"-"
    },
    {
      name: 'Package Number',
      width: "200px",
      selector: (row) => row.Package_Number?row.Package_Number:"-"
    },
    {
      name: 'Package Index',
      width: "200px",
      selector: (row) => row.Package_Index?row.Package_Index:"-"
    }
  ];

  // const tableData = ;
  const retrieveOrders = async () => {
    try {
      setPending(true);
      get('/admin/get-completed-orders-report/'+user_id, {headers}).then((response) => {
        // // console.log("response", response);
        setFilteredData(response.data);
        setPending(false);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    // // console.log("here");
    retrieveOrders();
  }, []);

  //meta title
  document.title = "Completed Orders Report | NullShip";

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'completed_order_report.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;

  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(filteredData)} />, [filteredData]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Completed Orders Report" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              export={true}
              exportHeaders={true}
              filterPlaceholder={`Filter Completed Orders Report...`}
            >
              <DataTable columns={columns} actions={actionsMemo} data={filteredData} customStyles={customStyles} progressPending={pending} pagination={50} paginationPerPage={50} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
CompletedOrdersReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default CompletedOrdersReport;