// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { post, get } from "../../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"


function Keys() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  const retrieveKeys = async () => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };

      post('/user/get-api-key', body, {headers}).then((response) => {
        if(response.status === 200) {
          setApiKey(response.data.api_key);
          setApiSecret(response.data.api_secret);
          setCreatedAt(response.data.created_at);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    retrieveKeys();
  }, [])

  // const tableData = ;
  const handleGenerateKey = async () => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };

      post('/user/generate-api-key', body, {headers}).then(async (response) => {
        if(response.status === 200) {
          toast.success(response.message);
          await retrieveKeys();
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }



  //meta title
  document.title = "API Keys | NullShip";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="API" breadcrumbItem="Keys" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">API Keys</CardTitle>
                <div className="">
                  <div className="mb-3">
                    <Label className="form-label">API Key</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="API Key"
                        value={apiKey?apiKey.toString().substring(0, 15) + '****************':"No API Key Found"}
                      />
                      <button type="button" onClick={() => {navigator.clipboard.writeText(apiKey);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">API Secret</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="API Secret"
                        value={apiSecret?apiSecret.toString().substring(0, 15) + '******************************':"No API Secret Found"}
                      />
                      <button type="button" onClick={() => {navigator.clipboard.writeText(apiSecret);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>

                    <Row className="mb-3">
                      <Col className="text-start">
                      <p style={{color:"lightgray"}}>
                        {createdAt?"Generated At " + moment(createdAt).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"):""}
                      </p>
                      </Col>
                      <Col className="text-end">
                        <button
                          className="btn btn-success w-md "
                          type="button"
                          onClick={handleGenerateKey}
                        >
                          Generate New Keys
                        </button>
                      </Col>
                    </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
Keys.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Keys;