// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle
} from "reactstrap"
import avatar from "../../../assets/images/users/avatar-1.jpg";
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
function UserTickets() {
  let fileInput = useRef(null);
  const headersMultipart = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [modal, setModal] = useState(false);
  const [ticketThread, setTicketTread] = useState([]);

  const [ticketId, setTicketId] = useState("");
  const [attachments, setAttachments] = useState([]);

  const [pending, setPending] = useState(true);

  const columns = [
    {
      name: 'Ticket ID',
      selector: row => row.unique_id,
    //  sortable: true,
      width: "180px"
    },
    {
      name: 'Category',
      // selector: 'category',
      sortable: true,
      selector: row => row.category?row.category:"-"
    },
    {
      name: 'Status',
      // selector: 'status',
      sortable: true,
      selector: row => row.status==="Closed"?<span className={"badge badge-danger"}>{row.status}</span>:<span className={"badge badge-success"}>{row.status}</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "170px",
      selector: (row) =>
        <>
          <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleThread(row.id)}>View Thread</button>
        </>
    },
  ];

  const handleThread = async (id) => {
    setTicketId(id);
    const body = {
      id: id
    };
    post('/user/get-ticket-by-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        setTicketTread(response.data);
        validationRespond.resetForm();
        fileInput.current = null;
        setModal(!modal);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  const retrieveTickets = async () => {
    try {
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id
      }
      // console.log("body",body);
      post('/user/get-tickets-by-user-id', body, {headers}).then((response) => {
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveAttachments = async () => {
    try {
      get('/user/get-attachments', {headers}).then((response) => {
        setAttachments(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleViewFile = async (fileName) => {
    get(`/user/ticket-attachment/${fileName}`, {headers,responseType: 'blob'}).then((response) => {
      if(response instanceof Blob){
        const url = URL.createObjectURL(response);
        window.open(url,'_blank');
      }
      else {
        // console.log("not a blob")
      }
    });
  }

  const handleChange = (event) => {
    // console.log(fileInput.current.files);
  };

  const validationRespond = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: (values) => {
      dispatch(handleResponseTicket(values));
    }
  });

  const handleResponseTicket = (values) => {
    try {
      const {message} = values;

      const formData = new FormData();
      for (let i=0;i<fileInput.current.files.length;i++) {
        formData.append('files', fileInput.current.files[i]);
      }
      formData.append('message', message);
      formData.append('user_id', JSON.parse(localStorage.getItem("authUser")).id);
      formData.append('ticket_belongs_to', JSON.parse(localStorage.getItem("authUser")).id);
      formData.append('ticket_id', ticketId);
      postFile('/user/user-respond-ticket', formData, {headersMultipart}).then((response) => {
        if(response.status === 200) {
          retrieveAttachments().then(() => {
            const body = {
              id: ticketId
            };
            post('/user/get-ticket-by-id', body, {headers}).then((responsee) => {
              if(responsee.status === 200) {
                setTicketTread(responsee.data);
                validationRespond.resetForm();
                fileInput.current.value = null;
                document.getElementById("fileInput").files = null;
                toast.success(response.message);
              }
            });
          })
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    if(JSON.parse(localStorage.getItem("authUser")).role_id === 1) {
      props.history.push("/tickets");
    }
    retrieveTickets().then(() => {
      retrieveAttachments();
    });
  }, []);

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  //meta title
  document.title = "Tickets | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Tickets" />
        <Card>
          <CardBody>
            <Link to={"/ticket/generate"}><button className={"btn btn-primary waves-effect waves-light"}>Generate New Ticket</button></Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Generated Tickets</h4>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Ticket...`}
            >
              <DataTable theme={localStorage.getItem("theme")=="dark"?"solarized":""} columns={columns} data={filteredData} pagination={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                         highlightOnHover={true}
                         customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Ticket Thread
            </ModalHeader>
            <ModalBody className={"ticket-bd"}>
              {/*{ticketThread[0].message}*/}
              {ticketThread.map((part, id) => {
                return (
                <div className={"col-md-12 mt-4 mb-4 shadow-sm rounded p-4 " +(part.user_id === JSON.parse(localStorage.getItem("authUser")).id ? "user-response" : "admin-response")}
                     style={{backgroundColor:(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white")}}
                     key={id}>
                  <h5><img
                    src={avatar}
                    alt=""
                    className="avatar-sm rounded-circle img-thumbnail"
                  /> {part.first_name + " " + part.last_name} {(part.user_id === JSON.parse(localStorage.getItem("authUser")).id ? "" : <span style={{fontSize:"10px"}} className={"badge badge-success"}>Admin</span>)}
                  <span style={{float:"right",fontSize:"12px"}}>{moment(part.created_at).tz(moment.tz.guess()).format("DD MMM, YYYY hh:mm A")}</span>
                  </h5>
                  <div className={"container border rounded shadow-xm p-2"}>
                    {part.message}
                  </div><br/>
                  <h6>Attachments</h6>
                  {attachments.some(el=>el.ticket_id===part.id)?attachments.map((att, attId) => {
                    return (
                      <>
                        {att.ticket_id === part.id? <span className={"badge badge-success m-2 p-2"} onClick={() => handleViewFile(att.file_name)}>{att.file_name.toString().substring(19, att.file_name.toString().length)}</span>: ""}
                      </>
                    )
                  }):<p className={"text-center"}>No Attachment Found</p>}
                </div>
                );
              })}

              {ticketThread.length>0?(ticketThread[0].status==="Closed"?"":
              <div className="col-md-12">
                <hr/>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle className="mb-4">Respond Ticket</CardTitle>
                    <div className="">
                      <form
                        className="form-horizontal"
                        encType={"multipart/form-data"}
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationRespond.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Message</Label>
                          <Input
                            name="message"
                            // value={name}
                            className="form-control"
                            placeholder="Enter Message"
                            type="textarea"
                            onChange={validationRespond.handleChange}
                            onBlur={validationRespond.handleBlur}
                            value={validationRespond.values.message || ""}
                            invalid={
                              validationRespond.touched.message && validationRespond.errors.message ? true : false
                            }
                          />
                          {validationRespond.touched.message && validationRespond.errors.message ? (
                            <FormFeedback type="invalid">{validationRespond.errors.message}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Select Attachments</Label>
                          <input
                            type="file"
                            className="form-control"
                            ref={fileInput}
                            id={"fileInput"}
                            multiple={true}
                            accept={"image/*,.pdf,.xlsx,.csv"}
                            onChange={handleChange} />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-success w-md "
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>):""
              }
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
UserTickets.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default UserTickets;