import React from 'react';
// Components
import Payment from './payment';
// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from '../../../../config'
// Styles
import {useLocation} from "react-router";

const stripePromise = loadStripe(config.stripe.STRIPE_PUB_KEY);
function Wrapper(props) {
  const handleClose = (res) => {
    console.log("res res",res)
    props.closeModal(res)
  }
  return (
    <Elements stripe={stripePromise}>
      <Payment amount={props.amount} closeModal={handleClose}/>
    </Elements>
  );
}

export default Wrapper;
