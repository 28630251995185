// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function Service() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [id, setId] = useState(null);
  const [carrierID, setCarrierID] = useState(null);
  const [carrierName, setCarrierName] = useState(null);
  const [serviceKey, setServiceKey] = useState(null);
  const [price, setPrice] = useState(null);
  const [above_five_lbs_price, setAboveFiveLbsPrice] = useState(null);
  const [under_one_lbs_price, setUnderOneLbsPrice] = useState(null);
  const [above_twenty_lbs_price, setAboveTwentyLbsPrice] = useState(null);
  const [discount, setDiscount] = useState("");
  const [description, setDescription] = useState(null);
  const [status, setStatus] = useState(null);
  // const [weight, setWeight] = useState(null);
  const [discountType, setDiscountType] = useState("None");
  const [carriers, setCarriers] = useState([]);
  const [serviceImage, setServiceImage] = useState("");
  const [serviceImageToDisplay, setServiceImageToDisplay] = useState("");

  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carrier-names', {headers}).then((response) => {
        setCarriers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setServiceImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  const columns = [
    {
      name: 'Carrier',
      selector: row => row.carrier_name,
      //  sortable: true,
      width: "100px"
    },
    {
      name: 'Service Name',
      selector: row => row.name,
      //  sortable: true,
      minWidth: "140px"
    },
    {
      name: 'Service Key',
      selector: row => row.service_key,
      //  sortable: true,
      minWidth: "150px"
    },
    {
      name: 'Below 1 lb. Price',
      // selector: 'under_one_lbs_price',
      //  sortable: true,
      width: "140px",
      selector: (row) => (row.under_one_lbs_price?row.under_one_lbs_price:"-")
    },
    {
      name: '1lb. to 5lb. Price',
      selector: row => row.price,
      //  sortable: true,
      width: "140px"
    },
    {
      name: 'Above 5 lb. Price',
      selector: row => row.above_five_lbs_price??"-",
      //  sortable: true,
      width: "140px"
    },
    {
      name: 'Above 20 lb. Price',
      selector: row => row.above_twenty_lbs_price??"-",
      //  sortable: true,
      width: "150px"
    },
    {
      name: 'Discount',
      // selector: ['discount_type', 'discount'],
      //  sortable: true,
      width: "110px",
      selector: row => row.discount !== "" ? row.discount + " " + row.discount_type:"-"
      // cell: (row) => {row.is_discount===1?<span className={"badge badge-success"}>Available</span>:<span className={"badge badge-danger"}>Unavailable</span>}
    },
    // {
    //   name: 'Max limit',
    //   selector: 'max_limit',
    /////sortable: true,
    //   width: "110px",
    //   cell: row => row.max_limit !== "" ? row.max_limit :"-"
    //   // cell: (row) => {row.is_discount===1?<span className={"badge badge-success"}>Available</span>:<span className={"badge badge-danger"}>Unavailable</span>}
    // },
    // {
    //   name: 'Discount',
    //   selector: '',
    //   sortable: true,
    //   width: "110px",
    //   cell: (row) =>
    // },
    // {
    //   name: 'Final Price',
    //   selector: ['price', 'discount', 'discount_type'],
    //   sortable: true,
    //   width:"120px",
    //   cell: row => row.discount_type==="None"?row.price:(row.discount_type==="Flat"?row.price - row.discount:row.price - (row.discount / 100 * row.price))
    // },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      width: "150px",
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Updated At',
      // selector: 'updated_at',
      sortable: true,
      width: "150px",
      selector: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      // selector: 'updated_at',
      sortable: true,
      width: "130px",
      button: true,
      cell: (row) => (
        <>
          {row.status == 1?<span className="badge badge-success">Active</span>:<span className="badge badge-danger">Inactive</span>}
        </>
      )
    },
    {
      name: 'International Shipping',
      // selector: ['id','international_shipping'],
      width: "200px",
      button: true,
      selector: (row) =>
        <>
          <select name="is" className={"form-select"} style={{border:`1px solid ${row.international_shipping==1?"green":"red"}`}} onChange={(e) => handleEditInternatinalShipping(row.id, e.target.value)} value={row.international_shipping}>
            <option value="0">Disable</option>
            <option value="1">Enable</option>
          </select>
        </>
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "160px",
      button: true,
      selector: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleEdit(row.id)}>Edit</button>
          {/*<button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{marginLeft:"10px"}}>Delete</button>*/}
        </>
    },
  ];

  const handleEdit = async (id) => {
    const body = {
      id: id
    };
    post('/admin/get-service-by-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        const data = response.data;
        // console.log("data",data.carrier_id);
        setId(data.id);
        setCarrierID(data.carrier_id);
        setServiceImageToDisplay(data.service_image);
        setCarrierName(data.name);
        setServiceKey(data.service_key);
        setPrice(data.price);
        setAboveFiveLbsPrice(data.above_five_lbs_price);
        setUnderOneLbsPrice(data.under_one_lbs_price);
        setAboveTwentyLbsPrice(data.above_twenty_lbs_price);
        setDiscount(data.discount);
        setDiscountType(data.discount_type);
        setDescription(data.description);
        setStatus(data.status);
        // setWeight(data.weight)
        setModal(true);
      }
      else {
        toast.success(response.message);
      }
    })
  }

  const handleEditInternatinalShipping = async (id, internationalShipping) => {
    try {
      const body = {
        id: id,
        internationalShipping: parseInt(internationalShipping)
      };

      // console.log("body",body);
      post('/admin/update-service-international-shipping', body, {headers}).then((response) => {
        // // console.log("resss",response);
        if(response.status === 200) {
          toast.success(response.message);
          retrieveServices();
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleDelete = async (id) => {
    let confrm = confirm("Are you sure you want to delete this service?");
    if(confrm) {
      const body = {
        id: id
      };
      post('/admin/delete-service', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveServices();
        } else {
          toast.success(response.message);
        }
      })
    }
  }

  // const tableData = ;
  const retrieveServices = async () => {
    try {
      get('/admin/get-all-services', {headers}).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      carrierId: carrierID || '',
      carrierName: carrierName || '',
      serviceKey: serviceKey || '',
      price: price || '',
      above_five_lbs_price: above_five_lbs_price || '',
      discount: discount || '',
      description: description || '',
      weight: '',
    },
    validationSchema: Yup.object({
      carrierId: Yup.string().required("Please Enter Service Id"),
      carrierName: Yup.string().required("Please Enter Service Name"),
      serviceKey: Yup.string().required("Please Enter Service Key"),
      price: Yup.string().required("Please Enter Price"),
      above_five_lbs_price: Yup.string().required("Please Enter Above 5 lb. Price"),
      // description: Yup.string().required("Please Enter Description"),
      // weight: Yup.string().required("Please Enter Weight"),
    }),
    onSubmit: (values) => {
      dispatch(handleEditService(values));
    }
  });

  const handleDiscountType = (e) => {
    // console.log(e.target.value);
    setDiscountType(e.target.value);
    if(e.target.value == "None") {
      setDiscount("");
    }
  }

  const handleEditService = async () => {
    try {
      // const {carrierId, carrierName, serviceKey, price, discount, description, weight, above_five_lbs_price} = values;

      if(discountType !== "None" && discount == "" || discount == "0") {
        toast.error("Discount must be greater than 0");
      }
      else {
        // console.log(discount, price , parseFloat(discount) >= parseFloat(price));
        if(discountType === "Flat" && parseFloat(discount) >= parseFloat(price)) {
          toast.error("Discount amount must be lesser than actual amount");
          return true;
        }

        if(discountType === "Percent" && parseFloat(discount) > 100) {
          toast.error("Discount percentage must be lesser than 100");
          return true;
        }

        const body = {
          id: id,
          carrierId: carrierID,
          carrierName: carrierName,
          serviceKey: serviceKey,
          price: price,
          above_five_lbs_price: above_five_lbs_price,
          under_one_lbs_price: under_one_lbs_price,
          above_twenty_lbs_price: above_twenty_lbs_price,
          discountType: discountType,
          discount: discount,
          description: description,
          weight: 1,
          serviceImage: serviceImage,
          status: status
        };

        post('/admin/edit-service', body, {headers}).then((response) => {
          if(response.status === 200) {
            toast.success(response.message);
            retrieveServices();
            setModal(!modal);
          }
          else {
            toast.error(response.message);
          }
        })
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveServices();
    retrieveCarriers();
  }, []);

  //meta title
  document.title = "Services | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Services" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <Link to={"/service/add"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add New Service</button></Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Services...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Edit Service
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleEditService();
                  // validation.handleSubmit();
                  return false;
                }}
              >
                <div>
                  {serviceImageToDisplay?<img src={`data:image/jpeg;base64,${serviceImageToDisplay}`} className={"center-block"} height={"80px"} />:""}
                </div>
                <div className="mb-3">
                  <label>Change Service Image</label>
                  <input type={"file"} multiple={false} onChange={handleImage} className={"form-control"} accept={".png"}/>
                </div>
                <div className="mb-3">
                  <label>Carriers</label>
                  <select
                    name={"carrierId"}
                    className="form-select"
                    value={carrierID}
                    onChange={(e) => setCarrierID(e.target.value)}
                    required={true}
                  >
                    <option disabled={true} value={""}>Select a carrier</option>
                    {carriers.map((part, id) => {
                      return (<option key={id} value={part.id}>{part.name}</option>);
                    })};
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Service Name</Label>
                  <Input
                    name="carrierName"
                    className="form-control"
                    placeholder="Enter Service Name"
                    type="text"
                    value={carrierName}
                    onChange={(e) => setCarrierName(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Service Key</Label>
                  <Input
                    name="serviceKey"
                    className="form-control"
                    placeholder="Enter Service Key"
                    type="text"
                    value={serviceKey}
                    onChange={(e) => setServiceKey(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Under 1 lb. Price</Label>
                  <Input
                    name="under_one_lbs_price"
                    className="form-control non-arrow-number"
                    placeholder="Enter Under 1 lb. Price"
                    type="number"
                    step="any"
                    value={under_one_lbs_price}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setUnderOneLbsPrice(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">(1lb. to 5lb.) Price</Label>
                  <Input
                    name="price"
                    className="form-control non-arrow-number"
                    placeholder="Enter (1lb. to 5lb.) Price"
                    type="number"
                    step="any"
                    value={price}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setPrice(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Above 5 lb. Price</Label>
                  <Input
                    name="above_five_lbs_price"
                    className="form-control non-arrow-number"
                    placeholder="Enter Above 5 lb. Price"
                    type="number"
                    step="any"
                    value={above_five_lbs_price}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAboveFiveLbsPrice(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Above 20 lb. Price</Label>
                  <Input
                    name="under_one_lbs_price"
                    className="form-control non-arrow-number"
                    placeholder="Enter Above 20 lb. Price"
                    type="number"
                    step="any"
                    value={above_twenty_lbs_price}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAboveTwentyLbsPrice(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Discount Type</Label>
                  <select
                    className="form-select"
                    name="discountType"
                    value={discountType}
                    onChange={handleDiscountType}
                  >
                    <option>None</option>
                    <option>Flat</option>
                    <option>Percent</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Discount</Label>
                  <Input
                    name="discount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount"
                    type="number"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setDiscount(e.target.value)}
                    value={discountType==="None"?"":(discount || "")}
                    disabled={discountType==="None"?true:false}
                    max={discountType==="Percent"?100:""}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Description</Label>
                  <Input
                    name="description"
                    className="form-control non-arrow-number"
                    placeholder="Enter Description"
                    type="textarea"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    // required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Status</Label>
                  <select
                    className="form-select"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value="1">Activate</option>
                    <option value="0">Deactivate</option>
                  </select>
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    color="secondary"*/}
            {/*    onClick={() => {*/}
            {/*      setModal(!modal);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </Button>*/}
            {/*</ModalFooter>*/}
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
Service.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Service;