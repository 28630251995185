// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Select from "react-select"
import config from "../../../config"

function TrackingReport(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [stores, setStores] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [pending, setPending] = useState(true);

  const [filterStore, setFilterStore] = useState("all");
  const [filterDate, setFilterDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [total, setTotal] = useState(0);


  const columns = [
    {
      name: 'Tracking Number',
      sortable: true,
      selector: (row) => row.tracking_code
    },
    {
      name: 'Service Name',
      // selector: 'date',
      sortable: true,
      selector: (row) => row.service_name
    },
    {
      name: 'Ship From Name',
      // selector: 'date',
      sortable: true,
      selector: (row) => row.from_name
    },
    {
      name: 'Ship To Name',
      // selector: 'date',
      sortable: true,
      selector: (row) => row.to_name
    },
    {
      name: 'Cost',
      // selector: 'debit',
      sortable: true,
      right: true,
      selector: row => '$' + amountFormat(row.discounted_price)
    }
  ];

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, {headers}).then((response) => {
        // console.log("stores in nav", response);
        let arr = [];
        arr.push({
          value: 'all',
          label: "All Stores"
        }, {
          value: 0,
          label: "Manual Store"
        })
        for (let i=0;i<response.data.length;i++) {
          arr.push({
            value: response.data[i].id,
            label: response.data[i].store_name,
          })
        }
        console.log("arr",arr);
        setStores(arr);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  // const tableData = ;
  const retrieveOrdersReporting = async (date = filterDate, store = filterStore) => {
    try {
      setPending(true);
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        date: date,
        store: store
      }
      post('/user/get-tracking-cost-reporting', body, {headers}).then((response) => {
        console.log("response", response);
        setPending(false);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setTotal(data.reduce((acc, obj) => acc + parseFloat(obj.discounted_price), 0));
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    // // console.log("here");
    retrieveOrdersReporting();
    retrieveStores();
  }, []);

  const handleDate = async (e) => {
    setFilterDate(e.target.value);
    await retrieveOrdersReporting(e.target.value, filterStore);
  }

  const handleStore = async (e) => {
    setFilterStore(e.value);
    await retrieveOrdersReporting(filterDate, e.value);
  }

  //meta title
  document.title = "Daily Report | NullShip";

  const handleExport = () => {
    // console.log("filtered data", filteredData)
    if (filteredData.length > 0) {
      let header =
        "Tracking Number,Service Name,Cost\n"
      let data = ""
      for (let i = 0; i < filteredData.length; i++) {
        if(filteredData[i].tracking_code && filteredData[i].service_name && filteredData[i].discounted_price) {
          let tracking_code = filteredData[i].tracking_code;
          let service_name = filteredData[i].service_name;
          let discounted_price = filteredData[i].discounted_price;

          data += `${tracking_code},${service_name},${discounted_price}\n`
        }
      }

      data +=`Total,,${total}`;
      let final_data = header + data
      const blob = new Blob([final_data], { type: "text/csv;charset=utf-8" })
      saveAs(
        blob,
        "tracking-cost-report-" + moment(filterDate).format("DD-MMM-YYYY") + ".csv"
      )
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Daily Report" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className="row">
              <div className={"col-md-2"}>
                <label>Select Store</label>
                <Select
                  options={stores}
                  defaultValue={{value: "all", label: "All Stores"}}
                  onChange={handleStore}
                  className="select2-selection"
                />
              </div>
              <div className={"col-md-2"}>
                <label>Select Date</label>
                <input type={"date"} className={"form-control"} onChange={handleDate} value={filterDate}/>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className={"col-xl-1 col-lg-2 col-md-1 p-1"}>
              <button
                className={"btn btn-light"}
                style={{
                  marginBottom: "-80px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  display: (total > 0 ? "block": "none")
                }}
                title={"Export"}
                onClick={handleExport}
              >
                Export
                <i style={{marginLeft:"5px"}} className={"bx bx-export"}></i>
              </button>
            </div>
            <DataTableExtensions
              {...tableData}
              // exportHeaders={true}
              // export={true}
              filterPlaceholder={`Filter Tracking Cost Report...`}
            >
            <DataTable columns={columns} data={filteredData} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                       highlightOnHover={true}
                       theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </DataTableExtensions>
            <div style={{display: (total > 0 ? "block": "none"), float: "right", border:"1px solid #333", padding: "5px", borderRadius:"5px"}}><b>Total:</b> ${amountFormat(total)}</div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

TrackingReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default TrackingReport;