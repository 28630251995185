import React, { useState } from "react"
import { MdContentCopy } from "react-icons/md"
import "../stylesheet.css"
import config from "config"
import toast from "react-hot-toast"
const AddFundDetails = ({ details, code }) => {
  const [referenceCodeProvided, setReferenceCodeProvided] = useState(true)
  return (
    <div className="FlexColBaseLine">
      <div style={{margin: "20px",padding: "20px",border: "1px solid #e3e3e3",borderRadius: "8px",backgroundColor: "white",width: "100%",}}>
        <div className="FlexColBaseLine">
          <label style={{ display: "block", fontWeight: "500", marginBottom: "5px" }}>Account Type</label>
          <div className="addFundDetailsTextIconWrap">
            <span>{details.name ? details.name : "-"}</span>
            <img src={config.site.BASE_URL_FOR_IMAGES +"payment-option/" +details.image_url} alt={details.name} style={{height:"40px"}}/>
          </div>
        </div>
        <div className="FlexColBaseLine">
          <label style={{ display: "block", fontWeight: "500", marginBottom: "5px" }}>Account Details</label>
          <div className="addFundDetailsTextIconWrap2">
            <span>
              {" "}
              {details.account_details
                ? details.account_details.toString().split("|").map((el, index) => (
                  <p key={index} className="m-0">
                    <b>{el.split(":")[0]}</b>: {el.split(":")[1]}
                  </p>
                ))
              : "-"}
            </span>
            <button style={{ border: "none", background: "none", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(details.account_details);toast.success("Account Details Copied!")}}>
              <MdContentCopy />
            </button>
          </div>
        </div>

        <div className="FlexColBaseLine">
          <label style={{ display: "block", fontWeight: "500", marginBottom: "5px" }}>Payment Code</label>
          <div className="addFundDetailsTextIconWrap2">
            <span style={{fontWeight:"bold"}}>{code}</span>
            <button style={{ border: "none", background: "none", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(code);toast.success("Payment Code Copied!") }}>
              <MdContentCopy />
            </button>
          </div>
        </div>
      </div>

      <div>
        <input type="checkbox" checked={referenceCodeProvided} onChange={() => setReferenceCodeProvided(!referenceCodeProvided)} style={{ marginRight: "10px" }}/>
        <label style={{ fontWeight: "bold" }}>
          Yes, I will provide the Reference Code{" "}
          <span style={{ color: "#0366d6", backgroundColor: "#e1ecf4", padding: "2px 4px", borderRadius: "4px", fontSize: "12px",fontWeight:500 }}>MANDATORY</span>
        </label>
      </div>
      <p style={{ marginTop: "5px", color: "#6c757d", fontSize: "14px", textAlign: "start",}}>
        The reference code is required to process your payment. Without it, the
        funds will not be credited to your account.
      </p>
    </div>
  )
}

export default AddFundDetails
