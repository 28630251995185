import React from "react"
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import templateCsv from "../../../../assets/templete/Order bulk import template.csv"
import templateItemCsv from "../../../../assets/templete/Order with items bulk import template.csv"
import fedex from "../../../../assets/images/fedex.png"
import ups from "../../../../assets/images/ups-logo.png"
import usps from "../../../../assets/images/usps-logo.png"
import dhl from "../../../../assets/images/dhl-logo.png"

const ImportOrderModal = ({
  type,
  heading,
  isOpen,
  onClosed,
  toggle,
  fileInputRef, // Update prop name for clarity
  handleChange,
  accept,
  setSelectedMeasurement,
  selectedMeasurement,
  setSelectAvailableCarrier,
  selectAvailableCarrier,
  selectedCarrierName,
  carriers,
  handleCarrier,
  selectedCarrier,
  setSelectAvailableService,
  selectAvailableService,
  selectedServiceName,
  services,
  handleService,
  selectedService,
  handleSubmit,
}) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        onClosed={onClosed}
      >
        <div>
          <ModalHeader toggle={toggle}>{heading}</ModalHeader>
          <ModalBody>
            {type === "bulk orders" ? (
              <a
                href={localStorage.getItem("store") == 0 ? templateCsv : "#"}
                style={{
                  cursor:
                    localStorage.getItem("store") == 0 ? "pointer" : "default",
                }}
                download={localStorage.getItem("store") == 0 ? true : false}
              >
                <button
                  disabled={localStorage.getItem("store") == 0 ? false : true}
                  className={"btn btn-light waves-effect waves-light m-1"}
                >
                  Download Template <i className={"bx bx-file"}></i>
                </button>
              </a>
            ) : (
              <a
                href={
                  localStorage.getItem("store") == 0 ? templateItemCsv : "#"
                }
                style={{
                  cursor:
                    localStorage.getItem("store") == 0 ? "pointer" : "default",
                }}
                download={localStorage.getItem("store") == 0 ? true : false}
              >
                <button
                  disabled={localStorage.getItem("store") == 0 ? false : true}
                  className={"btn btn-light waves-effect waves-light m-1"}
                >
                  Download Template <i className={"bx bx-file"}></i>
                </button>
              </a>
            )}
            <hr />
            <form onSubmit={handleSubmit}>
              <Label className="form-label">
                {type === "bulk orders"
                  ? "Select A File (.xlsx / .csv / .xls)"
                  : "Select A File ( .csv )"}
              </Label>
              <input
                type="file"
                required={true}
                className="form-control mb-3"
                ref={fileInputRef} // Use the ref here
                onChange={handleChange}
                accept={accept}
              />
              <Label className="form-label">Unit of Measurement</Label>
              <select
                required={true}
                className="form-control mb-3"
                onChange={e => setSelectedMeasurement(e.target.value)}
                value={selectedMeasurement} // Set the value prop for controlled input
              >
                <option value="pounds">Pounds</option>
                <option value="ounces">Ounces</option>
              </select>

              <label>
                Select Carrier <span style={{ color: "red" }}>*</span>
              </label>
              <div
                className={"col-md-12 rounded p-2"}
                style={{ border: "1px solid #ced4da", cursor: "pointer" }}
                onClick={() =>
                  setSelectAvailableCarrier(!selectAvailableCarrier)
                }
              >
                <h5 className={"mt-2"}>
                  {selectedCarrierName
                    ? selectedCarrierName.toString().replace(" ", "")
                    : "Select Available Carrier"}
                </h5>
              </div>
              <div
                className={"row"}
                style={{ display: selectAvailableCarrier ? "block" : "none" }}
              >
                <div className={"col-md-12"}>
                  <table className={"table table-hover"}>
                    <tbody>
                      {carriers.map(
                        (part, id) =>
                          part.is_active == 1 && (
                            <tr
                              key={id}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                document
                                  .getElementById(`carrier-${part.id}`)
                                  .click()
                              }
                            >
                              <td style={{ width: "10px" }}>
                                <input
                                  type="radio"
                                  name="carrier"
                                  id={`carrier-${part.id}`}
                                  data-name={part.name}
                                  style={{ marginRight: "20px" }}
                                  onChange={handleCarrier}
                                  value={part.id}
                                  required={true}
                                  checked={part.id == selectedCarrier}
                                />
                              </td>
                              <td style={{ width: "30px" }}>
                                <img
                                  src={
                                    part.name == "FedEx"
                                      ? fedex
                                      : part.name == "UPS" ||
                                        part.name == "UPSv2"
                                      ? ups
                                      : part.name == "USPS"
                                      ? usps
                                      : dhl
                                  }
                                  height={"30px"}
                                />
                              </td>
                              <td>{part.name.toString().replace(" ", "")}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <label>
                Service <span style={{ color: "red" }}>*</span>
              </label>
              <div
                className={"col-md-12 rounded p-2"}
                style={{ border: "1px solid #ced4da", cursor: "pointer" }}
                onClick={() => {
                  if (selectedCarrier) {
                    setSelectAvailableService(!selectAvailableService)
                  } else {
                    toast.error("Please select a carrier")
                  }
                }}
              >
                <h5 className={"mt-2"}>
                  {selectedServiceName
                    ? selectedServiceName
                    : "Select Available Service"}
                </h5>
              </div>
              <div
                className={"row"}
                style={{ display: selectAvailableService ? "block" : "none" }}
              >
                <div className={"col-md-12"}>
                  <table className={"table table-hover"}>
                    <thead>
                      <tr>
                        <th colSpan={3}>Services</th>
                        <th style={{ textAlign: "right" }}>
                          1 lb. to 5 lb. Cost
                        </th>
                        <th style={{ textAlign: "right" }}>Above 5 lb. Cost</th>
                        <th style={{ textAlign: "right" }}>Below 1 lb. Cost</th>
                        <th style={{ textAlign: "right" }}>
                          Above 20 lb. Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {services
                        .filter(el => el.carrier_id == selectedCarrier)
                        .map((part, id) => (
                          <tr
                            key={id}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              document
                                .getElementById(`service-${part.id}`)
                                .click()
                            }
                          >
                            <td style={{ width: "10px" }}>
                              <input
                                type="radio"
                                name="service_id"
                                style={{ marginRight: "20px" }}
                                id={`service-${part.id}`}
                                data-name={part.name}
                                onClick={handleService}
                                value={part.id}
                                required={true}
                                checked={part.id == selectedService}
                              />
                            </td>
                            <td style={{ width: "30px" }}>
                              <img
                                src={`data:image/jpeg;base64,${part.service_image}`}
                                height={"30px"}
                              />
                            </td>
                            <td>
                              {part.name}
                              {part.description ? " - " + part.description : ""}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              $
                              {part.discount_type === "None"
                                ? part.price
                                : part.discount_type === "Flat"
                                ? part.price - part.discount
                                : part.price -
                                  (part.discount / 100) * part.price}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              $
                              {part.discount_type === "None"
                                ? part.above_five_lbs_price
                                : part.discount_type === "Flat"
                                ? part.above_five_lbs_price - part.discount
                                : part.above_five_lbs_price -
                                  (part.discount / 100) *
                                    part.above_five_lbs_price}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              $
                              {part.discount_type === "None"
                                ? part.below_one_lb_price
                                : part.discount_type === "Flat"
                                ? part.below_one_lb_price - part.discount
                                : part.below_one_lb_price -
                                  (part.discount / 100) *
                                    part.below_one_lb_price}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              $
                              {part.discount_type === "None"
                                ? part.above_twenty_lbs_price
                                : part.discount_type === "Flat"
                                ? part.above_twenty_lbs_price - part.discount
                                : part.above_twenty_lbs_price -
                                  (part.discount / 100) *
                                    part.above_twenty_lbs_price}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <button type="submit" className="btn btn-primary">
                  Import
                </button>
              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default ImportOrderModal
