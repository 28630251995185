// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function Carriers() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const dispatch = useDispatch();

  const [filteredData, setData] = useState([]);
  const [tableData, setTableData] = useState(null);



  const columns = [
    // {
    //   name: 'ID',
    //   selector: 'sequence',
    //   width:"100px"
    // },
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'Actions',
      // selector: ['is_active', 'id'],
      width: "160px",
      selector: (row) =>
        <>
          <select onChange={handleStatusChange} style={{width:"120px"}} className={"form-select"}>
            <option selected={row.is_active === 1?true:false} value={1 + "," + row.id}>Activate</option>
            <option selected={row.is_active === 0?true:false} value={0 + "," + row.id}>Deactivate</option>
          </select>
        </>
    },
  ];

  const handleStatusChange = async (e) => {
    const { value } = e.target;
    const id = value.split(",")[1];
    const is_active = value.split(",")[0];
    let body = {
      id: id,
      is_active: is_active
    };
    post('/admin/update-carrier-active-status', body, {headers}).then((response) => {
      if(response.status === 200) {
        retrieveCarriers();
        toast.success(response.message);
      }
      else{
        toast.error(response.message);
      }
    })
  }

  // const tableData = ;
  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carriers', {headers}).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
      })
    }
    catch (e) {
      // console.log(e)
    }
  }



  const handleEditCarrier = async (id, internationalShipping) => {
    try {
      const body = {
        id: id,
        internationalShipping: parseInt(internationalShipping)
      };

      // console.log("body",body);
      post('/admin/update-carrier', body, {headers}).then((response) => {
        // // console.log("resss",response);
        if(response.status === 200) {
          toast.success(response.message);
          retrieveCarriers();
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    // // console.log("here");
    retrieveCarriers();
  }, []);

  //meta title
  document.title = "Carriers | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Carriers" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Carriers...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
Carriers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Carriers;