import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"

import { withRouter } from "react-router-dom";
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction, logoutUser
} from "../../store/actions"

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

//redux
import { useSelector, useDispatch } from "react-redux";
import { get } from "../../helpers/api_helper"
import { GoogleTagManager } from "../../assets/js/gtag"
import config from "../../config"

const Layout = props => {
  const dispatch = useDispatch();

  const [google, setGoogle] = useState("");

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    layoutModeType : state.Layout.layoutModeType,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);

  const handleStorageChange = () => {
    if (localStorage.getItem("logout-event") && localStorage.getItem("logout-event") === 'true') {
      dispatch(logoutUser());
    }
  }

    //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout  settings
  */


  // const retrieveKeys = async () => {
  //   try {
  //     await get('/admin/get-api-keys', {headers}).then((response) => {
  //     // console.log("retrieveKeys", response.data);
  //       let arr = response.data;
  //       setGoogle(arr[arr.findIndex(el => el.name === "google")].api_key);
  //
  //       // document.head
  //       let googleCdn = document.createElement("script");
  //       googleCdn.type = "text/javascript"; mf.defer = true;
  //       googleCdn.src = "//cdn.mouseflow.com/projects/4333240f-cd13-40b4-874a-488b32d5c071.js";
  //       document.getElementsByTagName("head")[0].appendChild(mf);
  //     });
  //   }
  //   catch (e) {
  //   // console.log(e)
  //   }
  // }

  useEffect(() => {
    if(config.site.MODE == "production") {
      // initGA();
      GoogleTagManager('UA-254226105-1');
      GoogleTagManager('G-YQ0XXFE0NR');
    }

    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);
    if(document.title != "Edit Order | NullShip" && document.title != "Awaiting Shipment | NullShip" && document.title != "Completed Orders | NullShip" && document.title != "Cancelled Orders | NullShip") {
      localStorage.removeItem("order-filter");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  return (
    <React.Fragment>
      {/* <div id="preloader">*/}
      {/*  <div id="status">*/}
      {/*    <div className="spinner-chase">*/}
      {/*      <div className="chase-dot" />*/}
      {/*      <div className="chase-dot" />*/}
      {/*      <div className="chase-dot" />*/}
      {/*      <div className="chase-dot" />*/}
      {/*      <div className="chase-dot" />*/}
      {/*      <div className="chase-dot" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div> */}
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
