import React from "react"

const RequiredHeaders = () => {
  return (
    <>
      <br />
      <h5>Required Headers</h5>
      <table className={"table  table-bordered"}>
        <tr>
          <th className={"p-2"}>Header Name</th>
          <th className={"p-2"}>Example</th>
        </tr>
        <tr>
          <td className={"p-2"}>api-key</td>
          <td className={"p-2"}>ns_key_C1ThsK0YxKGqoAL7BjBb**********</td>
        </tr>
        <tr>
          <td className={"p-2"}>api-secret</td>
          <td className={"p-2"}>
            ns_secret_cAdxFrkYFDOjnQHy4crDmqz6Q**********
          </td>
        </tr>
      </table>
      <br />
    </>
  )
}

export default RequiredHeaders
