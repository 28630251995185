import React, { useEffect, useState } from "react"
import {
  Modal, ModalHeader, ModalBody
} from "reactstrap"
import { post } from "helpers/api_helper";
import moment from "moment-timezone"
import { amountFormat } from "../../assets/js/numberFormatter"
import { toast } from "react-hot-toast"


export default function OrderViewModal({ orderId, viewModal, setViewModal, showPrice = true }) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [id, setId] = useState(null);
  const [orderUniqueId, setOrderUniqueId] = useState(null);
  const [service_image, setServiceImage] = useState(null);
  const [from_country, setFromCountry] = useState(null);
  const [from_name, setFromName] = useState(null);
  const [from_company, setFromCompany] = useState(null);
  const [from_phone, setFromPhone] = useState(null);
  const [from_street1, setFromStreet1] = useState(null);
  const [from_street2, setFromStreet2] = useState(null);
  const [from_city, setFromCity] = useState(null);
  const [from_zip, setFromZip] = useState(null);
  const [from_state, setFromState] = useState(null);

  const [to_country, setToCountry] = useState(null);
  const [to_name, setToName] = useState(null);
  const [to_company, setToCompany] = useState(null);
  const [to_phone, setToPhone] = useState(null);
  const [to_street1, setToStreet1] = useState(null);
  const [to_street2, setToStreet2] = useState(null);
  const [to_city, setToCity] = useState(null);
  const [to_zip, setToZip] = useState(null);
  const [to_state, setToState] = useState(null);

  const [return_country, setReturnCountry] = useState(null);
  const [return_name, setReturnName] = useState(null);
  const [return_company, setReturnCompany] = useState(null);
  const [return_phone, setReturnPhone] = useState(null);
  const [return_street1, setReturnStreet1] = useState(null);
  const [return_street2, setReturnStreet2] = useState(null);
  const [return_city, setReturnCity] = useState(null);
  const [return_zip, setReturnZip] = useState(null);
  const [return_state, setReturnState] = useState(null);


  const [length, setLength] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [weight, setWeight] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [aboveFiveLbsPrice, setAboveFiveLbsPrice] = useState(null);
  const [discount_type, setDiscountType] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [description, setDescription] = useState(null);
  const [created_at, setCreatedAt] = useState(null);
  const [is_custom_form, setIsCustomForm] = useState(0);
  const [content_type, setContentType] = useState(null);
  const [restriction_type, setRestrictionType] = useState(null);
  const [content_explanation, setContentExplanation] = useState(null);
  const [restriction_comments, setRestrictionComments] = useState(null);
  const [non_delivery_action, setNonDeliveryAction] = useState(null);
  const [eel_or_ppc, setEelOrPpc] = useState(null);
  const [custom_form_items, setCustomFormItems] = useState([]);
  const [receiver_signature, setReceiverSignature] = useState(null);
  const [saturdayDelivery, setSaturdayDelivery] = useState(null)
  const [isInsured, setIsInsured] = useState(false)
  const [insurance_amount, setInsuranceAmount] = useState("");
  const [order_number, setOrderNumber] = useState("");
  const [reference_number, setReferenceNumber] = useState("");
  const [customReference1, setCustomReference1] = useState("");
  const [customReference2, setCustomReference2] = useState("");
  const [tag, setTag] = useState("");
  const [tag_color, setTagColor] = useState("");

  const retrieveOrder = async (oid) => {
    try {
      let body = {
        id: oid
      };
      post('/user/get-order', body, { headers }).then((response) => {
        // console.log("get order", response);
        let data = response.data[0];
        setId(data.id);
        setOrderUniqueId(data.order_unique_id);
        setServiceImage(data.service_image);

        setFromCountry(data.from_country);
        setFromName(data.from_name);
        setFromCompany(data.from_company);
        setFromPhone(data.from_phone);
        setFromStreet1(data.from_street1);
        setFromStreet2(data.from_street2);
        setFromCity(data.from_city);
        setFromZip(data.from_zip);
        setFromState(data.from_state);

        setToCountry(data.to_country);
        setToName(data.to_name);
        setToCompany(data.to_company);
        setToPhone(data.to_phone);
        setToStreet1(data.to_street1);
        setToStreet2(data.to_street2);
        setToCity(data.to_city);
        setToZip(data.to_zip);
        setToState(data.to_state);

        setReturnCountry(data.return_country);
        setReturnName(data.return_name);
        setReturnCompany(data.return_company);
        setReturnPhone(data.return_phone);
        setReturnStreet1(data.return_street1);
        setReturnStreet2(data.return_street2);
        setReturnCity(data.return_city);
        setReturnZip(data.return_zip);
        setReturnState(data.return_state);

        setLength(data.length);
        setHeight(data.height);
        setWidth(data.width);
        setWeight(data.weight);
        setServiceId(data.service_id);
        setName(data.name);
        setPrice(data.price);
        setAboveFiveLbsPrice(data.above_five_lbs_price);
        setDescription(data.description);
        setDiscount(data.discount);
        setDiscountType(data.discount_type);
        setCreatedAt(data.created_at);
        setIsCustomForm(parseInt(data.is_custom_form));
        setContentType(data.content_type);
        setContentExplanation(data.content_explanation);
        setRestrictionType(data.restriction_type);
        setRestrictionComments(data.restriction_comments);
        setNonDeliveryAction(data.non_delivery_action);
        setEelOrPpc(data.eel_or_ppc);
        setCustomFormItems(data.is_custom_form.toString() === "1" ? JSON.parse(data.custom_items_data) : []);
        setIsInsured(data.is_insured==1?true:false);
        setInsuranceAmount(data.insurance_amount);
        setReceiverSignature(data.receiver_signature);
        setSaturdayDelivery(data.saturday_delivery);
        setOrderNumber(data.order_number);
        setReferenceNumber(data.reference_number);
        setCustomReference1(data.custom_reference_1);
        setCustomReference2(data.custom_reference_2);

        setTag(data.tag);
        setTagColor(data.tag_color);
      })
    }
    catch (e) {
    // console.log(e)
    }
  }


  useEffect(() => {
    if(orderId !== null && orderId !== undefined) {
      setId(null);
      setServiceImage(null);
      setFromCountry(null);
      setFromName(null);
      setFromCompany(null);
      setFromPhone(null);
      setFromStreet1(null);
      setFromStreet2(null);
      setFromCity(null);
      setFromZip(null);
      setFromState(null);
      setToCountry(null);
      setToName(null);
      setToCompany(null);
      setToPhone(null);
      setToStreet1(null);
      setToStreet2(null);
      setToCity(null);
      setToZip(null);
      setToState(null);
      setLength(null);
      setHeight(null);
      setWidth(null);
      setWeight(null);
      setServiceId(null);
      setName(null);
      setPrice(null);
      setAboveFiveLbsPrice(null);
      setDescription(null);
      setDiscount(null);
      setDiscountType(null);
      setCreatedAt(null);
      setIsCustomForm(0);
      setContentType(null);
      setContentExplanation(null);
      setRestrictionType(null);
      setRestrictionComments(null);
      setNonDeliveryAction(null);
      setEelOrPpc(null);
      setCustomFormItems([]);
      setIsInsured(false);
      setInsuranceAmount("");
      setReceiverSignature(null)
// setTimeout(() => {
  retrieveOrder(orderId)
// }, 3000)

    }
  }, [orderId])

  // Calculate brightness
  const getBrightness = (color) => {
    const hexColor = color.replace('#', '');
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);
    // console.log("ddd", 0.299 * r + 0.587 * g + 0.114 * b)
    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  return (
    <div>
      <Modal
        isOpen={viewModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          setViewModal(false);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setViewModal(false);
            }}
          >
            {orderUniqueId} <span style={{ color: "#e3e3e3", cursor: "pointer" }} className={"bx bx-clipboard"} title={"Copy Order ID"} onClick={() => {navigator.clipboard.writeText(orderUniqueId);toast.success("Copied!")}}></span>
            {/*<a href={"order/edit/"+id}><i title={"Edit Order"} className={"bx bx-edit"} style={{marginLeft:"10px",cursor:"pointer"}}></i></a>*/}
          </ModalHeader>
          <ModalBody>
            <div className={"container"}>
              <div className={"col-md-12"}>
                {id?<div className={"row"}>
                  {name ?
                    <div className={"col-md-12"}>
                      {/*<h2><img src={ups_logo} height={"50px"}/>*/}
                      <img src={`data:image/jpeg;base64,${service_image}`} height={"50px"} style={{float:"left",marginRight:"10px"}} />
                      <h2>{" " + name}
                        {
                          showPrice && <> {discount_type !== "None" ? <span style={{ float: "right", textDecoration: "line-through", color: "gray" }}>${amountFormat(weight < 5 ? price : aboveFiveLbsPrice)}</span> : ""}
                            <span style={{ float: "right", fontSize: "3rem" }}>${amountFormat(weight < 5 ?
                              discount_type === "None" ? price : (discount_type === "Flat" ? price - discount : price - (discount / 100 * price)) :
                              discount_type === "None" ? aboveFiveLbsPrice : (discount_type === "Flat" ? aboveFiveLbsPrice - discount : aboveFiveLbsPrice - (discount / 100 * aboveFiveLbsPrice))
                            )}</span>
                          </>
                        }</h2>
                      <br/>
                      {showPrice?<>
                        <p style={{float:"right",color:"#495057"}}>{insurance_amount ? <>Cost of Insurance : <strong>${insurance_amount}</strong></>: ""}</p>
                      </>:""}
                      <p style={{ marginTop: "-25px" }}>{description}</p>
                    </div> : <h4 className={"text-danger"}>Service Not Assigned</h4>
                  }
                  <div>
                    {/*{tag?<><span style={{fontWeight:"600"}}>Tag:</span> <span title={tag} style={{backgroundColor: tag_color?tag_color:"#e3e3e3", color: (getBrightness(tag_color?tag_color:"#e3e3e3") > 128 ? 'black' : 'white')}} className={"badge"}>{tag}</span></>:""}*/}
                    <h4 className="card-title mb-4 mt-2">
                      Order Details
                      <span style={{ float: "right", color: "gray", fontWeight: "400" }}>
                        {moment(created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')}
                      </span>
                    </h4>
                    <fieldset style={{position:"relative"}}>
                      <legend>Ship From</legend>
                      <div className={"col-md-12"}>
                        <i title={"Copy From Address"} onClick={() => {
                          if(from_street1) {
                            navigator.clipboard.writeText(from_street1 + (from_street2?" " + from_street2 + " ": " ") + ", " + from_city + ", " + from_state + " " + from_zip + " " + from_country)
                            toast.success("Address Copied!");
                          }
                        }} style={{position:"absolute",right:"10px",top:"5px",cursor:"pointer"}} className={"bx bx-copy"}></i>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Name:</strong> {from_name ? from_name : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Company:</strong> {from_company ? from_company : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Phone:</strong> {from_phone ? from_phone : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>City:</strong> {from_city ? from_city : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Zip:</strong> {from_zip ? from_zip : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>State:</strong> {from_state ? from_state : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Country:</strong> {from_country ? from_country : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Address:</strong> {from_street1 ? from_street1 : "-"} {from_street2}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset style={{position:"relative"}}>
                      <legend>Ship To</legend>
                      <div className={"col-md-12"}>
                        <i title={"Copy To Address"} onClick={() => {
                          if(to_street1) {
                            navigator.clipboard.writeText(to_street1 + (to_street2?" " + to_street2 + " ": " ") + ", " + to_city + ", " + to_state + " " + to_zip + " " + to_country)
                            toast.success("Address Copied!");
                          }
                        }} style={{position:"absolute",right:"10px",top:"5px",cursor:"pointer"}} className={"bx bx-copy"}></i>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Name:</strong> {to_name ? to_name : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Company:</strong> {to_company ? to_company : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Phone:</strong> {to_phone ? to_phone : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>City:</strong> {to_city ? to_city : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Zip:</strong> {to_zip ? to_zip : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>State:</strong> {to_state ? to_state : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Country:</strong> {to_country ? to_country : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Address:</strong> {to_street1 ? to_street1 : "-"} {to_street2}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset style={{position:"relative"}}>
                      <legend>Return To</legend>
                      <div className={"col-md-12"}>
                        <i title={"Copy Return Address"} onClick={() => {
                          if(to_street1) {
                            navigator.clipboard.writeText(return_street1 + (return_street2?" " + return_street2 + " ": " ") + ", " + return_city + ", " + return_state + " " + return_zip + " " + return_country)
                            toast.success("Address Copied!");
                          }
                        }} style={{position:"absolute",right:"10px",top:"5px",cursor:"pointer"}} className={"bx bx-copy"}></i>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Name:</strong> {return_name ? return_name : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Company:</strong> {return_company ? return_company : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Phone:</strong> {return_phone ? return_phone : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>City:</strong> {return_city ? return_city : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Zip:</strong> {return_zip ? return_zip : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>State:</strong> {return_state ? return_state : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Country:</strong> {return_country ? return_country : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Address:</strong> {return_street1 ? return_street1 : "-"} {return_street2}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>Dimensions & Weight</legend>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Length:</strong> {length ? length : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Width:</strong> {width ? width : "-"}</p>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-12"}>
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <p><strong>Height:</strong> {height ? height : "-"}</p>
                          </div>
                          <div className={"col-md-6"}>
                            <p><strong>Weight:</strong> {weight ? parseFloat(weight).toFixed(2) + " lb." : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Options</legend>
                      <div className={"row"}>
                        <div className={"col-md-6"}>
                          <p><strong>Receiver Signature:</strong> {receiver_signature == "1" ? "Yes" : "No"}</p>
                        </div>
                        <div className={"col-md-6"}>
                          <p><strong>Saturday Delivery:</strong> {saturdayDelivery == "1" ? "Yes" : "No"}</p>
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className={"col-md-6"}>
                          <p><strong>Order Number:</strong> {order_number?<span style={{color:"#e0530cff"}}>{order_number}</span>:"-"}</p>
                        </div>
                        <div className={"col-md-6"}>
                          <p><strong>Reference Number:</strong> {reference_number?reference_number:"-"}</p>
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className={"col-md-6"}>
                          <p><strong>Custom Reference 1:</strong> {customReference1?customReference1:"-"}</p>
                        </div>
                        <div className={"col-md-6"}>
                          <p><strong>Custom Reference 2:</strong> {customReference2?customReference2:"-"}</p>
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className={"col-md-6"}>
                          <p><strong>Cost of Insurance:</strong> {insurance_amount ? "$"+parseFloat(insurance_amount).toFixed(2): "-"}</p>
                        </div>
                      </div>
                    </fieldset>
                    {/*Comment custom form in order view because we add this in expendable in order table*/}
                    {/*{is_custom_form === 1 ?*/}
                    {/*  <fieldset>*/}
                    {/*    <legend>Custom Form</legend>*/}
                    {/*    <div className={"col-md-12"}>*/}
                    {/*      <div className={"row"}>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>Content Type:</strong> {content_type ? content_type : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>Restriction Type:</strong> {restriction_type ? restriction_type : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={"col-md-12"}>*/}
                    {/*      <div className={"row"}>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>Content Explanation:</strong> {content_explanation ? content_explanation : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>Restriction Comments:</strong> {restriction_comments ? restriction_comments : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={"col-md-12"}>*/}
                    {/*      <div className={"row"}>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>Non Delivery Action:</strong> {non_delivery_action ? non_delivery_action : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className={"col-md-6"}>*/}
                    {/*          <p><strong>EEL or PPC:</strong> {eel_or_ppc ? eel_or_ppc : "-"}</p>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <hr />*/}
                    {/*    <h6>Custom Items</h6>*/}
                    {/*    {custom_form_items.length > 0 ?*/}
                    {/*      custom_form_items.map((el, id) => {*/}
                    {/*        return (*/}
                    {/*          <div key={id} style={{ border: "2px solid #eeeeee", padding: "10px", borderRadius: "10px", marginBottom: "20px", display: (el.status === 1 ? "block" : "none") }}>*/}
                    {/*            <div className={"col-md-12"}>*/}
                    {/*              <p><strong>Description of Items:</strong> {el.description ? el.description : "-"}</p>*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-12"}>*/}
                    {/*              <div className={"row"}>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Quantity:</strong> {el.quantity ? el.quantity : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Total Value:</strong> {el.total_value ? el.total_value : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*              </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-12"}>*/}
                    {/*              <div className={"row"}>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Total Weight (oz):</strong> {el.total_weight ? el.total_weight : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Harmonized Tariff Schedule:</strong> {el.harmonized_tariff_schedule ? el.harmonized_tariff_schedule : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*              </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-12"}>*/}
                    {/*              <div className={"row"}>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Product Identifier:</strong> {el.product_identifier ? el.product_identifier : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className={"col-md-6"}>*/}
                    {/*                  <p><strong>Origin Country:</strong> {el.origin_country ? el.origin_country : "-"}</p>*/}
                    {/*                </div>*/}
                    {/*              </div>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*        )*/}
                    {/*      })*/}
                    {/*      : ""}*/}
                    {/*  </fieldset>*/}
                    {/*  : ""}*/}
                  </div>
                </div>:<h4 className={"text-center"}>Loading order details...</h4>}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}
    </div>
  )
}
