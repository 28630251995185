// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import { saveAs } from 'file-saver';

import OrderViewModal from "components/OrderViewModal";
import defaultLoader from "../../../assets/images/default_loader.gif"
import customStyles from "../../../assets/css/customTableStyle"
let page = 1;
let dataCompleted = false;
function OrderErrors() {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);

  const [viewModal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [service_image, setServiceImage] = useState(null);
  // const [id, setId] = useState(null);
  // const [from_name, setFromName] = useState(null);
  // const [from_company, setFromCompany] = useState(null);
  // const [from_phone, setFromPhone] = useState(null);
  // const [from_street1, setFromStreet1] = useState(null);
  // const [from_street2, setFromStreet2] = useState(null);
  // const [from_city, setFromCity] = useState(null);
  // const [from_zip, setFromZip] = useState(null);
  // const [from_state, setFromState] = useState(null);
  // const [to_name, setToName] = useState(null);
  // const [to_company, setToCompany] = useState(null);
  // const [to_phone, setToPhone] = useState(null);
  // const [to_street1, setToStreet1] = useState(null);
  // const [to_street2, setToStreet2] = useState(null);
  // const [to_city, setToCity] = useState(null);
  // const [to_zip, setToZip] = useState(null);
  // const [to_state, setToState] = useState(null);
  // const [length, setLength] = useState(null);
  // const [height, setHeight] = useState(null);
  // const [width, setWidth] = useState(null);
  // const [weight, setWeight] = useState(null);
  // const [serviceId, setServiceId] = useState(null);
  // const [name, setName] = useState(null);
  // const [price, setPrice] = useState(null);
  // const [aboveFiveLbsPrice, setAboveFiveLbsPrice] = useState(null);
  // const [discount_type, setDiscountType] = useState(null);
  // const [discount, setDiscount] = useState(null);
  // const [description, setDescription] = useState(null);
  // const [created_at, setCreatedAt] = useState(null);

  const [rowId, setRowId] = useState()

  const columns = [
    {
      name: 'User Email',
      selector: row => row.email,
    //  sortable: true,
      minWidth: '200px'
    },
    {
      name: 'Error Type',
      selector: row => row.error_type,
    //  sortable: true,
      minWidth: '100px'
    },
    {
      name: 'Error Message',
      // selector: row => row.error_message,
    //  sortable: true,
      minWidth: '400px',
      selector: row => <span onDoubleClick={() => handleDownloadError(row.error_message)} title={"Double click to download"}>{row.error_message.toString().substring(0, 100)}</span>
    },
    {
      name: 'Carrier DB ID',
      // selector: 'id_of_carrier_id',
    //  sortable: true,
      width: "140px",
      selector: row => row.id_of_carrier_id?row.id_of_carrier_id:"-"
      // minWidth: '200px'
    },
    {
      name: 'Carrier ID',
      // selector: 'carrier_id',
    //  sortable: true,
      selector: row => row.carrier_id?row.carrier_id:"-"
      // minWidth: '200px'
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      width: "140px",
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      // selector: 'order_id',
      selector: (row) => row.order_id ? <><button className={"btn btn-success btn-sm"} onClick={() => {
        setRowId(row.order_id)
        setViewModal(true)
        // handleView(row.order_id)
      }}>View Order</button></> : ""
    }
  ];

  const handleDownloadError = (error) => {
    const data = error.toString();
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    saveAs(blob, 'error-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
    toast.success("Error log downloaded");
  }

  // const handleView = (id) => {
  //   // e.preventDefault();
  //   // const id = e.target.getAttribute("data-id");
  //   // // console.log("handleView",e.target)
  //   retrieveOrder(id);
  //   setViewModal(!viewModal);
  // }

  // const retrieveOrder = async (oid) => {
  //   try {
  //     let body = {
  //       id: oid
  //     };
  //     post('/user/get-order', body, { headers }).then((response) => {
  //       // console.log("get order", response);
  //       let data = response.data[0];
  //       setId(data.id);
  //       setServiceImage(data.service_image)
  //       setFromName(data.from_name);
  //       setFromCompany(data.from_company);
  //       setFromPhone(data.from_phone);
  //       setFromStreet1(data.from_street1);
  //       setFromStreet2(data.from_street2);
  //       setFromCity(data.from_city);
  //       setFromZip(data.from_zip);
  //       setFromState(data.from_state);
  //       setToName(data.to_name);
  //       setToCompany(data.to_company);
  //       setToPhone(data.to_phone);
  //       setToStreet1(data.to_street1);
  //       setToStreet2(data.to_street2);
  //       setToCity(data.to_city);
  //       setToZip(data.to_zip);
  //       setToState(data.to_state);
  //       setLength(data.length);
  //       setHeight(data.height);
  //       setWidth(data.width);
  //       setWeight(data.weight);
  //       setServiceId(data.service_id);
  //       setName(data.name);
  //       setPrice(data.price);
  //       setAboveFiveLbsPrice(data.above_five_lbs_price);
  //       setDescription(data.description);
  //       setDiscount(data.discount);
  //       setDiscountType(data.discount_type);
  //       setCreatedAt(data.created_at);
  //     })
  //   }
  //   catch (e) {
  //     // console.log(e)
  //   }
  // }


  // const tableData = ;
  const retrieveOrderErrors = async () => {
    try {
      get('/admin/get-order-errors/'+page, { headers }).then((response) => {
        // // console.log("response", response);
        if(response.data.length > 0) {
          let mergeData = filteredData.concat(response.data);
          setFilteredData(mergeData);
          let data = mergeData;
          // console.log("table data", { columns, data });
          setTableData({ columns, data });
          setPending(false);
          setLoading(false);
          dataCompleted = false;
          page = page + 1;
        }
        else {
          dataCompleted = true;
          setLoading(false);
          setPending(false);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    dataCompleted = false;
    page = 1;
    setFilteredData([]);
    let data = [];
    setTableData({columns, data});
    retrieveOrderErrors();
  }, []);

  async function handleScroll() {
    // console.log("scrolling")
    // if (parseInt(window.innerHeight + document.documentElement.scrollTop) == parseInt(document.documentElement.scrollHeight)) {
    if ((parseInt(document.documentElement.scrollHeight) - parseInt(window.innerHeight + document.documentElement.scrollTop)) <= 5) {
      // console.log("scrolling bottom",dataCompleted);
      if(!dataCompleted) {
        setLoading(true);
      }
      await retrieveOrderErrors();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [filteredData]);


  //meta title
  document.title = "Order Errors | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Logs" breadcrumbItem="Order Errors" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Order Errors...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles}
                         // pagination={30}
                         // paginationPerPage={30}
                         progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
            {loading?<h4 className={"text-center"}><img src={defaultLoader} className={"mt-3 mb-3"} width={"40px"} /></h4>:""}
          </CardBody>
        </Card>

        {/*Custom Modal*/}
        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />
        {/*Custom Modal*/}


      </div>
    </div>
  );
}
OrderErrors.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default OrderErrors;