// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.xlsx"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import defaultLoader from '../../../assets/images/default_loader.gif'
import { initGA } from "../../../assets/js/gtag"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

function CancelledOrders(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pending, setPending] = useState(true)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [viewModal, setViewModal] = useState(false)

  const [rowId, setRowId] = useState();

  const [scrolling, setScrolling] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [filterType, setFilterType] = useState(
    localStorage.getItem("order-filter")
      ? localStorage.getItem("order-filter") == "Unassigned" ? "FedEx" : localStorage.getItem("order-filter")
      : "FedEx"
  )

  const [invoiceData, setInvoiceData] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)

  const columns = [
    // {
    //   name: 'Shipment ID',
    //   selector: 'wallet_batch_id',
    //   sortable: true,
    //   width: "330px",
    //   cell: row => row.wallet_batch_id !== null ? row.wallet_batch_id : "-"
    // },
    {
      name: "Service Name",
      selector: row => row.service_name,
      reorder: true,
      //  sortable: true,
      width: "180px",
    },
    // {
    //   name: 'From Name',
    //   selector: 'from_name',
    //   sortable: true,
    //   minWidth: "150px",
    //   cell: row => row.from_name ? row.from_name : "-"
    // },
    {
      name: "To Name",
      // selector: "to_name",
      reorder: true,
      //  sortable: true,
      // width: "160px",
      selector: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: "Tracking Code",
      // selector: 'after_ship_id',
      // selector: ["tracking_code"],
      // sortable: false,
      reorder: true,
      width: "260px",
      selector: row =>
        row.tracking_code ? (
          <>
            {/*<a*/}
            {/*  target={"_blank"}*/}
            {/*  rel={"noreferrer"}*/}
            {/*  href={`/track/${row.tracking_code}`}*/}
            {/*>*/}
              {row.tracking_code}
            {/*</a>*/}
            {/*<span style={{ color: "#e3e3e3", cursor: "pointer",marginLeft:"5px" }} className={"bx bx-clipboard"} title={"Copy Tracking Number"} onClick={() => {navigator.clipboard.writeText(row.tracking_code);toast.success("Copied!")}}></span>*/}
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   name: 'Cost',
    //   // selector: 'after_ship_id',
    //   selector: ['discounted_price'],
    //   sortable: false,
    //   width: "80px",
    //   cell: row => row.discounted_price ? "$" + amountFormat(row.discounted_price) : "-"
    // },
    // {
    //   name: 'Updated At',
    //   selector: ['tracking_updated_at'],
    //   sortable: false,
    //   width: "130px",
    //   cell: row => row.tracking_updated_at ? moment(row.tracking_updated_at).format("DD-MM-YYYY hh:mm A") : "-"
    // },
    {
      name: "Cancellation Status",
      // selector: ["cancel_status"],
      sortable: false,
      width: "180px",
      reorder: true,
      button: true,
      selector: row =>
        row.cancel_status ? (
          row.cancel_status == "Pending"?<span className={"badge badge-warning"}>{row.cancel_status}</span>:<span className={"badge badge-success"}>{row.cancel_status}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Cancelled At",
      // selector: "cancel_at",
      sortable: true,
      width: "180px",
      selector: row => moment(row.cancel_at).utc(false).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
    },
    {
      name: "Actions",
      // selector: ["id", "order_id"],
      //  sortable: true,
      width: "100px",
      button: true,
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      selector: row => (
        <>
          {" "}
          <button title={"View Order Details"}
                  className={"btn btn-success btn-sm"}
                  onClick={() => {
                    setRowId(row.order_id)
                    setViewModal(true)
                  }}
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
        </>
      ),
    },
    {
      name: '',
      selector: row => row.order_unique_id,
      omit: true
    },
  ];


  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("download-checkbox")
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }
  }

  const retrieveOrdersCancelled = async (type) => {
    localStorage.setItem("order-filter", type)
    setPending(true);
    try {
        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).id,
          carrier: type
        }
        post("/user/get-orders-cancelled-by-carrier", body, { headers }).then(
          response => {
            // console.log("response",response);
            let data = response.data;

            setFilteredData(data);
            setTableData({columns, data});

            setPending(false);
          }
        )
    } catch (e) {
    }
  }
  useEffect(() => {
    retrieveOrdersCancelled(filterType);
  }, [])

  const handleFedExOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("FedEx")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("FedEx")
    }
  }

  const handleUPSOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("UPS")
    }
  }

  const handleUPSv2Orders = async () => {
    if(!loadingOrders) {
      setPending(true);
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPSv2")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("UPSv2")
    }
  }

  const handleDHLOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("DHL")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("DHL")
    }
  }

  const handleUSPSOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      // document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("USPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveOrdersCancelled("USPS")
    }
  }

  const handleOnPageChange = () => {
    document.getElementById("select-all-checkbox").checked = false;
    if(selectedOrders.length > 0) {
      for (let i=0;i<selectedOrders.length;i++) {
        if(document.getElementById(`order-${selectedOrders[i]}`)) {
          document.getElementById(`order-${selectedOrders[i]}`).checked = true;
        }
      }
    }
  }


  const handleRowClick = row => {
    document.getElementById(`order-${row.pdf}`).click();
  }


  const expandableRows = (row) => {
    // console.log("row",row);
    return (
      <>
        <div className={"col-md-12 mt-2 mb-2 row p-2"} style={{backgroundColor:localStorage.getItem("theme") == "dark"?"#222224":"#f7f5f5"}}>
          <h5>Item Details</h5>
          <div style={{display:"flex", justifyContent:"start", alignItems:"center", flexWrap:"wrap"}}>
            {row.data.is_custom_form >= 0 ?
              (row.data.custom_items_data?.length > 0 ?
                JSON.parse(row.data.store_type=="ebay"?JSON.parse(row.data.custom_items_data):row.data.custom_items_data).map((el, id) => {
                  if(row.data.store_type == null) {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.product_identifier}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.description ? el.description : "-"}</div></h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'shopify') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.id}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.name ? el.name : "-"}</div></h5>
                        <p>{el.price ? el.price : "-"} {el.price_set.shop_money.currency_code}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'ebay') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.lineItemId}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.title ? el.title : "-"}</div></h5>
                        <p>{el.lineItemCost.value} {el.lineItemCost.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'walmart') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>SKU: {el.item.sku}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                        <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'zenventory') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.customerorderitemid}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.itemmaster.description ? el.itemmaster.description : "-"}</div></h5>
                        <p>${el.customerprice}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'sellercloud') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.ProductID}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.Qty ? el.Qty : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.DisplayName ? el.DisplayName : "-"}</div></h5>
                        <p>{el.DisplayDescription?el.DisplayDescription:"-"}</p>
                        <div style={{display:"flex"}}>
                          <p style={{width:"50%"}}>${el.LineTotal}</p>
                          <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>
                        </div>
                        <p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>
                      </div>
                    )
                  }
                })
                : "")
              : <h6 style={{textAlign:"center"}}>No Item Found</h6>}
          </div>
        </div>
      </>
    )
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  //meta title
  document.title = "Cancelled Orders | NullShip"

  return (
    <div className="page-content" style={{position:"relative"}}>
      {/*<span style={{fontSize:"12px",fontWeight:"normal",position:"fixed", bottom:"80px", right:"0px",padding:"5px", backgroundColor:"white",zIndex:"999", display: (selectedOrdersCount>0?"block":"none")}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>*/}
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Orders" breadcrumbItem="Cancelled" />
        {/* <Table columns={columns} data={data} /> */}
        <div
          style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8" }}
          className="rounded-top"
        >
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "FedEx" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "FedEx" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={handleFedExOrders}
            disabled={pending}
          >
            FedEx
          </button>
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "UPS" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "UPS" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={handleUPSOrders}
            disabled={pending}
          >
            UPS
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "UPSv2" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "UPSv2" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={handleUPSv2Orders}
            disabled={pending}
          >
            UPSv2
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "DHL" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "DHL" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={handleDHLOrders}
            disabled={pending}
          >
            DHL
          </button>
          <button
            className={
              "btn btn-md  " +
              (filterType === "USPS" ? " text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "USPS" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
            onClick={handleUSPSOrders}
            disabled={pending}
          >
            USPS
          </button>
        </div>
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
          <CardBody>
            {/*<h1>page {page}</h1>*/}
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Cancelled Orders...`}
            >
              <DataTable
                columns={columns}
                data={filteredData}
                pagination={50}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
                onChangePage={handleOnPageChange}
                // onRowClicked={handleRowClick}
                expandableRows={true} expandableRowsComponent={expandableRows}
                expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                className={"order-table"}
                theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

      </div>
    </div>
  )
}
CancelledOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CancelledOrders
