// src/components/filter.
import React, { useEffect, useMemo, useState,useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Order bulk import template.xlsx'
import axios from 'axios';
import ups_logo from '../../../assets/images/ups-logo.png';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody,CardHeader
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

let selectedOrders = [];
function UserOrders(props) {

  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filterType, setFilterType] = useState(localStorage.getItem("order-filter")?localStorage.getItem("order-filter"):"FedEx");
  const [pending, setPending] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [rowId, setRowId] = useState()


  const columns = [
    {
      name: 'User Email',
      selector: row => row.email,
      // sortable: true,
    },
    // {
    //   name: 'Carrier ID',
    //   selector: 'carrier_id',
    //   // sortable: true,
    //   cell: row => row.carrier_id ? row.carrier_id : "-"
    // },
    {
      name: 'Service Name',
      // selector: 'service_name',
    //  sortable: true,
      selector: row => row.service_name ? row.service_name : <span style={{color:"red"}}>Not Assigned</span>
    },
    {
      name: 'From Name',
      // selector: 'from_name',
    //  sortable: true,
      selector: row => row.from_name ? row.from_name : "-"
    },
    {
      name: 'To Name',
      // selector: 'to_name',
    //  sortable: true,
      selector: row => row.to_name ? row.to_name : "-"
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      // selector: 'status',
    //  sortable: true,
      selector: (row) => row.status === 0 ? <span className={"badge badge-warning"}>To Process</span>:(row.status === 2 ?<span className={"badge badge-danger"}>Incomplete</span>:<span className={"badge badge-success"}>Completed</span>)
    },
    {
      name: 'Label Source',
      selector: (row) => row.label_source?row.label_source:"-",
    //  sortable: true,
    //   cell: (row) => row.status === 0 ? <span className={"badge badge-warning"}>To Process</span>:(row.status === 2 ?<span className={"badge badge-danger"}>Incomplete</span>:<span className={"badge badge-success"}>Completed</span>)
    },
    {
      name:'Actions',
      // selector: 'id',
      width:"130px",
      selector: (row) =>
        <>
          <button className={"btn btn-success btn-sm"} onClick={() => {
            setRowId(row.id)
            setViewModal(true)
            // handleView(row.id)
             }}>View Order</button>
          {/*<button className={"btn btn-light btn-sm"} onClick={() => props.history.push('/order/edit/'+row.id)} style={{float:"right",fontSize:"18px",marginRight:"10px"}}><i className={"bx bx-edit"}></i></button>*/}
          {/*<button className={"btn btn-danger btn-sm"} onClick={() => { handleDelete(row.id)}} style={{float:"right",fontSize:"18px"}}><i className={"bx bx-trash"}></i></button>*/}
        </>
    }
  ];

  const recursiveRetrieve = (type, page, orderData = []) => {
    let body = {
      carrier: type,
      page: page
    }
    post("/admin/get-user-orders-by-carrier", body, { headers }).then(
      async (response) => {
        if(response.status === 200) {
          if(response.data.length > 0) {
            let res;
            if(orderData.length == 0) {
              res = response.data;
            }
            else {
              res = orderData.concat(response.data);
            }
            setFilteredData(res)
            let data = res;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            let nextPage = +page + +1;
            // console.log("next page", nextPage)
            await recursiveRetrieve(type, nextPage, res);
          }
          else {
            setFilteredData(orderData)
            let data = orderData;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            return data;
          }
        }
        else {
          setFilteredData(orderData)
          let data = orderData;
          // console.log("table data", { columns, data })
          setTableData({ columns, data })
          setPending(false)
          return data;
        }
      }
    )
  }

  const retrieveOrders = async (type) => {
    try {
      // if(!loadingOrders) {
        localStorage.setItem("order-filter", type);

        setFilterType(type);
        setPending(true);
        setLoadingOrders(true);
        // let page = 1;
        // const fetchingOrders = await recursiveRetrieve(type, page);
        // // console.log("fetchingOrders",fetchingOrders);
        //
        setFilteredData([]);
        let body = {
          carrier: type
        };
        post('/admin/get-user-completed-orders-by-carrier', body, {headers}).then((response) => {
          // // console.log("response", response);
          setFilteredData(response.data.filter(el => el.status == 1));
          let data = response.data.filter(el => el.status == 1);
          // console.log("table data" , { columns, data });
          setTableData({ columns, data });
          setPending(false);
          setLoadingOrders(false);
        }).catch((err) => {
          // console.log("err", err);
          setPending(false);
          setLoadingOrders(false);
        })
      // }
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveOrders(filterType);
  }, []);

  const handleFedExOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    retrieveOrders('FedEx');
  }

  const handleUPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    retrieveOrders('UPS');
  }

  const handleUPSv2Orders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    retrieveOrders('UPSv2');
  }

  const handleDHLOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    retrieveOrders('DHL');
  }

  const handleUSPSOrders = async () => {
    document.getElementsByClassName("filter-text")[0].value = "";
    retrieveOrders('USPS');
  }

  //meta title
  document.title = "Orders | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Orders" />
        <Card>
          <CardBody>
            <button className={"btn btn-sm btn-outline-dark " + (filterType==="FedEx"?"active":"")} style={{marginRight:"10px"}} onClick={handleFedExOrders}>FedEx</button>
            <div className={"vr"}></div>
            <button className={"btn btn-sm btn-outline-dark " + (filterType === "UPS" ? "active" : "")} style={{ marginLeft: "10px", marginRight: "10px" }} onClick={handleUPSOrders}>UPS</button>
            <div className={"vr"}></div>
            <button className={"btn btn-sm btn-outline-dark " + (filterType === "UPSv2" ? "active" : "")} style={{ marginLeft: "10px", marginRight: "10px" }} onClick={handleUPSv2Orders}>UPSv2</button>
            <div className={"vr"}></div>
            <button className={"btn btn-sm btn-outline-dark " + (filterType==="DHL"?"active":"")} style={{marginLeft:"10px",marginRight:"10px"}} onClick={handleDHLOrders}>DHL</button>
            <div className={"vr"}></div>
            <button className={"btn btn-sm btn-outline-dark " + (filterType==="USPS"?"active":"")} style={{marginLeft:"10px",marginRight:"10px"}} onClick={handleUSPSOrders}>USPS</button>
            <br/>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Orders...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} pagination={30} paginationPerPage={30}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={(data) => { setViewModal(data) }}
        />

      </div>
    </div>
  );
}
UserOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default UserOrders;


// {/*Modal*/}
// <Modal
// isOpen={viewModal}
// role="dialog"
// autoFocus={true}
// centered={true}
// className="exampleModal modal-lg"
// tabIndex="-1"
// toggle={() => {
//   setViewModal(!viewModal);
// }}
// >
// <div>
//   <ModalHeader
//     toggle={() => {
//       setViewModal(!viewModal);
//     }}
//   >
//     Order View
//   </ModalHeader>
//   <ModalBody>
//     <div className={"container"}>
//       <div className={"col-md-12"}>
//         <div className={"row"}>
//           {name?
//             <div className={"col-md-12"}>
//               {/*<h2><img src={ups_logo} height={"50px"}/>*/}
//               <h2><img src={`data:image/jpeg;base64,${service_image}`} height={"50px"}/>
//                 {" "+name}
//                 {discount_type!=="None"?<span style={{float:"right",textDecoration:"line-through",color:"gray"}}>
//                     ${weight<5?price:aboveFiveLbsPrice}
//                   </span>:""}
//                 <span style={{float:"right",fontSize:"3rem"}}>
//                     ${weight<5?
//                   discount_type==="None"?price:(discount_type==="Flat"?price - discount:price - (discount / 100 * price)):
//                   discount_type==="None"?aboveFiveLbsPrice:(discount_type==="Flat"?aboveFiveLbsPrice - discount:aboveFiveLbsPrice - (discount / 100 * aboveFiveLbsPrice))
//                 }
//                   </span>
//               </h2>
//               <p style={{ marginLeft: "59px",marginTop:"-20px" }}>
//                 {description}
//               </p>
//             </div>:<h4 className={"text-danger"}>Service Not Assigned</h4>
//           }
//           <div>
//             <h4 className="card-title mb-4 mt-2">
//               Order Details
//               <span style={{float:"right",color:"gray",fontWeight:"400"}}>
//                       {moment(created_at).format('MMM DD, YYYY')}
//                     </span>
//             </h4>
//             <fieldset>
//               <legend>From</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Name:</strong> {from_name}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Company:</strong> {from_company?from_company:"-"}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Phone:</strong> {from_phone}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>City:</strong> {from_city}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Zip:</strong> {from_zip}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>State:</strong> {from_state}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-12"}>
//                     <p><strong>Address:</strong> {from_street1} {from_street2}</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>
//             <fieldset>
//               <legend>To</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Name:</strong> {to_name}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Company:</strong> {to_company?to_company:"-"}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Phone:</strong> {to_phone}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>City:</strong> {to_city}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Zip:</strong> {to_zip}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>State:</strong> {to_state}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-12"}>
//                     <p><strong>Address:</strong> {to_street1} {to_street2}</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>

//             <fieldset>
//               <legend>Dimensions & Weight</legend>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Length:</strong> {length}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Width:</strong> {width}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className={"col-md-12"}>
//                 <div className={"row"}>
//                   <div className={"col-md-6"}>
//                     <p><strong>Height:</strong> {height}</p>
//                   </div>
//                   <div className={"col-md-6"}>
//                     <p><strong>Weight:</strong> {weight} lb</p>
//                   </div>
//                 </div>
//               </div>
//             </fieldset>
//           </div>
//         </div>
//       </div>
//     </div>
//   </ModalBody>
// </div>
// </Modal>
// {/*Modal*/}