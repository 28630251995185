import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { TickCircle, AddCircle, Warning2 } from "iconsax-react"
import {
  Card,
  CardBody,
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Dropdown,
  Tooltip,
} from "reactstrap"
import { Country, State } from "country-state-city"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import ebay from "../../../assets/images/integrations/ebay.svg"
import walmart from "../../../assets/images/integrations/walmart.svg"
import shopify from "../../../assets/images/integrations/shopify.svg"
import walmart_icon from "../../../assets/images/integrations/walmart-icon-yellow.png"
import default_store from "../../../assets/images/integrations/default-store.png"
import zenventory from "../../../assets/images/integrations/1651688363447.jfif"
import sellercloud from "../../../assets/images/integrations/sellercloud.png"
import shipstation from "../../../assets/images/integrations/shipstation.png"
import sellercloudSmall from "../../../assets/images/integrations/sellercloud-small.png"
import shipstationSmall from "../../../assets/images/integrations/shipstation-small.png"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import veeqo from "../../../assets/images/integrations/veeqo.png"
import veeqoSmall from "../../../assets/images/integrations/veeqo-small.png"
import shiphero from "../../../assets/images/integrations/shiphero.jpg"
import StoreModal from "./Modals/StoreModal"
import shipheroSmall from "../../../assets/images/integrations/shiphero-small.png"
import orderDeskLogo from "../../../assets/images/integrations/orderDeskLogo.png"
import smallOrderDesk from "../../../assets/images/integrations/smallOrderDesk.png"
import axios from "axios"
import Tabs from "components/Tabs"
import { settingsTabs } from "constants/tabsData"
import DisplayOptions from "./configurations/DisplayOptions"
let filteredData = []
let filteredAllData = []
let skipData = []
function Stores(props) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [tableData, setTableData] = useState(null)
  const [pending, setPending] = useState(true)

  const [modalSetting, setModalSetting] = useState(false)
  const [numberOfDays, setNumberOfDays] = useState(null)
  const [skipAddressValidation, setSkipAddressValidation] = useState(false)

  const [store_id, setStoreId] = useState(null)

  const [modalUpdateDetails, setModalUpdateDetails] = useState(false)
  const [street1, setStreet1] = useState("")
  const [street2, setStreet2] = useState("")
  const [city, setCity] = useState("")
  const [zip, setZip] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const [store_type, setStoreStype] = useState("")

  const [modalConnect, setModalConnect] = useState(false)

  const [modalZenventory, setModalZenventory] = useState(false)
  const [warehouseId, setWarehouseId] = useState("")
  const [warehouseName, setWarehouseName] = useState("")
  const [secureKey, setSecureKey] = useState("")

  const [modalWalmart, setModalWalmart] = useState(false)
  const [walmartSellerId, setWalmartSellerId] = useState("")
  const [walmartSellerName, setWalmartSellerName] = useState("")
  const [walmartClientId, setWalmartClientId] = useState("")
  const [walmartClientSecret, setWalmartClientSecret] = useState("")

  const [modalSellerCloud, setModalSellerCloud] = useState(false)
  const [sellerCloudWarehouseId, setSellerCloudWarehouseId] = useState("")
  const [sellerCloudCompanyId, setSellerCloudCompanyId] = useState("")
  const [sellerCloudTeamName, setSellerCloudTeamName] = useState("")
  const [sellerCloudUsername, setSellerCloudUsername] = useState("")
  const [sellerCloudPassword, setSellerCloudPassword] = useState("")

  const [modalShipstation, setModalShipstation] = useState(false)
  const [modalShipstationStores, setModalShipstationStores] = useState(false)
  const [shipstationStoreId, setShipstationStoreId] = useState("")
  const [shipstationApiKey, setShipstationApiKey] = useState("")
  const [shipstationApiSecret, setShipstationApiSecret] = useState("")
  const [services, setServices] = useState([])

  const [shipstationStores, setShipstationStores] = useState([])

  const [storeExist, setStoreExist] = useState(false)

  const [scheduler_dropdown, setSchedulerDropdown] = useState(false)
  const [hoveredRowIdStatus, setHoveredRowIdStatus] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false)
  const [veeqoApiKey, setVeeqoApiKey] = useState("")
  const [modalVeeqo, setModalVeeqo] = useState(false)
  const [modalOrderDesk, setModalOrderDesk] = useState(false)
  const [veeqoStores, setVeeqoStores] = useState([])
  const [veeqoStoreModal, setVeeqoStoreModal] = useState(false)
  const [modalShiphero, setModalShiphero] = useState(false)
  const [shipheroStores, setShipheroStores] = useState([])
  const [shipheroStoreModal, setShipheroStoreModal] = useState(false)
  const [shipheroApiKey, setShipheroApiKey] = useState("")
  const [shipheroRefresh, setShipheroRefresh] = useState("")

  const [orderDeskStoreId, setOrderDeskStoreId] = useState("")
  const [orderApiKey, setorderApiKey] = useState("")
  const [inputFields, setInputFields] = useState(['']);
  const [destinationFolder, setDestinationFolder] = useState("")

  const [filterType, setFilterType] = useState("stores")

  const skipColumns = [
    { name: "Ship To Name", value: "to_name" },
    { name: "Ship To Company", value: "to_company" },
    { name: "Ship To Street 1", value: "to_street1" },
    { name: "Ship To Street 2", value: "to_street2" },
    { name: "Ship To City", value: "to_city" },
    { name: "Ship To State", value: "to_state" },
    { name: "Ship To Zip", value: "to_zip" },
    { name: "Ship To Country", value: "to_country" },
    { name: "Channel ID/Order Source", value: "channel_id" },
  ]

  const handleStatusMouseEnter = rowId => {
    setHoveredRowIdStatus(rowId)
  }

  const handleStatusMouseLeave = () => {
    setHoveredRowIdStatus(null)
  }
  const storeTypeIcons = {
    shopify: shopify,
    ebay: ebay,
    walmart: walmart_icon,
    manual: default_store,
    zenventory: zenventory,
    sellercloud: sellercloudSmall,
    shipstation: shipstationSmall,
    veeqo: veeqoSmall,
    shiphero: shipheroSmall,
    orderdesk: smallOrderDesk,
  }

  const renderIcon = storeType => {
    const iconSrc = storeTypeIcons[storeType]
    console.log(storeType, "storeType")
    return iconSrc ? <img src={iconSrc} width={"25px"} /> : null
  }

  const renderStatus = (
    row,
    isFieldEmpty,
    handleStatusMouseEnter,
    handleStatusMouseLeave,
    hoveredRowIdStatus,
    handleAuth
  ) => {
    if (row.status == 1) {
      if (isFieldEmpty) {
        return (
          <>
            <Warning2
              color="#f54242"
              variant="Bold"
              id={`CancelCircle-${row.id}`}
              onMouseEnter={() => handleStatusMouseEnter(row.id)}
              onMouseLeave={handleStatusMouseLeave}
              style={{ cursor: "pointer" }}
            />
            {hoveredRowIdStatus === row.id && (
              <Tooltip
                placement="top"
                isOpen={true}
                target={`CancelCircle-${row.id}`}
                toggle={() => {}}
              >
                Kindly fill all fields by clicking edit button in the Actions.
              </Tooltip>
            )}
          </>
        )
      } else if (
        [
          "shopify",
          "manual",
          "walmart",
          "zenventory",
          "sellercloud",
          "shipstation",
          "veeqo",
          "shiphero",
          "orderdesk",
        ].includes(row.store_type)
      ) {
        return <TickCircle variant="Bold" color={"#43D082"} />
      } else if (row.store_type == "ebay") {
        return moment().isBefore(
          moment(row.refresh_token_expires_in, "YYYY-MM-DD HH:mm:ss")
        ) ? (
          <TickCircle variant="Bold" color={"#43D082"} />
        ) : (
          <>
            <span className={"badge badge-danger"}>Expired</span>
            <button
              className={"btn btn-success btn-sm waves-effect waves-light"}
              title="Refresh Store"
              style={{ marginLeft: "10px" }}
              onClick={() => handleAuth(row.id)}
            >
              <i className="mdi mdi-account-reactivate" />
            </button>
          </>
        )
      }
    } else {
      return (
        <AddCircle
          style={{ transform: "rotate(45deg)" }}
          variant="Bold"
          color={"#FF6969"}
        />
      )
    }
  }

  const renderActions = (
    row,
    handleUpdateStoreDetails,
    handleSettings,
    handleDisconnect,
    handleShopifyRefresh
  ) => {
    if (row.status !== 1) return ""

    const commonButtons = (
      <>
        <button
          className={"btn btn-warning btn-sm waves-effect waves-light"}
          title="Update Store Details"
          style={{ marginRight: "10px" }}
          onClick={() => handleUpdateStoreDetails(row.id)}
        >
          <i className={"bx bx-edit"} />
        </button>
        <button
          className={"btn btn-warning btn-sm waves-effect waves-light"}
          title="Update Store Settings"
          style={{ marginRight: "10px" }}
          onClick={() => handleSettings(row.id)}
        >
          <i className={"bx bx-cog"} />
        </button>
        <button
          className={"btn btn-danger btn-sm waves-effect waves-light"}
          title="Disconnect Store"
          onClick={() => handleDisconnect(row.id, row.store_type)}
        >
          Disconnect
        </button>
      </>
    )

    if (row.store_type === "manual") {
      return (
        <button
          className={"btn btn-warning btn-sm waves-effect waves-light"}
          title="Update Store Details"
          style={{ marginRight: "10px" }}
          onClick={() => handleUpdateStoreDetails(row.id)}
        >
          <i className={"bx bx-edit"} />
        </button>
      )
    } else if (row.store_type === "shopify") {
      return (
        <>
          <button
            className={"btn btn-success btn-sm waves-effect waves-light"}
            title="Sync Store Details"
            style={{ marginRight: "10px" }}
            onClick={() => handleShopifyRefresh(row.id)}
          >
            <i className="mdi mdi-sync" />
          </button>
          {commonButtons}
        </>
      )
    } else {
      return commonButtons
    }
  }

  const columns = [
    {
      name: "",
      width: "60px",
      sortable: true,
      selector: row => renderIcon(row.store_type),
    },
    {
      name: "Store Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Updated At",
      sortable: true,
      selector: row =>
        row.updated_at
          ? moment(row.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY")
          : "-",
    },
    {
      name: "Last Sync At",
      sortable: true,
      selector: row =>
        row.last_synced
          ? moment(row.last_synced)
              .utc(false)
              .tz(moment.tz.guess())
              .format("MMM DD, YYYY hh:mm A")
          : "-",
    },
    {
      name: "Status",
      width: "150px",
      selector: row => {
        const isFieldEmpty =
          !row.address1 ||
          !row.city ||
          !row.country ||
          !row.phone ||
          !row.state ||
          !row.zip

        return renderStatus(
          row,
          isFieldEmpty,
          handleStatusMouseEnter,
          handleStatusMouseLeave,
          hoveredRowIdStatus,
          handleAuth
        )
      },
    },
    {
      name: "Actions",
      width: "250px",
      selector: row =>
        renderActions(
          row,
          handleUpdateStoreDetails,
          handleSettings,
          handleDisconnect,
          handleShopifyRefresh
        ),
    },
  ]

  const handleUpdateStoreDetails = async id => {
    if (id == 0) {
      setStoreId(0)
      setName(filteredData[0].name)
      setPhone(filteredData[0].phone)
      setStreet1(filteredData[0].address1)
      setStreet2(filteredData[0].address2)
      setCity(filteredData[0].city)
      setState(filteredData[0].state)
      setZip(filteredData[0].zip)
      setCountry(filteredData[0].country)
      setStoreStype("manual")
      setModalUpdateDetails(true)
    } else {
      get("user/get-store-details/" + id, { headers }).then(response => {
        setStoreId(id)
        setName(response.data.name)
        setStreet1(response.data.address1)
        setStreet2(response.data.address2)
        setCity(response.data.city)
        setState(response.data.state)
        setZip(response.data.zip)
        setCountry(response.data.country)
        setPhone(response.data.phone)
        setStoreStype(response.data.store_type)
        setModalUpdateDetails(true)
      })
    }
  }

  //Refresh store details
  const handleShopifyRefresh = async id => {
    const conf = confirm(
      "Store details will overwrite, Are you sure you want to refresh the store?"
    )
    if (conf) {
      let body = {
        store_id: id,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post(`/shopify/refresh-store`, body, { headers }).then(async response => {
        // console.log("response",response);
        if (response.status === 200) {
          toast.success(response.message)
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const handleEbayRefresh = async id => {
    const conf = confirm(
      "Store details will overwrite, Are you sure you want to refresh the store?"
    )
    if (conf) {
      let body = {
        store_id: id,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post(`/ebay/refresh-store`, body, { headers }).then(async response => {
        // console.log("response",response);
        if (response.status === 200) {
          toast.success(response.message)
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  //Refresh store details

  const handleAuth = async id => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post(`/ebay/auth`, body, { headers }).then(response => {
        // console.log(response);
        if (response.status === 200) {
          location.href = response.url
          // window.open(response.url, '_blank');
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }
  const fetchServiceById = async id => {
    try {
      console.log("id", id)
      const serviceResponse = await get(`/user/get-service-by-id/${id}`, {
        headers,
      })

      console.log("Service by ID:", serviceResponse)
      setSelectedService(serviceResponse.data)
    } catch (error) {
      console.error("Error fetching service by ID:", error)
    }
  }
  const handleSettings = async id => {
    console.log(id, "id in handle settings")
    setStoreId(id)
    try {
      const response = await get(`/shopify/store-settings/${id}`, { headers })
      console.log("response", response)

      if (response.status === 200) {
        setNumberOfDays(response.data.number_of_days)
        setSkipAddressValidation(response.data.skip_address_validations === 1)

        let skipData = []
        if (response.data.import_conditions) {
          skipData = JSON.parse(response.data.import_conditions)
        }
        fetchServiceById(response.data.default_service_id)
        setModalSetting(true)
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      console.error("Error fetching store settings:", error)
    }
  }

  const handleDisconnect = async (id, store_type) => {
    const confirmation = window.confirm(
      "Are you sure you want to disconnect this store from NullShip?"
    )

    if (!confirmation) return

    const endpointMap = {
      shopify: "/shopify/disconnect",
      ebay: "/ebay/disconnect",
      walmart: "/walmart/disconnect",
      zenventory: "/zenventory/disconnect",
      sellercloud: "/sellercloud/disconnect",
      shipstation: "/shipstation/disconnect",
      veeqo: "/veeqo/disconnect",
      shiphero: "/shiphero/disconnect",
      orderdesk: "/orderdesk/disconnect",
    }

    const endpoint = endpointMap[store_type]

    if (!endpoint) {
      console.error(`Unsupported store_type: ${store_type}`)
      return
    }

    const loadingToast = toast.loading("Disconnecting store...")

    try {
      const response = await post(endpoint, { id }, { headers })

      toast.remove(loadingToast)

      if (response.status === 200) {
        localStorage.setItem("store", 0)
        toast.success(response.message)
        await retrieveStores()
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      console.error("Error disconnecting store:", error)
      toast.error("Failed to disconnect store.")
    }
  }

  // const tableData = ;
  const retrieveStores = async () => {
    setPending(true)
    return new Promise(resolve => {
      try {
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
        }
        post("/user/getAllStores", body, { headers }).then(response => {
          console.log("response", response)
          const body = {
            id: JSON.parse(localStorage.getItem("authUser")).id,
          }
          post("/user/get-ship-from-address", body, { headers }).then(
            responseAdd => {
              // console.log("get-ship-from-address",responseAdd);
              if (responseAdd.status === 200) {
                // setCompany(response.data[0].company);
                if (responseAdd.data.length > 0) {
                  response.data.splice(0, 0, {
                    id: 0,
                    store_id: null,
                    name: responseAdd.data[0].name,
                    store_type: "manual",
                    last_synced: null,
                    status: 1,
                    user_name: responseAdd.data[0].name,
                    phone: responseAdd.data[0].phone,
                    address1: responseAdd.data[0].street1,
                    address2: responseAdd.data[0].street2,
                    city: responseAdd.data[0].city,
                    zip: responseAdd.data[0].zip,
                    state: responseAdd.data[0].state,
                    country: responseAdd.data[0].country,
                  })
                } else {
                  response.data.splice(0, 0, {
                    id: 0,
                    store_id: null,
                    name: "Manual Store",
                    store_type: "manual",
                    last_synced: null,
                    status: 1,
                    user_name: "",
                    phone: "",
                    address1: "",
                    address2: "",
                    city: "",
                    zip: "",
                    state: "",
                    country: "",
                  })
                }

                // console.log("response.data", response.data)
                filteredData = response.data.filter(el => el.status == 1)
                filteredAllData = response.data
                let data = filteredAllData.filter(el => el.status == 1)

                setTableData({ columns, data })
                resolve(data)
              } else {
                toast.error(responseAdd.message)
              }
              setPending(false)
            }
          )
        })
      } catch (e) {
        // console.log(e)
      }
    })
  }

  const retrieveShipFromAddress = async data => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
    }
    post("/user/get-ship-from-address", body, { headers }).then(response => {
      // console.log("get-ship-from-address",response);
      // console.log("dataaaa",data);
      if (response.status === 200) {
        // setCompany(response.data[0].company);
        data[0].phone = response.data[0].phone
        data[0].address1 = response.data[0].street1
        data[0].address2 = response.data[0].street2
        data[0].city = response.data[0].city
        data[0].zip = response.data[0].zip
        data[0].state = response.data[0].state
        data[0].country = response.data[0].country

        setData(data)
        setTableData({ columns, data })
        // console.log("dataaaa",data);
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index] = event.target.value;
    setInputFields(values);
  };

  const handleAddField = () => {
    setInputFields([...inputFields, '']);
  };

  const handleDeleteField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const getCommaSeparatedValues = () => {
    return inputFields.join(',');
  };
  const handleShipFromAddress = async (
    name,
    phone,
    street1,
    street2,
    city,
    zip,
    state,
    country
  ) => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
      name: name,
      phone: phone,
      street1: street1,
      street2: street2,
      city: city,
      zip: zip,
      state: state,
      country: country,
    }
    // console.log("body",body);
    post("/user/update-ship-from-address", body, { headers }).then(response => {
      if (response.status === 200) {
        setStoreId("")
        setStreet1("")
        setStreet2("")
        setCity("")
        setState("")
        setZip("")
        setCountry("")
        setPhone("")
        setName("")
        setModalUpdateDetails(false)
        toast.success(response.message)
        retrieveStores().then(res => {
          // retrieveShipFromAddress(res);
        })
      } else {
        toast.error(response.message)
      }
    })
  }

  const retrieveServices = async () => {
    try {
      const response = await get("/admin/get-services", { headers })

      const filteredServices = response.data.filter(
        service => service.status === 1 && service.is_active === 1
      )

      setServices(filteredServices)
    } catch (e) {
      // Handle error
      console.error("Error retrieving services:", e)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      retrieveServices()
    }

    retrieveStores().then(res => {})
  }, [])

  const handleEbayConnect = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post(`/ebay/auth`, body, { headers }).then(response => {
        // console.log(response);
        if (response.status === 200) {
          location.href = response.url
          // window.open(response.url, '_blank');
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleWalmartSubmit = async e => {
    e.preventDefault()
    try {
      let loading = toast.loading("Connecting walmart store")

      let body = {
        seller_id: walmartSellerId,
        seller_name: walmartSellerName,
        client_id: walmartClientId,
        client_secret: walmartClientSecret,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/walmart/get-access-token-by-client-credentials", body, {
        headers,
      }).then(async response => {
        toast.remove(loading)
        if (response.status === 200) {
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
          toast.success(response.message)
          setModalWalmart(false)
          // setTimeout(() => {
          //   location.reload();
          // },2000);
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleSellercloudSubmit = e => {
    e.preventDefault()
    try {
      let loading = toast.loading("Connecting sellercloud store")

      let body = {
        warehouse_id: sellerCloudWarehouseId,
        company_id: sellerCloudCompanyId,
        team_name: sellerCloudTeamName,
        username: sellerCloudUsername,
        password: sellerCloudPassword,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/sellercloud/connect", body, { headers }).then(async response => {
        toast.remove(loading)
        if (response.status == 200) {
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
          toast.success(response.message)
          setModalSellerCloud(false)
          // setTimeout(() => {
          //   location.reload();
          // },2000);
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleShipstationGetStores = e => {
    e.preventDefault()
    try {
      let loading = toast.loading("Fetching ShipStation stores")
      document.body.style.pointerEvents = "none"
      let body = {
        api_key: shipstationApiKey,
        api_secret: shipstationApiSecret,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shipstation/get-stores", body, { headers }).then(
        async response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status === 200) {
            console.log("data", response.data)
            setShipstationStores(response.data)
            setModalShipstation(false)
            setModalShipstationStores(true)
          } else {
            toast.error(response.message)
          }
        }
      )
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleShipstationSubmit = async store_id => {
    try {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Connecting ShipStation store")

      console.log("store id", store_id)
      let body = {
        store_id: store_id,
        api_key: shipstationApiKey,
        api_secret: shipstationApiSecret,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      const response = await post("/shipstation/connect", body, {
        headers: { "Content-Type": "application/json" },
      })

      toast.remove(loading)
      document.body.style.pointerEvents = "auto"
      if (response.status === 200) {
        await retrieveStores().then(res => {
          // Update local state or perform additional logic
        })
        toast.success(response.message)
        setShipstationStores([])
        setModalShipstationStores(false)
      } else {
        toast.error(response.message)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleVeeqoSubmit = store_id => {
    try {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Connecting Veeqo store")

      let body = {
        store_id: store_id,
        api_key: veeqoApiKey,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/veeqo/connect", body, { headers }).then(async response => {
        toast.remove(loading)
        document.body.style.pointerEvents = "auto"
        if (response.status == 200) {
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
          toast.success(response.message)
          setVeeqoStores([])
          setVeeqoStoreModal(false)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleZenventoryConnect = async () => {
    try {
      setModalConnect(false)
      setWarehouseId("")
      setWarehouseName("")
      setSecureKey("")
      setStoreExist(false)
      setModalZenventory(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleSellerCloudConnect = async () => {
    try {
      setModalConnect(false)
      setWarehouseId("")
      setWarehouseName("")
      setSecureKey("")
      setStoreExist(false)
      setModalSellerCloud(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleShipstationConnect = async () => {
    try {
      setModalConnect(false)
      setShipstationStoreId("")
      setShipstationApiKey("")
      setShipstationApiSecret("")
      setStoreExist(false)
      setModalShipstation(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleVeeqoConnect = async () => {
    try {
      setModalConnect(false)
      setVeeqoApiKey("")
      setStoreExist(false)
      setModalVeeqo(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleOrderDeskConnect = async () => {
    try {
      setModalConnect(false)
      setOrderDeskStoreId("")
      setStoreExist(false)
      setModalOrderDesk(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleShipheroConnect = async () => {
    try {
      setModalConnect(false)
      setShipheroApiKey("")
      setShipheroRefresh("")
      setStoreExist(false)
      setModalShiphero(true)
    } catch (e) {
      // console.log("e",e);
    }
  }
  const handleVeeqoGetStores = e => {
    e.preventDefault()
    try {
      let loading = toast.loading("Fetching Veeqo stores")
      document.body.style.pointerEvents = "none"
      let body = {
        api_key: veeqoApiKey,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/veeqo/get-stores", body, { headers }).then(async response => {
        toast.remove(loading)
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          console.log("veeqo stores", response.data)
          setVeeqoStores(response.data)
          setModalVeeqo(false)
          setVeeqoStoreModal(true)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }
  
  
  const handleOrderDeskGetStores = async e => {
    e.preventDefault()
    try {
      let res = await post("/orderdesk/connect", { storeId: orderDeskStoreId, apiKey: orderApiKey, folderName: getCommaSeparatedValues(), userId: JSON.parse(localStorage.getItem("authUser")).id, destinationFolder })
      toast.success("Order Desk Connected")
      await retrieveStores();
      setModalOrderDesk(false)
    }
    catch (error) {
      console.log(error, 'error')
      toast.error("Please check your provided information")
    }
  }
  const handleShipheroGetStores = e => {
    e.preventDefault()
    try {
      let loading = toast.loading("Fetching Veeqo stores")
      document.body.style.pointerEvents = "none"
      let body = {
        api_key: shipheroApiKey,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
      }
      post("/shiphero/get-stores", body, { headers }).then(async response => {
        toast.remove(loading)
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          console.log("shiphero stores", response)
          setShipheroStores(response.data.data.account.data.warehouses)
          setModalShiphero(false)
          setShipheroStoreModal(true)
        } else {
          toast.error(response.message)
        }
      })
    } catch (error) {}
  }
  const handleWalmartConnect = async () => {
    try {
      setModalConnect(false)
      setWalmartSellerId("")
      setWalmartClientId("")
      setWalmartClientSecret("")
      setStoreExist(false)
      setModalWalmart(true)
    } catch (e) {
      // console.log("e",e);
    }
  }

  const handleShopifyConnect = async () => {
    try {
      location.href =
        "https://apps.shopify.com/null-ship?search_id=2a8c7484-9d29-48fd-b060-019f1fb21026&surface_detail=nullship&surface_inter_position=1&surface_intra_position=1&surface_type=search"
    } catch (e) {
      // console.log("e",e);
    }
  }

  const expandableRows = row => {
    console.log("object", row)
    return (
      <>
        <div className={"col-md-10 offset-md-1 p-2 row"}>
          <h5>Store Details</h5>
          <div className={"col-md-6"}>
            <table className={"table"}>
              <tr>
                <th>Phone</th>
                <td>{row.data.phone ? row.data.phone : "-"}</td>
              </tr>
              <tr>
                <th>Address 1</th>
                <td>{row.data.address1 ? row.data.address1 : "-"}</td>
              </tr>
              <tr>
                <th>Address 2</th>
                <td>{row.data.address2 ? row.data.address2 : "-"}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{row.data.city ? row.data.city : "-"}</td>
              </tr>
            </table>
          </div>
          <div className={"col-md-6"}>
            <table className={"table"}>
              <tr>
                <th>State</th>
                <td>{row.data.state ? row.data.state : "-"}</td>
              </tr>
              <tr>
                <th>Zip</th>
                <td>{row.data.zip ? row.data.zip : "-"}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{row.data.country ? row.data.country : "-"}</td>
              </tr>
              {/*<tr>*/}
              {/*  <th>Store URL</th>*/}
              {/*  <td>{row.data.store_name}</td>*/}
              {/*</tr>*/}
            </table>
          </div>
        </div>
      </>
    )
  }

  const handleUpdateSettings = async () => {
    if (numberOfDays && numberOfDays > 0) {
      let body = {
        numberOfDays,
        store_id,
        skipData: JSON.stringify(skipData),
        default_service_id: selectedService.id,
      }
      post("/shopify/update-store-settings", body, { headers }).then(
        response => {
          if (response.status === 200) {
            toast.success(response.message)
            setModalSetting(false)
            setNumberOfDays(null)
          } else {
            toast.error(response.message)
          }
        }
      )
      setSelectedService(null)
    }
  }

  const handleSkipAddressValidation = async e => {
    try {
      let body = {
        store_id: store_id,
        skip_address_validation: e.target.checked ? 1 : 0,
      }

      console.log("handleSkipAddressValidation", body)
      post("/user/update-store-skip-address-validation", body, {
        headers,
      }).then(response => {
        if (response.status == 200) {
          toast.success(response.message)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {}
  }

  const handleCountry = async e => {
    const { value } = e.target
    setCountry(value)
  }

  const handleAddressStreet1 = async value => {
    try {
      const results = await geocodeByAddress(value)
      // console.log("results", results);
      const addressComponents = results[0].address_components
      // console.log("addressComponents", addressComponents);
      let street1 = ""
      let street2 = ""
      let country = ""
      let city = ""
      let state = ""
      let zip = ""
      addressComponents.map(el => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if (el.types[0] === "street_number") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "route") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "premise") {
          street1 += el.long_name + " "
        } else if (el.types[0] === "subpremise") {
          street2 += el.long_name + " "
        } else if (el.types[0] === "locality") {
          city += el.long_name
        } else if (el.types[0] === "administrative_area_level_1") {
          state += el.short_name
        } else if (el.types[0] === "country") {
          country += el.short_name
        } else if (el.types[0] === "postal_code") {
          zip += el.long_name
        }
      })
      if (street1 !== "") {
        street1 = street1.substring(0, street1.length - 1)
      }

      if (street2 !== "") {
        street2 = street2.substring(0, street2.length - 1)
      }
      setStreet1(street1)
      setStreet2(street2)
      setCity(city)
      setCountry(country)
      setState(state)
      setZip(zip)
    } catch (error) {
      console.error("Error", error)
    }
  }

  const handleStoreDetailsSubmit = async e => {
    e.preventDefault()
    if (
      phone
        .toString()
        .replace(/[^0-9]/g, "")
        .replaceAll(" ", "").length < 10
    ) {
      toast.error("Phone number must contain 10 digit")
      return false
    } else {
      if (store_id == 0) {
        await handleShipFromAddress(
          name,
          phone,
          street1,
          street2,
          city,
          zip,
          state,
          country
        )
      } else {
        const body = {
          name: name,
          store_id: store_id,
          street1: street1,
          street2: street2,
          country: country,
          state: state,
          zip: zip,
          city: city,
          phone: phone,
          store_type: store_type,
        }

        post("/user/update-store-details", body, { headers }).then(
          async response => {
            if (response.status == 200) {
              toast.success(response.message)
              setStoreId("")
              setStreet1("")
              setStreet2("")
              setCity("")
              setState("")
              setZip("")
              setCountry("")
              setPhone("")
              setName("")
              setModalUpdateDetails(false)
              await retrieveStores().then(res => {
                // retrieveShipFromAddress(res);
              })
            } else {
              setStoreId("")
              setStreet1("")
              setStreet2("")
              setCity("")
              setState("")
              setZip("")
              setCountry("")
              setPhone("")
              setName("")
              setModalUpdateDetails(false)
              toast.error(response.message)
            }
          }
        )
      }
    }
  }

  const handleShowInactive = e => {
    const { checked } = e.target
    if (checked) {
      let data = filteredAllData
      filteredData = filteredAllData
      setTableData({ columns, data })
    } else {
      let data = filteredAllData.filter(el => el.status == 1)
      filteredData = filteredAllData.filter(el => el.status == 1)
      setTableData({ columns, data })
    }
  }

  const handleZenventory = e => {
    e.preventDefault()
    try {
      if (!storeExist) {
        let loading = toast.loading("Connecting zenventory store")
        let body = {
          warehouse_id: warehouseId,
          warehouse_name: warehouseName,
          secure_key: secureKey,
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
        }

        post("/zenventory/connect", body, { headers }).then(async response => {
          toast.remove(loading)

          setWarehouseId("")
          setWarehouseName("")
          setSecureKey("")
          setModalZenventory(false)

          if (response.status == 200) {
            await retrieveStores().then(res => {
              // retrieveShipFromAddress(res);
            })
            toast.success(response.message)
            // setTimeout(() => {
            //   location.reload();
            // },2000);
          } else {
            toast.error(response.message)
          }
        })
      }
    } catch (e) {}
  }

  const handleWarehouseId = e => {
    try {
      setWarehouseId(e.target.value)
      setStoreExist(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: e.target.value,
        type: "zenventory",
      }
      post("/user/is-store-id-exist", body, { headers }).then(response => {
        // console.log(response)
        if (response.status == 200) {
          if (response.data > 0) {
            setStoreExist(true)
          } else {
            setStoreExist(false)
          }
        }
      })
    } catch (e) {}
  }

  const handleSellerId = e => {
    try {
      setWalmartClientId(e.target.value)
      setStoreExist(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: e.target.value,
        type: "walmart",
      }
      post("/user/is-store-id-exist", body, { headers }).then(response => {
        // console.log(response)
        if (response.status == 200) {
          if (response.data > 0) {
            setStoreExist(true)
          } else {
            setStoreExist(false)
          }
        }
      })
    } catch (e) {}
  }

  const handleSellerCloudWarehouseId = e => {
    try {
      setSellerCloudWarehouseId(e.target.value)
      setStoreExist(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: e.target.value,
        company_id: sellerCloudCompanyId,
        type: "sellercloud",
      }
      post("/user/is-store-id-exist", body, { headers }).then(response => {
        // console.log(response)
        if (response.status == 200) {
          if (response.data > 0) {
            setStoreExist(true)
          } else {
            setStoreExist(false)
          }
        }
      })
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleSellerCloudCompanyId = e => {
    try {
      setSellerCloudCompanyId(e.target.value)
      setStoreExist(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: sellerCloudWarehouseId,
        company_id: e.target.value,
        type: "sellercloud",
      }
      post("/user/is-store-id-exist", body, { headers }).then(response => {
        // console.log(response)
        if (response.status == 200) {
          if (response.data > 0) {
            setStoreExist(true)
          } else {
            setStoreExist(false)
          }
        }
      })
    } catch (e) {
      console.log("error", e)
    }
  }

  const handleShipstationStoreId = e => {
    try {
      setShipstationStoreId(e.target.value)
      setStoreExist(false)
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: e.target.value,
        type: "shipstation",
      }
      post("/user/is-store-id-exist", body, { headers }).then(response => {
        // console.log(response)
        if (response.status == 200) {
          if (response.data > 0) {
            setStoreExist(true)
          } else {
            setStoreExist(false)
          }
        }
      })
    } catch (e) {
      console.log("error", e)
    }
  }

  createTheme(
    "solarized",
    {
      text: {
        primary: "#fffff",
        secondary: "#fffff",
      },
      background: {
        default: "#0f0f0f",
      },
      context: {
        background: "#cb4b16",
        text: "#0f0f0f",
      },
      divider: {
        default: "#282828",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  )

  //meta title
  document.title = "Settings | NullShip"

  const handleAddSkipCondition = () => {
    if (skipData.length > 0) {
      if (
        skipData.some(el => el.column == "") ||
        skipData.some(el => el.value == "")
      ) {
        toast.error("Please fill all required field before adding more")
      } else {
        skipData.push({
          id:
            skipData.length > 0
              ? parseInt(skipData[skipData.length - 1].id) + 1
              : 1,
          column: "",
          condition: "",
          value: "",
        })
      }
    } else {
      skipData.push({
        id:
          skipData.length > 0
            ? parseInt(skipData[skipData.length - 1].id) + 1
            : 1,
        column: "",
        condition: "",
        value: "",
      })
    }

    document.body.click()
  }

  const handleRemoveSkipCondition = id => {
    let index = skipData.findIndex(el => el.id == id)
    skipData.splice(index, 1)
    document.body.click()
  }

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen)
  }

  const handleServiceSelect = service => {
    setSelectedService(service)
  }
  const handleShipheroSubmit = store_id => {
    try {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Connecting Shiphero store")

      let body = {
        store_id: store_id,
        api_key: shipheroApiKey,
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        refresh_token: shipheroRefresh,
      }
      post("/shiphero/connect", body, { headers }).then(async response => {
        toast.remove(loading)
        document.body.style.pointerEvents = "auto"
        if (response.status == 200) {
          await retrieveStores().then(res => {
            // retrieveShipFromAddress(res);
          })
          toast.success(response.message)
          setShipheroStores([])
          setShipheroStoreModal(false)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log("e",e);
    }
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Settings" />
        <Tabs tabs={settingsTabs(setFilterType)} />
        {filterType === "stores" && (
          <Card>
            <CardBody>
              {/* {user.sellercloud == 1 ? (
            <ButtonDropdown
              isOpen={scheduler_dropdown}
              toggle={() => {
                setSchedulerDropdown(!scheduler_dropdown)
              }}
            >
              <DropdownToggle
                className={"btn btn-light waves-effect waves-light m-1"}
                caret
                color="light"
              >
                Auto Scheduler Logs <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <Link style={{ color: "black" }} to={"scheduler-logs"}>
                  <DropdownItem>Order Scheduler Logs</DropdownItem>
                </Link>
                <Link
                  style={{ color: "black" }}
                  to={"picklist-scheduler-logs"}
                >
                  <DropdownItem>Picklist Scheduler Logs</DropdownItem>
                </Link>
              </DropdownMenu>
            </ButtonDropdown>
          ) : null} */}
              {user.shopify == 1 ||
              user.ebay == 1 ||
              user.walmart == 1 ||
              user.zenventory == 1 ||
              user.sellercloud == 1 ||
              user.veeqo == 1 ||
              user.shipstation == 1 ||
              user.shiphero == 1 ? (
                <button
                  onClick={() => setModalConnect(true)}
                  className={"btn btn-primary"}
                  style={{ float: "right" }}
                >
                  Connect a Store or Marketplace
                </button>
              ) : (
                ""
              )}
              <label
                htmlFor={"show"}
                style={{
                  fontWeight: "500",
                  float: "right",
                  marginRight: "10px",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                <input
                  type={"checkbox"}
                  onClick={handleShowInactive}
                  id={"show"}
                />{" "}
                Show inactive stores
              </label>
              {/*<DataTableExtensions*/}
              {/*  {...tableData}*/}
              {/*  exportHeaders={true}*/}
              {/*  filterPlaceholder={`Filter Store...`}*/}
              {/*>*/}
              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={pending}
                progressComponent={
                  <h4 className={"mt-5 mb-5 text-center"}>
                    Loading data, this may take a few moments...
                  </h4>
                }
                pagination={30}
                paginationPerPage={30}
                highlightOnHover={true}
                expandableRows={true}
                expandableRowsComponent={expandableRows}
                theme={
                  localStorage.getItem("theme") == "dark" ? "solarized" : ""
                }
                customStyles={
                  localStorage.getItem("theme") == "dark"
                    ? customDarkStyles
                    : customStyles
                }
              />
              {/*</DataTableExtensions>*/}
            </CardBody>
          </Card>
        )}
        {/* {filterType === "display-options" && (
<DisplayOptions />
        )} */}
      </div>

      {/*Modal*/}
      <Modal
        isOpen={modalSetting}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModalSetting(!modalSetting)
          skipData = []
          setSelectedService(null)
          setServiceDropdownOpen(false)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalSetting(!modalSetting)
              skipData = []
              setSelectedService(null)
              setServiceDropdownOpen(false)
            }}
          >
            Store Settings
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleUpdateSettings()
              }}
            >
              <div className={"row"}>
                <div className={"col-md-5"}>
                  {/* <div className="form-group mb-3">
                    <Label className="form-label">Select carrier</Label>
                    <Dropdown
                      isOpen={carrierDropdownOpen}
                      toggle={toggleCarrierDropdown}
                    >
                      <DropdownToggle
                        className={"btn btn-light waves-effect waves-light m-1"}
                        caret
                        color="light"
                      >
                        {selectedCarrier
                          ? selectedCarrier.name
                          : "Select your carrier"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {carriers.map(carrier => (
                          <DropdownItem
                            key={carrier.id}
                            onClick={() => handleCarrierSelect(carrier)}
                          >
                            {carrier.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  <div className="form-group mb-3">
                    <Label className="form-label">Select service</Label>

                    <Dropdown
                      isOpen={serviceDropdownOpen}
                      toggle={toggleServiceDropdown}
                    >
                      <DropdownToggle
                        className={"btn btn-light waves-effect waves-light m-1"}
                        caret
                        color="light"
                      >
                        {selectedService
                          ? selectedService.name
                          : "Select your service"}
                      </DropdownToggle>
                      <DropdownMenu style={{ backgroundColor: "white" }}>
                        {services.length > 0 ? (
                          services.map(service => (
                            <DropdownItem
                              key={service.id}
                              onClick={() => handleServiceSelect(service)}
                            >
                              {service.name}
                            </DropdownItem>
                          ))
                        ) : (
                          <DropdownItem disabled>
                            No services available currently
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="form-group mb-3">
                    <Label className="form-label">Fetch Record Of Last</Label>
                    <Input
                      name="from_name"
                      // value={name}
                      className="form-control non-arrow-number"
                      onWheel={e => e.target.blur()}
                      placeholder="Enter Number Of Days"
                      type="number"
                      min="1"
                      max="15"
                      required={true}
                      onChange={e => setNumberOfDays(e.target.value)}
                      value={numberOfDays}
                    />
                  </div>
                </div>
                <div className={"col-md-7"}>
                  <div className="form-group mb-3">
                    <input
                      name="international_shipment"
                      type="checkbox"
                      className={""}
                      disabled={true}
                      title={"This option is temporary disabled"}
                      style={{ marginRight: "10px" }}
                    />
                    <Label
                      title={"This option is temporary disabled"}
                      className="form-label text-secondary"
                    >
                      Import International Shipments
                    </Label>
                  </div>

                  <div className="form-group mb-3">
                    <input
                      name="skip-address-validation"
                      id={"skip-address-validation"}
                      type="checkbox"
                      className={""}
                      title={"Skip address validations"}
                      style={{ marginRight: "10px" }}
                      onClick={handleSkipAddressValidation}
                      defaultChecked={skipAddressValidation}
                    />
                    <Label
                      title={"Skip address validations"}
                      for={"skip-address-validation"}
                      className="form-label"
                    >
                      {" "}
                      Skip Address Validations
                    </Label>
                  </div>
                </div>
              </div>
              <br />
              <fieldset>
                <legend>Skip Order Conditions</legend>
                {/*<p className={"text-success"}></p>*/}
                <p className={"text-danger"}>
                  Please use Country Code and State Code. (i.e. TX for Texas /
                  US for United States)
                </p>
                <div
                  style={{ height: "350px", overflow: "auto", padding: "5px" }}
                >
                  {skipData.map((skipElement, i) => (
                    <>
                      <div className={"skip-card"}>
                        <div style={{ width: "100%", textAlign: "right" }}>
                          <span
                            className={"text-danger"}
                            onClick={() =>
                              handleRemoveSkipCondition(skipElement.id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            ✕
                          </span>
                        </div>
                        <div className={"skip-main"} key={skipElement.id}>
                          <div className={"skip-column"}>
                            <div className="form-group">
                              <Label className="form-label">
                                Column <span style={{ color: "red" }}>*</span>
                              </Label>
                              <select
                                className="form-select"
                                required={true}
                                onChange={e => {
                                  let index = skipData.findIndex(
                                    el => el.id == skipElement.id
                                  )
                                  skipData[index].column = e.target.value
                                }}
                              >
                                <option value="">Select an Option</option>
                                {skipColumns.map((el, index) => (
                                  <option
                                    key={index}
                                    selected={
                                      skipElement.column
                                        ? el.value == skipElement.column
                                        : false
                                    }
                                    value={el.value}
                                  >
                                    {el.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className={"skip-condition"}>
                            <h4>=</h4>
                          </div>
                          <div className={"skip-value"}>
                            <Label className="form-label">
                              Value <span style={{ color: "red" }}>*</span>
                            </Label>
                            <input
                              type={"text"}
                              required={true}
                              className={"form-control"}
                              defaultValue={skipElement.value}
                              placeholder={"Enter Value"}
                              onChange={e => {
                                let index = skipData.findIndex(
                                  el => el.id == skipElement.id
                                )
                                skipData[index].value = e.target.value
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <button
                    onClick={handleAddSkipCondition}
                    type={"button"}
                    className={"btn btn-primary btn-sm"}
                  >
                    Add More
                  </button>
                </div>
              </fieldset>

              {/*<br/>*/}
              {/*<div className="form-group">*/}
              {/*  <Label className="form-label">Show Orders</Label>*/}
              {/*  <div className="form-check form-check-end">*/}
              {/*    <input*/}
              {/*      className="form-check-input"*/}
              {/*      type="checkbox"*/}
              {/*      value={paidCheckbox}*/}
              {/*      onChange={handlePaidCheckbox}*/}
              {/*    />*/}
              {/*    <label*/}
              {/*      className="form-check-label"*/}
              {/*      htmlFor="customCheckbox"*/}
              {/*    >*/}
              {/*      Paid*/}
              {/*    </label>*/}
              {/*  </div>*/}
              {/*  <div className="form-check form-check-end">*/}
              {/*    <input*/}
              {/*      className="form-check-input"*/}
              {/*      type="checkbox"*/}
              {/*      value={partiallyPaidCheckbox}*/}
              {/*      onChange={handlePartiallyPaidCheckbox}*/}
              {/*    />*/}
              {/*    <label*/}
              {/*      className="form-check-label"*/}
              {/*      htmlFor="customCheckbox"*/}
              {/*    >*/}
              {/*      Partially Paid*/}
              {/*    </label>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <br />
              <button
                type={"submit"}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Save
              </button>
              <br />
              <br />
            </Form>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}

      {/*Modal*/}
      <Modal
        isOpen={modalUpdateDetails}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          setModalUpdateDetails(!modalUpdateDetails)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalUpdateDetails(!modalUpdateDetails)
            }}
          >
            Update Store Details
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleStoreDetailsSubmit}>
              <div className={"row"}>
                <div className={"col-md-6 mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Name{" "}
                      <span
                        style={{
                          color: "red",
                          display:
                            store_type == "shopify" ||
                            store_type == "zenventory" ||
                            store_type == "sellercloud" ||
                            store_type == "shipstation"
                              ? "none"
                              : "inline-flex",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="name"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      onChange={e => {
                        // console.log("store_type",store_type)
                        // console.log("e.target.value",e.target.value)
                        setName(e.target.value)
                        // if(store_type != "shopify" && store_type != "zenventory" && store_type != "sellercloud" && store_type == "shipstation" ) {
                        //   setName(e.target.value)
                        // }
                      }}
                      readOnly={
                        store_type == "shopify" ||
                        store_type == "zenventory" ||
                        store_type == "sellercloud"
                        // store_type == "shipstation"
                      }
                      disabled={
                        store_type == "shopify" ||
                        store_type == "zenventory" ||
                        store_type == "sellercloud"
                        // store_type == "shipstation"
                      }
                      value={name}
                      required={
                        store_type == "shopify" ||
                        store_type == "zenventory" ||
                        store_type == "sellercloud"
                      }
                    />
                  </div>
                </div>
                <div className={"col-md-6 mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Phone <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="phone"
                      // value={name}
                      className="form-control non-arrow-number"
                      placeholder="Enter Phone"
                      type="text"
                      onWheel={e => e.target.blur()}
                      onChange={e => setPhone(e.target.value)}
                      value={phone}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"col-md-6"}>
                  <PlacesAutocomplete
                    value={street1}
                    onChange={setStreet1}
                    onSelect={handleAddressStreet1}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ position: "relative" }}>
                        <div className="form-group">
                          <Label className="form-label">
                            Address Street 1{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="from_street1"
                            // value={name}
                            className="form-control"
                            {...getInputProps({
                              placeholder: "Enter Ship To Street 1",
                            })}
                            type="text"
                            // onChange={(e) => setFromStreet1(e.target.value)}
                            required={true}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "65px",
                            left: "0px",
                            backgroundColor: "white",
                            zIndex: "99",
                            width: "100%",
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage.getItem("theme") == "dark"
                                    ? "#0f0f0f"
                                    : "#f1f1f1",
                              }}
                            >
                              Loading...
                            </div>
                          ) : null}
                          {suggestions.map(suggestion => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? localStorage.getItem("theme") == "dark"
                                  ? "#333"
                                  : "#ff6600"
                                : localStorage.getItem("theme") == "dark"
                                ? "#0f0f0f"
                                : "#f1f1f1",
                              padding: "5px 10px",
                              border: "1px solid #efefef",
                              cursor: "pointer",
                            }
                            return (
                              <div
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className={"col-md-6"}>
                  <div className="form-group">
                    <Label className="form-label">Address Street 2</Label>
                    <Input
                      name="address2"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Address Street 2"
                      type="text"
                      onChange={e => setStreet2(e.target.value)}
                      value={street2}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-12 mt-3"}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">
                        Country <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name={"country"}
                        className="form-select"
                        onChange={handleCountry}
                        value={country}
                        required={true}
                      >
                        <option
                          disabled={true}
                          selected={country ? false : true}
                          value={""}
                        >
                          Select a country
                        </option>
                        {Country.getAllCountries().map((part, id) => {
                          return (
                            <option key={id} value={part.isoCode}>
                              {part.name}
                            </option>
                          )
                        })}
                        ;
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">
                        City <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="to_city"
                        // value={name}
                        className="form-control"
                        placeholder="Enter City"
                        type="text"
                        onChange={e => setCity(e.target.value)}
                        value={city}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">
                        Zip <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="to_zip"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Zip"
                        type="text"
                        onChange={e => setZip(e.target.value)}
                        value={zip}
                        required={true}
                        // minLength={5}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">
                        State <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name={"to_state"}
                        className="form-select"
                        onChange={e => setState(e.target.value)}
                        value={state}
                        required={
                          State.getAllStates().filter(
                            el => el.countryCode === country
                          ).length > 0
                            ? true
                            : false
                        }
                      >
                        <option disabled={true} value={""}>
                          Select a state
                        </option>
                        {State.getAllStates()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .filter(el => el.countryCode === country)
                          .map((part, id) => {
                            return (
                              <option key={id} value={part.isoCode}>
                                {part.name}
                              </option>
                            )
                          })}
                        ;
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Update
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}

      {/*Connection Modal*/}
      <Modal
        isOpen={modalConnect}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          setModalConnect(!modalConnect)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalConnect(!modalConnect)
            }}
          >
            Connect a Store or Marketplace
          </ModalHeader>
          <ModalBody>
            <div className={"col-md-12"}>
              <div className={"store-connect-main"}>
                {user.shopify == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a shopify store"}
                    onClick={handleShopifyConnect}
                  >
                    <img src={shopify} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.ebay == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect an ebay store"}
                    onClick={handleEbayConnect}
                  >
                    <img src={ebay} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.walmart == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a walmart store"}
                    onClick={handleWalmartConnect}
                  >
                    <img src={walmart} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.zenventory == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a walmart store"}
                    onClick={handleZenventoryConnect}
                  >
                    <img src={zenventory} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.sellercloud == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a walmart store"}
                    onClick={handleSellerCloudConnect}
                  >
                    <img src={sellercloud} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.shipstation == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a ShipStation store"}
                    onClick={handleShipstationConnect}
                  >
                    <img src={shipstation} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.veeqo == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a Veeqo store"}
                    onClick={handleVeeqoConnect}
                  >
                    <img src={veeqo} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
                {user.shiphero == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a Shiphero store"}
                    onClick={handleShipheroConnect}
                  >
                    <img src={shiphero} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}

                {user.orderdesk == 1 ? (
                  <div
                    className={"store-connect"}
                    title={"Connect a Order Desk store"}
                    onClick={handleOrderDeskConnect}
                  >
                    <img src={orderDeskLogo} width={"100px"} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/*Connection Modal*/}

      {/*Zenventory Modal*/}
      <Modal
        isOpen={modalZenventory}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModalZenventory(!modalZenventory)
        }}
        onClosed={() => {
          setWarehouseId("")
          setWarehouseName("")
          setSecureKey("")
          setStoreExist(false)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalZenventory(!modalZenventory)
            }}
          >
            Connect Zenventory Warehouse
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct Warehouse
              ID, Warehouse Name, and Secure Key when filling out the form.
            </p>
            <form onSubmit={handleZenventory}>
              <div className={"col-md-12"}>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Warehouse ID <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control non-arrow-number"
                      placeholder="Enter Warehouse ID"
                      type="number"
                      onChange={handleWarehouseId}
                      value={warehouseId}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                  {storeExist ? (
                    <p className={"text-danger"}>
                      Warehouse ID already connected
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Warehouse Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Warehouse Name"
                      type="text"
                      onChange={e => setWarehouseName(e.target.value)}
                      value={warehouseName}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Secure Key <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Secure Key"
                      type="text"
                      onChange={e => setSecureKey(e.target.value)}
                      value={secureKey}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Zenventory Modal*/}

      {/*Walmart Modal*/}
      <Modal
        isOpen={modalWalmart}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModalWalmart(!modalWalmart)
        }}
        onClosed={() => {
          setWalmartClientId("")
          setWalmartClientSecret("")
          setWalmartSellerId("")
          setStoreExist(false)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalWalmart(!modalWalmart)
            }}
          >
            Connect Walmart Store
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct Client ID
              and Client Secret when filling out the form.
            </p>
            <form onSubmit={handleWalmartSubmit}>
              <div className={"col-md-12"}>
                {/*<div className={"mb-3"}>*/}
                {/*  <div className="form-group">*/}
                {/*    <Label className="form-label">Seller ID <span style={{ color: "red" }}>*</span></Label>*/}
                {/*    <Input*/}
                {/*      name="whid"*/}
                {/*      // value={name}*/}
                {/*      className="form-control non-arrow-number"*/}
                {/*      placeholder="Enter Seller ID"*/}
                {/*      type="number"*/}
                {/*      onChange={handleSellerId}*/}
                {/*      value={walmartSellerId}*/}
                {/*      required={true}*/}
                {/*      // minLength={5}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  {storeExist?<p className={"text-danger"}>Warehouse ID already connected</p>:""}*/}
                {/*</div>*/}
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Seller Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Seller Name"
                      type="text"
                      onChange={e => setWalmartSellerName(e.target.value)}
                      value={walmartSellerName}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Client ID <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Client ID"
                      type="text"
                      onChange={handleSellerId}
                      value={walmartClientId}
                      required={true}
                      // minLength={5}
                    />
                    {storeExist ? (
                      <p className={"text-danger"}>
                        Client ID already connected
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Client Secret <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Client Secret"
                      type="text"
                      onChange={e => setWalmartClientSecret(e.target.value)}
                      value={walmartClientSecret}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Walmart Modal*/}

      {/*Sellercloud Modal*/}
      <Modal
        isOpen={modalSellerCloud}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModalSellerCloud(!modalSellerCloud)
        }}
        onClosed={() => {
          setSellerCloudWarehouseId("")
          setSellerCloudCompanyId("")
          setSellerCloudTeamName("")
          setSellerCloudUsername("")
          setSellerCloudPassword("")
          setStoreExist(false)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalSellerCloud(!modalSellerCloud)
            }}
          >
            Connect Sellercloud Store
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct Warehouse
              ID, Company ID, Team Name, Username and Password when filling out
              the form.
            </p>
            <form onSubmit={handleSellercloudSubmit}>
              <div className={"col-md-12"}>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Warehouse ID <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Warehouse ID"
                      type="text"
                      onChange={handleSellerCloudWarehouseId}
                      value={sellerCloudWarehouseId}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">Company ID</Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control non-arrow-number"
                      onWheel={e => e.target.blur()}
                      placeholder="Enter Company ID"
                      type="number"
                      onChange={handleSellerCloudCompanyId}
                      value={sellerCloudCompanyId}
                      // required={true}
                      // minLength={5}
                    />
                  </div>
                  {storeExist ? (
                    <p className={"text-danger"}>
                      Warehouse ID And Company ID already connected
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Team Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Team Name"
                      type="text"
                      onChange={e => setSellerCloudTeamName(e.target.value)}
                      value={sellerCloudTeamName}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Username <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Username"
                      type="text"
                      onChange={e => setSellerCloudUsername(e.target.value)}
                      value={sellerCloudUsername}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Password <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Password"
                      type="password"
                      onChange={e => setSellerCloudPassword(e.target.value)}
                      value={sellerCloudPassword}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Sellercloud Modal*/}

      {/*Shipstation Modal*/}
      <Modal
        isOpen={modalShipstation}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        // toggle={() => {
        //   setModalShipstation(!modalShipstation);
        // }}
        onClosed={() => {
          // setShipstationStoreId("");
          // setShipstationApiKey("");
          // setShipstationApiSecret("");
          // setStoreExist(false);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalShipstation(!modalShipstation)
            }}
          >
            Connect ShipStation Store
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct API Key and
              API Secret when filling out the form.
            </p>
            <form onSubmit={handleShipstationGetStores}>
              <div className={"col-md-12"}>
                {/*<div className={"mb-3"}>*/}
                {/*  <div className="form-group">*/}
                {/*    <Label className="form-label">Store ID <span style={{ color: "red" }}>*</span></Label>*/}
                {/*    <Input*/}
                {/*      name="whid"*/}
                {/*      // value={name}*/}
                {/*      className="form-control"*/}
                {/*      placeholder="Enter Store ID"*/}
                {/*      type="text"*/}
                {/*      onChange={handleShipstationStoreId}*/}
                {/*      value={shipstationStoreId}*/}
                {/*      required={true}*/}
                {/*      // minLength={5}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  {storeExist?<p className={"text-danger"}>Warehouse ID already connected</p>:""}*/}
                {/*</div>*/}
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      API Key <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter API Key"
                      type="text"
                      onChange={e => setShipstationApiKey(e.target.value)}
                      value={shipstationApiKey}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      API Secret <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter API Secret"
                      type="text"
                      onChange={e => setShipstationApiSecret(e.target.value)}
                      value={shipstationApiSecret}
                      required={true}
                      // minLength={5}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/*Shipstation Modal*/}
      {/* veeqo modal start */}
      <Modal
        isOpen={modalVeeqo}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        // toggle={() => {
        //   setModalShipstation(!modalShipstation);
        // }}
        onClosed={() => {
          // setShipstationStoreId("");
          // setShipstationApiKey("");
          // setShipstationApiSecret("");
          // setStoreExist(false);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalVeeqo(!modalVeeqo)
            }}
          >
            Connect Veeqo Store
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct API Key when
              filling out the form.
            </p>
            <form onSubmit={handleVeeqoGetStores}>
              <div className={"col-md-12"}>
                {/*<div className={"mb-3"}>*/}
                {/*  <div className="form-group">*/}
                {/*    <Label className="form-label">Store ID <span style={{ color: "red" }}>*</span></Label>*/}
                {/*    <Input*/}
                {/*      name="whid"*/}
                {/*      // value={name}*/}
                {/*      className="form-control"*/}
                {/*      placeholder="Enter Store ID"*/}
                {/*      type="text"*/}
                {/*      onChange={handleShipstationStoreId}*/}
                {/*      value={shipstationStoreId}*/}
                {/*      required={true}*/}
                {/*      // minLength={5}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  {storeExist?<p className={"text-danger"}>Warehouse ID already connected</p>:""}*/}
                {/*</div>*/}
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      API Key <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      // value={name}
                      className="form-control"
                      placeholder="Enter API Key"
                      type="text"
                      onChange={e => setVeeqoApiKey(e.target.value)}
                      value={veeqoApiKey}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/* veeqo modal end  */}
      {/* shiphero modal start */}
      <Modal
        isOpen={modalShiphero}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        // toggle={() => {
        //   setModalShipstation(!modalShipstation);
        // }}
        onClosed={() => {
          // setShipstationStoreId("");
          // setShipstationApiKey("");
          // setShipstationApiSecret("");
          // setStoreExist(false);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalShiphero(!modalShiphero)
            }}
          >
            Connect Shiphero Store
          </ModalHeader>
          <ModalBody>
            <p className={"text-danger"}>
              <b>Note:</b> Please ensure that you enter the correct Access and
              Refresh token when filling out the form.
            </p>
            <form onSubmit={handleShipheroGetStores}>
              <div className={"col-md-12"}>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Access Token <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      className="form-control"
                      placeholder="Enter API Key"
                      type="text"
                      onChange={e => setShipheroApiKey(e.target.value)}
                      value={shipheroApiKey}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">
                      Refresh Token<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="whid"
                      className="form-control"
                      placeholder="Enter API Key"
                      type="text"
                      onChange={e => setShipheroRefresh(e.target.value)}
                      value={shipheroRefresh}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button
                type={"submit"}
                disabled={storeExist}
                className={"btn btn-success btn-sm"}
                style={{ float: "right" }}
              >
                Connect
              </button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>

      {/* modalOrderDesk */}
      <Modal isOpen={modalOrderDesk} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" onClosed={() => { }}>
        <div>
          <ModalHeader toggle={() => { setModalOrderDesk(!modalOrderDesk) }} > Connect Order Desk Store</ModalHeader>
          <ModalBody>
            <p className={"text-danger"}><b>Note:</b> Please ensure that you enter the correct Access andRefresh token when filling out the form.</p>
            <form onSubmit={handleOrderDeskGetStores}>
              <div className={"col-md-12"}>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label"> Store Id <span style={{ color: "red" }}>*</span> </Label>
                    <Input name="whid" className="form-control" placeholder="Enter Store Id" type="text" onChange={e => setOrderDeskStoreId(e.target.value)} value={orderDeskStoreId} required={true}/>
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label"> Api Key<span style={{ color: "red" }}>*</span></Label>
                    <Input name="whid" className="form-control" placeholder="Enter API Key" type="text" onChange={e => setorderApiKey(e.target.value)} value={orderApiKey} required={true} />
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">Awaiting Shipment Folder<span style={{ color: "red" }}>*</span></Label>
                    <div className="form-group">
                      {inputFields.map((inputField, index) => (
                        <div key={index} className="mb-3 d-flex">
                          <Input required={true} type="text" placeholder={`Enter Folder Name To Fetch Data`} value={inputField} onChange={event => handleInputChange(index, event)} className="form-control" />
                          {index != 0 && <button type="button" onClick={() => handleDeleteField(index)} className="btn btn-danger" style={{ marginLeft: "4px" }}>Delete</button>}
                        </div>
                      ))}
                      <button type="button" onClick={handleAddField} className="btn btn-primary">Add Folder</button>
                    </div>
                  </div>
                </div>
                <div className={"mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">Fulfilled Order Folder<span style={{ color: "red" }}>*</span></Label>
                    <Input name="whid" className="form-control" placeholder="Enter Folder Name To Push Tracking" type="text" onChange={e => setDestinationFolder(e.target.value)} value={destinationFolder} required={true} />
                  </div>
                </div>
              </div>
              <br />
              <button type={"submit"} disabled={storeExist} className={"btn btn-success btn-sm"} style={{ float: "right" }}>Connect</button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/* shipheromodal enmd */}
      {/*Shipstation Modal*/}

      {/* shipstation stores */}
      <StoreModal
        isOpen={modalShipstationStores}
        toggle={() => {
          setModalShipstationStores(!modalShipstationStores)
        }}
        heading="ShipStation"
        onClosed={() => {
          setShipstationStores([])
        }}
        data={shipstationStores}
        handleSubmit={handleShipstationSubmit}
      />

      {/* veeqo stores */}
      <StoreModal
        isOpen={veeqoStoreModal}
        toggle={() => {
          setVeeqoStoreModal(!veeqoStoreModal)
        }}
        heading="Veeqo"
        onClosed={() => {
          setVeeqoStores([])
        }}
        data={veeqoStores}
        handleSubmit={handleVeeqoSubmit}
      />
      {/* shiphero stores */}
      <StoreModal
        isOpen={shipheroStoreModal}
        toggle={() => {
          setShipheroStoreModal(!shipheroStoreModal)
        }}
        heading="Shiphero"
        onClosed={() => {
          setShipheroStores([])
        }}
        data={shipheroStores}
        handleSubmit={handleShipheroSubmit}
      />
    </div>
  )
}
Stores.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Stores