import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  Line,
  StyleSheet,
} from "@react-pdf/renderer"
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
  detailsText: {
    left: "20px",
    fontSize: "10px",
    fontWeight: 400,
    paddingTop: "3px",
    color: "black",
  },
  tags: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "black",
    marginTop: "10px",
    direction: "rtl",
  },
  mergedSlipDetailsText: {
    left: "20px",
    fontSize: "9px",
    fontWeight: 400,
    paddingTop: "3px",
    color: "black",
  },
})
const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableEmptyRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    height: "20px",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  tableEmptyLastRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    height: "20px",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
  },
  tableHeaderSKUCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderItemCol: {
    width: "47%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderQuantityCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderReturnCodeCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 7,
    backgroundColor: "#d3d9de",
  },
  tableSKUCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableItemCol: {
    width: "47%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableQuantityCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableReturnCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 7,
  },
  tableLeftCell: {
    marginTop: 5,
    fontSize: 7,
    marginLeft: "5px",
  },
  centerTextHeading: {
    fontSize: 7,
    textAlign: "center",
    width: "100%",
    marginTop: "10px",
  },
  centerText: {
    fontSize: 7,
    textAlign: "center",
    width: "100%",
  },
})
export const PackingSlip = ({
  pc_from_name,
  pc_from_street1,
  pc_from_street2,
  pc_from_city,
  pc_from_state,
  pc_from_zip,
  pc_from_country,
  pc_to_street1,
  pc_to_street2,
  pc_to_city,
  pc_to_state,
  pc_to_zip,
  pc_to_country,
  pc_order_number,
  pc_order_date,
  pc_to_name,
  pc_items,
  pc_sub_total,
  pc_total,
}) => {
  
  return (
    <Document>
      <Page size={[384, 520]} style={styles.page}>
        <View>
          <View>
            <Text
              style={{
                left: "150px",
                fontSize: "16px",
                marginTop: "10px",
                fontWeight: 900,
                // fontFamily: "Georgia",
                color: "black",
              }}
            >
              Packing Slip
            </Text>
          </View>
          <View
            style={{
              marginTop: "15px",
            }}
          >
            <Text style={styles.detailsText}>{pc_from_name}</Text>
            <Text style={styles.detailsText}>{pc_from_street1}</Text>
            {pc_from_street2 ? (
              <Text style={styles.detailsText}>{pc_from_street2}</Text>
            ) : null}
            <Text style={styles.detailsText}>{pc_from_city},</Text>
            <Text style={styles.detailsText}>
              {pc_from_state} {pc_from_zip} {pc_from_country}
            </Text>
          </View>
          {/* header detail section */}
          <View
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                left: "20px",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "black",
                  marginRight: "10px",
                }}
              >
                Ship To:
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: 400,
                  marginLeft: "10px",
                  width: "117px",
                  color: "black",
                }}
              >
                {pc_to_street1}
                {pc_to_street2 ? " " + pc_to_street2 : null} {pc_to_city},{" "}
                {pc_to_state} {pc_to_zip} {pc_to_country}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                // left: "20px",
                marginLeft: "80px",
                gap: "6px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View>
                <Text style={styles.tags}>Order #</Text>
                <Text style={styles.tags}>Date</Text>
                <Text style={styles.tags}>User</Text>
                <Text style={styles.tags}>Ship Date</Text>
              </View>
              <Line
                style={{
                  width: "2px",
                  height: "80px",
                  // right: "10px",
                  // marginLeft: 350,
                  // marginTop: -80,
                  borderLeftWidth: 1,
                  borderLeftColor: "black",
                }}
              ></Line>
              <View
                style={{
                  width: "140px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                  }}
                >
                  {pc_order_number}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  {pc_order_date}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginTop: "13px",
                  }}
                >
                  {pc_to_name}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginTop: "13px",
                  }}
                >
                  -
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "98%", marginHorizontal: "auto" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                // border: "1px solid black",
                padding: "10px",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  Item
                </Text>
              </View>
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  SKU
                </Text>
              </View>
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "30%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  Description
                </Text>
              </View>
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  Price
                </Text>
              </View>
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "10%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  Qty
                </Text>
              </View>
              <View
                style={{
                  padding: "6px 0px 0px 5px",
                  border: "1px 1px 1px 1px solid black",
                  width: "20%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  Ext. Price
                </Text>
              </View>
            </View>
            {pc_items.length > 0
              ? pc_items.map((el, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      padding: "5px",
                      width: "100%",
                      marginTop: "3px",
                    }}
                  >
                    <View
                      style={{
                        width: "25%",
                        paddingLeft: "10px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "black",
                        }}
                      >
                        {el.product_identifier}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "25%",
                        paddingLeft: "10px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "black",
                        }}
                      >
                        {el.product_sku}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "30%",
                        paddingLeft: "10px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          padding: 1,
                        }}
                      >
                        {el.description}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "15%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "center",
                          color: "black",
                          padding: 1,
                        }}
                      >
                        ${el.price}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "center",
                          padding: 1,
                        }}
                      >
                        {el.quantity}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "20%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "center",
                          color: "black",
                          padding: 1,
                        }}
                      >
                        ${el.total_price}
                      </Text>
                    </View>
                  </View>
                ))
              : null}
            <Line
              style={{
                width: "100%",
                borderBottom: "1px solid black",
              }}
            ></Line>
          </View>
          <View
            style={{
              display: "flex",
              marginLeft: "60%",
              marginTop: "15px",
              width: "40%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                fontSize: "10px",
              }}
            >
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Sub Total:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Tax:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Shipping:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Total:</Text>
                </View>
              </View>
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>${pc_sub_total}</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>${pc_total}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export const PackingSlipTemplate2 = ({
  pc_to_street1,
  pc_to_street2,
  pc_to_city,
  pc_to_state,
  pc_to_zip,
  pc_to_country,
  pc_order_number,
  pc_reference_number,
  pc_order_date,
  pc_to_name,
  pc_bill_to_street1,
  pc_bill_to_street2,
  pc_bill_to_city,
  pc_bill_to_state,
  pc_bill_to_zip,
  pc_bill_to_country,
  pc_items,
  pc_extra_row,
  pc_from_name,
  pc_from_street1,
  pc_from_street2,
  pc_from_city,
  pc_from_state,
  pc_from_zip,
}) => {
  return (
    <Document>
      <Page size={[384, 520]} style={styles.page}>
        <View>
          <View
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <View
              style={{
                width: "25%",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                SHIP TO
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_street1}
                {pc_to_street2 ? " " + pc_to_street2 : null}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_city}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_state}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_zip}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_country}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                ORDER# {pc_order_number}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                REFERENCE# {pc_reference_number ? pc_reference_number : "-"}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                ORDER DATE - {pc_order_date}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_name}
              </Text>
              <Line
                style={{
                  width: "100%",
                  borderBottom: "1px solid gray",
                }}
              ></Line>
            </View>
            <View
              style={{
                width: "25%",
                textAlign: "right",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                BILL TO
              </Text>
              {pc_bill_to_street1 &&
              pc_bill_to_city &&
              pc_bill_to_state &&
              pc_bill_to_country ? (
                <>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_street1}
                    {pc_bill_to_street2 ? " " + pc_bill_to_street2 : null}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_city}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_state}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_zip}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_country}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_street1}
                    {pc_to_street2 ? " " + pc_to_street2 : null}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_city}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_state}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_zip}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_country}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View
            style={{
              marginTop: "10px",
              padding: "0px 10px",
            }}
          >
            <View style={tableStyles.table}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableHeaderSKUCol}>
                  <Text style={tableStyles.tableHeader}>SKU</Text>
                </View>
                <View style={tableStyles.tableHeaderItemCol}>
                  <Text style={tableStyles.tableHeader}>ITEM</Text>
                </View>
                <View style={tableStyles.tableHeaderQuantityCol}>
                  <Text style={tableStyles.tableHeader}>QUANTITY</Text>
                </View>
                <View style={tableStyles.tableHeaderReturnCodeCol}>
                  <Text style={tableStyles.tableHeader}>RETURN CODE</Text>
                </View>
              </View>
              {pc_items.length > 0
                ? pc_items.map((el, index) => (
                    <View key={index} style={tableStyles.tableRow}>
                      <View style={tableStyles.tableSKUCol}>
                        <Text style={tableStyles.tableLeftCell}>
                          {el.product_sku}
                        </Text>
                      </View>
                      <View style={tableStyles.tableItemCol}>
                        <Text style={tableStyles.tableLeftCell}>
                          {el.description}
                        </Text>
                      </View>
                      <View style={tableStyles.tableQuantityCol}>
                        <Text style={tableStyles.tableCell}>{el.quantity}</Text>
                      </View>
                      <View style={tableStyles.tableReturnCol}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                    </View>
                  ))
                : ""}
            </View>
            {pc_extra_row}
          </View>
          <View
            style={{
              marginTop: "10px",
              padding: "0px 10px",
            }}
          >
            <Text style={tableStyles.centerTextHeading}>RETURN CODES</Text>
            <Text style={tableStyles.centerText}>
              01- TOO BIG 02-TO SMALL 03-DAMAGED/DEFECTIVE 04-NOT AS PICTURED
              05-CHANGED MIND 06-WRONG COLOR/SIZE
            </Text>

            <Text style={tableStyles.centerTextHeading}>
              RETURN INFORMATION
            </Text>
            <Text style={tableStyles.centerText}>
              1. Complete this return form and include it inside your return
              shipment.
            </Text>
            <Text style={tableStyles.centerText}>
              2. Ship your package using a trackable, insured shipping method.
            </Text>
            <Text style={tableStyles.centerText}>
              3. We cannot accept responsibility for packages we do not receive.
            </Text>
            <Text style={tableStyles.centerText}>
              4. Please keep the tracking details for your records.
            </Text>
            <Text style={tableStyles.centerText}>
              5. Please send your return to:
            </Text>
            <Text style={tableStyles.centerText}>{pc_from_name}</Text>
            <Text style={tableStyles.centerText}>
              {pc_from_street1}
              {pc_from_street2 ? " " + pc_from_street2 : ""}
            </Text>
            <Text style={tableStyles.centerText}>
              {pc_from_city}, {pc_from_state} {pc_from_zip}
            </Text>
            <Text style={tableStyles.centerText}>
              6. You will receive an email once your return has been processed.
            </Text>
            <Text style={tableStyles.centerText}>
              7. Please allow 5 business days from the time we receive your
              return for it to be processed.
            </Text>

            <Text style={tableStyles.centerTextHeading}>RETURN POLICY</Text>
            <Text style={tableStyles.centerText}>
              1. Merchandise must be returned within 14 days of shipment
              delivery.
            </Text>
            <Text style={tableStyles.centerText}>
              2. Merchandise must not be worn, altered, or washed.
            </Text>
            <Text style={tableStyles.centerText}>
              3. Merchandise must have all tags attached.
            </Text>

            <Text style={tableStyles.centerTextHeading}>CONTACT INFO</Text>
            <Text style={tableStyles.centerText}>
              Call us at or email us at
            </Text>
            <Text style={tableStyles.centerText}>1-866-531-1773</Text>
            <Text style={tableStyles.centerText}>
              SUPPORT@FANTASTICSPORTSSTORE.COM
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export const MergedPackingSlip = ({ mergedPackingSlip }) => {
  return (
    <Document>
      {mergedPackingSlip.map((el, i) => (
        <Page size={[384, 520]} style={styles.page} key={i}>
          <View>
            <View>
              <Text
                style={{
                  left: "150px",
                  fontSize: "12px",
                  marginTop: "10px",
                  fontWeight: 900,
                  // fontFamily: "Georgia",
                  color: "black",
                }}
              >
                Packing Slip
              </Text>
            </View>
            <View
              style={{
                marginTop: "15px",
              }}
            >
              <Text style={styles.mergedSlipDetailsText}>{el.from_name}</Text>
              <Text style={styles.mergedSlipDetailsText}>
                {el.from_street1}
              </Text>
              {el.from_street2 ? (
                <Text style={styles.mergedSlipDetailsText}>
                  {el.from_street2}
                </Text>
              ) : null}
              <Text style={styles.mergedSlipDetailsText}>{el.from_city},</Text>
              <Text style={styles.mergedSlipDetailsText}>
                {el.from_state} {el.from_zip} {el.from_country}
              </Text>
            </View>
            {/* header detail section */}
            <View
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50%",
                  left: "20px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "black",
                    marginRight: "5px",
                  }}
                >
                  Ship To:
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                    marginLeft: "10px",
                    width: "117px",
                    color: "black",
                  }}
                >
                  {el.to_street1}
                  {el.to_street2 ? " " + el.to_street2 : null} {el.to_city},{" "}
                  {el.to_state} {el.to_zip} {el.to_country}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  // left: "20px",
                  marginLeft: "80px",
                  gap: "6px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View>
                  <Text style={styles.tags}>Order #</Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "black",
                      marginTop: "10px",
                      direction: "rtl",
                      // marginLeft: 125,
                    }}
                  >
                    Date
                  </Text>
                  <Text style={styles.tags}>User</Text>
                  <Text style={styles.tags}>Ship Date</Text>
                </View>
                <Line
                  style={{
                    width: "2px",
                    height: "80px",
                    // right: "10px",
                    // marginLeft: 350,
                    // marginTop: -80,
                    borderLeftWidth: 1,
                    borderLeftColor: "black",
                  }}
                ></Line>
                <View
                  style={{
                    width: "140px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                    }}
                  >
                    {el.order_number}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    {el.order_date}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      marginTop: "13px",
                    }}
                  >
                    {el.to_name}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      marginTop: "13px",
                    }}
                  >
                    {el.shipment_date}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: "98%", marginHorizontal: "auto" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  // border: "1px solid black",
                  padding: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "25%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Item
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "25%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    SKU
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "30%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Description
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "15%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Price
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "10%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Qty
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding: "6px 0px 0px 5px",
                    border: "1px 1px 1px 1px solid black",
                    width: "20%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Ext. Price
                  </Text>
                </View>
              </View>
              {el.items.length > 0
                ? el.items.map((ell, index) => (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        padding: "5px",
                        width: "100%",
                        marginTop: "3px",
                      }}
                    >
                      <View
                        style={{
                          // borderLeft: "1px solid black",
                          width: "25%",
                          paddingLeft: "10px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            color: "black",
                          }}
                        >
                          {ell.product_identifier}
                        </Text>
                      </View>
                      <View
                        style={{
                          // borderLeft: "1px solid black",
                          width: "25%",
                          paddingLeft: "10px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            color: "black",
                          }}
                        >
                          {ell.product_sku}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "30%",
                          paddingLeft: "10px",
                          // borderLeft: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            padding: 2,
                          }}
                        >
                          {ell.description}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          // borderLeft: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            textAlign: "center",
                            color: "black",
                            padding: 2,
                          }}
                        >
                          ${ell.price}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "10%",
                          // borderLeft: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            textAlign: "center",
                            padding: 2,
                          }}
                        >
                          {ell.quantity}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%", // borderLeft: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            textAlign: "center",
                            color: "black",
                            padding: 2,
                          }}
                        >
                          ${ell.total_price}
                        </Text>
                      </View>
                    </View>
                  ))
                : null}
              <Line
                style={{
                  width: "100%",
                  borderBottom: "1px solid black",
                }}
              ></Line>
            </View>
            <View
              style={{
                display: "flex",
                // justifyContent : 'flex-end',
                marginLeft: "60%",
                marginTop: "15px",
                width: "40%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "9px",
                }}
              >
                <View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Sub Total:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Tax:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Shipping:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Total:</Text>
                  </View>
                </View>
                <View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "black" }}>${el.sub_total}</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "black" }}>$0.00</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "black" }}>$0.00</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "black" }}>${el.total}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}
export const MergedPackingSlipTemplate2 = ({ mergedPackingSlipTemplete2 }) => {
  return (
    <Document>
      {mergedPackingSlipTemplete2.map((el, i) => (
        <Page size={[384, 520]} style={styles.page} key={i}>
          <View>
            <View
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0px 10px",
              }}
            >
              <View
                style={{
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  SHIP TO
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_street1}
                  {el.to_street2 ? " " + el.to_street2 : null}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_city}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_state}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_zip}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_country}
                </Text>
              </View>
              <View
                style={{
                  width: "50%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  ORDER# {el.order_number}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  REFERENCE# {el.reference_number ? el.reference_number : "-"}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  ORDER DATE - {el.order_date}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_name}
                </Text>
                <Line
                  style={{
                    width: "100%",
                    borderBottom: "1px solid gray",
                  }}
                ></Line>
              </View>
              <View
                style={{
                  width: "25%",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  BILL TO
                </Text>
                {el.bill_to_street1 &&
                el.bill_to_city &&
                el.bill_to_zip &&
                el.bill_to_country ? (
                  <>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_street1}
                      {el.bill_to_street2 ? " " + el.bill_to_street2 : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_city}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_state}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_zip}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_country}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_street1}
                      {el.to_street2 ? " " + el.to_street2 : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_city}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_state}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_zip}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_country}
                    </Text>
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                marginTop: "10px",
                padding: "0px 10px",
              }}
            >
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableHeaderSKUCol}>
                    <Text style={tableStyles.tableHeader}>SKU</Text>
                  </View>
                  <View style={tableStyles.tableHeaderItemCol}>
                    <Text style={tableStyles.tableHeader}>ITEM</Text>
                  </View>
                  <View style={tableStyles.tableHeaderQuantityCol}>
                    <Text style={tableStyles.tableHeader}>QUANTITY</Text>
                  </View>
                  <View style={tableStyles.tableHeaderReturnCodeCol}>
                    <Text style={tableStyles.tableHeader}>RETURN CODE</Text>
                  </View>
                </View>
                {el.items.length > 0
                  ? el.items.map((ell, index) => (
                      <View key={index} style={tableStyles.tableRow}>
                        <View style={tableStyles.tableSKUCol}>
                          <Text style={tableStyles.tableLeftCell}>
                            {ell.product_sku}
                          </Text>
                        </View>
                        <View style={tableStyles.tableItemCol}>
                          <Text style={tableStyles.tableLeftCell}>
                            {ell.description}
                          </Text>
                        </View>
                        <View style={tableStyles.tableQuantityCol}>
                          <Text style={tableStyles.tableCell}>
                            {ell.quantity}
                          </Text>
                        </View>
                        <View style={tableStyles.tableReturnCol}>
                          <Text style={tableStyles.tableCell}></Text>
                        </View>
                      </View>
                    ))
                  : ""}
              </View>
              {el.extra_row}
            </View>
            <View
              style={{
                marginTop: "10px",
                padding: "0px 10px",
              }}
            >
              <Text style={tableStyles.centerTextHeading}>RETURN CODES</Text>
              <Text style={tableStyles.centerText}>
                01- TOO BIG 02-TO SMALL 03-DAMAGED/DEFECTIVE 04-NOT AS PICTURED
                05-CHANGED MIND 06-WRONG COLOR/SIZE
              </Text>

              <Text style={tableStyles.centerTextHeading}>
                RETURN INFORMATION
              </Text>
              <Text style={tableStyles.centerText}>
                1. Complete this return form and include it inside your return
                shipment.
              </Text>
              <Text style={tableStyles.centerText}>
                2. Ship your package using a trackable, insured shipping method.
              </Text>
              <Text style={tableStyles.centerText}>
                3. We cannot accept responsibility for packages we do not
                receive.
              </Text>
              <Text style={tableStyles.centerText}>
                4. Please keep the tracking details for your records.
              </Text>
              <Text style={tableStyles.centerText}>
                5. Please send your return to:
              </Text>
              <Text style={tableStyles.centerText}>{el.from_name}</Text>
              <Text style={tableStyles.centerText}>
                {el.from_street1}
                {el.from_street2 ? " " + el.from_street2 : ""}
              </Text>
              <Text style={tableStyles.centerText}>
                {el.from_city}, {el.from_state} {el.from_zip}
              </Text>
              <Text style={tableStyles.centerText}>
                6. You will receive an email once your return has been
                processed.
              </Text>
              <Text style={tableStyles.centerText}>
                7. Please allow 5 business days from the time we receive your
                return for it to be processed.
              </Text>

              <Text style={tableStyles.centerTextHeading}>RETURN POLICY</Text>
              <Text style={tableStyles.centerText}>
                1. Merchandise must be returned within 14 days of shipment
                delivery.
              </Text>
              <Text style={tableStyles.centerText}>
                2. Merchandise must not be worn, altered, or washed.
              </Text>
              <Text style={tableStyles.centerText}>
                3. Merchandise must have all tags attached.
              </Text>

              <Text style={tableStyles.centerTextHeading}>CONTACT INFO</Text>
              <Text style={tableStyles.centerText}>
                Call us at or email us at
              </Text>
              <Text style={tableStyles.centerText}>1-866-531-1773</Text>
              <Text style={tableStyles.centerText}>
                SUPPORT@FANTASTICSPORTSSTORE.COM
              </Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}
