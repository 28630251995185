import React from 'react';
import PropTypes from 'prop-types';
import namelogo from "assets/images/nullship-logo.png";
import { FaInstagramSquare, FaTwitter, FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import "../assets/css/loginfooter.css"
import config from '../config';
const LoginFooter = () => {
  return (
    <div className='loginfooter'>
      <div className='footer1'>
        <p>We are NullShip, no subscription or</p>
        <p>hidden fees, and best shipping rates</p>
        <div className='footer1logo'>
          <svg stroke="currentColor" style={{cursor:"pointer"}} fill="currentColor" strokeWidth="0" viewBox="0 0 640 512"
               className="items hover:text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z"></path>
          </svg>
          <svg stroke="currentColor" style={{cursor:"pointer"}} fill="currentColor" strokeWidth="0" viewBox="0 0 320 512"
               className="items hover:text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
          </svg>
          <svg stroke="currentColor" style={{cursor:"pointer"}} fill="currentColor" strokeWidth="0" viewBox="0 0 512 512"
               className="items hover:text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
          </svg>
          <svg stroke="currentColor" style={{cursor:"pointer"}} fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
               className="items hover:text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path>
          </svg>
        </div>
        <p>ⓒ 2023 NullShip All Right Reserved</p>
      </div>
      <div className='footer2'>
        <h3 style={{color:"white"}}>Menus</h3>
        <p className='email'><a target='_blank' rel="noreferrer" href={`${config.main_web.URL}`}>Home</a></p>
        <p className='email'><a target='_blank' rel="noreferrer" href={`${config.main_web.URL}/#about`}>About</a></p>
        <p className='email'><a target='_blank' rel="noreferrer"  href={`${config.main_web.URL}`}>How it Works</a></p>
        <p className='email'><a target='_blank' rel="noreferrer"  href={`${config.main_web.URL}/shipping-calculator`}>Shipping Calculator</a></p>
      </div>
      <div className='footer2'>
        <h3 style={{color:"white"}}>Service</h3>
        <p>Delivery</p>
        <p>Shipping</p>
        <p className='email'><a rel="noreferrer" href="https://app.termly.io/document/terms-of-use-for-website/b87050e7-31f4-44af-9d3c-0662df399124" target='_blank'>Terms and Condition</a></p>
        <p className='email'><a rel="noreferrer" href='https://app.termly.io/document/privacy-policy/ba70b01f-5eac-4a28-ad0d-90abf1806155'  target="_blank">Privacy Policy</a></p>
      </div>
      <div className='footer4'>
        <h3 style={{color:"white"}}>Contact</h3>
        <div className='email'>
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@nullship.gg">info@nullship.gg</a>
        </div>
        <p className='address'>NullShip LLC,</p>
        <p className='address'>261 N UNIVERSITY DR STE 500 # 1077</p>
        <p className='address'>PLANTATION, FL 33324-2009</p>
        <p className='address'>UNITED STATES</p>
      </div>
    </div>
  );
}

export default LoginFooter;