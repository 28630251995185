// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
// import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form, CardTitle
} from "reactstrap"

const API_URL= config.site.BASE_URL;
const UPLOAD_ENDPOINT = "api/user/upload-blog-image";


import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { CKEditor } from 'ckeditor4-react';
import config from "../../../config"

class UploadAdapter {
  constructor(loader, handleUpload) {
    this.loader = loader;
    this.handleUpload = handleUpload;
  }

  upload() {
    return this.loader.file.then((file) => this.handleUpload(file));
  }

  abort() {
    // No need to implement the abort method for image uploads
  }
}

function CreateBlog(props) {

  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  const handleEditorStateChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const htmlString = stateToHTML(contentState);
  // console.log(htmlString);
    setDescription(htmlString);
    setEditorState(newEditorState);
  };

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [description, setDescription] = useState("");
  const [blogCategories, setBlogCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
      excerpt: '',
      category_id: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      excerpt: Yup.string().required("Please Enter excerpt"),
      category_id:  Yup.string().required("Please Select a Category"),
    }),
    onSubmit: (values) => {
      handleAddBlog(values)
    }
  });

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append('file', file);
          // console.log("file",file);

            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            //
            postFile('/user/upload-blog-image', formData)
              .then((res) => {
              // console.log("response", res);
                resolve({
                  default: `${API_URL}/apis/blog-image/${res.filename}`
                });
              })
              .catch((err) => {
                reject(err);
              });

            // fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
            //   method: "post",
            //   body: body
            //   // mode: "no-cors"
            // })
            //   .then((res) => res.json())
            //   .then((res) => {
            //     resolve({
            //       default: `${API_URL}/blog-image/${res.filename}`
            //     });
            //   })
            //   .catch((err) => {
            //     reject(err);
            //   });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
  // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

    // console.log("base64String",base64String);
      setBlogImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    retrieveBlogCategories();
  }, []);

  function getHtmlFromEditorState() {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlString = convertToHTML(rawContentState);
  // console.log("html",htmlString)
    return htmlString;
  }

  const retrieveBlogCategories = async () => {
    try {
      get('/admin/get-blog-categories', {headers}).then((response) => {
        setBlogCategories(response.data);
      })
    }
    catch (e) {
    // console.log(e)
    }
  }

  // const tableData = ;
  const handleAddBlog = async () => {
    try {
      // if(blogImage !== "") {
        if(title && excerpt && category_id && slug && description !== "" && description !== "<p></p>") {

          const body = {
            title: title,
            slug: slug,
            excerpt: excerpt,
            description: description,
            category_id: category_id,
            image: blogImage,
            created_by: JSON.parse(localStorage.getItem("authUser")).id
          };

          post('/admin/add-blog', body, {headers}).then((response) => {
            if(response.status === 200) {
              toast.success(response.message);
              setTimeout(() => {
                props.history.push("/blogs");
              },2000)
            }
            else {
              toast.error(response.message);
            }
          })
        }
        else {
          toast.error("Please fill all required fields");
        }
      // }
      // else {
      //   toast.error("Please select blog image");
      // }
    }
    catch (e) {
    // console.log(e)
    }
  }

  //meta title
  document.title = "Add Blog | NullShip";
  const [imageURL, setImageURL] = useState('');
  const handleImageUpload = async (file) => {
    try {
      // console.log("fileeeeeeeeeee",file);
      const formData = new FormData();
      formData.append('file', file);
      const response = await postFile('/user/upload-blog-image', formData);

      const data = await response.json();

      // Assuming the response contains the URL of the uploaded image
      const uploadedImageURL = data.imageURL;

      setImageURL(uploadedImageURL);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')  // Remove non-word characters
      .replace(/--+/g, '-')     // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Trim hyphens from the beginning and end of the slug
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Blogs" breadcrumbItem="Add Blog" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={10} lg={10} xl={10}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Blog Information</CardTitle>
                <div className="">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddBlog();
                      return false;
                    }}
                  >
                    <Row>
                      <div className={"col-md-6"}>
                        <div className="mb-3">
                          <label>Category</label>
                          <select
                            name={"category_id"}
                            className="form-select"
                            onChange={(e) => setCategoryId(e.target.value)}
                            value={category_id}
                            required={true}
                          >
                            <option disabled={true} value={""}>Select a category</option>
                            {blogCategories.map((part, id) => {
                              return (<option key={id} value={part.id}>{part.title}</option>);
                            })};
                          </select>
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className="mb-3">
                          <label>Blog Image</label>
                          <input type={"file"} multiple={false} ref={fileInput} onChange={handleImage} className={"form-control"} accept={".png"}/>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Title</Label>
                          <Input
                            name="title"
                            className="form-control"
                            placeholder="Enter Title"
                            type="text"
                            onChange={(e) => {
                              let value = e.target.value;
                              // let slug = value.toString().toLowerCase().replace("-"," ").replace(" ","-");
                              setTitle(e.target.value)
                              setSlug(slugify(value));
                            }}
                            value={title}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Slug</Label>
                          <Input
                            name="slug"
                            className="form-control"
                            placeholder="Slug"
                            type="text"
                            readOnly={true}
                            // onChange={(e) => setSlug(e.target.value)}
                            value={slug}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Excerpt</Label>
                          <Input
                            name="excerpt"
                            className="form-control non-arrow-number"
                            placeholder="Enter Excerpt"
                            type="text"
                            onChange={(e) => setExcerpt(e.target.value)}
                            value={excerpt}
                            required={true}
                          />
                        </div>
                      </div>
                    </Row>
                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      {/*<Editor*/}
                      {/*  editorState={editorState}*/}
                      {/*  onEditorStateChange={handleEditorStateChange}*/}
                      {/*/>*/}

                      <CKEditor
                        config={ {
                          extraPlugins: ['colorbutton','font','blockquote','image2','emoji']
                        }}
                        onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          editor.setData( '<h1><i>Foo</i></h1><p class="left"><span>Bar</span> <a href="http://foo.bar">foo</a></p>' );
                        } }
                        onChange={ ( event ) => {
                          const data = event.editor.getData();
                          setDescription(data);
                        // console.log( "Data",data );
                        } }
                      />

                      {/*<CKEditor*/}
                      {/*  editor={ClassicEditor}*/}
                      {/*  data="<p>Enter your content here</p>"*/}
                      {/*  onReady={(editor) => {*/}
                      {/*    // Optional: Perform additional setup or actions when the editor is ready*/}
                      {/*  }}*/}
                      {/*  onChange={(event, editor) => {*/}
                      {/*    // Optional: Perform additional actions when the content changes*/}
                      {/*  }}*/}
                      {/*/>*/}

                      {/*<CKEditor*/}
                      {/*  config={{*/}
                      {/*    extraPlugins: [uploadPlugin]*/}
                      {/*  }}*/}
                      {/*  editor={ClassicEditor}*/}
                      {/*  onReady={(editor) => {}}*/}
                      {/*  onBlur={(event, editor) => {}}*/}
                      {/*  onFocus={(event, editor) => {}}*/}
                      {/*  onChange={(event, editor) => {*/}
                      {/*      const data = editor.getData();*/}
                      {/*      setDescription(data);*/}
                      {/*    // console.log("data",data);*/}
                      {/*  }}*/}
                      {/*/>*/}
                      
                      
                      
                      {/*<CKEditor*/}
                      {/*  editor={ClassicEditor}*/}
                      {/*  data="<p>Start typing here...</p>"*/}
                      {/*  config={{*/}
                      {/*    ckfinder: {*/}
                      {/*      uploadUrl: 'http://localhost:3000/api/user/upload-blog-image',*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*  onReady={(editor) => {*/}
                      {/*    // Enable image upload*/}
                      {/*    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {*/}
                      {/*      return new UploadAdapter(loader, handleImageUpload);*/}
                      {/*    };*/}
                      {/*  }}*/}
                      {/*  // onChange={(event, editor) => {*/}
                      {/*  //   const data = editor.getData();*/}
                      {/*  //   setDescription(data);*/}
                      {/*  // // console.log("data",data);*/}
                      {/*  // }}*/}
                      {/*/>*/}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/blogs') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
CreateBlog.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default CreateBlog;