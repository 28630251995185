// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import config from '../../../config'
import '../../../assets/css/detail_btn.css'
import { saveAs } from 'file-saver';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import ReactJson from "react-json-view"
import JsonFormatter from "react-json-formatter"
import customStyles from "../../../assets/css/customTableStyle"


function TrackingWebhookLogs(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [status, setStatus] = useState("all");

  const [pending, setPending] = useState(false);

  function capitalizeWords(str) {
    // split the string into an array of words
    let words = str.split(' ');

    // capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    // join the words back together into a string and return it
    return words.join(' ');
  }

  const columns = [
    {
      name: 'Email',
      selector: row => row.email,
      // sortable: true,
      // width: "210px",
      cell: (row) => <span>{row.email}</span>
    },
    {
      name: 'Date',
      // selector: ['aftership_updated_at', ' updated_at'],
      // sortable: true,
      // width: "210px",
      selector: (row) => row.aftership_updated_at?moment(row.aftership_updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A'):moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Tracking Code',
      // selector: 'tracking_code',
      // width:"200px",
      // sortable: true,
      selector: row =>
        // <a target={"_blank"} rel={"noreferrer"} href={`/track/${row.tracking_code}`}>{
        row.tracking_code
      // }</a>
    },
    // {
    //   name: 'Time',
    //   selector: 'aftership_updated_at',
    //   sortable: true,
    //   width: "120px",
    //   cell: (row) => moment(row.aftership_updated_at).format('')
    // },
    // {
    //   name: 'Est. Delivery Date',
    //   selector: 'json',
    //   // sortable: true,
    //   // width: "210px",
    //   cell: (row) => JSON.parse(row.json).expected_delivery != null ? moment(JSON.parse(row.json).expected_delivery).format('MMM DD, YYYY hh:mm A') : "-"
    // },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: "150px",
      // cell: row => row.charge_status == "charge:created" ? <span className={"badge badge-warning"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:failed"?<span className={"badge badge-danger"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:confirmed"?<span className={"badge badge-success"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:<span className={"badge badge-light"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>))
      // cell: row => row.charge_status == "charge.created" ? <span className={"badge badge-danger"}>Pending</span>:<span className={"badge badge-success"}>Charged</span>
    },
    // {
    //   name: 'Actions',
    //   selector: 'details',
    //   // sortable: true,
    //   style: {
    //     fontWeight: "600",
    //     color: "#ff6600",
    //     cursor: "pointer"
    //   },
    //   width: "120px",
    //   // cell: row => <span style={{fontWeight:"600"}} onClick={() => document.getElementById("").click()}>Details <i style={{fontSize:"8px"}} className={"bx bxs-down-arrow"}></i></span>
    //   // cell: row => row.charge_status == "charge:created" ? <span className={"badge badge-warning"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:failed"?<span className={"badge badge-danger"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:confirmed"?<span className={"badge badge-success"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:<span className={"badge badge-light"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>))
    //   // cell: row => row.charge_status == "charge.created" ? <span className={"badge badge-danger"}>Pending</span>:<span className={"badge badge-success"}>Charged</span>
    // },
  ];


  const retrieveTrackingLogs = async () => {
    try {
      setPending(true);
      let arr = [];
      get(`/admin/get-orders-tracking`, {headers}).then((response) => {
        // console.log("get-orders-tracking response", response);
        // for (let i=0;i<response.data.length;i++) {
        //   let found = arr.some(el => el.aftership_tracking_id == response.data[i].aftership_tracking_id);
        //   if(!found) {
        //     response.data[i].details = 'Details';
        //     arr.push(response.data[i]);
        //   }
        //   // else {
        //   //   let index = arr.findIndex(el => el.aftership_tracking_id == response.data[i].aftership_tracking_id);
        //   //   // console.log("index", index);
        //   //   JSON.parse(arr[index].json).checkpoints.push(JSON.parse(response.data[i].json).checkpoints);
        //   //   // console.log("JSON.parse(arr[index].json).checkpoints",JSON.parse(arr[index].json).checkpoints);
        //   //   // console.log("JSON.parse(response.data[i].json).checkpoints",response.data[i].json);
        //   // }
        // }
        setAllData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      }).catch(()=> {
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    retrieveTrackingLogs();
  }, []);

  const ExpandedComponent = props => {
    const jsonStyle = {
      propertyStyle: { fontWeight: '600' },
      stringStyle: { },
      numberStyle: { }
    }
    // console.log("props", props);
    return (
      <div className={"col-md-12"} key={props.data.tracking_code} style={{ backgroundColor: "#fafafa" }}>
        <h3 style={{ padding: "20px" }}>{props.data.tracking_code}</h3>
        <div className={"col-md-12 p-3"} style={{marginTop:"-20px"}}>
          <div className={"row"}>
            <div className={"col-md-8"}>
              <div className={"bg-white p-2 rounded shadow"}>
                {props.data.json?<JsonFormatter json={props.data.json} tabWith={5} jsonStyle={jsonStyle} />:"Tracking do not exist"}
              </div>
            </div>
            <div className={"col-md-4 mt-3"}>
              {/*<h4>Tracking URL</h4>*/}
              {/*<a target={"_blank"} rel={"noreferrer"} href={`/track/${props.data.tracking_code}`}><p>{config.site.URL}/track/{props.data.tracking_code}</p></a>*/}
              {/*<br/>*/}
              <h4>Tracking Details</h4>
              <hr/>
              {JSON.parse(props.data.json)?JSON.parse(props.data.json).checkpoints.length > 0 ?
                JSON.parse(props.data.json).checkpoints.map((part, id) => {
                  return (
                    <div key={id}>
                      <h5>{moment(part.checkpoint_time).format("MMM DD, YYYY hh:mm A")}</h5>
                      <p>{part.message}</p>
                    </div>
                  )
                }) : <>
                  <h5>{moment(JSON.parse(props.data.json).last_updated_at).format("MMM DD, YYYY hh:mm A")}</h5>
                  <p>{JSON.parse(props.data.json).tag}</p>
                </>:"Tracking do not exist"
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleFrom = (e) => {
    setFrom(e.target.value);

    if(to != "") {
      FilterTracking(e.target.value, to, status);
    }
  }

  const handleTo = (e) => {
    setTo(e.target.value);
    if(from != "") {
      FilterTracking(from, e.target.value, status)
    }
  }

  const FilterTracking = (fromDate, toDate, status) => {
    setPending(true);

    let dataAfterFilteringDate;
    if(fromDate != "" && toDate != "") {
      dataAfterFilteringDate = allData.filter(el => new Date(el.aftership_updated_at) >= new Date(fromDate) && new Date(el.aftership_updated_at) <= new Date(toDate));
    }
    else {
      dataAfterFilteringDate = allData;
    }


    let data;
    if(status == "all") {
      data = dataAfterFilteringDate;
    }
    else {
      data = dataAfterFilteringDate.filter(el => el.status == status);
    }

    setTimeout(() => {
      setPending(false);
      // console.log("dates", fromDate, toDate);
      // console.log("tracking", data);
      setFilteredData(data);
      // console.log("table data" , { columns, data });
      setTableData({ columns, data });
    }, 1000)

  }

  const handleStatus = (e) => {
    let {value} = e.target;
    setStatus(value);
    FilterTracking(from, to, value);
  }

  const handleExport = () => {
    // console.log("filtered data", filteredData);
    if(filteredData.length > 0) {
      let header = 'Order ID, Tracking Code, Tracking Created At, Tracking Updated At, Tracking Status, Tracking Slug, Carrier, Service, Est. Delivery Date, Current Detail Message, Current Detail Status, Current Detail Date,Destination Street1, Destination Street2, Destination State, Destination City, Destination Zip, Destination Country, Tracking Url\n'
      let data = "";
      for (let i=0;i<filteredData.length;i++) {
        let json = JSON.parse(filteredData[i].json);
        let order_id = filteredData[i].order_unique_id;
        let destination_street1 = filteredData[i].to_street1;
        let destination_street2 = filteredData[i].to_street2;
        let destination_state = filteredData[i].to_state;
        let destination_city = filteredData[i].to_city;
        let destination_zip = filteredData[i].to_zip;
        let destination_country = filteredData[i].to_country;
        let tracking_code = filteredData[i].tracking_code;
        let tracking_created_at = filteredData[i].aftership_created_at;
        let tracking_updated_at = filteredData[i].aftership_updated_at;
        let tracking_status = filteredData[i].status;
        let tracking_slug = json.slug;
        let carrier = filteredData[i].carrier_name;
        let service = filteredData[i].service_name;
        let est_delivery_date = json.expected_delivery;
        let current_detail_message = json.checkpoints.length > 0 ? json.checkpoints[json.checkpoints.length - 1].message:"Pending";
        let current_detail_status = json.checkpoints.length > 0 ? json.checkpoints[json.checkpoints.length - 1].tag:"Pending";
        let current_detail_date = json.checkpoints.length > 0 ? json.checkpoints[json.checkpoints.length - 1].created_at:filteredData[i].aftership_updated_at;
        let public_url = config.site.URL+ "/track/" + filteredData[i].tracking_code;

        data += `${order_id},${tracking_code},${tracking_created_at},${tracking_updated_at},${tracking_status},${tracking_slug},${carrier},${service},${est_delivery_date},${current_detail_message},${current_detail_status},${current_detail_date},${destination_street1},${destination_street2},${destination_state},${destination_city},${destination_zip},${destination_country},${public_url}\n`;
      }

      // console.log("data is",data);

      let final_data = header + data;
      const blob = new Blob([final_data], {type: "text/csv;charset=utf-8"});
      saveAs(blob, 'tracking-logs-'+moment(new Date).format("DD-MMM-YYYY")+'.csv');
    }
  }

  //meta title
  document.title = "Tracking Webhook Logs | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="Logs" breadcrumbItem="Tracking Webhook" />
        <Card>
          <CardBody>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <div className="mb-3">
                  <Label className="form-label">From</Label>
                  <Input
                    name="from"
                    className="form-control"
                    type="date"
                    max={to}
                    value={from}
                    onChange={handleFrom}
                  />
                </div>
              </div>
              <div className={"col-md-3"}>
                <div className="mb-3">
                  <Label className="form-label">To</Label>
                  <Input
                    name="to"
                    className="form-control"
                    type="date"
                    disabled={from==""?true:false}
                    title={from==""?"Please select from date":""}
                    min={from}
                    value={to}
                    onChange={handleTo}
                  />
                </div>
              </div>
              <div className={"col-md-2"}>
                <div className="mb-3">
                  <Label className="form-label">Status</Label>
                  <select className={"form-select"} onChange={handleStatus}>
                    <option title={"All Statuses"} value={"all"}>All Statuses</option>
                    <option title={"Carrier has received request from shipper and is about to pick up the shipment."} value={"InfoReceived"}>Information Received</option>
                    <option title={"Carrier has accepted or picked up shipment from shipper. The shipment is on the way."} value={"InTransit"}>In Transit</option>
                    <option title={"Carrier is about to deliver the shipment , or it is ready to pickup."} value={"OutForDelivery"}>Out for Delivery</option>
                    <option title={"Carrier attempted to deliver but failed, and usually leaves a notice and will try to deliver again."} value={"AttemptFail"}>Failed Attempt</option>
                    <option title={"The shipment was delivered successfully."} value={"Delivered"}>Delivered</option>
                    <option title={"The package arrived at a pickup point near you and is available for pickup."} value={"AvailableForPickup"}>Available For Pickup</option>
                    <option title={"Custom hold, undelivered, returned shipment to sender or any shipping exceptions."} value={"Exception"}>Exception</option>
                    <option title={"Shipment has no tracking information for 30 days since added."} value={"Expired"}>Expired</option>
                    <option title={"New shipments added that are pending to track, or new shipments without tracking information available yet."} value={"Pending"}>Pending</option>
                  </select>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {/*<button className={"btn btn-warning"} style={{float:"left",marginRight:"10px",marginTop:"10px"}} onClick={handleExport}>Export CSV</button>*/}
            <DataTableExtensions
              {...tableData}
              filterPlaceholder={`Filter Tracking Webhook Logs...`}
            >
              <DataTable
                columns={columns}
                data={filteredData} customStyles={customStyles}
                className={"tracking-table"}
                pagination={100}
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 200, 500]}
                expandOnRowClicked={true}
                expandableRows={true}
                // expandableIcon={false}
                // expandableRowsHideExpander={true}
                responsive={true}
                highlightOnHover={true}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                expandableRowsComponent={ExpandedComponent}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
TrackingWebhookLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default TrackingWebhookLogs;