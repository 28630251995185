import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import React from "react"

const Tags = ({
                isOpen,
                toggle,
                onClosed,
                handleCreateTag,
                handleUpdateTag,
                handleDeleteTag,
                tags,
                tag,
                tagColor,
                selectedTags,
                setTag,
                setTagColor,
                handleTagCheck,
                getBrightness
              }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal modal"
      tabIndex="-1"
      toggle={toggle}
      onClosed={onClosed}
    >
      <div>
        <ModalHeader
          toggle={toggle}
        >
          Manage Tag
        </ModalHeader>
        <ModalBody>
          <fieldset className={"mt-2"}>
            <legend>Add New Tag</legend>
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleCreateTag()
              }}
              className={"mb-2"}
            >
              {/*<label>Tag Name</label>*/}
              <div
                className={"row px-2"}
                style={{ justifyContent: "space-around" }}
              >
                <input
                  type={"text"}
                  value={tag}
                  required={true}
                  placeholder={"Enter Tag Name"}
                  maxLength={"30"}
                  style={{ width: "60%" }}
                  onChange={e => setTag(e.target.value)}
                  className={"form-control"}
                />
                <input
                  type={"color"}
                  value={tagColor}
                  required={true}
                  title={"Set Tag Background Color"}
                  onChange={e => {
                    setTagColor(e.target.value)
                  }}
                  className={"form-control form-control-color"}
                  style={{ width: "15%", height: "40px" }}
                />
                <button
                  className={"btn btn-sm btn-primary"}
                  style={{ width: "15%", height: "40px" }}
                  type="submit"
                >
                  Add Tag
                </button>
              </div>
            </Form>
          </fieldset>
          <h5>
            Tags
            <span
              style={{
                float: "right",
                fontWeight: "400",
                fontSize: "12px",
              }}
            >
                  {selectedTags.length > 0
                    ? selectedTags.length + " selected"
                    : null}
                </span>
          </h5>
          <div style={{ width: "100%", height: "300px", overflow: "auto" }}>
            <table className={"table table-hover"}>
              <thead>
              <tr>
                <th
                  style={{
                    backgroundColor:
                      localStorage.getItem("theme") == "dark"
                        ? "#212124"
                        : "#F6F6FA",
                  }}
                ></th>
                <th
                  style={{
                    backgroundColor:
                      localStorage.getItem("theme") == "dark"
                        ? "#212124"
                        : "#F6F6FA",
                  }}
                >
                  Tag
                </th>
                <th
                  style={{
                    backgroundColor:
                      localStorage.getItem("theme") == "dark"
                        ? "#212124"
                        : "#F6F6FA",
                  }}
                >
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              {tags.length > 0
                ? tags.map((el, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        verticalAlign: "middle",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type={"checkbox"}
                        onClick={handleTagCheck}
                        className={"tag"}
                        id={"tag-" + el.id}
                        value={el.id}
                      />
                    </td>
                    <td
                      style={{ verticalAlign: "middle" }}
                      onClick={() => {
                        document.getElementById("tag-" + el.id).click()
                      }}
                    >
                              <span
                                className={"badge"}
                                style={{
                                  backgroundColor: el.color,
                                  color:
                                    parseFloat(getBrightness(el.color)) > 128
                                      ? "black"
                                      : "white",
                                  padding: "5px",
                                }}
                              >
                                {el.tag}
                              </span>
                    </td>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <button
                        className={"btn btn-danger btn-sm"}
                        onClick={() => handleDeleteTag(el.id)}
                      >
                        <i className={"bx bx-trash"}></i>
                      </button>
                    </td>
                  </tr>
                ))
                : null}
              </tbody>
            </table>
          </div>
          <br />
          <table className={"table"}>
            <tbody>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  border: "none",
                  width: "100%",
                }}
              >
                <button
                  className={"btn btn-success btn-sm"}
                  onClick={handleUpdateTag}
                >
                  Apply Tags
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default Tags;