// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import handleSubmit from "redux-form/lib/handleSubmit"
import customStyles from "../../../assets/css/customTableStyle"


function Discounts() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);

  const [allId, setAllId] = useState(null);
  const [allAmount, setAllAmount] = useState(null);
  const [allAmountAboveFive, setAllAmountAboveFive] = useState(null);
  const [allAmountUnderOne, setAllAmountUnderOne] = useState(null);
  const [allAmountAboveTwenty, setAllAmountAboveTwenty] = useState(null);
  const [allDiscountType, setAllDiscountType] = useState("Flat");
  const [allStatus, setAllStatus] = useState(1);
  const [modal, setModal] = useState(false);

  const [id, setId] = useState(null);


  const [amount, setAmount] = useState(null);
  const [discountType, setDiscountType] = useState("Flat");
  const [amountAboveFive, setAmountAboveFive] = useState(null);
  const [amountUnderOne, setAmountUnderOne] = useState(null);
  const [amountAboveTwenty, setAmountAboveTwenty] = useState(null);
  const [discountTypeAboveFive, setDiscountTypeAboveFive] = useState("Flat");

  const [user_id, setUserId] = useState(null);

  const [services, setServices] = useState([]);
  const [service_id, setServiceId] = useState(0);


  const [status, setStatus] = useState(1);
  const [allModal, setAllModal] = useState(false);



  const allColumns = [
    // {
    //   name: 'Type',
    //   selector: 'type',
    //   sortable: true,
    //   minWidth: "200px"
    // },
    {
      name: 'Below 1lb. Amount',
      selector: row => row.amount_under_one_lbs,
      //  sortable: true,
      minWidth: "160px"
    },
    {
      name: '(1lb. to 5lb.) Amount',
      selector: row => row.amount,
    //  sortable: true,
      minWidth: "160px"
    },
    {
      name: 'Above 5lb. Amount',
      selector: row => row.amount_above_five_lbs,
    //  sortable: true,
      minWidth: "160px"
    },
    {
      name: 'Above 20lb. Amount',
      selector: row => row.amount_above_twenty_lbs,
      //  sortable: true,
      minWidth: "160px"
    },
    {
      name: 'Discount Type',
      selector: row => row.discount_type,
    //  sortable: true,
      width: "160px",
      // cell: (row) => {row.is_discount===1?<span className={"badge badge-success"}>Available</span>:<span className={"badge badge-danger"}>Unavailable</span>}
    },
    {
      name: 'Status',
      // selector: 'status',
    //  sortable: true,
      width: "110px",
      selector: (row) => row.status === 1 ?<span className={"badge badge-success"}>Active</span>:<span className={"badge badge-danger"}>Inactive</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
    //  sortable: true,
      selector: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "160px",
      selector: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={()=>handleAllEdit(row.id)}>Edit</button>
        </>
    },
  ];

  const columns = [
    // {
    //   name: 'Type',
    //   selector: 'type',
    //   sortable: true,
    //   minWidth: "200px"
    // },
    {
      name: 'Email',
      selector: row=>row.email,
    //  sortable: true,
      width: "200px"
    },
    {
      name: 'Service',
      // selector: ['service_id', 'service_name'],
    //  sortable: true,
      width: "130px",
      selector: row => row.service_id == 0?<span>All Services</span>:row.service_name
    },
    {
      name: 'Type',
      // selector: 'discount_type',
      selector:row=>row.discount_type,
    //  sortable: true,
      width: "100px",
      // cell: (row) => {row.is_discount===1?<span className={"badge badge-success"}>Available</span>:<span className={"badge badge-danger"}>Unavailable</span>}
    },
    {
      name: 'Below 1lb. Amount',
      // selector: 'amount_under_one_lbs',
      selector: row=>row.amount_under_one_lbs,
      //  sortable: true,
      width: "160px"
    },
    {
      name: '(1lb. to 5lb.) Amount',
      selector: row=>row.amount,
      //  sortable: true,
      width: "160px"
    },
    // {
    //   name: 'Type (5 lb.+)',
    //   selector: 'discount_type_above_five_lbs',
    // //  sortable: true,
    //   width: "130px",
    //   // cell: (row) => {row.is_discount===1?<span className={"badge badge-success"}>Available</span>:<span className={"badge badge-danger"}>Unavailable</span>}
    // },
    {
      name: 'Above 5lb. Amount',
      // selector: 'amount_above_five_lbs',
      selector:row=>row.amount_above_five_lbs,
      //  sortable: true,
      width: "160px"
    },
    {
      name: 'Above 20lb. Amount',
      // selector: 'amount_above_five_lbs',
      selector:row=>row.amount_above_twenty_lbs,
      //  sortable: true,
      width: "160px"
    },
    {
      name: 'Status',
      // selector: 'status',
     sortable: true,
      width: "90px",
      selector: (row) => row.status === 1 ?<span className={"badge badge-success"}>Active</span>:<span className={"badge badge-danger"}>Inactive</span>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
     sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "160px",
      cell: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleEdit(row.id)}>Edit</button>
          <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{marginLeft:"10px"}}>Delete</button>
        </>
    },
  ];

  const handleAllEdit = async (id) => {
    const body = {
      id: id
    };
    post('/admin/get-discount-by-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        const data = response.data;
        setAllId(data.id);
        setAllAmount(data.amount);
        setAllAmountAboveFive(data.amount_above_five_lbs);
        setAllAmountUnderOne(data.amount_under_one_lbs);
        setAllAmountAboveTwenty(data.amount_above_twenty_lbs);
        setAllDiscountType(data.discount_type);
        setAllStatus(data.status);
        setAllModal(true);
      }
      else {
        toast.success(response.message);
      }
    })
  }

  const handleEdit = async (id) => {
    const body = {
      id: id
    };
    post('/admin/get-discount-by-id', body, {headers}).then((response) => {
      if(response.status === 200) {
        const data = response.data;
        setId(data.id);
        setAmount(data.amount);
        setServiceId(data.service_id);
        setDiscountType(data.discount_type);
        setAmountAboveFive(data.amount_above_five_lbs);
        setAmountUnderOne(data.amount_under_one_lbs);
        setAmountAboveTwenty(data.amount_above_twenty_lbs);
        setDiscountTypeAboveFive(data.discount_type_above_five_lbs?data.discount_type_above_five_lbs:"Flat");
        setStatus(data.status)
        setModal(true);
      }
      else {
        toast.success(response.message);
      }
    })
  }

  const handleDelete = async (id) => {
    let confrm = confirm("Are you sure you want to delete this discount?");
    if(confrm) {
      // const id = e.target.getAttribute("data-id");
      const body = {
        id: id
      };
      post('/admin/delete-discount', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveUserDiscounts();
        } else {
          toast.success(response.message);
        }
      })
    }
  }

  // const tableData = ;
  const retrieveAllDiscount = async () => {
    try {
      get('/admin/get-all-discounts', {headers}).then((response) => {
        // // console.log("response", response);
        setAllData(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveUserDiscounts = async () => {
    try {
      get('/admin/get-discounts', {headers}).then((response) => {
        // // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      amount: amount || '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Please Enter Amount"),
    }),
    onSubmit: (values) => {
      dispatch(handleEditService(values));
    }
  });

  const validationAll = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: allAmount || '',
      amountAboveFive: allAmountAboveFive || '',
      amountUnderOne: allAmountUnderOne || '',
      amountAboveTwenty: allAmountAboveTwenty || '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Please Enter (1lb. to 5lb.) Amount"),
      amountAboveFive: Yup.string().required("Please Enter Above 5lb. Amount"),
      amountUnderOne: Yup.string().required("Please Enter Below 1lb. Amount"),
      amountAboveTwenty: Yup.string().required("Please Enter Above 20lb. Amount"),
    }),
    onSubmit: (values) => {
      dispatch(handleAllEditService(values));
    }
  });

  const handleDiscountType = (e) => {
    // console.log(e.target.value);
    setDiscountType(e.target.value);
  }

  const handleAllDiscountType = (e) => {
    // console.log(e.target.value);
    setAllDiscountType(e.target.value);
  }

  const handleAllEditService = async (values) => {
    try {
      const {amount, amountAboveFive, amountUnderOne, amountAboveTwenty} = values;

      const body = {
        id: allId,
        amount: amount,
        amountAboveFive: amountAboveFive,
        amountUnderOne: amountUnderOne,
        amountAboveTwenty: amountAboveTwenty,
        discountType: allDiscountType,
        status: allStatus
      };

      post('/admin/edit-discount', body, {headers}).then((response) => {
        if(response.status === 200) {
          toast.success(response.message);
          retrieveAllDiscount();
          setAllModal(!allModal);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleEditService = async () => {
    try {

      if(amount && discountType && amountAboveFive && amountUnderOne && amountAboveTwenty) {
        // if(discountType !== "None" && amount == "" || amount == "0") {
        //   toast.error("Discount must be greater than 0");
        // }
        // else {
          const body = {
            id: id,
            amount: amount,
            discountType: discountType,
            discountTypeAboveFive: discountTypeAboveFive,
            amountAboveFive: amountAboveFive,
            amountUnderOne: amountUnderOne,
            amountAboveTwenty: amountAboveTwenty,
            // service_id: service_id,
            status: status
          };

          post('/admin/edit-discount', body, {headers}).then((response) => {
            if(response.status === 200) {
              toast.success(response.message);
              retrieveUserDiscounts();
              setModal(!modal);
            }
            else {
              toast.error(response.message);
            }
          })
        // }
      }
      else {
        // console.log(amount ,"&&", discountType ,"&&", service_id)
        toast.error("Please select all required fields");
      }
    }
    catch (e) {
      // console.log(e)
    }
  }


  const retrieveServices = async () => {
    try {
      get('/admin/get-services', {headers}).then((response) => {
        // // console.log("response", response);
        let arr = [];
        for (let i=0;i<response.data.length;i++) {
          if(response.data[i].status == 1) {
            arr.push(response.data[i]);
          }
        }
        setServices(arr);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // console.log("here");
    retrieveAllDiscount();
    retrieveUserDiscounts();
    retrieveServices();
  }, []);


  const handleStatus = (e) => {
    setStatus(e.target.value);
  }

  const handleAllStatus = (e) => {
    setAllStatus(e.target.value);
  }

  //meta title
  document.title = "Discounts | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Discounts" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <Link to={"/discount/add"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add New Discount</button></Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">All Discount</h4>
              <DataTable columns={allColumns} data={allData} customStyles={customStyles} pagination={false}
                         highlightOnHover={true}/>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">User Discounts</h4>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter User Discounts...`}
            >
              <DataTable columns={columns} data={data} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Edit Discount
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditService();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Service</Label>
                  <input type="text" className="form-control" disabled={true} value={services.some(el => el.id == service_id)?services[services.findIndex(el => el.id == service_id)].name:""}/>
                  {/*<select*/}
                  {/*  name={"service_id"}*/}
                  {/*  className="form-select"*/}
                  {/*  value={service_id}*/}
                  {/*  onChange={(e) => setServiceId(e.target.value)}*/}
                  {/*  // required={true}*/}
                  {/*>*/}
                  {/*  <option selected={true} value={0}>All Services</option>*/}
                  {/*  {services.map((part, id) => {*/}
                  {/*    return (<option key={id} value={part.id}>ID #{part.id} - {part.name}</option>);*/}
                  {/*  })};*/}
                  {/*</select>*/}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Discount Type</Label>
                  <select
                    className="form-select"
                    name="discountType"
                    value={discountType}
                    onChange={handleDiscountType}
                    required={true}
                  >
                    {/*<option>None</option>*/}
                    <option value={"Flat"}>Flat</option>
                    <option value={"Percent"}>Percent</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Below 1lb. Amount</Label>
                  <Input
                    name="amount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Below 1lb. Amount"
                    type="number"
                    step="any"
                    value={amountUnderOne}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAmountUnderOne(e.target.value)}
                    max={discountType==="Percent"?100:""}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">(1lb. to 5lb.) Amount</Label>
                  <Input
                    name="amount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount (1lb. to 5lb.) Amount"
                    type="number"
                    step="any"
                    value={amount}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAmount(e.target.value)}
                    max={discountType==="Percent"?100:""}
                    required={true}
                  />
                </div>
                {/*<div className="mb-3">*/}
                {/*  <Label className="form-label">Discount Type (Above 5 lb.)</Label>*/}
                {/*  <select*/}
                {/*    className="form-select"*/}
                {/*    name="discountType"*/}
                {/*    value={discountTypeAboveFive}*/}
                {/*    // onChange={validation.handleChange}*/}
                {/*    onChange={(e) => setDiscountTypeAboveFive(e.target.value)}*/}
                {/*    required={true}*/}
                {/*  >*/}
                {/*    /!*<option>None</option>*!/*/}
                {/*    <option value={"Flat"}>Flat</option>*/}
                {/*    <option value={"Percent"}>Percent</option>*/}
                {/*  </select>*/}
                {/*</div>*/}
                <div className="mb-3">
                  <Label className="form-label">Above 5lb. Amount</Label>
                  <Input
                    name="amount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Above 5lb. Amount"
                    type="number"
                    step="any"
                    value={amountAboveFive}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAmountAboveFive(e.target.value)}
                    max={discountType==="Percent"?100:""}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Above 20lb. Amount</Label>
                  <Input
                    name="amount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Above 20lb. Amount"
                    type="number"
                    step="any"
                    value={amountAboveTwenty}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setAmountAboveTwenty(e.target.value)}
                    max={discountType==="Percent"?100:""}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Status</Label>
                  <select
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={handleStatus}
                    required={true}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}


        {/*Modal*/}
        <Modal
          isOpen={allModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setAllModal(!allModal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setAllModal(!allModal);
              }}
            >
              Edit All Discount
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationAll.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Discount Type</Label>
                  <select
                    className="form-select"
                    name="discountType"
                    value={allDiscountType}
                    onChange={handleAllDiscountType}
                  >
                    <option value={"Flat"}>Flat</option>
                    <option value={"Percent"}>Percent</option>
                  </select>
                </div>

                <div className="mb-3">
                  <Label className="form-label">Below 1lb. Amount</Label>
                  <Input
                    name="amountUnderOne"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Below 1lb. Amount"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={validationAll.handleChange}
                    onBlur={validationAll.handleBlur}
                    value={validationAll.values.amountUnderOne || ""}
                    max={allDiscountType==="Percent"?100:""}
                    required={true}
                    invalid={
                      validationAll.touched.amountUnderOne && validationAll.errors.amountUnderOne ? true : false
                    }
                  />
                  {validationAll.touched.amountUnderOne && validationAll.errors.amountUnderOne ? (
                    <FormFeedback type="invalid">{validationAll.errors.amountUnderOne}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">(1lb. to 5lb.) Amount</Label>
                  <Input
                    name="amount"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount (1lb. to 5lb.) Amount"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={validationAll.handleChange}
                    onBlur={validationAll.handleBlur}
                    value={validationAll.values.amount || ""}
                    max={allDiscountType==="Percent"?100:""}
                    required={true}
                    invalid={
                      validationAll.touched.amount && validationAll.errors.amount ? true : false
                    }
                  />
                  {validationAll.touched.amount && validationAll.errors.amount ? (
                    <FormFeedback type="invalid">{validationAll.errors.amount}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Above 5lb. Amount</Label>
                  <Input
                    name="amountAboveFive"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Above 5lb. Amount"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={validationAll.handleChange}
                    onBlur={validationAll.handleBlur}
                    value={validationAll.values.amountAboveFive || ""}
                    max={allDiscountType==="Percent"?100:""}
                    required={true}
                    invalid={
                      validationAll.touched.amountAboveFive && validationAll.errors.amountAboveFive ? true : false
                    }
                  />
                  {validationAll.touched.amountAboveFive && validationAll.errors.amountAboveFive ? (
                    <FormFeedback type="invalid">{validationAll.errors.amountAboveFive}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Above 20lb. Amount</Label>
                  <Input
                    name="amountAboveTwenty"
                    className="form-control non-arrow-number"
                    placeholder="Enter Discount Above 20lb. Amount"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={validationAll.handleChange}
                    onBlur={validationAll.handleBlur}
                    value={validationAll.values.amountAboveTwenty || ""}
                    max={allDiscountType==="Percent"?100:""}
                    required={true}
                    invalid={
                      validationAll.touched.amountAboveTwenty && validationAll.errors.amountAboveTwenty ? true : false
                    }
                  />
                  {validationAll.touched.amountAboveTwenty && validationAll.errors.amountAboveTwenty ? (
                    <FormFeedback type="invalid">{validationAll.errors.amountAboveTwenty}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Status</Label>
                  <select
                    className="form-select"
                    name="status"
                    value={allStatus}
                    onChange={handleAllStatus}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
Discounts.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Discounts;