// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"


function PlaidPayments(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [pending, setPending] = useState(true);

  function capitalizeWords(str) {
    // split the string into an array of words
    let words = str.split(' ');

    // capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    // join the words back together into a string and return it
    return words.join(' ');
  }

  const columns = [
    {
      name: 'User Email',
      selector: row => row.email,
      width: "200px",
      // selector: 'email',
      //  sortable: true,
    },
    {
      name: 'Transaction ID',
      // selector: 'transaction_id',
      width: "200px",
      selector: row => row.transaction_id,
      //  sortable: true,
    },
    {
      name: 'Amount',
      // selector: 'transaction_amount',
      // transaction_amount
      //  sortable: true,
      width: "120px",
      selector: row => (row.transaction_amount) > 0 ? "$" + amountFormat(row.transaction_amount) : "-"
    },
    {
      name: 'Transaction By',
      // selector: 'transaction_by',
      //sortable: true,
      selector: row => row.transaction_by,
      width: "300px",
      // cell: row => JSON.parse(row.json).payments.length > 0 ? JSON.parse(row.json).payments[0].value.crypto.amount + " " + JSON.parse(row.json).payments[0].value.crypto.currency : "-"
    },
    {
      name: 'Updated At',
      // selector: 'transaction_by',
      sortable: true,
      width: "170px",
      selector: (row) => row.status == 1 ? moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY') : moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Auth Status',
      // selector: 'auth_status',
      sortable: true,
      width: "180px",
      // cell: row => row.transaction_status == "pending" ? <span className={"badge badge-warning"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:(row.transaction_status == "charge:failed"?<span className={"badge badge-danger"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:(row.transaction_status == "charge:confirmed"?<span className={"badge badge-success"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:<span className={"badge badge-light"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>))
      selector: row => row.auth_status == "declined" ? <span className={"badge badge-danger"}>Declined</span> : <span className={"badge badge-success"}>Approved</span>
    },
    {
      name: 'Transaction Status',
      // selector: 'transaction_status',
      sortable: true,
      width: "180px",
      // cell: row => row.transaction_status == "pending" ? <span className={"badge badge-warning"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:(row.transaction_status == "charge:failed"?<span className={"badge badge-danger"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:(row.transaction_status == "charge:confirmed"?<span className={"badge badge-success"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>:<span className={"badge badge-light"}>{capitalizeWords(row.transaction_status.replaceAll(":"," "))}</span>))
      selector: row => row.transaction_status === null ? <span className=""></span> : (row.transaction_status == "returned" || row.transaction_status == "pending" || row.transaction_status == "failed") ? <span className={"badge badge-danger"}>{row.transaction_status}</span> : (row.transaction_status === "posted") ? <span className={"badge badge-warning"}>{row.transaction_status}</span> : <span className={"badge badge-success"}>{row.transaction_status}</span>
    },
    {
      name: 'Actions',
      // selector: 'id',
      width: "200px",
      selector: (row) => row.status == 1 ? <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleCheckPaymentStatus(row.id)}>Check Payment Status</button> : ""
    },
  ];
  // const tableData = ;
  const retrievePaymentLogs = async () => {
    try {
      let arr = [];
      get('/user/get-transactions-plaid', { headers }).then((response) => {
        console.log("data data", response);
        for (let i = 0; i < response.data.length; i++) {
          arr.push(response.data[i]);
        }
        setFilteredData(arr);
        let data = arr;
        console.log("table data", { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleCheckPaymentStatus = async (id) => {
    try {
      //let arr = [];
      const body = {
        transaction_id: id
      };
      post('/user/check-plaid-transfer-status', body, { headers }).then((response) => {
        console.log("data", response);
        if (response.status === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        retrievePaymentLogs();
      })
    }
    catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    retrievePaymentLogs();
  }, []);

  //meta title
  document.title = "Plaid Payments | NullShip";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Plaid Payments" />
        {/* <Card>
          <CardBody>
            <Link to={"/route-name"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Button Text</button></Link>
          </CardBody>
        </Card> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Plaid Payments...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
PlaidPayments.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default PlaidPayments;