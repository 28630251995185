const customStyles = {
  headCells: {
    style: {
      border:"1px solid #F6F6FA",
      background:"#F6F6FA",
      // overflow:"scroll"
    },
  },
  cells: {
    style: {
      // background:"red"
    },
  },
  rows: {
    style: {
      // overflow:"auto"
    },
  },
  // headRow: {
  //   style: {
  //     // backgroundColor: 'red !important', // Change to your desired color
  //     overflowX:"scroll !important",
  //     width:"90% !important",
      
  //     // maxWidth:"full !important",

  //   },
  // },
};
export default customStyles;